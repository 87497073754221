﻿<!--suppress HtmlFormInputWithoutLabel -->

<template>
    <div v-bind="fieldAttrs">
        <slot name="label">
            <aunoa-form-label v-if="label" v-bind="labelAttrs" />
        </slot>

        <div v-bind="inputGroupAttrs">
            <div class="form-control form-control-sm embed-input embed-button enable-focus-border d-flex align-items-center">
                <input ref="inputElement" type="text" :value="value" readonly />
                <button class="btn" tabindex="-1" @click="onSelect">
                    <i class="fad fa-clipboard pe-2" />
                    <span v-text="t('Aunoa.Edit.Command.Copy')" />
                </button>
            </div>
        </div>
        <aunoa-error-message v-bind="errorMessageAttrs" />
    </div>
</template>

<script lang="ts">

import {fieldProps, useField} from "../../implementations/forms/useField";
import AunoaErrorMessage from "./AunoaErrorMessage.vue";
import {useAunoaI18n} from "../../utils/useAunoaI18n";
import AunoaFormLabel from "./AunoaFormLabel.vue";
import {useToasts} from "../../utils/useToasts";
import {defineComponent, ref} from "vue";

export default defineComponent({
    name: "AunoaCopyTextBox",
    inheritAttrs: false,
    components: {AunoaFormLabel, AunoaErrorMessage},
    props: {
        ...fieldProps,
        modelValue: {
            type: String,
        }
    },
    setup(props, ctx) {

        const inputElement = ref<HTMLInputElement>()

        const {t} = useAunoaI18n()
        const {addSuccessToast} = useToasts();
        const field = useField(props as any, ctx, {})

        const copy = (input: HTMLInputElement) => {
            input.focus();
            input.select();
            const result = document.execCommand("copy");
            if (result) {
                addSuccessToast({title: t("Aunoa.Operation.ClipboardCopy.Success")});
            }
            setTimeout(()=>{
                input.setSelectionRange(null,null);
                input.blur();                
            }, 50);
        }

        const onSelect = () =>
            inputElement.value && copy(inputElement.value);
        
        return {
            t,
            ...field,
            inputElement,
            onSelect
        }
    }
});

</script>
