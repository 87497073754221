﻿import type {Ref} from "vue";
import type {AccessToken} from "./types";
import type {AxiosInstance} from "axios";
import type {ApiError} from "./base/error";

import {interceptAxiosActivities} from "./base/useAxiosActivities";
import {useJsonEndpoint} from "./base/useJsonEndpoint";
import {useXmlEndpoint} from "./base/useXmlEndpoint";
import {useMateBaseUrl} from "./base/useMateBaseUrl";
import {useEndpoint, createAuthorizationHeader, createAcceptLanguageHeader} from "./base/useEndpoint";
import {createRedHeaders} from "./base/scope";
import {useLocale} from "./useLocale";
import {unref, ref} from "vue";
import axios from "axios";

export const useMateApi = (
    accessToken: AccessToken,
    tenantOid?: number | Ref<number>,
    locationRoleOid?: number | Ref<number>,
    terminalOid?: number | Ref<number>
) => {

    const {locale} = useLocale();
    const {baseUrl} = useMateBaseUrl();
    const lastApiError = ref<ApiError>();

    const axiosInstance: AxiosInstance = axios.create({baseURL: baseUrl});

    const {eject} = interceptAxiosActivities(axiosInstance);
    // TODO: LGB, find a way to handle this correct without warnings. 
    //onBeforeUnmount(() => eject());
    
    const getUri = (url: string, params: any) => axios.getUri({url, params: params})
    
    const authorizationHeader = () => createAuthorizationHeader(<string>unref(accessToken));
    const acceptLanguageHeader = () => createAcceptLanguageHeader(unref(locale));

    const baseHeaders = () => ({
        ...authorizationHeader(),
        ...acceptLanguageHeader(),
        ...createRedHeaders(unref(tenantOid), unref(locationRoleOid), unref(terminalOid))
    });

    const endpoint = (url: string | Ref<string>) =>
        useEndpoint(axiosInstance, url, baseHeaders, lastApiError, accessToken);

    const jsonEndpoint = (url: string | Ref<string>, preserveScope?: boolean) =>
        useJsonEndpoint(axiosInstance, url, baseHeaders, lastApiError, accessToken, preserveScope);

    const xmlEndpoint = (url: string | Ref<string>) =>
        useXmlEndpoint(axiosInstance, url, baseHeaders, lastApiError, accessToken);

    return {
        getUri,
        baseUrl,
        authorizationHeader,
        acceptLanguageHeader,
        axiosInstance,
        jsonEndpoint,
        xmlEndpoint,
        endpoint
    }
};