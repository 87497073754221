﻿import type {CompressedScopeItem, Scope, ScopeItem, ScopeID} from "@tnt/mate-abstractions";
import {ScopeVisibility} from "@tnt/mate-abstractions";

const INDEX = 0;
const PARENT_INDEX = 1;
const ID = 2;
const TYPE = 3;
const OID = 4;
const TEXT = 5;
const DESCRIPTION = 6;
const DISABLED = 7;
const DELETED = 8;

interface IDictionary {
    [index: number]: ScopeItem;
}

const globalScope: Scope = {
    type: 0,
    id: "GLOBAL",
    name: "Global",
    bgClassName: "bg-scope-global",
    getCountryCode: (_item: ScopeItem) => "",
    getCountryText: (_item: ScopeItem) => "",
    getLocationText: (_item: ScopeItem) => ""
}

const countryScope: Scope = {
    type: 1,
    id: "COUNTRY",
    name: "Country",
    bgClassName: "bg-scope-country",
    getCountryCode: (item: ScopeItem) => `${item.id}`,
    getCountryText: (item: ScopeItem) => `${item.id}`,
    getLocationText: (_item: ScopeItem) => ""
}

const tenantScope: Scope = {
    type: 2,
    id: "TENANT",
    name: "Tenant",
    bgClassName: "bg-scope-tenant",
    getCountryCode: (item: ScopeItem) => countryScope.getCountryCode(item.parent),
    getCountryText: (item: ScopeItem) => `${item.id} (${countryScope.getCountryText(item.parent)})`,
    getLocationText: (_item: ScopeItem) => ""
}

const locationScope: Scope = {
    type: 3,
    id: "LOCATION",
    name: "Location",
    bgClassName: "bg-scope-location",
    getCountryCode: (item: ScopeItem) => tenantScope.getCountryCode(item.parent),
    getCountryText: (item: ScopeItem) => tenantScope.getCountryText(item.parent),
    getLocationText: (item: ScopeItem) => `${item?.id}`
}

const terminalScope: Scope = {
    type: 4,
    id: "TERMINAL",
    name: "Terminal",
    bgClassName: "bg-scope-terminal",
    getCountryCode: (item: ScopeItem) => locationScope.getCountryCode(item.parent),
    getCountryText: (item: ScopeItem) => locationScope.getCountryText(item.parent),
    getLocationText: (item: ScopeItem) => `${locationScope.getLocationText(item.parent)}.${item.id}`
}

export const scopes: Record<string, Scope> = {
    "GLOBAL": globalScope,
    "COUNTRY": countryScope,
    "TENANT": tenantScope,
    "LOCATION": locationScope,
    "TERMINAL": terminalScope
}

const decompressScopeItem = (compressedScopeItem: CompressedScopeItem, scope: Scope, parents: IDictionary): ScopeItem => {
    const parentIndex = compressedScopeItem[PARENT_INDEX] || 0;
    const parent = parents[parentIndex];
    const item = {
        index: compressedScopeItem[INDEX],
        scope: scope,
        oid: compressedScopeItem[OID],
        id: compressedScopeItem[ID],
        text: compressedScopeItem[TEXT],
        description: compressedScopeItem[DESCRIPTION],
        parent: parent,
        children: [],
        visibility: compressedScopeItem[DELETED]
            ? ScopeVisibility.Deleted
            : compressedScopeItem[DISABLED]
                ? ScopeVisibility.Disabled
                : ScopeVisibility.Visible,
        countryCode: () => "",
        countryFlag: () => "",
        countryText: () => "",
        locationText: () => ""
    } as ScopeItem;
    if (parent) {
        parent.children.push(item);
    }
    item.countryCode = () => scope.getCountryCode(item);
    item.countryFlag = () => `flag-icon-${scope.getCountryCode(item)}`.toLowerCase();
    item.countryText = () => scope.getCountryText(item);
    item.locationText = () => scope.getLocationText(item);
    return item;
};

export const decompressScopeItems = (compressedScopeItems: CompressedScopeItem[], parents: ScopeItem[], type: ScopeID): ScopeItem[] => {
    const scope = scopes[type];
    const dict: IDictionary = {};
    parents.forEach(i => dict[i.index] = i);
    return compressedScopeItems
        .filter(i => i[TYPE] === scope.type)
        .map(i => decompressScopeItem(i, scope, dict));
};

