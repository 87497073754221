﻿import type {PaneSubscription} from "./usePaneSubscription";

import {inject, InjectionKey, provide} from "vue";

interface ContentSubscription extends PaneSubscription {
    setSortedTabIds(ids: string[]): void;
}

const INJECTION_KEY: InjectionKey<ContentSubscription> = Symbol("AUNOA_CONTENT_SUBSCRIPTION");

export const provideContentSubscription = (contentSubscription:ContentSubscription) => {

    provide(INJECTION_KEY, contentSubscription);
}

export const useContentSubscription = () => {
    const contentSubscription = inject(INJECTION_KEY, undefined);

    return {
        contentSubscription
    }
}