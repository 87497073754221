﻿<template>
    <nav class="navbar navbar-command navbar-expand" :class="{'d-none':!(commands+endCommands)}">
        <aunoa-container>
            <ul class="navbar-nav" id="commands" />
            <ul class="navbar-nav ms-auto" id="endCommands" />
        </aunoa-container>
    </nav>
</template>

<script lang="ts">

import {defineComponent, watch, onMounted, onBeforeUnmount} from "vue";
import AunoaContainer from "../components/AunoaContainer.vue";
import {usePageHost} from "../utils/usePageHost";

export default defineComponent({
    name: "AunoaTitleBar",
    components: {
        AunoaContainer,
    },
    props: {},
    setup: () => {

        const {pageHost} = usePageHost();
        const {commands} = pageHost;
        const {endCommands} = pageHost;

        watch([commands, endCommands], ([c, ec]) => {
            document.body.classList.toggle("has-command-bar", c + ec)
        }, {immediate: true});

        return {
            commands,
            endCommands
        }
    }
});

</script>
