interface ScopeMappingItem {
    m1: number;
    m2: number;
    name: string;
    prop: string;
}

interface $$scope {
    tenantOid?: number;
    locationRoleOid?: number;
    terminalOid?: number;
}

const RED_SCP_TNT = "red-scp-tnt";
const RED_SCP_LTN = "red-scp-ltn";
const RED_SCP_TML = "red-scp-tml";

const scopeMapping: Record<string, ScopeMappingItem> = {
    [RED_SCP_TNT]: {m1: 97, m2: 67, name: "Red-Scp-Tnt", prop: "tenantOid"},
    [RED_SCP_LTN]: {m1: 89, m2: 73, name: "Red-Scp-Ltn", prop: "locationRoleOid"},
    [RED_SCP_TML]: {m1: 71, m2: 47, name: "Red-Scp-Tml", prop: "terminalOid"}
};

export const create$$scope = (headers: any) =>
    <$$scope>Object.keys(headers)
        .reduce((scope, name) => {
            const map = scopeMapping[name]
            if (map) {
                const value = parseInt(headers[name], 16);
                scope[map.prop] = (value + map.m2) / map.m1;
            }
            return scope;
        }, <Record<string,number>>{})

const createRedHeader = (map: ScopeMappingItem, value: number | undefined) =>
    value ? {[map.name]: (value * map.m1 - map.m2).toString(16)} : undefined;

export const createRedHeaders = (tenantOid?: number, locationRoleOid?: number, terminalOid?: number) => ({
    ...createRedHeader(scopeMapping[RED_SCP_TNT], tenantOid),
    ...createRedHeader(scopeMapping[RED_SCP_LTN], locationRoleOid),
    ...createRedHeader(scopeMapping[RED_SCP_TML], terminalOid)
})
