﻿import type {Ref} from "vue";
import type {User} from "../mixins/UserMixin";
import type {RouteLocation} from "vue-router";

import {useLocalStorage} from "./storage/useLocalStorage";
import {singleton} from "./singletons";

interface PageStorage {
    path: string;
}

interface Storage {
    version: number;
    storedAt: Date;
    pages: PageStorage[];
}

const createStorage = (pages: PageStorage[]): Storage => ({
    version: 1,
    storedAt: new Date(),
    pages: pages
})

const useStorage = (user: User) => singleton(`AUNOA_FAVORITES_STORAGE_${user?.id}`, () => {
    const storage = useLocalStorage(user).items("favorites");
    const pages = <Ref<Storage>>storage.subscribeObject<Storage>("pages", createStorage([]));

    const getPages = () => pages.value?.pages || [];
    const getPathes = () => getPages().map(p => p.path);

    return {
        pages,
        getPages,
        getPathes
    }
});

export const provideFavoritePages = (user: User) => {

    const max = 10;
    const {pages, getPathes, getPages} = useStorage(user);

    const otherPages = (route: RouteLocation) =>
        getPages()
            .filter(p => p.path !== route.path)
            .filter((p, i) => i < max);

    const addRoute = (route: RouteLocation) =>
        pages.value = createStorage([...otherPages(route), {path: route.path}]);

    const removeRoute = (route: RouteLocation) =>
        pages.value = createStorage([...otherPages(route)]);

    const containsRoute = (route: RouteLocation) => getPathes().includes(route.path);

    return {
        addRoute,
        removeRoute,
        containsRoute
    }
}

export const useFavoritePages = (user: User) => {

    const {getPathes} = useStorage(user);

    return {
        getPathes
    }
}