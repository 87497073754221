import type {ModalSize} from "bootstrap-aunoa";
import type {LanguageMode} from "@bootstrap-aunoa/codemirror";

import {ref} from "vue";


export interface ModalOptions {
    readOnly?: any;
    lineNumbers?: boolean;
    lineWrapping?: boolean;
    languageMode: LanguageMode;
    content: string;
    modalSize: ModalSize;
    modalIcon?: string;
    modalTitle?: string;  
}

export interface ModalCodeEditor {
    open(options: ModalOptions): Promise<void>
}


const modalCodeEditor = ref<ModalCodeEditor>();

export const provideModalCodeEditor = () => {
    return {
        modalCodeEditor
    }
}

export const useModalCodeEditor = () => {

    const open = (options: ModalOptions): Promise<void> => 
        modalCodeEditor.value?.open(options) || Promise.reject("Modal Code Editor not provided")

    return {
        open
    }

}