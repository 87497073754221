﻿<template>
    <img :src="url" :alt="alt" @load="onLoad" @error="onError" />
</template>

<script lang="ts">

import type {PropType} from "vue";

import {defineComponent, computed, toRefs} from "vue";
import md5 from "md5";

const hashedEmailAddress = (emailAddress: string) =>
    md5(emailAddress?.trim().toLowerCase() || "");


export type DefaultGravatarImage = "404" | "mp" | "identicon" | "monsterid" | "wavatar" | "retro" | "robohash" | "blank";

export default defineComponent({
    name: "AunoaGravatar",
    props: {
        emailAddress: {
            type: String,
            default: ""
        },
        hash: {
            type: String,
            default: ""
        },
        size: {
            type: Number,
            default: 64
        },
        defaultImg: {
            type: String as PropType<DefaultGravatarImage>,
            default: "blank"
        },
        rating: {
            type: String,
            default: "g"
        },
        alt: {
            type: String,
            default: "Avatar"
        },
        protocol: {
            type: String,
            default: ""
        }
    },
    emits: ["load", "error"],
    setup(props, {emit}) {

        const {protocol, emailAddress, hash, size, defaultImg, rating} = toRefs(props);
        const prot = computed(() =>
            protocol.value.slice(-1) === ':'
                ? protocol.value
                : `${protocol.value}:`);

        const url = computed(() =>
            [
                `${prot.value === ':' ? '' : prot.value}//www.gravatar.com/avatar/`,
                hash.value || hashedEmailAddress(emailAddress.value),
                `?s=${size.value}`,
                `&d=${defaultImg.value}`,
                `&r=${rating.value}`
            ].join(""));

        const onLoad = (...args: any[]) => emit("load", ...args);
        const onError = (...args: any[]) => emit("error", ...args);

        return {
            url,
            onLoad,
            onError
        }
    }
});

</script>

