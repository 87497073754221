﻿import {computed, inject, InjectionKey, provide, ref, Ref, unref, watch} from "vue";
import {getValueFromPropertyPath} from "../../utils/properties";
import {Validation} from "@vuelidate/core";
import {WatchStopHandle} from "@vue/runtime-core";

const INJECTION_KEY: InjectionKey<Ref<Validation>> = Symbol("AUNOA_FORM_VALIDATION");

export const provideValidation = (v: Ref<Validation>) => {

    provide(INJECTION_KEY, v);
}

export const useFormValidation = () => {

    const v = inject(INJECTION_KEY);

    const validationErrorCount = computed(() => v?.value?.$errors.length || 0);

    return {
        validationErrorCount,
        ...v
    }
}

export const useFormValidationOptional = (enabled: Ref<boolean>) => {
    const validationErrorCount = ref(0);

    let stopHandle: WatchStopHandle | undefined = undefined;

    watch(enabled, newEnabled => {
        if (stopHandle) {
            stopHandle();
            stopHandle = undefined;
        }
        if (newEnabled) {
            const {validationErrorCount: count} = useFormValidation();
            stopHandle = watch(count, v => validationErrorCount.value = v, {immediate: true});
        }
    }, {immediate: true});

    return {
        validationErrorCount
    }
}


export const useFieldValidation = (property: string | Ref<string>) => {

    const validationRef = inject(INJECTION_KEY, () => undefined, true);

    const fieldValidation = computed(() =>
        unref(validationRef) && unref(property)
            ? getValueFromPropertyPath(unref(validationRef), unref(property))
            : undefined);

    return {
        v: validationRef,
        fieldValidation
    }
}