﻿import type {Tables} from "../types";

import {buildArrayProperty, buildObjectProperty} from "../utils/properties";
import {isUndefined} from "../utils/inspect";
import {createGridModel} from "./gridModel";


export interface DetailsModelOptions {
    locale?: string;
    compress?: boolean;
}

interface DetailsModelContext {
    options: DetailsModelOptions;
    //columns: {
    //    createModel(): Tables.Model.Column
    //}[];
}


const createDetailsGridModel = <TEntity = any, TState = any, TEntityState = any>(property: string) => {

    let title:string;
    
    const base = createGridModel();
    const grid: Tables.Builder.DetailsGrid<TEntity, TState, TEntityState> = {
        ...base,
        Title: value => set(() => title = value),
        Column: (options: (col: Tables.Builder.Column) => void) => {
            base.Column(options);
            return grid;
        }
    }

    const set = (action: () => void) => {
        action();
        return grid;
    }

    return {
        grid,
        build: () => ({
            title,
            grid: property,
            ...base.Build(),
        })
    };
}


export const createDetailsModel = <TEntity = any, TState = any, TEntityState = any>(options?: DetailsModelOptions):
    Tables.Builder.Details<TEntity, TState, TEntityState> => {

    const context: DetailsModelContext = {
        options: options || {},
        //columns: []
    }

    context.options.compress = isUndefined(context.options.compress)
        ? true
        : context.options.compress;

    const builders: any[] = [];

    const details: Tables.Builder.Details<TEntity, TState, TEntityState> = {
        Grid: (getProperty: (o: any) => any, options: (grid: Tables.Builder.DetailsGrid) => void) => {
            const ar: string[] = ["Entity"];
            const builder = buildArrayProperty(ar);
            getProperty?.(builder);
            const property = ar.join(".");
            const grid = createDetailsGridModel(property);
            options?.(grid.grid);
            builders.push(grid.build);
            return details;
        },
        SingleGrid: (getProperty: (o: any) => any, options: (grid: Tables.Builder.DetailsGrid) => void) => {
            const ar: string[] = ["Entity"];
            const builder = buildObjectProperty(ar);
            getProperty?.(builder);
            const property = ar.join(".");
            const grid = createDetailsGridModel(property);
            options?.(grid.grid);
            builders.push(grid.build);
            return details;
        },
        Build: () => (builders.map(build => build()))
    }

    return details
};
