﻿import type {PropType} from "vue";
import type {Entities, Tables} from "bootstrap-aunoa";
import {defineComponent, h} from "vue";
import {
    ensureResolvedPropertyPath, getValueFromPropertyPath,
    decorate, formatNumber, formatDate,
    getCurrencyFormatSegments, toCurrencyAmount,
    isArray, isDefined, isPromise, isString, isUndefinedOrNull,
    isStartContent, isCenterContent, isEndContent, isJustifyContent, isLoopContent,
    resolveCondition, toCase, useAunoaI18n,
    AunoaLookupSpan, AunoaGravatar, isUndefined
} from "bootstrap-aunoa";

const iconRegex = /^(fa[tlrsdb]|cc-icon|flag-icon)/;

const createInnerEntityContexts = (
    entityContext: Entities.EntityContext,
    arrayProperty: string,
    max?: number
) => {
    let ar = getValueFromPropertyPath(entityContext, arrayProperty) as any[];
    if (isArray(ar)) {
        if (max) {
            ar = ar.filter((v, i) => i < max);
        }
        return ar.map(o => ({
            ...entityContext,
            Entity: o,
        }));
    }
    return [];
}

const createSpanFromLookup = (
    lookup: Tables.Model.SpanLookup,
    entityContext: Entities.EntityContext,
    value: any | undefined,
    decoration?: string[]
) => {
    const lookups = entityContext.Lookups;
    const toDisplayCase = (text: any) => text && lookup.displayCasing
        ? toCase(text, lookup.displayCasing)
        : (text && text.text ? text.text : text);

    if (lookups?.[lookup.dataSource]) {
        const resolved = lookups[lookup.dataSource].resolve(value, entityContext.Entity);

        if (isPromise(resolved)) {
            return h(AunoaLookupSpan, {
                resolved: resolved,
                casing: lookup.displayCasing,
                decoration: decoration,
            });
        } else {
            return h("span", toDisplayCase(resolved));
        }
    }

    return h("span", {class: "text-danger"}, `Lookup '${lookup.dataSource}' missing!`);
}

const styleDict = {
    "Small": "small",
    "Bold": "font-weight-bold",
    "Muted": "text-muted"
}

const renderSpan = (
    span: Tables.Model.Span,
    entityContext: Entities.EntityContext,
    conditions: any[][],
    ensureTextTranslated: ((t: string) => string)
) => {

    if (!span) {
        return undefined;
    }

    if (span.if) {
        const bool = resolveCondition(entityContext, span.if);
        conditions.push([bool]);
        return undefined;
    } else if (span.elseIf) {
        const bools = conditions.pop();
        if (bools && isArray(bools)) {
            if (bools.every(b => !b)) {
                const bool = resolveCondition(entityContext, span.elseIf);
                bools.push(bool);
            } else {
                bools.push(false);
            }
            conditions.push(bools);
        }
        return undefined;
    } else if (span.else) {
        const bools = conditions.pop();
        if (bools && isArray(bools)) {
            if (bools.every(b => !b)) {
                bools.push(true);
            } else {
                bools.push(false);
            }
            conditions.push(bools);
        }
        return undefined;
    } else if (span.endIf) {
        conditions.pop();
        return undefined;
    }

    if (conditions.length > 0 && conditions.some(bools => !bools[bools.length - 1])) {
        return undefined;
    }

    const classes:string[] = span.decoration?.length > 0 
        ? span.decoration.map(d => styleDict[d]).filter(Boolean) 
        : [];

    if (isString(span.content) && iconRegex.test(span.content)) {
        classes.push(span.content);
        return h("i", {
            class: classes
        });
    }

    let value = ensureResolvedPropertyPath(span.content, entityContext);
    if (isUndefinedOrNull(value) && span.fallback) {
        value = span.fallback;
    }

    if (span.lookup) {
        return createSpanFromLookup(span.lookup, entityContext, value, span.decoration);
    }

    if (isDefined(value)) {
        if (isDefined(span.numberOptions)) {
            value = formatNumber(value, span.numberOptions);
        } else if (span.dateFormat) {
            value = formatDate(value, span.dateFormat);
        } else if (span.currencyAmountOptions) {

            const ca = toCurrencyAmount(value, span.currencyAmountOptions, entityContext);
            if (isDefined(ca)) {
                classes.push("deco deco-currency-amount");
                return h("span", {class: classes}, getCurrencyFormatSegments(ca.amount, ca.currency).map(s => h("span", {class: s.class}, s.text)));
            }
        }
    }

    if (isUndefinedOrNull(value) && span.fallback) {
        value = span.fallback;
    }

    if (span.decoration && span.decoration.length === 1) {
        switch (span.decoration[0]) {
            case "Gravatar":
                return h(AunoaGravatar, {
                    "email-address": value,
                    "size": 48
                });
            case "Icon":
                classes.push(value);
                return h("i", {
                    "class": classes
                });
            case "Positions":
                classes.push("deco deco-positions");
                return isDefined(value)
                    ? h("span", {class: classes}, [
                        h("i", {class: "fal fa-fw fa-sm fa-list"}),
                        h("span", ` ${value}`)
                    ])
                    : undefined;
            case "QuantitySum":
                classes.push("deco deco-quantity-sum");
                return isDefined(value)
                    ? h("span", {class: classes}, [
                        h("i", {class: "fal fa-fw fa-sm fa-sigma fmt-icon"}),
                        h("span", {class: "fmt-space"}, " "),
                        h("span", {class: "fmt-value"}, value),
                        h("span", {class: "fmt-suffix"}, "×")
                    ])
                    : undefined;
        }
    }

    value = value && span.decoration
        ? span.decoration.reduce(decorate, value)
        : value;

    if (isArray(value)) {
        value = value.join(", ");
    }

    return h("span", {class: classes}, ensureTextTranslated(value));
}

const renderLine = (
    line: Tables.Model.Line,
    entityContext: Entities.EntityContext,
    conditions: any[][],
    ensureTextTranslated: ((t: string) => string)
) => {
    const renderSpans = (spans: Tables.Model.Span[]) => spans
        .map(span => renderSpan(span, entityContext, conditions, ensureTextTranslated))
        .filter(Boolean)

    if (isLoopContent(line)) {
        const list: any[] = [];
        createInnerEntityContexts(entityContext, line.array, line.max)
            .forEach(innerEntityContext =>
                line.lines
                    .forEach((innerLine: any) => list.push(renderLine(innerLine, innerEntityContext, conditions, ensureTextTranslated)))
            );
        return h("div", list);
    }

    if (isJustifyContent(line)) {
        return h("div", {class: "text-justify"}, [
            h("div", {class: "text-start"}, renderSpans(line.start)),
            h("div", {class: "text-end"}, renderSpans(line.end))
        ]);
    }

    if (isEndContent(line)) {
        return h("div", {class: "text-end"}, renderSpans(line.end));
    }

    if (isCenterContent(line)) {
        return h("div", {class: "text-center"}, renderSpans(line.center));
    }

    if (isStartContent(line)) {
        return h("div", renderSpans(line.start));
    }

    return h("div", {class: "text-danger"}, "Missing content");
};

export const renderCell = (
    lines: Tables.Model.Line[],
    entityContext: Entities.EntityContext,
    ensureTextTranslated: ((t: string) => string)
) => {
    const conditions: any[][] = [];
    return h("td", {class: "dg-cell"}, lines.map(line => renderLine(line, entityContext, conditions, ensureTextTranslated)));
}

export default defineComponent({
    name: "AunoaDataCell",
    components: {AunoaLookupSpan},
    props: {
        entityContext: {
            type: Object as PropType<Entities.EntityContext>,
            required: true,
        },
        lines: {
            type: Array as PropType<Tables.Model.Line[]>,
            required: true
        }
    },
    setup(props) {
        const {ensureTextTranslated} = useAunoaI18n();
        return () => renderCell(props.lines, props.entityContext, ensureTextTranslated);
    }
});
