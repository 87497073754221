﻿import {AccessToken} from "./types";
import {useMateApi} from "./useMateApi";
import axios from "axios";

export interface MateVersionInfo {
    version: string;
    buildDate: Date;
}

export interface TableInfo {
    name: string;
    schema: string;
    total: number;
    rowCount: number;
}

export interface TableInfoSummary {
    topTables: [TableInfo],
    count: number;
    total: number;
    rowCount: number;
}

export const useInfoApi = (accessToken: AccessToken) => {

    const {jsonEndpoint} = useMateApi(accessToken);
    const api = jsonEndpoint("/api");

    const getUiVersionInfo = () => axios.get<MateVersionInfo>("/version.json").then(result => result.data);
    
    const getApiVersionInfo = () => api.get<MateVersionInfo>({}, {url: "/info"});
    const getDatabaseUsage = () => api.get<TableInfo[]>({}, {url: "/db-info/database-usage"});
    const getDatabaseUsageSummary = (take = 5) => api.get<TableInfoSummary>({take: take}, {url: "/db-info/database-usage/summary"});
    //const getLogs = (take=1000) => api.get({take}, {url: "/logs"});

    return {
        //getLogs,
        getUiVersionInfo,
        getDatabaseUsage,
        getApiVersionInfo,
        getDatabaseUsageSummary
    }
}
