﻿

import {App} from "vue";
import AunoaForm from "./AunoaForm.vue";
import AunoaFormField from "./AunoaFormField.vue";
import AunoaFormGroup from "./AunoaFormGroup.vue";
import AunoaCurrencyAmountBox from "./AunoaCurrencyAmountBox.vue";
import {formPropsCoreOptions, formPropsOptions} from "../../utils/forms";

import AunoaDateBox from "./AunoaDateBox.vue";
import AunoaNumberBox from "./AunoaNumberBox.vue";
import AunoaSelectBox from "./AunoaSelectBox.vue";
import AunoaMultiSelectBox from "./AunoaMultiSelectBox.vue";
import AunoaTextBox from "./AunoaTextBox.vue";
import AunoaCopyTextBox from "./AunoaCopyTextBox.vue";


export interface FormComponentOptions {
}

export const installFormComponents = (app: App, options: FormComponentOptions) => {
    options = options || {};
    app
        .component(AunoaForm.name, AunoaForm)
        .component(AunoaFormField.name, AunoaFormField)
        .component(AunoaFormGroup.name, AunoaFormGroup)
        .component(AunoaCurrencyAmountBox.name, AunoaCurrencyAmountBox)
        .component(AunoaDateBox.name, AunoaDateBox)
        .component(AunoaNumberBox.name, AunoaNumberBox)
        .component(AunoaSelectBox.name, AunoaSelectBox)
        .component(AunoaMultiSelectBox.name, AunoaMultiSelectBox)
        .component(AunoaTextBox.name, AunoaTextBox)
        .component(AunoaCopyTextBox.name, AunoaCopyTextBox);
};

export {
    formPropsCoreOptions,
    formPropsOptions,
    
    AunoaForm,
    AunoaFormField,
    AunoaFormGroup,
    AunoaCurrencyAmountBox,
    AunoaDateBox,
    AunoaNumberBox,
    AunoaSelectBox,
    AunoaMultiSelectBox,
    AunoaTextBox,
    AunoaCopyTextBox
}

