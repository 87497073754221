﻿import {defineComponent, PropType} from "vue";

export interface User {
    id: string;
    username: string;
    emailAddress?: string;
}


export default defineComponent({
    props: {
        user: {
            type: Object as PropType<User>,
            default: undefined
        }
    }
});
