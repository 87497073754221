﻿import {useLocalStorage} from "./storage/useLocalStorage";
import {ref, watch, computed} from "vue";
import {singleton} from "./singletons";
import {locales} from "../core";

type CurrentThemeMode = "dark" | "light";
type ThemeMode = "auto" | CurrentThemeMode;
type FluidMode = "auto" | "full-width" | "boxed";

export type {
    ThemeMode,
    FluidMode
}

const setFluidClasses = (element: HTMLElement, isFluid: boolean) => {
    element.classList.toggle("container-xl", !isFluid);
    element.classList.toggle("container-fluid", isFluid);
    element.classList.toggle("mw-100", isFluid);
};

const transitionDuration = 250; // sync with _variables.scss

export const useAunoa = () => singleton("AUNOA_CORE", () => {
    const storage = useLocalStorage();
    const ui = storage.items("ui");

    const theme = ui.subscribeString<ThemeMode>("theme", "auto");
    const fluid = ui.subscribeString<FluidMode>("fluid", "auto");
    const currentTheme = ref<CurrentThemeMode>("light");

    watch(theme, newTheme => {
        document.body.dataset.theme = <string>newTheme;
        if (newTheme === "auto") {
            newTheme = <any>(getComputedStyle(document.body).getPropertyValue("--aunoa-theme").trim());
        }
        currentTheme.value = <any>newTheme;
    }, {immediate: true});

    const _isFluid = ref(false);
    const isFluid = computed(() => {
        switch (fluid.value) {
            case "full-width":
                return true;
            case "boxed":
                return false;
            default:
                return _isFluid.value;
        }
    });

    const isFluidReady = ref(false);
    watch(isFluid, f => setTimeout(() => isFluidReady.value = f, transitionDuration + 20));

    const getIsFluid = (value?: boolean) => value === true || value === false ? value : isFluid.value;

    const getFluidClasses = (isFluid?: boolean) => getIsFluid(isFluid)
        ? "container-fluid mw-100"
        : "container-xl";

    const defaultContainerClass = computed(() => getFluidClasses(isFluid.value));
    const setIsFluid = (value: boolean = true) => _isFluid.value = value;

    return {
        currentTheme,
        theme,
        fluid,
        locales,

        isFluid,
        isFluidReady,
        setIsFluid,

        getFluidClasses,
        setFluidClasses,
        transitionDuration,
        defaultContainerClass
    }
});