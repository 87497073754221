﻿<template>
    <thead ref="thead">
    <slot name="before-row" :columnClass="columnClass" />
    <tr :class="trClass">
        <slot name="before-cells" :columnClass="columnClass" />

        <slot :columns="columns" :columnClass="columnClass">
            <th
                v-for="({head, width}, index) in columns"
                :class="columnClass"
                :style="{width: width+'%'}">
                <button v-if="showCloseButton(index)" class="btn-close float-end" @click="onClose"/>
                <div v-for="title in titles(head)" v-text="ensureTextTranslated(title)" />
            </th>
        </slot>

        <slot name="after-cells" :columnClass="columnClass" />
    </tr>
    <slot name="after-row" :columnClass="columnClass" />
    </thead>
</template>

<script lang="ts">

import type {PropType} from "vue";
import type {Tables} from "bootstrap-aunoa";
import {useAunoaI18n, useElementSize} from "bootstrap-aunoa";
import {computed, defineComponent, watch} from "vue";

export default defineComponent({
    name: "AunoaDataHead",
    components: {},
    props: {
        gridModel: {
            type: Object as PropType<Tables.Model.Grid>,
            default: undefined
        },
        calcHeadHeight: {
            type: Boolean,
            default: false
        },
        canClose: {
            type: Boolean,
            default: false
        },
        trClass: {},
        columnClass: {}
    },
    emits: ["close"],
    setup(props, {emit}) {

        const columns = computed(() => props.gridModel?.columns || []);

        const {ensureTextTranslated} = useAunoaI18n();
        const {element: thead, contentRect} = useElementSize();

        watch([contentRect, thead], ([rect, element]) => {
            if (props.calcHeadHeight && element && element.parentElement) {
                element.parentElement.style.setProperty("--thead-height", rect.height + "px" );
            }
        });

        const titles = (head: Tables.Model.Head) =>
            typeof head.title === "string"
                ? [head.title]
                : head.title;

        const showCloseButton = (index: number) => props.canClose && index === columns.value.length - 1;

        const onClose = () => emit("close");

        return {
            ensureTextTranslated,
            showCloseButton,
            thead,
            columns,
            titles,
            onClose
        }
    }
});

</script>
