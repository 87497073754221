﻿import {isBoolean, isNumber, isString} from "./inspect";

const durationFactors: Record<string, number> = {
    ms: 1,
    s: 1000
}

export const convertDuration = (time: number | string):number => {
    const [value, type = "ms"] = String(time).split(/(ms|s)/i)
    return Number(value) * durationFactors[type]
};

export const getDuration = (value: any, defaultDuration: number): number => {
    if (isNumber(value)) {
        return value;
    }
    if (isString(value)) {
        return convertDuration(value);
    }
    if (isBoolean(value)) {
        return value ? defaultDuration : 0;
    }
    return defaultDuration;
}
