﻿<template>
    <div v-bind="attrs">
        <slot name="before-links" />
        <slot :tabInfos="tabInfos">
            <aunoa-tab-nav-link
                v-for="(tabInfo,i) in tabInfos"
                :class="navLinkClass"
                :tab-info="tabInfo"
                :tab-id="tabInfo.id"
                :icon="tabInfo.icon"
                :text="tabInfo.linkText"
                :error-badge="tabInfo.fieldErrorCount"
                @show-tab="$emit('show-tab', i)"
                @tab-shown="onShown(tabInfo, i)"
                @hide-tab="$emit('hide-tab', i)"
                @tab-hidden="$emit('tab-hidden', i)"
            />
        </slot>
        <button
            v-if="0"
            class="nav-link text-end"
            type="button"
            role="tab"
            @click.prevent="collapse">
            <i class="fat fa-angle-double-up fa-sm" />
        </button>
        <slot name="after-links" />
    </div>
</template>

<script lang="ts">

import type {PropType} from "vue";
import type {TabInfo} from "./usePaneSubscription";

import {getVisibilityEventNames} from "../../utils/useVisibilityEvents";
import {computed, defineComponent, ref, toRefs, watch} from "vue";
import AunoaTabNavLink from "./AunoaTabNavLink.vue";

export type NavDirection = "horizontal" | "vertical";
export type Mode = "tabs" | "embedded-tabs";

const classMap: Record<Mode, string> = {
    "tabs": "nav-tabs",
    "embedded-tabs": "nav-tabs nav-tabs-embedded",
}

export default defineComponent({
    name: "AunoaTabNav",
    inheritAttrs: false,
    components: {
        AunoaTabNavLink
    },
    props: {
        direction: {
            type: String as PropType<NavDirection>,
            default: "horizontal"
        },
        mode: {
            type: String as PropType<Mode | undefined>,
            default: undefined
        },
        canToggleMode: {
            type: Boolean,
            default: true
        },
        tabInfos: {
            type: Object as PropType<TabInfo[]>,
            default: undefined
        },
        activeIndex: {
            type: Number,
            default: 0
        },
        navLinkClass: {
            default: undefined
        }
    },
    emits: ["update:activeIndex", ...getVisibilityEventNames("tab")],
    setup(props, {attrs: _attrs, emit}) {

        const {activeIndex, tabInfos} = toRefs(props);

        watch(tabInfos, infos=>{
            if (infos?.length)
            {
                setTimeout(()=>{
                    infos[0].show?.();
                });
            }
        });

        watch(activeIndex, index => {
            tabInfos.value?.[index || 0]?.show?.();
        });

        const attrs = computed(() => ({
            ..._attrs,
            "class": [
                _attrs.class,
                "nav",
                props.mode ? classMap[props.mode] : undefined,
                props.direction === "vertical" ? "flex-column" : undefined
            ].filter(Boolean),
            "role": "tablist"
        }));

        const collapse = () => {
            console.warn("collapse not implemented");
        };

        const expand = () => {
            console.warn("expand not implemented");
        };

        const onShown = (tabInfo: TabInfo, index: number) => {
            emit("update:activeIndex", index);
            emit("tab-shown", index);
        };

        return {
            collapse,
            expand,
            attrs,
            onShown
        }
    }
});

</script>

