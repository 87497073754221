﻿import {useDecoration, Target as BaseTarget} from "./useDecoration";
import {DateDecoration, DateDistanceOptions, DateFormat} from "../../types";
import {isDistanceDateFormat, isString} from "../../utils/inspect";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import {useAunoaI18n} from "../../utils/useAunoaI18n";
import format from "date-fns/format";
import {Ref, unref} from "vue";
import {useLocale} from "../../utils/useLocale";

interface Target extends BaseTarget {
    dateFormat?: DateFormat;
}


const useDateDistanceOptions = () => {

    let includeSeconds = false;
    let addSuffix = false;
    let locale: string;

    const options: DateDistanceOptions = {
        IncludeSeconds: (value = true) => set(() => includeSeconds = value),
        AddSuffix: (value = true) => set(() => addSuffix = value),
        Locale: (value: string) => set(() => locale = value),
    }

    function set(action: () => void) {
        action();
        return options;
    }

    return {
        options,
        includeSeconds: () => includeSeconds,
        addSuffix: () => addSuffix,
        locale: () => locale,
    }
}


export const formatDate = (value: any, options?: DateFormat) => {
    if (!value) {
        return value;
    }

    if (isString(value)) {
        value = Date.parse(value);
    }

    if (!options) {
        return value;
    }

    const {getDateFnsLocale} = useLocale()

    if (isDistanceDateFormat(options)) {
        return formatDistanceToNow(value, {
            includeSeconds: options.distance.includeSeconds,
            addSuffix: options.distance.addSuffix,
            locale: getDateFnsLocale(options.distance.locale)
        })

        // may be more 
    } else if (isString(options)) {
        return format(value, options, {
            locale: getDateFnsLocale()
        });
    }

    return value;

}

export const useDateDecoration = (target: Target | Ref<Target>) => {

    unref(target).dateFormat = "P";

    const baseDecoration = useDecoration(target);

    const decoration: DateDecoration = {
        ...baseDecoration,
        Format: (value: string) => set(() => unref(target).dateFormat = value),
        AsDate: () => set(() => unref(target).dateFormat = "P"),
        AsDateTime: () => set(() => unref(target).dateFormat = "Pp"),
        AsTime: () => set(() => unref(target).dateFormat = "p"),
        AsDistance: (value?: (d: DateDistanceOptions) => void) => {
            const options = useDateDistanceOptions();
            value?.(options.options);
            unref(target).dateFormat = {
                distance: {
                    includeSeconds: options.includeSeconds(),
                    addSuffix: options.addSuffix(),
                    //locale: getDateFnsLocale(),
                }
            }
            return baseDecoration
        }

    }

    const set = (action: () => void) => {
        action();
        return decoration;
    }

    return decoration;
};