<template>
    <form
        ref="dropzoneElement"
        class="dropzone"
    />
</template>

<script lang="ts">

import {defineComponent, computed, toRefs, ref, watch} from "vue";
import Dropzone, {DropzoneOptions} from "dropzone";
import {useAunoaI18n} from "../utils/useAunoaI18n";


const de = {
    'dictDefaultMessage': 'Legen Sie Dateien hier ab um Sie hochzuladen',
    'dictFallbackMessage': 'Ihr Browser unterstützt Drag&Drop Dateiuploads nicht',
    'dictFallbackText': 'Benutzen Sie das Formular um Ihre Dateien hochzuladen',
    'dictFileTooBig': 'Die Datei ist zu groß. Die maximale Dateigröße beträgt {{maxFileSize}}MB',
    'dictInvalidFileType': 'Eine Datei dieses Typs kann nicht hochgeladen werden',
    'dictResponseError': 'Der Server hat ihre Anfrage mit Status {{statusCode}} abgelehnt',
    'dictCancelUpload': 'Hochladen abbrechen',
    'dictCancelUploadConfirmation': null,
    'dictRemoveFile': 'Datei entfernen',
    'dictMaxFilesExceeded': 'Sie können keine weiteren Dateien mehr hochladen'
}

export default defineComponent({
    name: "AunoaDropzone",
    props: {
        url: {
            type: String,
            default: "/target"
        },
        method: {
            type: String,
            default: "post"
        },
        timeout: {
            type: Number,
            default: null
        },
        parallelUploads: {
            type: Number,
            default: 2
        },
        uploadMultiple: {
            type: Boolean,
            default: false
        },
        maxFiles: {
            type: Number,
            default: null
        }
    },
    setup: (props) => {
        const dropzoneElement = ref<HTMLFormElement>();

        const {locale} = useAunoaI18n()

        const mergeOptions = (options: DropzoneOptions) => {
            options.url = props.url;
            options.method = props.method;
            options.timeout = props.timeout;
            options.parallelUploads = props.parallelUploads;
            options.uploadMultiple = props.uploadMultiple;
            options.maxFiles = props.maxFiles;
            return options;
        }

        function init(this: Dropzone) {
            this.on("processing", file => {
                console.log("processing", file);
                mergeOptions(this.options);
            });
        }

        const dropzoneOptions = computed<DropzoneOptions>(() => ({
            ...mergeOptions({}),
            dictDefaultMessage: "Drop files here to upload " + locale.value,
            init
        }));

        let dropzone: Dropzone | undefined;
        watch([dropzoneElement, dropzoneOptions], ([element, options], [oldElement]) => {
            if (oldElement) {
                if (dropzone) {
                    dropzone.destroy();
                    dropzone = undefined;
                }
                oldElement.innerHTML = "";
            }
            if (element) {
                // console.log(options);
                dropzone = new Dropzone(element, options);
            }
        });

        return {
            dropzoneElement,
            dropzoneOptions
        }

    }
})

</script>
