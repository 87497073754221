﻿import type {AxiosInstance, AxiosResponse, AxiosRequestConfig, AxiosError, AxiosBasicCredentials} from "axios";

import {useMateApi} from "./useMateApi";

//Todo: recaptcha fields

export interface LoginRequest extends AxiosBasicCredentials {
    //username?: string;
    //password?: string;
    // captcha?: string;
}

export interface AccessTokenResponse {
    access_token: string;
    token_type: string;
    expires_in: number;
}

export interface LoginResponse {    
    token: string;
}

export interface RegisterRequest {
    username?: string;
    password?: string;
    firstname?: string;
    lastname?: string;
    email?: string;
    // captcha?: string;
}

export interface ResetPasswordRequest {
    userId?: string;
    token?: string;
    newPassword?: string;
}

export interface PrepareResetPasswordRequest {
    email?: string;
}

export interface ValidateEmailRequest {
    userId?: string;
    token?: string;
}

export interface RegisterResponse {
}

export interface MateAuthSettings {
    allowRegistration: boolean,
    aad?: {
        applicationId?:string,
        tenantId?:string
    }
}


export const useAuthApi = () => {

    const {jsonEndpoint} = useMateApi("ANONYMOUS")
    const authApi = jsonEndpoint("/api/v1-pub/auth/user");
    const aadAuthApi = jsonEndpoint("/api/v1-pub/aad-auth");
    const accountApi = jsonEndpoint("/api/auth");

    const params: any = undefined;

    const login = (loginRequest: LoginRequest) => authApi
        .get<AccessTokenResponse>(undefined, {
            url: "/token.json",
            auth: loginRequest,
        })
        .then((response : AccessTokenResponse) => {
            return <LoginResponse>{                
                token: response.access_token
            }
        });

    const loginWithAadToken = (request: any) => aadAuthApi
        .post<AccessTokenResponse>(request, params, {url: "/token.json"})
        .then((response:AccessTokenResponse) => {
            return <LoginResponse>{
                username: "",
                token: response.access_token
            }
        });

    const getAuthSettings = () => accountApi.get<MateAuthSettings>( params, {url: "/settings"});    
    const register = (request: RegisterRequest) => accountApi.post<RegisterResponse>(request, params, {url: "/register"});
    const resetPassword = (request: PrepareResetPasswordRequest) => accountApi.post(request, params, {url: "/reset-password"});
    const resetPasswordSubmit = (request: ResetPasswordRequest) => accountApi.post<LoginResponse>(request, params, {url: "/set-password"});
    const validateEmail = (request: ValidateEmailRequest) => accountApi.post<LoginResponse>(request, params, {url: "/confirm-registration"});

    return {
        login,
        register,
        resetPassword,
        resetPasswordSubmit,
        validateEmail,
        getAuthSettings,
        loginWithAadToken
    }
}