﻿import {AunoaForbidden403, AunoaNotFound404} from "bootstrap-aunoa";
import EmbeddingLayout from "./layouts/EmbeddingLayout.vue";
import {createRouter, createWebHistory} from "vue-router";
import Dashboard from "./views/Dashboard/Dashboard.vue";
import AdminLayout from "./layouts/Admin.vue";

import {
    ADMIN_ROUTE,
    CODE_EDITOR_ROUTE,
    FORBIDDEN_ROUTE,
    NOT_FOUND_ROUTE,
    CHANGE_PASSWORD_ROUTE,
    DASHBOARD_ROUTE,
    EMBEDDED_ROUTE,
    LOGIN_ROUTE,
    REGISTER_ROUTE,
    RESET_PASSWORD_ROUTE,
    VALIDATE_EMAIL_ROUTE
} from "./consts";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            name: ADMIN_ROUTE,
            path: '/',
            component: AdminLayout,
            children: [
                {
                    name: DASHBOARD_ROUTE,
                    path: "/",
                    component: Dashboard
                },
                {
                    name: CODE_EDITOR_ROUTE,
                    path: "tools/editor",
                    component: () => import( "./views/tools/CodeEditor.vue"),
                    props: (route: any) => ({
                        boxed: route.params.boxed != "false",
                        readOnly: route.params.readOnly == "true",
                        lineWrapping: route.params.lineWrapping == "true",
                        languageMode: route.params.languageMode,
                        content: route.params.content
                    }),
                    meta: {
                        icon: "fad fa-code",
                        title: "Code Editor",
                        ignorePermissions: true,
                        allowAnonymous: true
                    }
                },
                {
                    name: FORBIDDEN_ROUTE,
                    path: "forbidden",
                    component: AunoaForbidden403,
                    meta: {
                        icon: "fas fa-ban",
                        title: "@@Aunoa.Routing.PageForbidden403"
                    }
                },
                {
                    name: NOT_FOUND_ROUTE,
                    path: "/:pathMatch(.*)*",
                    component: AunoaNotFound404,
                    meta: {
                        icon: "fas fa-circle-x",
                        title: "@@Aunoa.Routing.PageNotFound404"
                    }
                }
            ]
        },
        {
            name: EMBEDDED_ROUTE,
            path: '/e',
            component: EmbeddingLayout,
            children: [],
        },
        {
            path: '/auth',
            component: () => import( "./layouts/Auth.vue"),
            children: [
                {
                    name: LOGIN_ROUTE,
                    path: "login",
                    component: () => import( "./views/auth/Login.vue"),
                    meta: {
                        ignorePermissions: true,
                        allowAnonymous: true,
                    }
                },
                {
                    name: REGISTER_ROUTE,
                    path: "register",
                    component: () => import( "./views/auth/Register.vue"),
                    meta: {
                        ignorePermissions: true,
                        allowAnonymous: true
                    }
                },
                {
                    name: RESET_PASSWORD_ROUTE,
                    path: "reset-password",
                    component: () => import( "./views/auth/ResetPassword.vue"),
                    meta: {
                        ignorePermissions: true,
                        allowAnonymous: true
                    },
                    props: (route: any) => ({
                        query: route.query
                    })
                },
                {
                    name: VALIDATE_EMAIL_ROUTE,
                    path: "validate-email",
                    component: () => import( "./views/auth/ValidateEmail.vue"),
                    meta: {
                        ignorePermissions: true,
                        allowAnonymous: true
                    },
                    props: (route: any) => ({
                        query: route.query
                    })
                },
                {
                    name: CHANGE_PASSWORD_ROUTE,
                    path: "change-password",
                    component: () => import( "./views/auth/ChangePassword.vue"),
                    meta: {
                        ignorePermissions: true,
                        allowAnonymous: false
                    }
                }
            ],
        }
    ],
})


export default router;