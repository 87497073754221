﻿<template>
    <svg x="0px" y="0px" viewBox="0 0 123 32" >
        <polygon class="st0" points="43,21 43,22 34.36,22 34.07,21.57 38.48,13 34,13 34,10 42.54,10 42.78,10.46 38.41,19 43,19 "/>
        <path class="st0" d="M49.5,22c-0.88,0-1.62-0.12-2.2-0.35c-0.58-0.24-1.04-0.58-1.38-1.02c-0.34-0.45-0.58-0.99-0.71-1.62
	c-0.14-0.63-0.2-1.35-0.2-2.15V10h3v6.58c0,0.5,0.02,0.93,0.06,1.28c0.04,0.35,0.11,0.64,0.22,0.86c0.11,0.22,0.26,0.38,0.45,0.48
	c0.19,0.1,0.45,0.14,0.77,0.14c0.32,0,0.57-0.05,0.77-0.14c0.2-0.1,0.35-0.25,0.46-0.48c0.11-0.22,0.18-0.51,0.21-0.86
	c0.04-0.35,0.05-0.78,0.05-1.28V10h3v6.85c0,0.8-0.07,1.51-0.2,2.15c-0.14,0.63-0.37,1.18-0.71,1.62c-0.34,0.45-0.8,0.79-1.38,1.02
	C51.12,21.88,50.38,22,49.5,22z"/>
        <path class="st0" d="M59.01,16.03c0,2.29,0.96,3.43,2.87,3.43c0.34,0,0.67-0.03,0.98-0.1c0.31-0.06,0.68-0.17,1.12-0.32
	c0.35,0.78,0.59,1.57,0.71,2.39c-0.31,0.12-0.6,0.22-0.87,0.29c-0.27,0.08-0.53,0.13-0.77,0.17c-0.25,0.04-0.49,0.06-0.73,0.08
	C62.07,21.99,61.82,22,61.56,22c-0.91,0-1.72-0.15-2.41-0.44c-0.69-0.3-1.27-0.71-1.74-1.24c-0.47-0.53-0.82-1.16-1.06-1.89
	C56.12,17.69,56,16.9,56,16.03c0-0.86,0.12-1.66,0.37-2.4c0.25-0.74,0.6-1.38,1.07-1.92c0.47-0.54,1.05-0.96,1.73-1.26
	c0.69-0.3,1.47-0.45,2.34-0.45c0.52,0,1,0.04,1.47,0.11c0.46,0.08,0.98,0.23,1.55,0.45c-0.02,0.44-0.1,0.87-0.24,1.29
	c-0.14,0.42-0.28,0.78-0.44,1.09c-0.22-0.08-0.41-0.14-0.59-0.19c-0.18-0.05-0.35-0.1-0.51-0.13c-0.16-0.03-0.32-0.06-0.48-0.07
	c-0.16-0.02-0.33-0.02-0.52-0.02c-0.87,0-1.55,0.29-2.02,0.88C59.25,13.99,59.01,14.87,59.01,16.03z"/>
        <path class="st0" d="M68.93,16.03c0,2.29,0.93,3.43,2.78,3.43c0.33,0,0.65-0.03,0.95-0.1c0.3-0.06,0.66-0.17,1.09-0.32
	c0.34,0.78,0.57,1.57,0.69,2.39c-0.3,0.12-0.58,0.22-0.84,0.29c-0.26,0.08-0.51,0.13-0.75,0.17c-0.24,0.04-0.48,0.06-0.71,0.08
	C71.89,21.99,71.65,22,71.4,22c-0.89,0-1.67-0.15-2.34-0.44c-0.67-0.3-1.24-0.71-1.69-1.24c-0.45-0.53-0.8-1.16-1.02-1.89
	C66.11,17.69,66,16.9,66,16.03c0-0.86,0.12-1.66,0.36-2.4c0.24-0.74,0.59-1.38,1.04-1.92c0.45-0.54,1.01-0.96,1.68-1.26
	c0.67-0.3,1.42-0.45,2.27-0.45c0.5,0,0.98,0.04,1.42,0.11c0.45,0.08,0.95,0.23,1.5,0.45c-0.02,0.44-0.1,0.87-0.23,1.29
	c-0.14,0.42-0.28,0.78-0.42,1.09c-0.21-0.08-0.4-0.14-0.57-0.19c-0.17-0.05-0.34-0.1-0.49-0.13c-0.16-0.03-0.31-0.06-0.47-0.07
	c-0.16-0.02-0.32-0.02-0.5-0.02c-0.84,0-1.5,0.29-1.96,0.88C69.16,13.99,68.93,14.87,68.93,16.03z"/>
        <path class="st0" d="M82,22v-5h-3v5h-3V10h3v4.46h3V10h3v12C84,22,83,22,82,22z"/>
        <polygon class="st0" points="94,17.08 91,17.08 91,19 95,19 95,22 88,22 88,10 95,10 95,13 91,13 91,14.46 94,14.46 "/>
        <path class="st0" d="M99,13h-3v-3h9v3h-3v9c-1,0-2,0-3,0V13z"/>
        <path class="st0" d="M109,13h-3v-3h9v3h-3v9c-1,0-2,0-3,0V13z"/>
        <path class="st0" d="M116,22V10c0,0,2.71-0.05,3,0v12H116z"/>
        <path class="st0" d="M22.94,9.92L22.94,9.92z"/>
        <polygon class="st0" points="8,3 8,4 8.86,4 "/>
        <polygon class="st0" points="8,22 8.78,21 8,21 "/>
        <polygon class="st0" points="8,21 8.78,21 9.56,20 8,20 "/>
        <polygon class="st0" points="12.29,8 8,8 8,9 13.14,9 "/>
        <polygon class="st0" points="11,10 4,10 4,11 10.33,11 "/>
        <polygon class="st0" points="10.57,6 8,6 8,7 11.43,7 "/>
        <polygon class="st0" points="9.67,12 4,12 4,13 9,13 "/>
        <polygon class="st0" points="8,19 10.33,19 11.11,18 8,18 "/>
        <polygon class="st0" points="8.86,4 8,4 8,5 9.71,5 "/>
        <polygon class="st0" points="4,17 11.89,17 12.67,16 4,16 "/>
        <polygon class="st0" points="4,15 13.44,15 14.22,14 4,14 "/>
        <polygon class="st0" points="23,10 22.11,11 23,11 "/>
        <polygon class="st0" points="22.1,28 22.95,29 22.95,28 "/>
        <polygon class="st0" points="23,11 22.11,11 21.22,12 23,12 "/>
        <polygon class="st0" points="23,13 20.33,13 19.44,14 23,14 "/>
        <polygon class="st0" points="23,15 18.56,15 17.67,16 23,16 "/>
        <polygon class="st0" points="27,17 16.78,17 15.89,18 27,18 "/>
        <polygon class="st0" points="27,19 22,19 21.33,20 27,20 "/>
        <polygon class="st0" points="27,21 20.67,21 20,22 27,22 "/>
        <polygon class="st0" points="23,23 17.85,23 18.7,24 22.99,24 "/>
        <polygon class="st0" points="22.98,25 19.55,25 20.4,26 22.97,26 "/>
        <polygon class="st0" points="22.96,27 21.25,27 22.1,28 22.95,28 "/>
</svg>

    
</template>

<script lang="ts">

import {defineComponent} from "vue";

export default defineComponent({
    name: "ZucchettiLowRes"
});


</script>
