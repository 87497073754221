import type {BootstrapAunoaOptions} from "bootstrap-aunoa";
import aunoa, {useAunoaOptions, useLocale, useLocalStorage} from "bootstrap-aunoa";

import mateApi, {interceptAxiosActivities, usePingApi} from "@tnt/mate-api";
import {useLocalizationHelper} from "./utils/useLocalizationHelper";
import {locales as envLocales, mateApiUrl, version} from "./env";
import aunoaDataGrid from "@bootstrap-aunoa/data-grid";
import global from "./views/_Mike/global";
import {createApp, watch} from "vue";
import {initializeAuth, login} from "./utils/useAuth";
import App from "./App.vue";
import AppFailed from "./AppFailed.vue";
import axios from "axios";
import RolePermissions from "./views/data/RolePermissions.vue";
import UserPermissions from "./views/data/UserPermissions.vue";
import UserRoles from "./views/data/UserRoles.vue";
import router from "./router";

// @ts-ignore
import {Dropdown, Popover, Tooltip} from "bootstrap";
import {CHANGE_PASSWORD_ROUTE, LOGIN_ROUTE, REGISTER_ROUTE, RESET_PASSWORD_ROUTE} from "./consts";

console.log("%c" + "MATE v" + version(), "background-color:#f2a900;padding:10px 20px;margin:20px 0;color:#fff;font-size:20px");

interceptAxiosActivities(axios);

const dummy = [Dropdown];
const {ensureTextTranslated2} = useLocalizationHelper();
const {options: aunoaOptions} = useAunoaOptions();

aunoaOptions.auth.loginRoute = {name: LOGIN_ROUTE};
aunoaOptions.auth.logoutRoute = {name: LOGIN_ROUTE, params: {action: "logout"}};
aunoaOptions.auth.registerRoute = {name: REGISTER_ROUTE};
aunoaOptions.auth.resetPasswordRoute = {name: RESET_PASSWORD_ROUTE};
aunoaOptions.auth.changePasswordRoute = {name: CHANGE_PASSWORD_ROUTE};
aunoaOptions.auth.reloginPromise = login;

const start = async () => {

    const app = createApp(App)
        .use(aunoa, <BootstrapAunoaOptions>{
            storageDefaultNamespace: "MATE",
            locales: envLocales(),
            defaultLocale: "en",
            fallbackLocale: "en",
            ensureTranslated: ensureTextTranslated2,
            registerAllComponents: true
        })
        .use(mateApi, {
            baseUrl: mateApiUrl(),
            locale: useLocale().locale
        });

    await initializeAuth()

    const {ping} = usePingApi();
    try {
        await ping();
    } catch (error:any) {
        switch (error?.httpStatus) {
            case 521:
                break;
            default:
                return createApp(AppFailed).mount("#app");
        }
    }

    //await new Promise(resolve => setTimeout(resolve, 5000));
   
    return app
        .use(router)
        .use(aunoaDataGrid)
        .provide("global", global)
        .component(RolePermissions.name, RolePermissions)
        .component(UserPermissions.name, UserPermissions)
        .component(UserRoles.name, UserRoles)
        .mount("#app");
};

start().then();






