﻿import {isIndex, isUndefinedOrNull} from "./inspect";
import {PropertyPath, ContextablePropertyPath, Entities, Tables} from "../types";
import {getPropertyName} from "./reflectionLike";

const isPathResolved = (path: string) => /^\[.+]$/i.test(path);

const cleanupResolvedPath = (path: string) => isPathResolved(path)
    ? path.replace(/[|]/gi, "")
    : path;

export const getValueFromPropertyPath = (obj: Record<string, any> | undefined, path: string): any =>
    obj && path ? isPathResolved(path)
        ? obj[cleanupResolvedPath(path)]
        : path
            .split(/\.|\[(\d+)]/)
            .filter(Boolean)
            .reduce((o, prop) => o && prop in o ? o[prop] : undefined, obj) : undefined;


export const setInPathFunc = (obj: Record<string, any>, path: string, getValue: (v: any, p: string) => any): void => {

    if (isPathResolved(path)) {
        path = cleanupResolvedPath(path);
        obj[path] = getValue(obj[path], path);
        return;
    }

    const keys = path.split(/\.|\[(\d+)]/).filter(Boolean);
    let o = obj;

    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        // Last key, set it
        if (i === keys.length - 1) {
            o[key] = getValue(o[key], key);
            return;
        }

        // Key does not exist, create a container for it
        if (!(key in o)) {
            // container can be either an object or an array depending on the next key if it exists
            o[key] = isIndex(keys[i + 1])
                ? []
                : {};
        }

        if (isUndefinedOrNull(o[key])) {
            o[key] = {};
        }
        o = o[key];
    }
};

export const setInPath = (obj: Record<string, any>, path: string, value: any, converter?: (v: any) => any): void =>
    setInPathFunc(obj, path, () => converter ? converter(value) : value)

export const ensureResolvedPropertyPath = <T = any>(value: string | undefined, obj: Record<string, any> | undefined): T | undefined =>
    value && value.startsWith("{{") && value.endsWith("}}")
        ? getValueFromPropertyPath(obj, value.substr(2, value.length - 4))
        : value;

const propertyPathBuilder = (property?: string, parentProperty?: string): PropertyPath => {
    parentProperty = parentProperty && property
        ? parentProperty + "." + property
        : property
    let childBuilder: PropertyPath<any>;
    return {
        Property: (getter: (o: any) => any) => childBuilder = propertyPathBuilder(getPropertyName(getter), parentProperty),
        Build: () => childBuilder?.Build() || parentProperty || ""
    }
}

export const createPropertyPath = (): PropertyPath => propertyPathBuilder(undefined);

export const createContextablePropertyPath = (): ContextablePropertyPath => {
    let builder = propertyPathBuilder("Entity")
    return {
        ...builder,
        Context: () => builder = propertyPathBuilder(),
        Build: () => builder.Build()
    }
}


export const buildArrayProperty = (properties: string[]): Tables.Builder.ArrayProperty<any> => ({
    Property: getter => {
        properties.push(getPropertyName(getter));
        return buildArrayProperty(properties);
    },
    Array: getter => {
        properties.push(getPropertyName(getter));
        return [] as any[];
    }
});


export const buildObjectProperty = (properties: string[]): Tables.Builder.ObjectProperty<any> => ({
    Property: getter => {
        properties.push(getPropertyName(getter));
        return buildObjectProperty(properties);
    },
    Object: getter => {
        properties.push(getPropertyName(getter));
        return {} as any;
    }
});
