﻿import {TextCasing} from "../../utils/textCasing";
import {LookupDisplayOptions, LookupValueOptions} from "../../types";


export const useLookupValueOptions = () => {

    let expression: string;

    const options: LookupValueOptions = {
        Expression: (value: string) => set(() => expression = value)
    }

    function set(action: () => void) {
        action();
        return options;
    }

    return {
        options,
        expression: () => expression
    }
}

export const useLookupDisplayOptions = () => {

    let expression: string;
    let casing: TextCasing;

    const options: LookupDisplayOptions = {
        Expression: (value: string) => set(() => expression = value),
        Casing: (value: TextCasing) => set(() => casing = value)
    }

    function set(action: () => void) {
        action();
        return options;
    }

    return {
        options,
        expression: () => expression,
        casing: () => casing
    }
}