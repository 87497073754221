﻿import {ref, Ref, watch} from "vue";
import {createSearchOption, SearchOption} from "./search";
import {useDebounce} from "./useDebounce";

export const useSearching = (text: Ref<string>, debounceWait: number | string) => {

    const searchOption = ref<SearchOption>();
    const create = (str: string) => searchOption.value = createSearchOption(str);
    const debounce = useDebounce(create, debounceWait);

    watch(text, str => {
        if (str) {
            debounce(str);
        } else {
            debounce.cancel();
            create("");
        }
    });

    return {
        searchOption,
        cancel: debounce.cancel
    }
}