<template>
    <teleport to="#footerCustomIconNavItems">
        <slot />
    </teleport>
</template>

<script lang="ts">

import {usePageHost} from "../utils/usePageHost";
import {defineComponent} from "vue";

export default defineComponent({
    name: "AunoaFooterIconCommandsPane",
    setup: () => usePageHost().withTeleport("footerIconCommands")
});

</script>
