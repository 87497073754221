﻿<template>
    <span class="hourglass fa-stack">
        <i :class="iconStyle" class="fa-stack-1x fa-hourglass-start" />
        <i :class="iconStyle" class="fa-stack-1x fa-hourglass-half" />
        <i :class="iconStyle" class="fa-stack-1x fa-hourglass-end" />
        <i :class="iconStyle" class="fa-stack-1x fa-hourglass-end" />
        <i :class="iconStyle" class="fa-stack-1x fa-hourglass-empty" />
    </span>
</template>

<script lang="ts">

import {defineComponent} from "vue";

export default defineComponent({
    name: "AunoaHourglass",
    props: {
        iconStyle: {
            type: String,
            default: "far"
        }
    }
});


</script>
