﻿import type {Ref} from "vue"
import {ref, readonly, watch} from "vue"

let _locale = ref("en");

export const provideLocale = (locale: Ref<string>) => {
    _locale = locale;
}


export const useLocale = () => {
    return {
        locale: readonly(_locale)
    }
}