import {computed, ref, watch} from "vue";
import {useTenancy} from "@tnt/mate-api";
import {useRouter} from "vue-router";
import {useAuth} from "./useAuth";

export const useRoutePermissions = (pathGiven?: string) => {

    const permissions = ref<string[]>();
    const permittedTenantOids = ref<number[]>();
    const tenancyPermissions = ref<Record<number, string[]>>();

    const {navigation} = useAuth();
    const {currentRoute} = useRouter();
    const {primaryTenantOid} = useTenancy();
    const ignorePermissions = () => !!currentRoute.value.meta?.ignorePermissions;
    const getPath = (path?: string) => pathGiven || path || currentRoute.value.path;
    const isRoot = () => getPath() === "/";

    const hasPermission = (permission: string) => ignorePermissions() || permissions.value?.includes(permission) === true;

    watch(
        [navigation, currentRoute, primaryTenantOid],
        ([nav, route, tenantOid]) => {
            const path = getPath(route.meta?.responsiblePath as string || route.path);
            tenancyPermissions.value = nav.permissions?.[path];
            permissions.value = tenancyPermissions.value?.[tenantOid];
            permittedTenantOids.value = Object.keys(tenancyPermissions.value || []).map(oid => +oid);
            //console.log(route.path, tenantOid, JSON.stringify(permissions.value));
        },
        {immediate: true}
    );

    const permitted = computed(() => ignorePermissions() || !!permissions.value || isRoot());
    const tenancyPermitted = computed(() => !!tenancyPermissions.value || isRoot());

    const createPermission = computed(() => hasPermission("Create"));
    const updatePermission = computed(() => hasPermission("Update"));
    const deletePermission = computed(() => hasPermission("Delete"));
    const demandPermission = computed(() => hasPermission("Demand"));
    const patchPermission = computed(() => hasPermission("Patch"));

    return {
        permitted,
        permittedTenantOids,
        tenancyPermitted,
        createPermission,
        updatePermission,
        deletePermission,
        demandPermission,
        patchPermission,
        hasPermission
    }
}