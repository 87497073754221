﻿import {inject, InjectionKey, provide, ref, Ref} from "vue";

const INJECTION_KEY: InjectionKey<Ref<any>> = Symbol("AUNOA_ENTITY");

export const provideEntity = <TEntity=any>(entity: Ref<TEntity>) => {

    provide(INJECTION_KEY, entity);
}

export const useEntity = <TEntity=any>(fallbackEntity?:TEntity) => {

    const entity = inject(INJECTION_KEY, ref(fallbackEntity));
    
    return {
        entity
    }
}