import type {Facade} from "./_abstracts";
import {HubConnection} from "@microsoft/signalr";

export interface NotificationFacade {
    wait(responseId: string): Promise<void>;
}

export const createNotificationFacade = (connection: HubConnection): NotificationFacade & Facade => {

    const dict: Record<string, {
        resolve: (value: void | PromiseLike<void>) => void,
        reject: (reason?: any) => void
    }> = {};

    const onNotifyResponse = (id: string, status: number, error: string) => {
        const promise = dict[id];
        if (promise) {
            delete dict[id];
            if (status >= 400) {
                promise.reject(error || `Error ${status}`);
            } else {
                promise.resolve();
            }
        }
    }

    connection.on("NotifyResponse", onNotifyResponse);

    const initialize = () => Promise.resolve();

    const dispose = () =>
        connection.off("NotifyResponse", onNotifyResponse);

    const wait = (responseId: string) =>
        new Promise<void>((resolve, reject) => {
            dict[responseId] = {resolve, reject};
        })

    return {
        initialize,
        dispose,
        wait,
    }
}