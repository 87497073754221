﻿import {decimal, integer} from "@vuelidate/validators";
import {Entities} from "../../types";
import {isNumber} from "../../utils/inspect";

const minNumbers: Record<string, number> = {
    "System.SByte": -128,
    "System.Int16": -32768,
    "System.Int32": -2147483648,
    "System.Int64": -9223372036854775808,
    "System.Byte": 0,
    "System.UInt16": 0,
    "System.UInt32": 0,
    "System.UInt64": 0,
    "System.Single": -3.4028235E+38,
    "System.Double": -1.7976931348623157E+308,
    "System.Decimal": -79228162514264337593543950335.0
}

const maxNumbers: Record<string, number> = {
    "System.SByte": 127,
    "System.Int16": 32767,
    "System.Int32": 2147483647,
    "System.Int64": 9223372036854775807,
    "System.Byte": 255,
    "System.UInt16": 65535,
    "System.UInt32": 4294967295,
    "System.UInt64": 18446744073709551615,
    "System.Single": 3.4028235E+38,
    "System.Double": 1.7976931348623157E+308,
    "System.Decimal": 79228162514264337593543950335.0
};

const typeNumbers: Record<string, any> = {
    "System.SByte": {integer},
    "System.Int16": {integer},
    "System.Int32": {integer},
    "System.Int64": {integer},
    "System.Byte": {integer},
    "System.UInt16": {integer},
    "System.UInt32": {integer},
    "System.UInt64": {integer},
    "System.Single": {decimal},
    "System.Double": {decimal},
    "System.Decimal": {decimal}
};


export const useManagedTypes = () => {

    const getMinValue = (p: Entities.Model.Property) => {
        const managed = p.managedType && minNumbers[p.managedType], value = p.min;
        return isNumber(managed)
            ? isNumber(value)
                ? Math.max(managed, value)
                : managed
            : value;
    }

    const getMaxValue = (p: Entities.Model.Property) => {
        const managed = p.managedType && maxNumbers[p.managedType], value = p.max;
        return isNumber(managed)
            ? isNumber(value)
                ? Math.min(managed, value)
                : managed
            : value;
    }

    const getNumberTypeValidation = (p: Entities.Model.Property) => 
        p.managedType 
            ? typeNumbers[p.managedType] 
            : undefined

    return {
        getMinValue,
        getMaxValue,
        getNumberTypeValidation
        
    }


}