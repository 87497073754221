const stringToHslColor0 = (str: string, saturation: number, lightness: number) => {
    let hash = 0;
    str = str || "??"
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return `hsl(${hash % 360}, ${saturation}%, ${lightness}%)`;
};


export const stringToHslColor = (str: string, saturation: number, lightness: number) => {
    const [...chars] = str || "??";
    const hash = chars.reduce((h, c) => (c.charCodeAt(0) + ((h << 2) - h)) % 360, 0)
    return `hsl(${hash}, ${saturation}%, ${lightness}%)`;
};


export const stringToHslStyles = (str: any, str2?: any): any =>
    ({
        "--custom-color-light": stringToHslColor(str, 50, 72),
        "--custom-color-dark": stringToHslColor(str, 60, 28),
        "--custom-color-light-2": str2 ? stringToHslColor(str2, 50, 72) : undefined,
        "--custom-color-dark-2": str2 ? stringToHslColor(str2, 60, 28) : undefined
    });

export const stringToHslStylesReversed = (str: any): any =>
    ({
        "--custom-color-light": stringToHslColor(str, 60, 28),
        "--custom-color-dark": stringToHslColor(str, 50, 72)
    });


export const stringToCode = (str: string | undefined) => {
    //console.log(str, typeof str);
    return str
        ? str.split(" ").map(s => s[0]?.toUpperCase()).filter(s => !!s).filter((_, i) => i < 2).join("")
        : undefined;
};
