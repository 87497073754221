﻿import {useMateApi} from "./useMateApi";
import {AccessToken} from "./types";


type TenancyGroup = number;
type CurrencyGroup = string;

export interface Currency {
    currency: string;
}

export interface ExchangeRate {
    foreignCurrency: string;
    exchangeRate: number;
    modifiedAt: any;
    validFrom: Date;
}

type ExchangeRates = Record<TenancyGroup, Record<CurrencyGroup, ExchangeRate[]>>;


export const useCurrenciesApi = (accessToken: AccessToken) => {

    const {jsonEndpoint} = useMateApi(accessToken);
    const api = jsonEndpoint("/api/currencies");

    const getCurrency = (tenantOid: number) =>
        api.get<Currency>({tenantOid}, {url: "/currency"});
    
    const getExchangeRates = (tenantOid: number) =>
        api.get<ExchangeRates>({tenantOid}, {url: "/exchange-rates"});

    return {
        getCurrency,
        getExchangeRates
    }
}