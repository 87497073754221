﻿import type {Ref} from "vue";
import {inject, InjectionKey, provide} from "vue";

export interface TabInfo {
    id: Ref<string>;
    icon: Ref<string|undefined>;
    linkText: Ref<string|undefined>;
    fieldErrorCount: Ref<number>;
    show?():void
}

export interface PaneSubscription {
    addTab(tab: TabInfo): void;
    removeTab(tab: TabInfo): void;
}

const INJECTION_KEY: InjectionKey<PaneSubscription> = Symbol("AUNOA_PANE_SUBSCRIPTION");

export const providePaneSubscription = (paneSubscription:PaneSubscription) => {
    
    provide(INJECTION_KEY, paneSubscription);    
}

export const usePaneSubscription = () => {
    const paneSubscription = inject(INJECTION_KEY, undefined);

    return {
        paneSubscription
    }
}