<template>
    <div v-bind="toastAttrs">
        <div class="toast-header">
            <i v-bind="iconAttrs" />
            <strong class="me-auto" v-text="ensureTextTranslated(title)" />
            <button type="button" class="btn-close" data-bs-dismiss="toast" @click="remove(message)"></button>
        </div>

        <div class="toast-body pt-0" v-if="message.content">
            <strong v-if="message.topic" v-text="ensureTextTranslated(message.topic)" />
            <strong v-if="message.topic" v-text="': '" />
            <span v-text="ensureTextTranslated(message.content)" />
        </div>
        <div class="toast-footer" v-if="message.actions?.length>0">
            <aunoa-nav class="ps-0">
                <aunoa-nav-item
                    v-for="a in message.actions"
                    :icon="a.icon"
                    :text="ensureTextTranslated(a.text)"
                    @click="a.onClick"
                />
            </aunoa-nav>
        </div>
    </div>
</template>

<script lang="ts">

import type {PropType} from "vue";
import type {TypedMessage, MessageType} from "../../utils/useToasts";

import AunoaNavItem from "../../components/nav/AunoaNavItem.vue";
import {getToastMessageIcon} from "../../utils/useIconAlias";
import AunoaNav from "../../components/nav/AunoaNav.vue";
import {defineComponent, computed, toRefs} from "vue";
import {useAunoaI18n} from "../../utils/useAunoaI18n";
import {useToasts} from "../../utils/useToasts";

const backgrounds: Record<MessageType, string> = {
    "info": "bg-info",
    "success": "bg-success",
    "warning": "bg-warning",
    "error": "bg-danger",
    "system-error": "bg-danger"
}

const titles: Record<MessageType, string> = {
    "info": "@@Aunoa.Message.Type.Info",
    "success": "@@Aunoa.Message.Type.Success",
    "warning": "@@Aunoa.Message.Type.Warning",
    "error": "@@Aunoa.Message.Type.Error",
    "system-error": "@@Aunoa.Message.Type.Error"
}

export default defineComponent({
    name: "Toast",
    components: {
        AunoaNav,
        AunoaNavItem
    },
    props: {
        message: {
            type: Object as PropType<TypedMessage>,
            required: true
        }
    },
    setup(props) {

        const {message} = toRefs(props);
        const {ensureTextTranslated} = useAunoaI18n();
        const toastAttrs = computed(() => ({
            "class": [
                "toast show",
                "as-" + message.value.type,
                message.value.monospace ? "monospace-content" : undefined,
                backgrounds[message.value.type]
            ].filter(Boolean),
            "style": {
                "--aunoa-animation-duration": `${message.value.duration}ms`
            },
            "role": "alert"
        }));

        const iconAttrs = computed(() => ({
            "class": [
                "fa-2x",
                "pe-2",
                message.value.icon  || getToastMessageIcon(message.value.type)
            ].filter(Boolean)
        }));

        const title = computed(() => message.value.title || titles[message.value.type]);
        const {remove} = useToasts();

        return {
            ensureTextTranslated,
            toastAttrs,
            iconAttrs,
            title,
            remove
        }
    }
})

</script>

