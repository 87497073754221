﻿import AzureCostsCard from "./AzureCostsCard.vue";
import BridgeCard from "./BridgeCard.vue";
import ConfigChangesCard from "./ConfigChangesCard.vue";
import DatabaseUsageCard from "./DatabaseUsageCard.vue";
import DocumentLocksCard from "./DocumentLocksCard.vue";
import ExchangeRatesCard from "./ExchangeRatesCard.vue";
import MonthlyDocumentThroughputCard from "./MonthlyDocumentThroughputCard.vue";
import PromotionsCard from "./PromotionsCard.vue";
import FavoritesCard from "./FavoritesCard.vue";
import RecentlyUsedCard from "./RecentlyUsedCard.vue";
import TerminalsCard from "./TerminalsCard.vue";
import UnprocessedDocumentsCard from "./UnprocessedDocumentsCard.vue";
import VersionCard from "./VersionCard.vue";
import WeeklyTurnoverCard from "./WeeklyTurnoverCard.vue";

type CardName = string;

export type TileSize = "medium";

export interface CardMetadata {
    component: any;
    required: boolean;
    multiInstance: boolean;
    tileSize: TileSize;
    createdAt: string;
    icon: string;
    title(t: any): string;
    variants(): any[];
}

const createMeta = (component: any) => ({
    component,
    tileSize: "medium",
    ...component.$$meta
})

const cardDefinitions: Record<CardName, CardMetadata> = {
    "favorites": createMeta(FavoritesCard),
    "recentlyUsed": createMeta(RecentlyUsedCard),
    "weeklyTurnover": createMeta(WeeklyTurnoverCard),
    "exchangeRates": createMeta(ExchangeRatesCard),
    //"terminals": createMeta(TerminalsCard),
    "configChanges": createMeta(ConfigChangesCard),
    //"azureCosts": createMeta(AzureCostsCard),
    "monthlyDocumentThroughput": createMeta(MonthlyDocumentThroughputCard),
    //"promotions": createMeta(PromotionsCard),
    "unprocessedDocuments": createMeta(UnprocessedDocumentsCard),
    "bridge": createMeta(BridgeCard),
    "databaseUsage": createMeta(DatabaseUsageCard),
    "documentLocks": createMeta(DocumentLocksCard),
    "version": createMeta(VersionCard)
}

export default cardDefinitions;

