﻿<template>
    <svg x="0px" y="0px" viewBox="0 0 256 256">
        <rect x="0" y="0" width="256" height="256" />
        <path transform="matrix(1,0,0,1,23.8931,63.947)"
            d="M0,64.053L64.054,0L128.107,64.053L0,64.053Z" style="fill:#f2a900;fill-rule:nonzero;" />
        <path transform="matrix(1,0,0,1,231.893,191.946)"
            d="M0,-63.946L-63.946,0L-127.893,-63.946L0,-63.946Z" style="fill:#000;fill-rule:nonzero;" />
    </svg>
</template>

<script lang="ts">

import {defineComponent} from "vue";

export default defineComponent({
    name: "BridgeIcon"
});

</script>
