﻿import { convertDuration } from "./dateAndTime";
import {isNumber} from "./inspect";


export const useDebounce = <TArg = any>(func: (arg: TArg) => void, wait: number | string) => {

    const timeout = isNumber(wait)
        ? wait
        : convertDuration(wait);

    let timer: number | undefined = undefined;

    const debounced = (arg: TArg) => {
        
        const later = () => {
            timer = undefined;
            func(arg);
        }

        window.clearTimeout(timer);
        timer = window.setTimeout(later, timeout)

        if (!timer) {
            func(arg);
        }
    }

    debounced.cancel = () => {
        window.clearTimeout(timer);
        timer = undefined;
    }

    return debounced;
};