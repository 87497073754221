﻿<template>
    <div v-bind="fieldAttrs">
        <slot name="label">
            <aunoa-form-label v-if="label" v-bind="labelAttrs" />
        </slot>
        <input v-bind="controlAttrs" />
        <aunoa-error-message v-bind="errorMessageAttrs" />
    </div>
</template>

<script lang="ts">
import {defineComponent, watch} from "vue";
import {fieldProps, useField} from "../../implementations/forms/useField";
import AunoaFormLabel from "./AunoaFormLabel.vue";
import AunoaErrorMessage from "./AunoaErrorMessage.vue";


export default defineComponent({
    name: "AunoaDateBox",
    inheritAttrs: false,
    components: {AunoaFormLabel, AunoaErrorMessage},
    props: {
        ...fieldProps,
        modelValue: {
            type: Date,
        },
        nullable: {
            type: Boolean,
            default: undefined
        }
    },
    setup(props, ctx) {

        const field = useField(props as any, ctx)
        return {
            ...field
        }
    }
});

</script>
