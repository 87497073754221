﻿<template>
    <div
        ref="modalElement"
        class="modal fade"
        tabindex="-1"
        :aria-labelledby="ariaLabelId"
        aria-hidden="true"
    >
        <div :class="dialogClass">
            <component :is="as" v-bind="$attrs" class="modal-content" v-if="visible">
                <div class="modal-header shadow-sm" v-if="$slots.header">
                    <slot :shown="shown" :hide="hide" :title="ensureTextTranslated(title)" name="header"  />
                </div>
                <div class="modal-header" :class="headerClass" v-else-if="title || !disableCloseButton">
                    <h4 class="modal-title _text-primary" :id="ariaLabelId">
                        <i class="text-primary pe-2" :class="icon" v-if="icon" /> 
                        <span class="font-weight-bold" v-text="ensureTextTranslated(title)" />
                    </h4>
                    <button
                        v-if="!disableCloseButton"
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close" />
                </div>
                <div class="modal-header shadow-sm p-0" v-if="$slots.commandsBar || hasCommandsBar">
                    <aunoa-nav class="modal-nav-bar" :grip="true">
                        <slot name="commandsBar" />
                    </aunoa-nav>
                </div>
                
                <div class="modal-body" :class="bodyClass">
                    <slot :shown="shown" :hide="hide"  />
                </div>
                <div class="modal-footer" :class="footerClass" v-if="$slots.footer || hasFooter">
                    <slot :shown="shown" :hide="hide" name="footer" />
                </div>
            </component>
        </div>
    </div>

</template>

<script lang="ts">

import {getVisibilityEventNames} from "../utils/useVisibilityEvents";
import {modalPropsOptions, useModal} from "../utils/useModal";
import AunoaNav from "../components/nav/AunoaNav.vue";
import {useAunoaI18n} from "../utils/useAunoaI18n";
import {defineComponent, computed} from "vue";

export default defineComponent({
    name: "AunoaModalDialog",
    inheritAttrs: false,
    components: {
      AunoaNav  
    },
    props: {
        ...modalPropsOptions,
        as: {
            type: String,
            default: "div"
        },
        icon: {
            default: undefined
        },
        title: {
            type: String,
            default: undefined
        },
        disableCloseButton: {
            type: Boolean,
            default: false
        },
        hasCommandsBar: {
            type: Boolean,
            default: false
        },
        hasFooter: {
            type: Boolean,
            default: false
        },
        bodyClass: {},
        headerClass: {},
        footerClass: {}
    },
    emits: [...getVisibilityEventNames("modal")],
    setup(props, {attrs, emit}) {


        const modal = useModal(<any>props, emit);
        
        const {ensureTextTranslated} = useAunoaI18n()
        
        const ariaLabelId = computed(() =>
            attrs.id && modal.visible.value && props.title
                ? attrs.id + "Label"
                : undefined);

        return {
            ...modal,
            ariaLabelId,
            ensureTextTranslated,
        }
    }
});

</script>


