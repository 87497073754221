﻿import type {MessageType} from "./useToasts";
import type {BusyOptions, PromisableEvent, ToastAction} from "../types";

import {isDefined, isFunction, isPromise} from "./inspect";
import {resolveIconAlias, getBusyMessageIcon} from "./useIconAlias";
import {computed, readonly, ref} from "vue";
import {getDuration} from "./dateAndTime";
import {useToasts} from "./useToasts";

const DEFAULT_SUCCESS_TOAST_TITLE = "@@Aunoa.Operation.Successful";
const DEFAULT_FAILED_TOAST_TITLE = "@@Aunoa.Operation.Failed";

const ensureOptions = (options: BusyOptions) => ({
    delay: getDuration(options.delay, 25),
    minDuration: getDuration(options.minDuration, 250),
    successDuration: getDuration(options.successDuration, 500),
    failedDuration: getDuration(options.failedDuration, 750),
    successToast: options.successToast || false,
    successToastTitle: options.successToastTitle || DEFAULT_SUCCESS_TOAST_TITLE,
    failedToast: options.failedToast || isDefined(options.failedToastActions) || false,
    failedToastTitle: options.failedToastTitle || DEFAULT_FAILED_TOAST_TITLE,
    failedToastActions: options.failedToastActions,
    toastGroup: options.toastGroup,
    dataKind: options.dataKind,
    keepMenuOpen: options.keepMenuOpen
});

const ensureEnabled = (value: any, result: any): boolean =>
    (isFunction(value) && value(result)) || (!isFunction(value) && value)

const ensureTitle = (value: any, result: any): string =>
    isFunction(value) ? value(result) : value

const ensureToastActions = (value: any, result: any): ToastAction[] =>
    isFunction(value) ? value(result) : value;


//https://stackoverflow.com/questions/64336083/unexpected-unhandledrejection-event-for-promise-which-rejection-does-get-handled
// window.addEventListener("unhandledrejection", event => {
//     //event.preventDefault();
//     // You can use `event.reason` here for the rejection reason, and
//     // `event.promise` for the promise that was rejected
//     //console.log(`Suppressed the rejection '${event.reason.message}'`);
//     console.log("unhandledrejection", event);
// });

const httpStatusMessageTypeDict: Record<number, MessageType> = {
    304: "info",
    409: "warning"
}

const STATUS_ERROR = "@@Aunoa.Error.Http.Status";
const httpStatusTranslationDict: Record<number, string> = {
    304: STATUS_ERROR + ".E304",
    404: STATUS_ERROR + ".E404",
    409: STATUS_ERROR + ".E409"
}

const httpStatusMethodTranslationDict: Record<string, Record<number, string>> =
    {
        "put": {
            409: STATUS_ERROR + ".E409Put"
        },
        "post": {
            409: STATUS_ERROR + ".E409Post"
        }
    }


const getHttpStatusTranslation = (httpMethod: string, httpStatus: number) =>
    (httpStatusMethodTranslationDict[httpMethod] || httpStatusTranslationDict)[httpStatus]


export const useBusy = () => {

    const {addSuccessToast, addToast} = useToasts();
    const busyIcon = ref<string>();
    //watch(busyIcon, i=> console.log("ICON", i));
    const isBusy = computed(() => !!busyIcon.value);

    const start = <T = any>(promise: Promise<T>, options?: BusyOptions) =>
        new Promise<T>((resolve, reject) => {

            const o = ensureOptions(options || {});
            const minDuration = new Promise<void>(resolve => setTimeout(resolve, o.minDuration));
            const busyIconDelay = setTimeout(() => busyIcon.value = resolveIconAlias("busy"), o.delay);

            const onSuccess = async (result: any) => {

                if (ensureEnabled(o.successToast, result)) {
                    addSuccessToast({
                        content: undefined,
                        title: ensureTitle(o.successToastTitle, result),
                        group: o.toastGroup
                    });
                }

                await minDuration;
                clearInterval(busyIconDelay);

                const doResolve = () => {
                    busyIcon.value = undefined;
                    resolve(result);
                }

                if (o.successDuration > 0) {
                    busyIcon.value = getBusyMessageIcon("success");
                    setTimeout(doResolve, o.successDuration);
                } else {
                    doResolve();
                }
            }

            const onFailed = async (error: any) => {

                const messageType = httpStatusMessageTypeDict[error?.httpStatus] || "error";
                if (ensureEnabled(o.failedToast, error)) {

                    if (error.type && error.content) {
                        console.log(8888);
                        addToast(error.type, {
                            content: error.content,
                            title: error.title || ensureTitle(o.failedToastTitle, error),
                            actions: ensureToastActions(o.failedToastActions, error),
                            group: error.group || o.toastGroup
                        });
                    }
                    else {
                        if (error?.httpStatus && o.dataKind) {
                            const translation = getHttpStatusTranslation(error.httpMethod, error.httpStatus);
                            if (translation) {
                                error.message = translation + o.dataKind;
                            }
                        }
                        addToast(messageType, {
                            content: error,
                            title: ensureTitle(o.failedToastTitle, error),
                            actions: ensureToastActions(o.failedToastActions, error),
                            group: o.toastGroup
                        });
                    }
                }

                await minDuration;
                clearInterval(busyIconDelay);

                const doReject = () => {
                    busyIcon.value = undefined;
                    reject(error);
                }

                if (o.failedDuration > 0) {
                    busyIcon.value = getBusyMessageIcon(messageType);
                    setTimeout(doReject, o.failedDuration);
                } else {
                    doReject();
                }
            }

            promise
                .then(onSuccess)
                .catch(onFailed);
        })

    const tryStart = <T = any>(promise: Promise<T>, options?: BusyOptions): Promise<T> =>
        isPromise(promise)
            ? start(promise, options)
            : Promise.resolve<T>(<any>undefined)

    const emitPromisableEvent = (event: PromisableEvent, emit: any) => {
        const emitPromise = new Promise<any>(resolve => {
            event.setPromise = (promise, options) => {
                if (options?.keepMenuOpen) {
                    event.target = undefined;
                }
                const busyPromise = tryStart(promise, options);
                resolve(busyPromise);
                return busyPromise;
            }
            setTimeout(() => resolve(false));
        })
        emit("click", event);
        return emitPromise;
    }

    return {
        busyIcon: readonly(busyIcon),
        isBusy,
        start,
        tryStart,
        emitPromisableEvent
    }
}