﻿import type {AccessToken} from "./types";
import {useMateApi} from "./useMateApi";

export const useScopeApi = (accessToken: AccessToken) => {
    
    const {jsonEndpoint} = useMateApi(accessToken);
    const settingsApi = jsonEndpoint("/api/settings");

    const getScope = () => settingsApi.get({}, {url: "/scope"});

    return {
        getScope
    }
}
