﻿import {Ref, unref} from "vue";
import {Decoration, NumberDecoration, NumberOptions} from "../../types";

export interface Target {
    decoration: (string | string[])[];
    fallback?: string;
}

export const useDecoration = (target: Target | Ref<Target>) => {

    const decoration: Decoration = {
        Decorate: d =>  d?.(decoration),
        Fallback: (text: string) => {
            unref(target).fallback = text;
            return decoration;
        },
        InQuotes: (value = true) => push("Quotes", value),
        InBrackets: (value = true) => push("Brackets", value),
        InSquareBrackets: (value = true) => push("SquareBrackets", value),
        InAngleBrackets: (value = true) => push("AngleBrackets", value),
        InCurlyBrackets: (value = true) => push("CurlyBrackets", value),
        InGoosfoot: (value = true) => push("Goosfoot", value),
        InSmall: (value = true) => push("Small", value),
        InBold: (value = true) => push("Bold", value),
        InMuted: (value = true) => push("Muted", value),
        SurroundWith: (textBefore: string, textAfter?: string) => push([textBefore, textAfter || textBefore]),
        WithPrefix: (textBefore: string) => push([textBefore || "", ""]),
        WithSuffix: (textAfter: string) => push(["", textAfter || ""]),
        AsQuantity: (value = true) => push(["", "×"], value),
        AsCelsius: (value = true) => push(["", "°C"], value),
        AsFahrenheit: (value = true) => push(["", "°F"], value),
        AsGravatar: (value = true) => {
            push("Gravatar", value);
        },
        AsIcon: (value = true) => {
            push("Icon", value);
        },
        AsPositions: (value = true) => {
            push("Positions", value);
        },
        AsQuantitySum: (value = true) => {
            push("QuantitySum", value);
        },
    }

    function push(deco: string | string[], doPush = true) {
        if (doPush) {
            unref(target).decoration.push(deco);
        }
        return decoration;
    }

    return decoration;
};


export interface NumberTarget extends Target {
    numberOptions?: NumberOptions;
}

export const useNumberDecoration = (target: NumberTarget | Ref<NumberTarget>) => {

    const decoration: NumberDecoration = {
        ...useDecoration(target),
        Digits: (value: number) => set(options => options.digits = value),
        SuppressZero: (value = true) => set(options => options.suppressZero = value),
    }

    function set(action: (options: NumberOptions) => void) {
        const t = unref(target);
        t.numberOptions = t.numberOptions || {};
        action(t.numberOptions);
        return decoration;
    }

    return decoration;
};
