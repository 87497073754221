﻿<template>
    <component :is="as" class="nav">
        <aunoa-nav-item icon="grip" class="pe-1" disabled tabindex="-1" v-if="grip" />
        <slot />
    </component>
</template>

<script lang="ts">

import {defineComponent} from "vue";
import AunoaNavItem from "./AunoaNavItem.vue";

export default defineComponent({
    name: "AunoaNav",
    components: {AunoaNavItem},
    props: {
        as: {
            default: "ul"
        },
        grip: {
            type: Boolean,
            default: false
        }
    },

});

</script>

