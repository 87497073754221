﻿//import {Transition, Tra} from "vue";

import {computed, defineComponent, toRefs, onBeforeMount, Transition, TransitionGroup} from "vue";


export default defineComponent({
    inheritAttrs: false,
    props: {
        duration: {
            type: [Number, Object],
            default: 300
        },
        delay: {
            type: [Number, Object],
            default: 0
        },
        group: {
            type: Boolean,
            default: false
        },
        tag: {
            type: String,
            default: "span"
        },
        origin: {
            type: String,
            default: ""
        },
        styles: {
            type: Object,
            default: () => {
                return {
                    animationFillMode: "both",
                    animationTimingFunction: "ease-out"
                }
            }
        }
    },
    // setup(props) {
    //
    //     const {group} = toRefs(props);
    //
    //     const componentType = computed(()=>group.value
    //         ? TransitionGroup
    //         : Transition);
    //
    //     return {
    //         componentType
    //     }
    //
    // },
    
    computed: {
        componentType():any {
            return this.group ? TransitionGroup : Transition
        },
        
        hooks():any {
            console.log(this);
            return {
                beforeEnter: this.beforeEnter,
                afterEnter: this.cleanUpStyles,
                beforeLeave: this.beforeLeave,
                leave: this.leave,
                afterLeave: this.cleanUpStyles,
                //...this.$listeners
            }
        }
    },
    methods: {
        beforeEnter(this:any, el:any) {
            const enterDuration = this.duration.enter ? this.duration.enter : this.duration;
            el.style.animationDuration = `${enterDuration}ms`;

            const enterDelay = this.delay.enter ? this.delay.enter : this.delay;
            el.style.animationDelay = `${enterDelay}ms`;

            this.setStyles(el)
        },
        cleanUpStyles(this:any, el:any) {
            Object.keys(this.styles).forEach(key => {
                const styleValue = this.styles[key];
                if (styleValue) {
                    el.style[key] = "";
                }
            })
            el.style.animationDuration = "";
            el.style.animationDelay = "";
        },
        beforeLeave(this:any, el:any) {
            const leaveDuration = this.duration.leave ? this.duration.leave : this.duration;
            el.style.animationDuration = `${leaveDuration}ms`;

            const leaveDelay = this.delay.leave ? this.delay.leave : this.delay;
            el.style.animationDelay = `${leaveDelay}ms`;

            this.setStyles(el)
        },
        leave(this:any, el:HTMLElement) {
            this.setAbsolutePosition(el);
        },
        setStyles(this:any, el:any) {
            this.setTransformOrigin(el);
            Object.keys(this.styles).forEach(key => {
                const styleValue = this.styles[key];
                if (styleValue) {
                    el.style[key] = styleValue;
                }
            })
        },
        setAbsolutePosition(this:any, el:any) {
            if (this.group) {
                el.style.position = "absolute";
            }
            return this;
        },
        setTransformOrigin(this:any, el:any) {
            if (this.origin) {
                el.style.transformOrigin = this.origin;
            }
            return this;
        }
    }


});
