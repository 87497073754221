<template>
    <teleport to="#endCommands">
        <slot />
    </teleport>
</template>

<script lang="ts">

import {usePageHost} from "../utils/usePageHost";
import {defineComponent} from "vue";

export default defineComponent({
    name: "AunoaEndCommandsPane",
    setup: () => usePageHost().withTeleport("endCommands")
});

</script>
