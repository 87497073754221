import type {InjectionKey, Ref} from "vue";
import {ref, readonly, onMounted, onUpdated, onBeforeUnmount, provide, inject, watch, markRaw} from "vue";
import {ResizeSensor} from "css-element-queries";
// @ts-ignore
import PackeryInstance from "packery";
import Draggabilly from "draggabilly";


interface Item {
    editMode: Ref<boolean>;
    width: Ref<number>;
    height: Ref<number>;
    fit(x?: number, y?: number): void;
    layout(): void;
}

declare interface PackeryItem {
    dropPlaceholder: HTMLElement;
    element: HTMLElement;
    isPlacing: boolean;
    isTransitioning: boolean;
    //...and more
}

const EDITMODE_INJECTION_KEY: InjectionKey<Ref<boolean>> = Symbol("AUNOA_PACKERY_EDITMODE");
const PACKERY_INJECTION_KEY: InjectionKey<Ref<Packery>> = Symbol("AUNOA_PACKERY_PACKERY");
const ITEM_INJECTION_KEY: InjectionKey<Item> = Symbol("AUNOA_PACKERY_ITEM");

export const providePackery = (options: Ref<PackeryOptions>, editMode: Ref<boolean>, emit: any) => {

    const packery = ref<Packery>();
    const containerElement = ref<HTMLElement>();

    const reloadItems = () => packery.value?.reloadItems();
    const layout = () => packery.value?.layout();

    const createPackery = (element: HTMLElement) => {
        const instance = markRaw(new PackeryInstance(element, options.value));

        const getIndex = (itemElement: HTMLElement) => instance.getItemElements().indexOf(itemElement);
        const getKeys = (packeryItems?: PackeryItem[]) => (packeryItems?.map(i => i.element) || instance.getItemElements()).map((e: any) => e.dataset["key"]);


        let oldIndex: any = undefined;
        instance.$$itemDragStart = instance.itemDragStart;
        instance.itemDragStart = (itemElement: HTMLElement) => {
            instance.$$itemDragStart(itemElement);
            oldIndex = getIndex(itemElement);
        }

        const onLayoutComplete = (laidOutItems: PackeryItem[]) =>
            emit("layoutCompleted", getKeys(laidOutItems));

        const onPositionChanged = (draggedItem: PackeryItem) =>
            emit("positionChanged", getIndex(draggedItem.element), oldIndex, getKeys());

        instance.on("layoutComplete", onLayoutComplete);
        instance.on("dragItemPositioned", onPositionChanged);

        onUpdated(() => setTimeout(() => instance.layout(), 10));
        packery.value = instance;

        return () => {
            instance.off("dragItemPositioned", onPositionChanged);
            instance.off("layoutComplete", onLayoutComplete);
            instance.destroy();
            packery.value = undefined;
        }
    }

    onMounted(() => {
        const dispose = createPackery(<HTMLElement>containerElement.value);
        onBeforeUnmount(dispose);
    });

    provide(EDITMODE_INJECTION_KEY, editMode);
    provide(PACKERY_INJECTION_KEY, packery);

    return {
        containerElement,
        reloadItems,
        packery,
        layout,
    }
}

export const providePackeryItem = () => {

    const _width = ref(0);
    const _height = ref(0);
    const itemElement = ref<HTMLElement>();

    const _packery = inject(PACKERY_INJECTION_KEY, () => ref<Packery>(), true);
    const _editMode = inject(EDITMODE_INJECTION_KEY, () => ref(false), true);

    const layout = () => _packery.value && _packery.value.layout();
    const fit = (x?: number, y?: number) => itemElement.value && _packery.value && _packery.value.fit(itemElement.value, x, y);

    const createDraggie = (element: HTMLElement) => {
        const draggie = markRaw(new Draggabilly(element));

        const disable = () => {
            draggie.disable();
            (<any>draggie).unbindHandles();
        }

        const enable = () => {
            (<any>draggie).bindHandles();
            draggie.enable();
        }

        const rect = element.getBoundingClientRect();
        _width.value = Math.round(rect.width);
        _height.value = Math.round(rect.height);

        const setSize = (size: { width: number; height: number; }) => {
            _width.value = size.width;
            _height.value = size.height;
        };

        const resizeSensor = new ResizeSensor(element, setSize);

        const unwatchPackery = watch(_packery, packery => {
            if (packery) {
                if (!packery.getItem(element)) {
                    //console.log("MOUNTED but NOT appended", element);
                    packery.addItems(element);
                }
                packery.bindDraggabillyEvents(draggie);
            }
        }, {immediate: true});

        const unwatchEditMode = watch(_editMode, editMode => editMode ? enable() : disable());
        if (!_editMode.value) {
            disable();
        }

        return () => {
            unwatchEditMode();
            unwatchPackery();
            resizeSensor.detach(setSize);
            draggie.destroy();
        }
    }

    onMounted(() => {
        const dispose = createDraggie(<HTMLElement>itemElement.value);
        onBeforeUnmount(dispose);
    });

    const item: Item = {
        width: readonly(_width),
        height: readonly(_height),
        editMode: readonly(_editMode),
        layout,
        fit
    }

    provide(ITEM_INJECTION_KEY, item);

    return {
        ...item,
        itemElement
    }
}

export const usePackery = () => {

    const packery = inject(PACKERY_INJECTION_KEY);

    const layout = () => packery?.value?.layout();
    const reloadItems = () => packery?.value?.reloadItems();

    return {
        layout,
        reloadItems
    }
}

export const usePackeryItem = () => {

    const warn = () => console.warn("No packery item provided");

    const item = <Item>inject(ITEM_INJECTION_KEY, () => ({
        fit: warn,
        layout: warn,
    }), true);

    return {
        ...item
    }
}