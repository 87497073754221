﻿import {computed, inject, InjectionKey, provide, ref} from "vue";

interface UndoRedo {
    dirtyChange: boolean;
    remark?: string;
    undo(): void;
    redo(): void;
}

const INJECTION_KEY: InjectionKey<(undoRedo: UndoRedo) => void> = Symbol();

export const provideUndoRedo = () => {

    const undoRedoStack = ref<UndoRedo[]>([]);
    provide(INJECTION_KEY, (undoRedo) => undoRedoStack.value.push(undoRedo));

    const hasDirtyChanges = computed(() => undoRedoStack.value.some(i => i.dirtyChange));

    const reset = () => {
        undoRedoStack.value.splice(0, undoRedoStack.value.length);
    }

    return {
        undoRedoStack,
        hasDirtyChanges,
        reset
    }
}

export const useUndoRedo = () => {

    const addUndoRedo = inject(INJECTION_KEY, undoRedo => {
    });

    return {
        addUndoRedo
    }
}


