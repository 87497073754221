import type {Ref} from "vue";
import type {Facade} from "./_abstracts";
import {HubConnection} from "@microsoft/signalr";
import {ref} from "vue";

export interface ActionFacade {

    resetProcessQueue(terminalOid:number): Promise<any>
    forcePosClassicSync(fullSync:boolean, tenantID:string|null|undefined, locationID:string|null|undefined, terminalGroup:string|null|undefined, terminalID:string|null|undefined,
    dataTypes:string[]|null|undefined): Promise<any>
}


export const createActionFacade = (connection: HubConnection): ActionFacade & Facade => {

    const resetProcessQueue = (terminalOid: number) => {
        return connection.invoke("ResetProcessQueue", terminalOid);
    }

    const forcePosClassicSync = (fullSync:boolean, tenantID:string|null|undefined, locationID:string|null|undefined, terminalGroup:string|null|undefined, terminalID:string|null|undefined,
                                 dataTypes:string[]|null|undefined) => {
        return connection.invoke("ForcePosClassicSync", fullSync, tenantID, locationID, terminalGroup, terminalID, dataTypes);
    }

    const initialize = async () => {        
    }

    const dispose = () => {
        // console.log("disposal");        
    }

    return {
        initialize,
        resetProcessQueue,
        forcePosClassicSync,
        dispose
    }
}