// @ts-ignore
import jsonlint from "jsonlint-mod/web/jsonlint";
import {isError} from "./inspect";

export function parseJson(text: string, reviver?: (this: any, key: string, value: any) => any): any {
    try {
        return JSON.parse(text, reviver);
    } catch (e) {
        try {
            return jsonlint.parse(text, reviver);
        } catch (e: any) {
            if (isError(e) && (e.message.startsWith("Parse error on line") || e.message.startsWith("Lexical error on line"))) {
                (e as any)["$$monospace"] = true;
            }
            throw e;
        }
    }
} 
