﻿import {ref, computed, onMounted, onBeforeUnmount, watch} from "vue";
import {WatchStopHandle} from "@vue/runtime-core";
import {useAunoaLayout} from "./layout";

let usageCount = 0;

export function useBodyScrollbar() {

    let disposables: WatchStopHandle[] | null;

    const {topVisible, bottomVisible, collapseHeader} = useAunoaLayout();

    const hasScrollbar = computed(() => !topVisible.value || !bottomVisible.value);
    const scrollbarAtTop = computed(() => topVisible.value && !bottomVisible.value);
    const scrollbarAtBottom = computed(() => !topVisible.value && bottomVisible.value);
    const scrollbarInBetween = computed(() => !topVisible.value && !bottomVisible.value);
    const canScrollToTop = computed(() => scrollbarAtBottom.value || scrollbarInBetween.value);
    const canScrollToBottom = computed(() => scrollbarAtTop.value);

    const body = document.body;

    onMounted(() => {
        if (usageCount++ === 0) {

            const watchOptions = {immediate: true};
            disposables = [
                //watch(topVisible, value => body.classList.toggle("view-at-top", value), watchOptions),
                watch([topVisible, collapseHeader], v => body.classList.toggle("collapse-header", !v[0] || v[1]), watchOptions),
                watch(hasScrollbar, value => body.classList.toggle("has-scrollbar", value), watchOptions),
                watch(scrollbarAtTop, value => body.classList.toggle("scrollbar-at-top", value), watchOptions),
                watch(scrollbarAtBottom, value => body.classList.toggle("scrollbar-at-bottom", value), watchOptions),
                watch(scrollbarInBetween, value => body.classList.toggle("scrollbar-in-between", value), watchOptions),
            ];
        }

    });

    onBeforeUnmount(() => {
        if (disposables) {
            disposables.forEach(disposable => disposable());
            disposables = null;
        }

        if (--usageCount === 0) {
            [
                "collapse-header",
                "has-scrollbar",
                "scrollbar-at-top",
                "scrollbar-at-bottom",
                "scrollbar-in-between"
            ].forEach(className => body.classList.toggle(className, false));
        }

    });

    const scrollToTop = () =>
        window.scroll({
            left: 0,
            top: 0,
            behavior: "smooth"
        });

    const scrollToBottom = () =>
        window.scroll({
            left: 0,
            top: document.body.scrollHeight,
            behavior: "smooth"
        });

    return {

        hasScrollbar,
        scrollbarAtTop,
        scrollbarAtBottom,
        scrollbarInBetween,
        canScrollToTop,
        canScrollToBottom,
        scrollToTop,
        scrollToBottom
    }

}