﻿import {computed, Ref} from "vue";
import {useI18n} from "vue-i18n";

export declare type TranslationEntry = string | (() => string);

export function useTranslationHelper<T>(obj: Ref<T> | undefined) {

    const {t} = useI18n();

    const translate = (
        getProperty: (o: T) => string | ((...p: any[]) => string) | undefined,
        defaultValue: string,
        ...args: any[]) => computed(() => {

        const value = obj?.value
            ? getProperty(obj.value)
            : undefined;

        switch (typeof value) {
            case "undefined":
                return defaultValue;
            case "string":
                return t(value, args);
            default:
                return value(...args);
        }
    });


    return {
        translate
    }
}

export const ensureClass = (cl:any[]) => {
    const ar = cl.filter(Boolean);
    return ar.length ? ar : undefined;
}  
