﻿<template>
    <aunoa-modal-dialog
        ref="modalDialog"
        id="reloginDialog"
        size="sm"
        backdrop="static"
        :disable-close-button="true"
        :centered="true"
        :keyboard="false"
    >
        <aunoa-auth-login
            v-if="loginRequest"
            :relogin="true"
            :login-request="loginRequest"
            :login-promise="loginPromise">
            <template v-slot:start v-if="user">
                <div class="my-2">
                    <aunoa-gravatar
                        class="rounded-circle gravatar"
                        :default-img="defaultGravatarImage"
                        :email-address="user.emailAddress"
                        :size="48"
                    />
                </div>
                <h5 v-text="user.username" />
                <div v-if="reason==='expired'" v-text="t('Aunoa.Auth.AccessTokenExpired')" />
                <div v-if="reason==='lock'" v-text="t('Aunoa.Auth.Locked')" />
            </template>
        </aunoa-auth-login>
        <template v-slot:footer>
            <div class="mt-2 small" v-if="logoutRoute">
                <span class="px-2 text-muted" v-text="t('Aunoa.Auth.SomeoneElse')" />
                <router-link :to="logoutRoute" v-text="t('Aunoa.Auth.LoginCommand')" />
            </div>
        </template>
    </aunoa-modal-dialog>
</template>

<script lang="ts">

import type {PropType} from "vue";
import type {ModalDialog} from "../types";
import type {User} from "../mixins/UserMixin";
import type {DefaultGravatarImage} from "../components/AunoaGravatar.vue";

import {useAunoaOptions, LoginRequest, AuthProvider} from "../utils/useAunoaOptions";
import {defineComponent, ref, toRefs, watch, computed} from "vue";
import AunoaGravatar from "../components/AunoaGravatar.vue";
import AunoaAuthLogin from "../views/AunoaAuthLogin.vue";
import AunoaModalDialog from "./AunoaModalDialog.vue";
import {useAunoaI18n} from "../utils/useAunoaI18n";
import {useRouter} from "vue-router";

export interface AuthRelogin {
    show(reason: "lock" | "expired"): Promise<void>;
}

export default defineComponent({
    name: "AunoaAuthRelogin",
    components: {
        AunoaModalDialog,
        AunoaAuthLogin,
        AunoaGravatar
    },
    props: {
        user: {
            type: Object as PropType<User>
        },
        defaultGravatarImage: {
            type: String as PropType<DefaultGravatarImage>,
            default: "blank"
        }
    },
    setup(props) {

        const {t} = useAunoaI18n();
        const reason = ref<string>();
        const modalDialog = ref<ModalDialog>();
        const loginRequest = ref<LoginRequest>();
        const {options: {auth}} = useAunoaOptions();
        const router = useRouter();
        let _resolve = () => {
        };

        const hide = () => {
            modalDialog.value?.hide();
            loginRequest.value = undefined;
            _resolve();
        }

        router.beforeEach((to) => {
            if ((loginRequest.value || auth.authProvider !== AuthProvider.Integrated) && to.name === auth.logoutRoute?.name) {
                hide();
                return new Promise(resolve => setTimeout(resolve, 350));
            }
        });

        const show = (withReason: "lock" | "expired") => {
          if(auth.authProvider === AuthProvider.Integrated) {
            loginRequest.value = {
              username: props.user?.username,
              password: undefined,
              remember: undefined
            }            
          }
          else {
            loginRequest.value = undefined;            
          }
          reason.value = withReason;
            modalDialog.value?.show();
            return new Promise<void>((resolve, reject) => {
                _resolve = resolve;
            });
        };

        const loginPromise = () => auth
            .reloginPromise(<LoginRequest>loginRequest.value)
            .then(hide);

        return {
            t,
            show,
            reason,
            modalDialog,
            loginRequest,
            loginPromise,
            ...auth
        }
    }
});

</script>


