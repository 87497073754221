import type {Conditions} from "../types";
import type {Ref, WatchStopHandle} from "vue";
import {isBoolean, isUnaryCondition, isBinaryOperatorCondition, isAllCondition, isAnyCondition, isNotCondition, isArray, isString} from "./inspect";
import {watch, ref, unref, readonly, computed, onBeforeUnmount} from "vue";
import {ensureResolvedPropertyPath, getValueFromPropertyPath} from "./properties";

interface Observer {
    bool: Ref<boolean>;
    dispose(): void;
}

interface ConditionResolver {
    get(): boolean;
    observe(): Observer;
}

const getValue = (obj: any, property: string) => {
    const value = getValueFromPropertyPath(obj, property);
    return value !== undefined ? value : null;
}

const getUnaryBool = (mode: Conditions.Model.UnaryConditionMode, value: any) => {

    switch (mode) {

        case "null":
            return value == null;
        case "notNull":
            return value != null;

        case "empty":
            return value == "";
        case "nullOrEmpty":
            return value == null || value == "";
        case "notEmpty":
            return value != "";
        case "notNullOrEmpty":
            return value != null && (value != "" || value?.length > 0);

        case "zero":
            return value == 0;
        case "nullOrZero":
            return value == null || value == 0;
        case "notZero":
            return value != 0;
        case "notNullOrZero":
            return value != null && value != 0;

        case "one":
            return value == 1;
        case "nullOrOne":
            return value == null || value == 1;
        case "notOne":
            return value != 1;
        case "notNullOrOne":
            return value != null && value != 1;

        case "positive":
            return value != null && value > 0;
        case "negative":
            return value != null && value < 0;

        case "false":
            return value == false;
        case "true":
            return value == true;
        case "notFalse":
            return value != false;
        case "notTrue":
            return value != true;
        case "nullOrFalse":
            return value == null || value == false;
        case "nullOrTrue":
            return value == null || value == true;
        default:
            return false;
    }
}

const getBinaryOperatorBool = (mode: Conditions.Model.BinaryOperatorConditionMode, obj: any, a: any, b: any) => {
    if (isString(b)) {
        b = ensureResolvedPropertyPath(b, obj);
    }

    switch (mode) {
        case "equal":
            return a == b;
        case "notEqual":
            return a != b;
        case "greaterThan":
            return a > b;
        case "greaterOrEqual":
            return a >= b;
        case "lessThan":
            return a < b;
        case "lessOrEqual":
            return a <= b;
        default:
            return false;
    }
}

const constantResolver = (value: boolean): ConditionResolver => {

    const get = () => value;

    const observe = (): Observer => {
        const bool = ref(value);
        const dispose = () => {
        };
        return {
            bool,
            dispose
        }
    }

    return {
        get,
        observe
    }
}

const unaryResolver = (obj: any, model: Conditions.Model.UnaryCondition): ConditionResolver => {

    const get = (o: any) => getUnaryBool(model.condition, getValue(o, model.property));

    const observe = (): Observer => {
        const bool = ref(false);
        const unwatch = watch(obj, o => bool.value = get(o), {immediate: true, deep: true});
        return {
            bool,
            dispose: unwatch
        }
    }

    return {
        get: () => get(unref(obj)),
        observe
    }
}

const binaryOperatorResolver = (obj: any, model: Conditions.Model.BinaryOperatorCondition): ConditionResolver => {

    const get = (e: any) => getBinaryOperatorBool(model.operator, obj, getValue(e, model.property), model.value);

    const observe = (): Observer => {
        const bool = ref(false);
        const unwatch = watch(obj, e => bool.value = get(e), {immediate: true, deep: true});
        return {
            bool,
            dispose: unwatch
        }
    }

    return {
        get: () => get(unref(obj)),
        observe
    }
}

const allResolver = (obj: any, model: Conditions.Model.AllCondition): ConditionResolver => {

    const resolvers = model.whenAll.map(m => createResolver(obj, m));
    const get = () => resolvers.every(r => r.get());

    const observe = () => {

        const observers = resolvers.map(r => r.observe());
        const bool = computed(() => observers.map(o => o.bool).every(b => unref(b)));
        const dispose = () => observers.forEach(o => o.dispose());

        return {
            bool,
            dispose
        }
    }

    return {
        get,
        observe
    }
}

const anyResolver = (obj: any, model: Conditions.Model.AnyCondition): ConditionResolver => {

    const resolvers = model.whenAny.map(m => createResolver(obj, m));
    const get = () => resolvers.some(r => r.get());

    const observe = () => {

        const observers = resolvers.map(r => r.observe());
        const bool = computed(() => observers.map(o => o.bool).some(b => unref(b)));
        const dispose = () => observers.forEach(o => o.dispose());

        return {
            bool,
            dispose
        }
    }

    return {
        get,
        observe
    }
}

const notResolver = (obj: any, model: Conditions.Model.NotCondition): ConditionResolver => {

    const resolver = createResolver(obj, model.not);
    const get = () => !resolver.get();

    const observe = () => {
        const observer = resolver.observe();
        const bool = computed(() => !unref(observer.bool));
        const dispose = () => observer.dispose();

        return {
            bool,
            dispose
        }
    }

    return {
        get,
        observe
    }
}

const createResolver = <T>(obj: T, model: Conditions.Model.Condition): ConditionResolver => {
    if (isBoolean(model)) {
        return constantResolver(model)
    }
    if (isUnaryCondition(model)) {
        return unaryResolver(obj, model);
    }
    if (isBinaryOperatorCondition(model)) {
        return binaryOperatorResolver(obj, model);
    }
    if (isAllCondition(model)) {
        return allResolver(obj, model);
    }
    if (isAnyCondition(model)) {
        return anyResolver(obj, model);
    }
    if (isNotCondition(model)) {
        return notResolver(obj, model);
    }
    return constantResolver(false);
}

export const resolveCondition = <T = any>(obj: T, model: Conditions.Model.Condition): boolean =>
    createResolver<T>(obj, model).get();

export const useConditionModel = <T = any>(obj: Ref<T>, model: Ref<Conditions.Model.Condition>) => {
    const _bool = ref(false);
    const _observer = ref<Observer>();

    //console.log(model.value, obj.value)

    let unwatchObserver: WatchStopHandle;

    const unwatch1 = watch(_observer, (observer, oldObserver) => {
        if (oldObserver) {
            unwatchObserver();
            oldObserver.dispose();
        }
        if (observer) {
            unwatchObserver = watch(() => observer.bool, b => _bool.value = unref(b), {immediate: true});
        }
    });

    const unwatch2 = watch(
        [obj, model],
        ([o, m]) => _observer.value = o && m ? createResolver<T>(o, m).observe() : undefined,
        {immediate: true, deep: true});

    onBeforeUnmount(() => {
        unwatch2();
        unwatch1();
        _observer.value = undefined
    });

    return {
        bool: readonly(_bool)
    }
}