﻿import type {CurrencyAmountOptions, DateFormat, DistanceDateFormat, NumberOptions, Conditions, Tables} from "../types";


// to


export const toType = (value: any) => typeof value;

export const toRawType = (value: any) => Object.prototype.toString.call(value).slice(8, -1);

export const toRawTypeLC = (value: any) => toRawType(value).toLowerCase();

// is null

export const isUndefined = (value: any) => value === undefined;

export const isDefined = (value: any) => value !== undefined;

export const isNull = (value: any) => value === null;

export const isUndefinedOrNull = (value: any) => isUndefined(value) || isNull(value);

// is primitive

export const isBoolean = (value: any): value is boolean => toType(value) === "boolean";

export const isString = (value: any): value is string => toType(value) === "string";

export const isNumber = (value: any): value is number => toType(value) === "number";

export const isIndex = (value: unknown): value is number => Number(value) >= 0;

export const isPrimitive = (value: any) => isBoolean(value) || isString(value) || isNumber(value);

// is 

export const isObject = (value: any) => typeof value === "object";


export const isPlainObject = (value: any) => Object.prototype.toString.call(value) === "[object Object]";


export const isArray = (value: any) => Array.isArray(value);

export const isEmptyString = (value: any) => value === "";

export const isUndefinedOrNullOrEmpty = (value: any) => isUndefinedOrNull(value) || isEmptyString(value);

export const isFunction = (value: any):value is Function => toType(value) === "function";


export const isDate = (value: any) => value instanceof Date;

export const isEvent = (value: any) => value instanceof Event;


export const isRegExp = (value: any) => toRawType(value) === "RegExp";

export const isPromise = <T = any>(value: any): value is Promise<T> => value && isFunction(value.then) && isFunction(value.catch);

export const isError = (value: any): value is Error => value instanceof Error;

export const hasMessage = (value: any): value is { message: string } => value && isString(value.message);


// lookup


export const isNumberOptions = (value: any): value is NumberOptions => isObject(value) && (isDefined(value.digits) || isDefined(value.suppressZero));

export const isDistanceDateFormat = (value: any): value is DistanceDateFormat => isObject(value) && value.distance;

export const isCurrencyAmountOptions = (value: any): value is CurrencyAmountOptions => isObject(value) && (isDefined(value.currency) || isDefined(value.suppressZero));

// conditions

export const isUnaryCondition = (value: any): value is Conditions.Model.UnaryCondition =>
    isObject(value) && value.property && value.condition;

export const isBinaryOperatorCondition = (value: any): value is Conditions.Model.BinaryOperatorCondition =>
    isObject(value) && value.property && value.operator && value.value;

export const isAllCondition = (value: any): value is Conditions.Model.AllCondition => isObject(value) && value.whenAll;

export const isAnyCondition = (value: any): value is Conditions.Model.AnyCondition => isObject(value) && value.whenAny;

export const isNotCondition = (value: any): value is Conditions.Model.NotCondition => isObject(value) && value.not;

// tables

export const isStartContent = (value: any): value is Tables.Model.StartContent => isObject(value) && value.start && !value.center && !value.end;

export const isCenterContent = (value: any): value is Tables.Model.CenterContent => isObject(value) && !value.start && value.center && !value.end;

export const isEndContent = (value: any): value is Tables.Model.EndContent => isObject(value) && !value.start && !value.center && value.end;

export const isJustifyContent = (value: any): value is Tables.Model.JustifyContent => isObject(value) && value.start && !value.center && value.end;

export const isLoopContent = (value: any): value is Tables.Model.LoopContent => isObject(value) && value.array && value.lines;

// currency amount

export const regexAC = /([\-+]?[0-9.,]{1,15})\s([a-zA-Z]{3})/; // 89.90 EUR

export const regexCA = /([a-zA-Z]{3})\s([\-+]?[0-9.,]{1,15})/; // EUR 89.90

export const isCurrencyAmountString = (value: any): value is string =>
    isString(value) && (!!regexAC.exec(value) || !!regexCA.exec(value));





