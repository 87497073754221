﻿import {ref, onMounted, onBeforeUnmount} from "vue";

const topVisible = ref(true);
const bottomVisible = ref(true);
const collapseHeader = ref(false);
const isMounted = ref(false);

// construction elements

const topElement = document.createElement("div");
topElement.classList.add("aunoa-top");

const bottomElement = document.createElement("div");
bottomElement.classList.add("aunoa-bottom");

// intersection observing

const observeOptions = {
    threshold: [0.0, 1.0]
};

const topObserver = new IntersectionObserver(([e]) => topVisible.value = e.intersectionRatio > 0, observeOptions);
const bottomObserver = new IntersectionObserver(([e]) => bottomVisible.value = e.intersectionRatio > 0, observeOptions);

// functions

export function getStickyTop() {
    const str = getComputedStyle(topElement).getPropertyValue("padding-top")
    return parseInt(str.substr(0, str.length-2));
}

// lifecycle

let usageCount = 0;

export function useAunoaLayout() {

    const body = document.body;

    onMounted(() => {
        if (usageCount++ === 0) {
            body.classList.toggle("aunoa", true);

            body.insertBefore(topElement, body.firstElementChild);
            body.appendChild(bottomElement);

            topObserver.observe(topElement);
            bottomObserver.observe(bottomElement);
            
            isMounted.value = true;
        }
    });

    onBeforeUnmount(() => {
        if (--usageCount === 0) {
            body.classList.toggle("aunoa", false);

            body.removeChild(topElement);
            body.removeChild(bottomElement);

            topObserver.unobserve(topElement);
            bottomObserver.unobserve(bottomElement);

            isMounted.value = false;
        }
    });

    return {
        isMounted,
        topVisible,
        bottomVisible,
        collapseHeader
    }


}