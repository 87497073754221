﻿<template>
    <aunoa-form
        class="text-center"
        :autocomplete="true"
        :entity="loginRequest"
        :entity-model="loginRequestModel"
        :on-submit="onSubmit">
        <slot name="start" v-if="$slots.start" />
        <i class="fat fa-users fa-4x d-block" v-else />

        <aunoa-text-box
            v-if="!relogin"
            field-class="mt-3"
            property="username"
            autocomplete="username"
            icon="fa-user"
            :placeholder="t('Aunoa.Auth.Username')"
            :aria-label="t('Aunoa.Auth.Username')"
        />

        <aunoa-text-box
            field-class="mt-3"
            property="password"
            autocomplete="password"
            icon="fa-lock"
            type="password"
            :placeholder="t('Aunoa.Auth.Password')"
            :aria-label="t('Aunoa.Auth.Password')"
        />

        <div class="text-end small mt-1" v-if="resetPasswordRoute && !relogin">
            <router-link :to="resetPasswordRoute" v-text="t('Aunoa.Auth.ForgotPasswordQuestion')" />
        </div>

        <slot v-if="$slots.default" />

        <div class="mt-3 form-check text-start" v-if="canRemember">
            <input
                class="form-check-input"
                type="checkbox"
                v-model="loginRequest.remember"
                id="remember-checked"
            >
            <label class="form-check-label small" for="remember-checked" v-text="t('Aunoa.Auth.RememberMe')" />
        </div>
        <div class="mt-3" v-else />

        <button type="submit" class="btn btn-primary btn-animatable mt-2 w-100" :class="{'btn-shake':serverError}" :disabled="busy">
            <i class="fad fa-spinner-third fa-spin" v-if="busy" />
            <span class="px-2" v-text="relogin ? t('Aunoa.Auth.ConfirmPasswordCommand') : t('Aunoa.Auth.LoginCommand')" />
        </button>

        <div class="form-field-error text-center py-2 font-weight-bold form-label-lg" v-if="serverError" v-text="ensureTextTranslated(serverError)">
        </div>

        <div class="mt-2 text-end small" v-if="allowRegistration && registerRoute && !relogin">
            <span class="px-2 text-muted" v-text="t('Aunoa.Auth.NoAccountQuestion')" />
            <router-link :to="registerRoute" v-text="t('Aunoa.Auth.RegisterCommand')" />
        </div>

        <slot name="end" v-if="$slots.end" />

    </aunoa-form>

</template>

<script lang="ts">

import {defineComponent, onBeforeMount, PropType, toRefs, ref, computed, Ref, toRef, watch} from "vue";
import {useAunoaI18n} from "../utils/useAunoaI18n";
import {isPromise} from "../utils/inspect";
import {useAunoaOptions, LoginRequest} from "../utils/useAunoaOptions";
import AunoaForm from "../components/form/AunoaForm.vue";
import AunoaTextBox from "../components/form/AunoaTextBox.vue";
import {Entities} from "../types";
import {useToasts} from "../utils/useToasts";


const loginRequestModel: Entities.Model.Property[] = [
    {name: "username", type: "String", required: true},
    {name: "password", type: "String", required: true}
];

export default defineComponent({
    name: "AunoaAuthLogin",
    components: {AunoaForm, AunoaTextBox},
    props: {
        loginRequest: {
            type: Object as PropType<LoginRequest>,
            required: true
        },
        loginPromise: {
            type: Function as PropType<() => Promise<void>>,
            required: true
        },
        canRemember: {
            type: Boolean,
            default: false
        },
        allowRegistration: {
            type: Boolean,
            default: true
        },
        relogin: {
            type: Boolean,
            default: false
        }        
    },
    setup: (props) => {

        const {loginRequest} = toRefs(props);

        const busy = ref(false);
        const serverError = ref();
        watch(loginRequest, r => serverError.value = undefined, {deep: true});

        const {t, ensureTextTranslated} = useAunoaI18n();
        const {options: {auth}} = useAunoaOptions();
        

        const onSubmit = (e: Event) => {
            serverError.value = undefined;
            if (props.loginPromise) {
                busy.value = true;
                props.loginPromise()
                    .catch((error: Error) => {
                        //let message = error.message;
                        // switch (error.errorCode) {
                        //     case 40101:
                        //     case 40102:
                        //         message = "@@Aunoa.Auth.Error.InvalidUsernamePassword";
                        //         break;
                        //     case 40103:
                        //         message = "@@Aunoa.Auth.Error.LockedOut";
                        //         break;
                        //     case 40104:
                        //         message = "@@Aunoa.Auth.Error.Disabled";
                        //         break;
                        // }                        
                        serverError.value = error.message;
                    }).finally(() => {
                    busy.value = false;
                });
            }
        };


        return {
            t,
            busy,
            onSubmit,            
            loginRequestModel,
            serverError,
            ensureTextTranslated,
            ...auth
        }
    }
});

</script>

