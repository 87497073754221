﻿import type {TextCasing} from "../../utils/textCasing";
import type {ComponentObjectPropsOptions, InjectionKey, PropType, Ref} from "vue";

import {isArray, isBoolean, isDefined, isFunction, isNumber, isObject, isString} from "../../utils/inspect";
import {computed, inject, provide, ref, toRefs, watch} from "vue";
import {getValueFromPropertyPath} from "../../utils/properties";
import {useAunoaI18n} from "../../utils/useAunoaI18n";
import {stringToHslStyles} from "../../utils/chips";
import {toCase} from "../../utils/textCasing";
import {useEntity} from "../useEntity";

// ############################################### types and interfaces

type Nullable<T> = T | null;

export interface LookupDetailedDisplay {
    text: Nullable<string>;
    icon?: Nullable<string>;
    country?: Nullable<string>;
    shortcut?: Nullable<string>;
    disabled?: boolean;
    deleted?: boolean;
    colorDeterminationText?: string;
}

export type LookupDisplay = string | number | LookupDetailedDisplay;

export interface LookupOption<TValue = any> {
    value: TValue;
    display: LookupDisplay;
}

export interface LookupDetailedOption<TValue = any> {
    value: TValue;
    display: LookupDetailedDisplay;
}

export interface Lookup<TEntity = any, TValue = any> {
    icon?: string;
    coloredIconBackground?: boolean;

    resolve(value: TValue, entity: TEntity, ...args: any[]): LookupDisplay | Promise<LookupDisplay> | null;

    options?(entity: TEntity): LookupOption<TValue>[];
}

export interface LookupFactory<TEntity = any, TValue = any> {
    name: string;

    create(): Lookup<TEntity, TValue>;
}

export type Lookups = Record<string, Lookup>;

export type LookupFactories = Record<string, LookupFactory>;

export type DataSource<TEntity = any, TValue = any> =
    string
    | Record<any, string | LookupDisplay>
    | Lookup<TEntity, TValue>
    | LookupFactory<TEntity, TValue>
    | LookupOption<TValue>[];

export type Expression = string | ((o: any) => any);

export interface LookupProps<TEntity = any, TValue = any> {
    dataSource: DataSource<TEntity, TValue>;
    valueExpression: Expression;
    displayExpression: Expression;
    colorDeterminationTextExpression: Expression;
    icon: string;
    coloredIconBackground: boolean;
    displayCasing: TextCasing;
}

export interface LookupUse<TValue = any> {
    lookup: Ref<Lookup<TValue>>;
    lookupError: Ref<string>;
    defaultIcon: Ref<string>;

    options: Ref<LookupDetailedOption<TValue>[]>;
    optionsDict: Ref<Record<any, LookupDetailedOption>>;

    optionsHaveIcons: Ref<boolean>;
    optionsHaveFlagIcons: Ref<boolean>;
    optionsHaveColors: Ref<boolean>;
    optionsAreBooleans: Ref<boolean>;

    ensureValue(value: TValue): TValue;
    getOption(value: TValue): LookupDetailedOption;
    getDisplay(value: TValue): LookupDetailedDisplay;
    getIcon(option: LookupDetailedOption): any;
    getFlagIcon(option: LookupDetailedOption): any;
    getTranslatedText(option: LookupDetailedOption): string;
    getColoredIconStyle(option: LookupDetailedOption): any;
}


// ############################################### helper

export const isLookupFactory = (value: any): value is LookupFactory =>
    value && isString(value.name) && isFunction(value.create);

export const isLookup = (value: any): value is Lookup =>
    value && isFunction(value.resolve);

export const isLookupDetailedDisplay = (value: any): value is LookupDetailedDisplay =>
    value && isDefined(value.text);

const get = (option: LookupOption, expression: Expression) =>
    isString(expression)
        ? getValueFromPropertyPath(option, expression)
        : isFunction(expression) ? expression(option) : option;

const get2 = (option: LookupOption, expression: Expression) =>
    isString(expression)
        ? getValueFromPropertyPath(option, expression)
        : isFunction(expression) ? expression(option) : undefined;

const toArray = (options: any) => Object
    .entries(options)
    .map(([value, display]) => ({value, display})) as any[];

const ensureArray = (options: any): any[] => isArray(options)
    ? options
    : isObject(options) ? toArray(options) : Array.from(options);

export const optionsToDict = (options: LookupOption[]) => options.reduce((dict, option) => {
    dict[option.value] = <LookupDetailedOption>{
        value: option.value,
        display: <LookupDetailedDisplay>option.display
    };
    return dict;
}, <Record<string, LookupDetailedOption>>{});

export const getLookupText = (display: LookupDisplay) =>
    isLookupDetailedDisplay(display)
        ? display.text
        : display?.toString();

// ############################################### lookup factories

const INJECTION_KEY: InjectionKey<Ref<LookupFactories>> = Symbol();

export const provideLookupFactories = (lookupFactories: Ref<LookupFactories | undefined>) => {

    provide(INJECTION_KEY, lookupFactories);
}

export const useLookupFactories = () => {

    const lookupFactories = inject(INJECTION_KEY, ref<LookupFactories>({}));

    const createLookup = <TEntity = any, TValue = any>(name: string) => {
        const factory = lookupFactories.value[name] as LookupFactory<TEntity, TValue>;
        return factory?.create();
    }

    return {
        createLookup
    }
}


// ############################################### lookup

export const lookupProps: ComponentObjectPropsOptions<LookupProps> = {
    dataSource: {
        type: [String, Object, Array] as PropType<DataSource>,
        default: undefined,
        required: true
    },
    valueExpression: {
        type: [String, Function] as PropType<Expression>,
        default: () => (o: any) => o.value
    },
    displayExpression: {
        type: [String, Function] as PropType<Expression>,
        default: () => (o: any) => o.display
    },
    colorDeterminationTextExpression: {
        type: [String, Function] as PropType<Expression>,
        default: undefined
    },
    icon: {
        type: String,
        default: undefined
    },
    coloredIconBackground: {
        type: Boolean,
        default: undefined
    },
    displayCasing: {
        type: String as PropType<TextCasing>,
        default: undefined
    }
}

export const useLookup = <TEntity = any, TValue = any>(props: LookupProps) => {

    const {dataSource} = toRefs(props);

    const lookupError = ref<string>();
    const lookup = ref<Lookup<TEntity, TValue>>();

    const defaultIcon = computed(() => props.icon || lookup.value?.icon);

    const {entity} = useEntity({});
    const {createLookup} = useLookupFactories();
    const {ensureTextTranslated} = useAunoaI18n();

    const valueOf = (option: LookupOption<TValue>) => get(option, props.valueExpression) as TValue;
    const displayOf = (option: LookupOption<TValue>) => get(option, props.displayExpression) as LookupDisplay;
    const colorDeterminationTextOf = (option: LookupOption<TValue>) => get2(option, props.colorDeterminationTextExpression) as string;

    const coloredIconBackground = () => isDefined(props.coloredIconBackground)
        ? props.coloredIconBackground
        : !!lookup.value?.coloredIconBackground;

    const toDisplayCase = (text: any) => text && props.displayCasing
        ? toCase(text, props.displayCasing)
        : text;

    //const getDisplay = (o: any) => toDisplayCase(get(o, displayExpression.value)) as unknown as any;

    const ensureDetailedDisplay = (display: LookupDisplay, colorDeterminationText?: string): LookupDetailedDisplay => {
        const detailed = (isLookupDetailedDisplay(display)
            ? display
            : isNumber(display)
                ? {text: display} // may we have to convert to string using culture
                : {text: toDisplayCase(display)}) as LookupDetailedDisplay;
        detailed.colorDeterminationText = detailed.colorDeterminationText || colorDeterminationText;
        return detailed;
    }

    const createLookupDetailedOption = (option: LookupOption<TValue>): LookupDetailedOption<TValue> => ({
        value: valueOf(option),
        display: ensureDetailedDisplay(displayOf(option), colorDeterminationTextOf(option))
    })

    watch(dataSource, value => {
        lookupError.value = undefined;
        if (isString(value)) {
            lookup.value = createLookup(value);
            if (!lookup.value) {
                lookupError.value = `Named Lookup '${value}' not found or lookup factory is invalid or missing`;
            }
        } else if (isLookupFactory(value)) {
            lookup.value = value.create();
        } else if (isLookup(value)) {
            lookup.value = value;
        } else if (isArray(value)) {
            const options = (<[]>value).map(o => isArray(o) ? {value: o[0], display: {text: o[1], icon: o[2]}} : o) as LookupDetailedOption<TValue>[];
            lookup.value = {
                options: e => options,
                resolve: (v, e, args) => options.filter(option => valueOf(option) == v).map(displayOf)[0],
            }
        } else if (isObject(value)) {
            const options = toArray(value);
            lookup.value = {
                options: e => options,
                resolve: (key, e, args) => (<any>value)[key] as any
            }
        } else {
            lookupError.value = `Lookup unknown or missing`;
            lookup.value = undefined;
        }
    }, {immediate: true})

    const options = computed<LookupDetailedOption<TValue>[]>(() =>
        lookup.value && lookup.value.options
            ? ensureArray(lookup.value.options(entity.value)).map(createLookupDetailedOption)
            : []);

    const optionsDict = computed(() => optionsToDict(options.value));

    const optionsHaveIcons = computed(() => !!defaultIcon.value ||
        options.value.some(option => isLookupDetailedDisplay(option.display) && option.display.icon));

    const optionsHaveFlagIcons = computed(() =>
        options.value.some(option => isLookupDetailedDisplay(option.display) && option.display.country));

    const optionsHaveColors = computed(() => coloredIconBackground());

    const optionsAreBooleans = computed(() =>
        options.value.length >= 2 &&
        options.value.length <= 3 &&
        isBoolean(options.value[0].value) &&
        isBoolean(options.value[1].value)
    );

    const ensureValue = (value: TValue) =>
        optionsDict.value[<any>value]?.value || value;

    const getOption = (value: TValue) =>
        optionsDict.value[<any>value];

    const getDisplay = (value: TValue) =>
        lookup.value
            ? ensureDetailedDisplay(lookup.value.resolve(value, entity.value) as LookupDisplay)
            : undefined;

    const getIcon = (option: LookupDetailedOption) => {
        const icon = option && option.display && isLookupDetailedDisplay(option.display) && option.display.icon
            ? option.display.icon
            : undefined;
        return icon || defaultIcon.value || "far";
    };

    const getFlagIcon = (option: LookupDetailedOption) =>
        option && option.display && option.display.country && isLookupDetailedDisplay(option.display) 
            ? `flag-icon-${option.display.country}`.toLowerCase()
            : undefined;


    const getDeletedIcon = (option: LookupDetailedOption) => {
        const icon = option && option.display && isLookupDetailedDisplay(option.display) && option.display.icon
            ? option.display.icon
            : undefined;
        return icon || defaultIcon.value || "far";
    };


    const getTranslatedText = (option: LookupDetailedOption) =>
        ensureTextTranslated(getLookupText(option?.display) || "") || option?.value || "";

    const getColoredIconStyle = (option: LookupDetailedOption) =>
        option && option.display && optionsHaveColors.value
            ? stringToHslStyles(option.display.colorDeterminationText || option.display.text)
            : undefined;

    return <LookupUse>{
        lookup,
        lookupError,
        defaultIcon,

        options,
        optionsDict,

        optionsHaveIcons,
        optionsHaveFlagIcons,
        optionsHaveColors,
        optionsAreBooleans,

        ensureValue,

        getOption,
        getDisplay,
        getIcon,
        getFlagIcon,
        getTranslatedText,
        getColoredIconStyle

    }

};