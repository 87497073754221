﻿import {App} from "vue";
import AunoaNav from "./AunoaNav.vue";
import AunoaNavItem from "./AunoaNavItem.vue";

export interface NavComponentOptions {
}

export function installNavComponents(app: App, options: NavComponentOptions) {
    options = options || {};
    app.component(AunoaNav.name, AunoaNav);
    app.component(AunoaNavItem.name, AunoaNavItem);
}

export {
    AunoaNav,
    AunoaNavItem
}
