﻿<template>
    <aunoa-card v-bind="cardAttrs">
        <aunoa-card-header :icon="icon" :text="title(t)" :to="link" />

        <div class="card-body pb-3">
            <table class="charts-css bar show-labels data-spacing-1 labels-align-start" style="--labels-size: 0">
                <tbody>
                <tr v-for="{name, total} in topTables">
                    <td style="--color:var(--aunoa-opacity-3-color)" :style="{'--size':total/totalTotal}">&nbsp;</td>
                    <th scope="row" class="fw-normal w-100" style="">
                        <div class="w-100 d-flex justify-content-between ps-2">
                            <span v-text="name" class="text-muted small" />
                            <aunoa-abbreviated-value :value="total*1024" unit="bytes" />
                        </div>
                    </th>
                </tr>
                </tbody>
            </table>
            <div class="text-muted text-end" v-text="'&hellip;'+t('Mate.AndCountMore',{count:totalCount-topTables.length})" />
        </div>
        <div class="card-footer d-flex justify-content-between align-items-start">
            <div>
                <span class="text-muted" v-text="t('Mate.Total')" />
            </div>
            <div class="text-end">
                <aunoa-abbreviated-value class="text-primary" :value="totalTotal*1024" unit="bytes" />
            </div>
        </div>

    </aunoa-card>
</template>

<script lang="ts">

import type {TableInfo} from "@tnt/mate-api";

import {useAunoaI18n, usePackeryItem, toByteSizeStr, AunoaCard, AunoaCardHeader, AunoaAbbreviatedValue} from "bootstrap-aunoa";
import {defineComponent, ref, nextTick} from "vue";
import {useDashboardCard} from "../useDashboard";
import {useAuth} from "../../../utils/useAuth";
import {useInfoApi} from "@tnt/mate-api";

const take = 5;
const totalCount = ref(0);
const totalTotal = ref(0);
const topTables = ref<TableInfo[]>([]);

const meta = {
    createdAt: "2021-04-22T00:00:00.000Z",
    icon: "fad fa-database",
    title: (t: any) => t("Sidebar.UsedTableSpace")
}

export default defineComponent({
    $$meta: meta,
    name: "UsedTableSpaceCard",
    components: {AunoaCard, AunoaCardHeader, AunoaAbbreviatedValue},
    setup() {

        const {t} = useAunoaI18n();
        const {fit} = usePackeryItem();
        const {accessToken} = useAuth();
        const {getDatabaseUsageSummary} = useInfoApi(accessToken);

        const card = useDashboardCard({
            link: "/diagnostics/database-usage",
            update: () => getDatabaseUsageSummary(take).then(r => {
                totalCount.value = r.count;
                totalTotal.value = r.total;
                topTables.value = r.topTables;
                nextTick(fit);
            })
        });

        return {
            t,
            //permitted,
            topTables,
            totalCount,
            totalTotal,
            toByteSizeStr,
            ...meta,
            ...card
        }
    }
});

</script>
