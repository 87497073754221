﻿<template>
    <component :is="tag" class="container-content" :class="{
        'container-content-fixed': disableBodyScrolling,
        'not-permitted': !permitted
    }">
        <aunoa-container
            class="h-100"
            :class="disableHorizontalPadding?'px-0':undefined"
            tag="div"
            :is-fluid="isFluid">
            <slot />
        </aunoa-container>
    </component>
</template>

<script lang="ts">

import {defineComponent} from "vue";
import {AunoaContainer} from "..";

export default defineComponent({
    name: "AunoaContentContainer",
    components: {
        AunoaContainer,
    },
    props: {
        tag: {
            type: String,
            default: "div"
        },
        isFluid: {
            type: Boolean,
            default: undefined
        },
        permitted: {
            type: Boolean,
            default: true
        },
        disableBodyScrolling: {
            type: Boolean,
            default: false
        },
        disableHorizontalPadding: {
            type: Boolean,
            default: false
        }
    }
});

</script>
