import type {Ref} from "vue";
import {onBeforeUnmount, onMounted, ref} from "vue";

type Component = "dropdown" | "modal" | "tab";

interface EventsOptions {
    element?: Ref;
}

interface VisibilityEventsOptions extends EventsOptions {
    onShow?(): void;
    onShown?(): void;
    onHide?(): void;
    onHidden?(): void;
}

export const getVisibilityEventNames = (component: Component) => [
    `show-${component}`, `${component}-shown`,
    `hide-${component}`, `${component}-hidden`
];

export const useVisibilityEvents = (component: Component, options: VisibilityEventsOptions) => {

    const eventMapping: Record<string, string> = {
        "onShow": `show.bs.${component}`,
        "onShown": `shown.bs.${component}`,
        "onHide": `hide.bs.${component}`,
        "onHidden": `hidden.bs.${component}`
    };

    const element = options.element || ref();

    onMounted(() => {
        const htmlElement = element.value?.$el || element.value;
        if (htmlElement) {
            Object.keys(eventMapping).filter(ev => (<any>options)[ev]).forEach((ev) => {
                htmlElement.addEventListener(eventMapping[ev], (<any>options)[ev]);
            });
            onBeforeUnmount(() => {
                Object.keys(eventMapping).filter(ev => (<any>options)[ev]).forEach((ev) => {
                    htmlElement.removeEventListener(eventMapping[ev], (<any>options)[ev]);
                })
            });
        }
    });

    return {
        element
    }
}

export const useEmitableVisibilityEvents = (component: Component, emit: any, options?: EventsOptions) =>
    useVisibilityEvents(component, {
        ...options,
        onShow: () => emit(`show-${component}`),
        onShown: () => emit(`${component}-shown`),
        onHide: () => emit(`hide-${component}`),
        onHidden: () => emit(`${component}-hidden`)
    })