﻿import type {TextCasing} from "../utils/textCasing";
import type {LookupFactory} from "./lookup/useLookup";
import type {CurrencyAmountOptions, DateFormat, LookupDisplayOptions, NumberOptions, Tables} from "../types";

import {useCurrencyAmountDecoration} from "./decoration/useCurrencyAmountDecoration";
import {useDecoration, useNumberDecoration} from "./decoration/useDecoration";
import {useLookupDisplayOptions} from "./lookup/useLookupOptions";
import {useDateDecoration} from "./decoration/useDateDecoration";
import {getPropertyName} from "../utils/reflectionLike";
import {isUndefined, isString, isObject, isFunction} from "../utils/inspect";
import {createCondition} from "./conditionsModel";
import {buildArrayProperty} from "../utils/properties";


declare type WriteFunc = (w: Tables.Builder.EntityProperty) => void;

declare type HorizontalTextAlignment = "start" | "center" | "end" | "justify";

type LineModel = Tables.Model.Line | undefined;

export interface GridModelOptions {
    locale?: string;
}

export interface GridModelContext {
    options: GridModelOptions;
    columns: {
        createModel(): Tables.Model.Column
    }[];
}

interface SpanContext {
    property?: string;
    //static?: string;
    decoration: (string | string[])[];
    fallback?: string;
    lookupSource?: string;
    lookupDisplayOptions?: {
        expression: () => string;
        casing: () => TextCasing;
    };
    numberOptions?: NumberOptions;
    dateFormat?: DateFormat;
    currencyAmountOptions?: CurrencyAmountOptions;
}


//const isBaa = <T>(value: any): value is Baa<T> => typeof value === "function";
function isWriteFunc(value: any): value is WriteFunc {
    if (typeof value === "function") {
        //console.log("isBaa", value);
    }
    return typeof value === "function";
}

function useLookup<T, TKey>(spanContext: SpanContext) {

    const lookup: Tables.Builder.Lookup<T, TKey> = {
        WithDataSource: (source: string | LookupFactory) => {
            spanContext.lookupSource = isString(source)
                ? source
                : source?.name;
            return lookup;
        },
        UseDisplay: (options?: (d: LookupDisplayOptions) => void) => {
            if (options) {
                const displayOptions = useLookupDisplayOptions();
                spanContext.lookupDisplayOptions = displayOptions;
                options(displayOptions.options);
            }
            return useDecoration(spanContext);
        }
    }

    return lookup;
}

const propertyWriter = (spanContext: SpanContext) => {

    const getModel = (propertyName: any) => spanContext.property
        ? `${spanContext.property}.${propertyName}`
        : propertyName;

    const writer: Tables.Builder.Property = {
        Property: getProperty => {
            spanContext.property = getModel(getPropertyName(getProperty));
            return propertyWriter(spanContext);
        },
        String: getProperty => {
            spanContext.property = getModel(getPropertyName(getProperty));
            return useDecoration(spanContext);
        },
        StringArray: getProperty => {
            spanContext.property = getModel(getPropertyName(getProperty));
            return useDecoration(spanContext);
        },
        Number: getProperty => {
            spanContext.property = getModel(getPropertyName(getProperty));
            return useNumberDecoration(spanContext);
        },
        Date: getProperty => {
            spanContext.property = getModel(getPropertyName(getProperty));
            return useDateDecoration(spanContext);
        },
        CurrencyAmount: (amount: (o: any) => any, currency?: (o: any) => string) => {
            if (currency) {
                spanContext.currencyAmountOptions = {
                    currency: "{{" + getModel(getPropertyName(currency)) + "}}"
                }
            }
            spanContext.property = getModel(getPropertyName(amount));
            return useCurrencyAmountDecoration(spanContext);

        },
        Lookup: (getProperty: (o: any) => any) => {
            spanContext.property = getModel(getPropertyName(getProperty));
            return useLookup<any, any>(spanContext);
        }
    }

    return writer;
};

const entityPropertyWriter = (spanContext: SpanContext) => {
    spanContext.property = "Entity";

    const writer: Tables.Builder.EntityProperty = {
        ...propertyWriter(spanContext),
        Context: () => {
            spanContext.property = undefined;
            return writer
        }
    }

    return writer;
};


const createSpanFunc = (value: string | WriteFunc | object, decorationFunc?:any): (() => string | undefined) => {

    let decoration:string[];
    if (decorationFunc) {
        const o = {decoration: []};
        const d = useDecoration(o);
        decorationFunc(d);
        decoration = o.decoration
    }
        
    if (isObject(value)) {
        return (): any => value;
    } else if (isString(value)) {
        return (): any => ({content: value, decoration});
    } else if (isWriteFunc(value)) {
        //const content: Tables.Model.Content = {};
        const spanContext: SpanContext = {
            property: "Entity",
            decoration: []
        };
        const propertyWriter = entityPropertyWriter(spanContext);
        value(propertyWriter);

        //console.log(JSON.stringify(spanContext));
        return (): any => (<Tables.Model.Span>{
            content: `{{${spanContext.property}}}`,
            fallback: spanContext.fallback,
            numberOptions: spanContext.numberOptions,
            dateFormat: spanContext.dateFormat,
            currencyAmountOptions: spanContext.currencyAmountOptions && isUndefined(spanContext.currencyAmountOptions.suppressZero)
                ? spanContext.currencyAmountOptions.currency
                : spanContext.currencyAmountOptions,
            lookup: spanContext.lookupSource ? {
                dataSource: spanContext.lookupSource,
                displayCasing: spanContext.lookupDisplayOptions && spanContext.lookupDisplayOptions.casing
                    ? spanContext.lookupDisplayOptions.casing()
                    : undefined
            } : undefined,
            decoration: spanContext.decoration.length
                ? spanContext.decoration
                : undefined
        });
    } else {
        return () => undefined;
    }
};

const createLineModel = (spans: any[], endSpans: any[], alignment: HorizontalTextAlignment = "start"): LineModel => {

    if (spans.length && endSpans.length) {
        return {
            start: spans.map(s => s()),
            end: endSpans.map(s => s())
        }
    }

    if (endSpans.length) {
        return {
            end: endSpans.map(s => s())
        }
    }

    if (spans.length) {
        return alignment === "end"
            ? {end: spans.map(s => s())}
            : alignment === "center"
                ? {center: spans.map(s => s())}
                : {start: spans.map(s => s())}
    }

    return undefined;
};

const createSpacingBuilder = (context: GridModelContext, spans: any[]) => {

    const spacingBuilder: Tables.Builder.Spacing = {
        Colon() {
            if (context.options.locale === "ru")
                spans.push(createSpanFunc(" :"));
            else
                spans.push(createSpanFunc(":"));
        }
    }

    return spacingBuilder;
}

const createEndSpanBuilder = (
    context: GridModelContext,
    lineModels: LineModel[],
    lineBuilder: Tables.Builder.Line,
    spans: any[],
    endSpans: any[],
    spacing?: (s: Tables.Builder.Spacing) => void
) => {

    if (spacing) {
        const s = createSpacingBuilder(context, spans);
        spacing(s)
    }

    const spanBuilder: Tables.Builder.EndSpan = {
        If: value => {
            endSpans.push(createSpanFunc({if: createCondition(value)}));
            return spanBuilder;
        },
        ElseIf: value => {
            endSpans.push(createSpanFunc({elseIf: createCondition(value)}));
            return spanBuilder;
        },
        Else: () => {
            endSpans.push(createSpanFunc({else: {}}));
            return spanBuilder;
        },
        EndIf: () => {
            endSpans.push(createSpanFunc({endIf: {}}));
            return spanBuilder;
        },
        Write: (value, decorationFunc?: any) => {
            endSpans.push(createSpanFunc(value, decorationFunc));
            return spanBuilder;
        },
        WriteLine: () => {
            lineModels.push(createLineModel(spans, endSpans));
            return createLineBuilder(context, lineModels);
        }
    };

    return spanBuilder;
};

const createSpanBuilder = (
    context: GridModelContext,
    lineModels: LineModel[],
    lineBuilder: Tables.Builder.Line,
    spans: any[],
    endSpans: any[]
) => {

    const spanBuilder: Tables.Builder.Span = {
        If: value => {
            //console.log("span, If");
            spans.push(createSpanFunc({if: createCondition(value)}));
            return spanBuilder;
        },
        ElseIf: value => {
            spans.push(createSpanFunc({elseIf: createCondition(value)}));
            return spanBuilder;
        },
        Else: () => {
            spans.push(createSpanFunc({else: {}}));
            return spanBuilder;
        },
        EndIf: () => {
            //console.log("span, EndIf");
            spans.push(createSpanFunc({endIf: {}}));
            return spanBuilder;
        },
        Write: (text: string | WriteFunc, decorationFunc?: any) => {
            //console.log("span, Write", text);
            spans.push(createSpanFunc(text, decorationFunc));
            return spanBuilder;
        },
        MoveToEnd: (spacing?: (s: Tables.Builder.Spacing) => void) => {
            return createEndSpanBuilder(context, lineModels, lineBuilder, spans, endSpans, spacing);
        },
        WriteLine: () => {
            //console.log("span, WriteLine");
            lineModels.push(createLineModel(spans, endSpans));
            //return lineBuilder;
            return createLineBuilder(context, lineModels);
        }
    };

    return spanBuilder;
};


const createLineBuilder = (context: GridModelContext, lineModels: LineModel[]) => {

    const spans: any[] = [];
    const endSpans: any[] = [];

    const lineBuilder: Tables.Builder.Line =
        {
            If: value => {
                //console.log("LINE, If");
                spans.push(createSpanFunc({if: createCondition(value)}));
                return lineBuilder;
            },
            ElseIf: value => {
                spans.push(createSpanFunc({elseIf: createCondition(value)}));
                return lineBuilder;
            },
            Else: () => {
                spans.push(createSpanFunc({else: {}}));
                return lineBuilder;
            },
            EndIf: () => {
                //console.log("LINE, EndIf", spans.length, endSpans.length);
                const endIfSpan = createSpanFunc({endIf: {}});
                if (spans.length === 0 && endSpans.length === 0) {
                    ///const lm = lineModels[lineModels.length-1];
                    //console.log(lm)
                } else {
                }
                spans.push(endIfSpan);
                return lineBuilder;
            },
            MoveToEnd: () => lineBuilder.Write("").MoveToEnd(),
            Write: (value: string | WriteFunc, decorationFunc?: any) => {
                const spanBuilder = createSpanBuilder(context, lineModels, lineBuilder, spans, endSpans);
                return spanBuilder.Write(<any>value, decorationFunc);
            },
            WriteLine: (value?: string | WriteFunc, decorationFunc?: any) => {
                //console.log("LINE, WriteLine");
                if (value) {
                    spans.push(createSpanFunc(value, decorationFunc));
                }
                const contentModel = createLineModel(spans, endSpans);
                lineModels.push(contentModel);
                return createLineBuilder(context, lineModels)
            },
            Loop: (getter, options, max) => {
                const properties: string[] = ["Entity"];

                const builder = buildArrayProperty(properties);
                getter?.(builder);
                const itemContentModels: any[] = [];

                const itemLineBuilder = createLineBuilder(context, itemContentModels);
                options?.(itemLineBuilder);

                lineModels.push({
                    array: properties.join("."),
                    lines: itemContentModels.filter(Boolean),
                    max
                });

                const contentModel = createLineModel(spans, endSpans);
                lineModels.push(contentModel);
                return createLineBuilder(context, lineModels)
            }
        };

    return lineBuilder;
}

const createColumn = (context: GridModelContext) => {
    let titles: string[] = [];
    let width: number;
    const lineModels: LineModel[] = []
    const columnBuilder: Tables.Builder.Column =
        {
            ...createLineBuilder(context, lineModels),
            Width: (percentage: number) => {
                width = percentage;
                return columnBuilder;
            },
            Head: (...values: string[]) => {
                titles = values;
                return columnBuilder;
            },
        };

    return {
        builder: columnBuilder,
        createModel: () => (<Tables.Model.Column>{
            width: width,
            head: {
                title: titles
            },
            lines: lineModels.filter(Boolean)
        })
    }
};


export const createGridModel = <TEntity = any, TState = any, TEntityState = any>(options?: GridModelOptions):
    Tables.Builder.Grid<TEntity, TState, TEntityState> => {

    const context: GridModelContext = {
        options: options || {},
        columns: []
    }

    const grid: Tables.Builder.Grid<TEntity, TState, TEntityState> = {
        // Locale: (locale: string) => {
        //     context.locale = locale;
        //     return tableBuilder
        // },
        Column: (options: (col: Tables.Builder.Column) => void) => {
            const column = createColumn(context);
            options(column.builder);
            context.columns.push(column);
            return grid;
        },
        Build: () => ({
            columns: context.columns.map(column => column.createModel())
        })
    }

    return grid
};
