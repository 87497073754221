﻿import {isArray, isBoolean, isObject} from "../../utils/inspect";
import {ref, Ref, watch} from "vue";

export interface Enabling {
    e?: boolean | null; // enabled
}

export interface Relationship extends Enabling {
    o?: number; // oid
    t: number; // tenantOid
}

interface DollarDollar {
    $$: () => {
        text: string;
        parent: Permission | undefined;
        activeParent: (t?: number) => Permission | undefined;
        relationships: RelationshipDict;
    }
}

export interface Permission extends Enabling, DollarDollar {
    pp: number; // parentPermissionOid
    p: number; // permissionOid

    k: number; // index of key
    o?: number; // oid

    t?: Relationship[]; // tenantItems
}

export interface Role extends DollarDollar {
    n: string; // name
    o: number; // oid
    d: Relationship;
    r: Relationship[]; // tenantItems
}

const isRole = (value: any): value is Role => value && value.d;
const isPermission = (value: any): value is Permission => value && value.p;
const isRelationships = (value: any): value is Relationship[] => isArray(value);

type PermissionDict = Record<number, Permission>;
type RelationshipDict = Record<number, Relationship>;
type SimplePermissionValue = boolean | Record<string, boolean>;
type TransferRolesDict = Record<string, Record<string, boolean>>;

export const createTransferRoles = (roles: Role[], tenantOidDict: Record<number, string>) =>
    roles.reduce((roleDict, role) => {
        let dict = <Record<string, boolean>>{};
        if (isBoolean(role.d.e)) {
            dict["*"] = role.d.e;
        }
        dict = role.r.reduce((d, tenantRole) => {
            if (isBoolean(tenantRole.e) && tenantOidDict[tenantRole.t]) {
                d[tenantOidDict[tenantRole.t]] = tenantRole.e;
            }
            return d;
        }, dict)
        if (Object.keys(dict).length > 0) {
            roleDict[role.n] = dict;
        }
        return roleDict;
    }, <TransferRolesDict>{});

export const createRoles = (emptyRoles: Role[], transferRoleDict: TransferRolesDict, tenantOidDict: Record<number, string>) =>
    emptyRoles.map(role => {
        const dict = transferRoleDict[role.n];
        if (dict) {
            if (dict["*"]) {
                role.d.e = dict["*"];
            }
            role.r.forEach(tenantRole => {
                const b = dict[tenantOidDict[tenantRole.t]];
                tenantRole.e = isBoolean(b) ? b : null;
            });
        }
        return role;
    })

const createPermissionsDict = (items: Permission[]): PermissionDict =>
    items.reduce((dict, p) => {
        if (isPermission(p)) {
            dict[p.p] = p;
        }
        return dict;
    }, <PermissionDict>{})

export const createPermissionTool = (items: Permission[], texts: string[]) => {
    const permissions = createPermissionsDict(items);

    const getParent = (p: Permission) => p.pp ? permissions[p.pp] : undefined;
    const getPath = (p: Permission): string[] => {
        const path: string[] = [];
        const segments = (p2: Permission | undefined) => {
            if (p2) {
                segments(getParent(p2));
                path.push(texts[p2.k]);
            }
        }
        segments(p);
        return path;
    }

    const createText = (p: Permission): string =>
        getPath(p).filter((_, i) => i > 0).join("/") || "*";

    const createTransferPermissions = (tenantOidDict?: Record<number, string>) =>
        items.reduce((dict, p) => {
            if (isPermission(p)) {
                if (isRelationships(p.t)) {
                    const relationships = p.t.filter(r => isBoolean(r.e) && tenantOidDict?.[r.t]);
                    if (relationships.length > 0) {
                        dict[createText(p)] = relationships.reduce((d, r) => {
                            // @ts-ignore
                            d[tenantOidDict[r.t]] = r.e;
                            return d;
                        }, <Record<string, boolean>>{});
                    }
                } else if (isBoolean(p.e)) {
                    dict[createText(p)] = p.e;
                }
            }
            return dict;
        }, <Record<string, SimplePermissionValue>>{});

    const createPermissionOids = () =>
        items.reduce((dict, p) => {
            if (isPermission(p)) {
                dict[createText(p)] = p.p;
            }
            return dict;
        }, <Record<string, number>>{});

    const applyTransferPermissions = (transferPermissions: any, tenantOidDict?: Record<number, string>) => {
        const oids = createPermissionOids();
        Object.entries(transferPermissions)
            .forEach(([p, e]: any) => {
                const oid = oids[p];
                if (oid) {
                    if (tenantOidDict) {
                        permissions[oid].t?.forEach(r => {
                            const b = e[tenantOidDict[r.t]];
                            if (isBoolean(b)) {
                                r.e = b;
                            }
                        });
                    } else {
                        permissions[oid].e = e;
                    }
                }
            });
    }

    return {
        permissions,
        applyTransferPermissions,
        createTransferPermissions,
        createPermissionOids,
        createText,
        getParent,
        getPath
    }
}


const createRelationshipsDict = (t: Relationship[]) => t.reduce((dict, t) => {
    dict[t.t || 0] = t;
    return dict;
}, <RelationshipDict>{})

export const getEnabling = (permission: Role | Permission, tenantOid?: number): Enabling =>
    tenantOid
        ? permission.$$().relationships[tenantOid]
        : isRole(permission) ? permission.d : permission;

//const hasValue = (b: boolean) => b || b === false;

export const usePermissions = (
    texts: Ref<string[]>,
    items: Ref<(Role | Permission)[]>
) => {
    const _permissions = ref<(Role | Permission)[]>([]);

    const getActiveParent = (permission: Permission, tenantOid?: number): Permission | undefined => {
        const parent = permission.$$().parent;
        if (parent) {
            switch (getEnabling(parent, tenantOid)?.e) {
                case true:
                    return parent;
                case false:
                    return undefined;
            }
            return getActiveParent(parent, tenantOid);
        }
        return parent;
    };


    //const isPermissionEnabled= (permission: RelatedPermission, tenantOid?: number): boolean =>{
    //    const enabled = isEnabled(permission, tenantOid);
    //    if (enabled===true || enabled===false) {
    //        return enabled;
    //        //return !!permission.e; old logic from TILLMATE
    //    }
    //    const parent = getParentPermission(permission);
    //    return parent ? isPermissionEnabled(parent, tenantOid) : false;
    //}
    //
    //const isPermissionActive = (permission: RelatedPermission, tenantOid?: number): boolean => {
    //    return hasValue(isEnabled(permission, tenantOid)) || isPermissionEnabled(permission, tenantOid);
    //}    


    watch([texts, items], ([newTexts, newItems]) => {

        const {createText, getParent} = createPermissionTool(<any>newItems, newTexts)

        /*const _dict = createPermissionsDict(newItems);

        const getParent = (p: Permission) => p.pp ? _dict[p.pp] : undefined;
        const getPath = (p: Permission): string[] => {
            const path: string[] = [];
            const segments = (p2: Permission | undefined) => {
                if (p2) {
                    segments(getParent(p2));
                    path.push(newTexts[p2.k]);
                }
            }
            segments(p);
            return path;
        }*/


        _permissions.value = newItems.map(p => {
            if (isRole(p)) {
                const relationships = createRelationshipsDict(p.r || []);
                p.$$ = () => ({
                    text: p.n,
                    parent: undefined,
                    relationships,
                    activeParent: () => undefined,
                });
            } else {
                const text = createText(p);
                const parent = getParent(p);
                const relationships = createRelationshipsDict(p.t || []);
                p.$$ = () => ({
                    text,
                    parent,
                    relationships,
                    activeParent: (tenantOid?: number) => getActiveParent(p, tenantOid),
                });
            }
            return p;
        });
    }, {immediate: true})


    const onToggle = (permission: Permission, tenantOid?: number) => {
        const enabling = getEnabling(permission, tenantOid);
        const parent = getActiveParent(permission);
        switch (enabling.e) {
            case true:
                enabling.e = null;
                break;
            case false:
                enabling.e = null;
                break;
            default:
                if (parent) {
                    const parentEnabling = getEnabling(parent, tenantOid);
                    switch (parentEnabling.e) {
                        case true:
                            enabling.e = false;
                            break;
                        case false:
                            enabling.e = null;
                            break;
                        default:
                            enabling.e = true;
                            break;
                    }
                } else {
                    enabling.e = true;
                }
                break;
        }
    }


    return {
        permissions: _permissions,
        //ensureItem,
        //getActiveParent,
        onToggle
    }
}