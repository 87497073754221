﻿
import AunoaDataGrid from "./controls/AunoaDataGrid.vue";
import AunoaSimpleDataGrid from "./controls/AunoaSimpleDataGrid.vue";
import AunoaDataGridInline from "./controls/AunoaDataGridInline.vue";
import {App} from "vue";

export * from "./DataGridOptions";
export * from "./useQuery";

export {
    AunoaDataGrid,
    AunoaSimpleDataGrid,
    AunoaDataGridInline,
}

function install(app: App) {
}

export default {
    install
}

