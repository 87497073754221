﻿<template>
    <aunoa-card v-bind="cardAttrs">
        <aunoa-card-header :icon="icon" :text="title(t)+' '+bridge.id" :to="'/service/bridge'" />


        <div class="card-body bg-info text-center" v-if="bridge.state==='CONNECTING'" v-text="t('Bridge.Connecting')" />
        <div class="card-body bg-danger text-center" v-text="t('Bridge.OperationFailure')" v-else-if="bridge.jobFailedCount>0" />
        <div class="card-body bg-success text-center" v-text="t('Bridge.OperationFailureFree')" v-else />
        

        <div class="list-group list-group-flush">
            <div class="list-group-item" v-if="bridge.state==='CONNECTING'">
                <i class="float-end" :class="busyIcon" />
                <span class="text-muted" v-text="t('Bridge.Connecting')" />
            </div>
            <div class="list-group-item _list-group-item-action" v-else-if="bridge.state==='ON'">
                <i class="fas fa-heart-pulse fa-beat text-success float-end" style="--fa-animation-duration: 1.5s;--fa-beat-scale: 1.1"/>
                <span class="text-muted" v-text="t('Bridge.Running')" />
            </div>
            <div class="list-group-item __list-group-item-action" v-else-if="bridge.state==='OFF'">
                <i class="far fa-pause float-end" />
                <span class="text-muted" v-text="t('Bridge.Stopped')" />
            </div>
            <div class="list-group-item" v-else>
                <span class="float-end" v-text="bridge.state" />
                <span class="text-muted" v-text="'State'" />
            </div>
            <div class="list-group-item _list-group-item-action">
                <span class="float-end" v-text="formatNumber(bridge.jobCount)" />
                <span class="text-muted" v-text="t('Bridge.Tasks', bridge.jobCount)" />
            </div>
            <div class="list-group-item _list-group-item-action" :class="{'__bg-danger':bridge.jobFailedCount>0}">
                <span class="float-end" v-text="formatNumber(bridge.jobFailedCount)" />
                <span v-text="t('Bridge.Faulted')" :class="{'text-muted':bridge.jobFailedCount===0}" />
            </div>
        </div>

        <div class="card-body">
            <table class="w-100 small">
                <tr>
                    <td>
                        <span v-text="'BRIDGE'" />
                        <span class="text-muted ps-1" v-text="t('Common.Version')" />
                    </td>
                    <td class="text-end" v-text="bridge.version" />
                </tr>
                <tr class="text-muted">
                    <td v-text="t('System.BuildDate')" />
                    <td class="text-end" v-text="formatDate(bridge.buildDate, 'PPp')" />
                </tr>
            </table>
        </div>
        <div class="card-footer mate-icon bridge-icon _bg-primary">
            <bridge-icon class="shadow-sm" />
        </div>
    </aunoa-card>
</template>

<script lang="ts">
import type {Bridge} from "@tnt/mate-api";
import type {Ref} from "vue";

import BridgeIcon from "../../../components/BridgeIcon.vue";
import {useAunoaI18n, formatDate, formatNumber, AunoaCard, AunoaCardHeader, resolveIconAlias} from "bootstrap-aunoa";
import {useDashboardCard} from "../useDashboard";
import {defineComponent, ref, watch, toRefs} from "vue";
import {useMateHub, createConnectingBridge} from "@tnt/mate-api";

const meta = {
    createdAt: "2021-04-22T00:00:00.000Z",
    //icon: "fad fa-chart-network",
    icon: "fad fa-cogs",
    title: (t: any) => "BRIDGE",
    variants: () =>
        Object.entries(useMateHub().bridge.bridgeDict.value)
            .map(([uid, bridge]) => ({
                name: bridge.id,
                params: {
                    uid: uid
                },
                keySuffix: uid
            }))
    //multiInstance: true
}

const useBridge = (uid: Ref<string>) => {
    const bridgeDict = useMateHub().bridge.bridgeDict;
    const bridge = ref<Bridge>(createConnectingBridge());
    watch(
        [bridgeDict, uid],
        ([dict, id]) => bridge.value = (dict && id ? dict[id] : undefined) || createConnectingBridge(),
        {immediate: true, deep: true}
    )
    return bridge;
}

export default defineComponent({
    $$meta: meta,
    name: "BridgeCard",
    components: {AunoaCard, AunoaCardHeader, BridgeIcon},
    props: {
        uid: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const {t} = useAunoaI18n();
        const {uid} = toRefs(props);
        const bridge = useBridge(uid);
        const card = useDashboardCard({});
        const busyIcon = resolveIconAlias("busy");

        return {
            t,
            uid,
            bridge,
            busyIcon,
            formatNumber,
            formatDate,
            ...meta,
            ...card
        }
    }
});

</script>

