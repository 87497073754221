﻿<template>
    <aunoa-card v-bind="cardAttrs" :required="required">
        <div class="card-body">
            <!--
            <h5 class="card-title" v-text="'Version'" />
            -->
            <table class="w-100 small">
                <tr>
                    <td>MATE Api</td>
                    <td class="text-end" v-text="api?.version" />
                </tr>
                <tr class="text-muted">
                    <td v-text="t('System.BuildDate')" />
                    <td class="text-end" v-text="formatDate(api?.buildDate, 'PPp')" />
                </tr>
                <tr>
                    <td class="pt-2">MATE UI</td>
                    <td class="pt-2 text-end" v-text="ui?.version" />
                </tr>
                <tr class="text-muted">
                    <td v-text="t('System.BuildDate')" />
                    <td class="text-end" v-text="formatDate(ui?.buildDate, 'PPp')" />
                </tr>
            </table>
        </div>
        <div class="card-footer mate-icon bg-primary">
            <mate-icon />
        </div>
    </aunoa-card>
</template>

<script lang="ts">

import type {MateVersionInfo} from "@tnt/mate-api";
import MateIcon from "../../../components/MateIcon.vue";
import {useAccessToken, useInfoApi} from "@tnt/mate-api";
import {formatDate, useAunoaI18n, AunoaCard} from "bootstrap-aunoa";
import {defineComponent, ref} from "vue";

import {useDashboardCard} from "../useDashboard";

const ui = ref<MateVersionInfo>();
const api = ref<MateVersionInfo>();

const meta = {
    createdAt: "2021-04-22T00:00:00.000Z",
    icon: "",
    title: (t:any) => t("Mate.Version???"),
    required: true
}

export default defineComponent({
    $$meta: meta,
    name: "MateVersionCard",
    components: {AunoaCard, MateIcon},
    setup() {

        const {t} = useAunoaI18n();
        const {accessToken} = useAccessToken()
        const {getApiVersionInfo, getUiVersionInfo} = useInfoApi(accessToken);
        const card = useDashboardCard({
            update: () =>
                Promise.all([
                    getApiVersionInfo().then(v => api.value = v),
                    getUiVersionInfo().then(v => ui.value = v)
                ])
        });

        return {
            t,
            ui,
            api,
            formatDate,
            ...meta,
            ...card
        }
    }
});

</script>

