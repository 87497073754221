﻿<template>
    <footer class="footer-aunoa">
        <nav class="navbar navbar-expand py-0">
            <aunoa-container :is-fluid="isFluid">
                <ul class="navbar-nav brand">
                    <li class="nav-item zucchetti" v-if="showZucchetti">
                        <zucchetti-low-res />
                    </li>
                    <slot name="startNavItems" />
                    <li class="nav-item" v-if="statusText">
                        <button role="button" class="nav-link" v-text="statusText" />
                    </li>
                </ul>
                <ul class="navbar-nav ms-auto" id="footerCustomNavItems" />
                <ul class="navbar-nav">
                    <slot name="internalNavItems" />
                </ul>
                <ul class="navbar-nav" id="footerCustomIconNavItems" />
                <ul class="navbar-nav">
                    <slot name="internalIconNavItems" />
                </ul>
            </aunoa-container>
        </nav>
    </footer>
</template>

<script lang="ts">

import {defineComponent, ref, onMounted, onBeforeUnmount} from "vue";
import {AunoaContainer, useAunoaI18n} from "..";
import Zucchetti from "../components/Zucchetti.vue";
import ZucchettiLowRes from "../components/ZucchettiLowRes.vue";

const toggleHasFooter = (value: boolean) => document.body.classList.toggle("has-footer", value);

export default defineComponent({
    name: "AunoaFooter",
    components: {
        ZucchettiLowRes,
        Zucchetti,
        AunoaContainer
    },
    props: {
        isFluid: {
            type: Boolean,
            default: undefined
        },
        showZucchetti: {
            type: Boolean,
            default: false
        },
        statusText: {
            type: String
        }
    },
    setup() {

        //const {t} = useAunoaI18n();
        onMounted(() => toggleHasFooter(true));
        onBeforeUnmount(() => toggleHasFooter(false));

        return {
        }
    }
});

</script>

<style lang="scss">

.tnt-mate-logo {
    margin-left: 1rem;
    height: 3em;

    border: 1px solid #000;
    fill: #fff;

    .tml1 {
        fill: #f2a900;
    }

    .tml2 {
        fill: #222;
    }
}

</style>

