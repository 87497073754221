﻿import type {App, Ref} from "vue";
import {computed, inject, readonly, ref, watch} from "vue";
import type {Composer, I18n, I18nScope} from "vue-i18n";
import {createI18n, useI18n} from "vue-i18n";
import {defaultLocale, fallbackLocale, locales, storageDefaultNamespace} from "../core";

import languages from "../data/languages.json";
import dateFnsLocales from "../../locales/au/date-fns-locales";

interface LanguageNames {
    [code: string]: string;
}

interface Language {
    code: string;
    name: string;
    native: string;
}

interface LocaleControllerOptions {
    onChanging(locale: string): Promise<any>;
    onChange(locale: string, data: any): void;
    onReady?(): void;
}

interface I18nControllerOptions {
    merge?: boolean;
    loadMessage(locale: string): Promise<any>;
}

interface Controller {
    loading: Ref<boolean>;
    i18n: I18n<any, any, any, false>;
    register(options: LocaleControllerOptions): () => void;
    setLocale(locale: string): Promise<void>;
    ensureTranslated(text: string, i18n: Composer<any, any, any>): string;
}

const languageNames: LanguageNames = (languages as Language[]).reduce((o, v) => {
    o[v.code] = v.name
    return o;
}, <LanguageNames>{});

const nativeLanguageNames: LanguageNames = (languages as Language[]).reduce((o, v) => {
    o[v.code] = v.native
    return o;
}, <LanguageNames>{});

const getPrimaryLocales = (locales: string[]) => {
    const includes = navigator.languages.map(value => value.toLowerCase());
    return locales.filter(value => includes.includes(value));
}

const getSecondaryLocales = (locales: string[]) => {
    const excludes = getPrimaryLocales(locales);
    return locales.filter(value => !excludes.includes(value)).sort((a, b) => a.localeCompare(b));
}

const primaryLocales = computed(() => getPrimaryLocales(locales.value));
const secondaryLocales = computed(() => getSecondaryLocales(locales.value));

let _controller: Controller;

const czechPluralRule = (choice: number, choicesLength: number, orgRule: any) => 
    choicesLength === 4
        ? choice < 2
            ? choice
            : choice > 4
                ? 3
                : 2
        : orgRule(choice, choicesLength);

export const provideLocaleController = (app: App, ensureTranslated: (text: string, i18n: Composer<any, any, any>) => string) => {

    const subscribers: LocaleControllerOptions[] = [];
    const storageKey = storageDefaultNamespace.value + ".ui.locale";

    const i18n = <any>createI18n({
        legacy: false,
        locale: localStorage.getItem(storageKey) || defaultLocale.value,
        fallbackLocale: fallbackLocale.value,
        missingWarn: false,
        fallbackWarn: false,
        pluralRules: {
            "cs": czechPluralRule
        }
    });

    const changings = (locale: string) => subscribers.map(subscriber => subscriber.onChanging(locale));

    const loadingCount = ref<number>(0);
    const loading = computed<boolean>(() => loadingCount.value > 0);

    const setLocale = (locale: string): Promise<void> => {
        loadingCount.value++;
        return new Promise<void>((resolve, reject) =>
            Promise
                .all(changings(locale))
                .then(results => {
                    subscribers.forEach((subscriber, index) => subscriber.onChange(locale, results[index]));
                    i18n.global.locale.value = locale;
                    localStorage.setItem(storageKey, locale);
                    resolve();
                })
                .catch(reject)
                .finally(() => loadingCount.value--)
        );
    };

    const register = (subscriber: LocaleControllerOptions) => {
        subscribers.push(subscriber);
        const locale = i18n.global.locale.value;
        loadingCount.value++;
        subscriber
            .onChanging(locale)
            .then(data => {
                subscriber.onChange(locale, data);
                subscriber.onReady?.();
            })
            .finally(() => loadingCount.value--);
        return () => {
            const index = subscribers.indexOf(subscriber);
            if (index > -1) {
                subscribers.splice(index, 1);
            }
        }
    }

    _controller = <Controller>{
        i18n,
        loading,
        register,
        setLocale,
        ensureTranslated,
    };
    app
        .use(i18n)
    ;
}

export const useLocaleController = (options: LocaleControllerOptions) => {
    const {register, ensureTranslated, loading} = _controller;
    const unregister = register(options)
    return {
        loading,
        ensureTranslated,
        dispose: () => {
            unregister();
        }
    };
}

export const useI18nController = (options: I18nControllerOptions) => {
    const _locale = ref<string>();
    const _message = ref({});

    const {loading, ensureTranslated, dispose} = useLocaleController({
        onChanging: options.loadMessage,
        onChange: (locale, messages) => {
            _locale.value = locale;
            _message.value = messages;
        }
    })

    const _useI18n = (scope: I18nScope = "parent") =>
        useI18n({
            useScope: scope,
            fallbackLocale: fallbackLocale.value
        });

    const registerI18n = (i18n: Composer<any, any, any>) => {
        const stop = watch([_locale, _message], ([locale, message]) => {
            if (locale && message) {
                if (options?.merge) {
                    // not tested
                    i18n.mergeLocaleMessage(locale, message);
                } else {
                    i18n.setLocaleMessage(locale, message);
                }
            }
        }, {immediate: true});

        return () => {
            stop();
        };
    }

    const isReady = () =>
        new Promise<void>(resolve => {
            const unwatch = watch(loading, (newLoading, oldLoading) => {
                if (newLoading === false) {
                    unwatch();
                    resolve();
                }
            }, {immediate: true})
        });

    return {
        loading,
        locale: _locale,
        message: _message,
        useI18n: _useI18n,
        ensureTranslated,
        registerI18n,
        isReady,
        dispose
    }
}

export const useLocale = () => {
    //const {i18n, setLocale} = <Controller>inject(INJECTION_KEY);
    const {i18n, setLocale} = _controller;

    const getDateFnsLocale = (locale?: string) =>
        dateFnsLocales[locale || i18n.global.locale.value || defaultLocale.value] || dateFnsLocales[defaultLocale.value]

    return {
        locale: readonly(i18n.global.locale),
        locales: readonly(locales),
        primaryLocales,
        secondaryLocales,
        languageNames,
        nativeLanguageNames,
        getPrimaryLocales,
        getSecondaryLocales,
        getDateFnsLocale,
        setLocale
    }
}