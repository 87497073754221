﻿import {useTenancy} from "./useTenancy";
import {useScopeSources} from "./useScopeSources";
import {computed} from "vue";

export const useScope = () => {

    const sources = useScopeSources();
    const {tenants: allTenants} = sources;

    const {tenantOids} = useTenancy();
    
    const tenants = computed(() => {
        if (tenantOids.value?.length) {
            // @ts-ignore
            return allTenants.value.filter(t => t.oid && tenantOids.value.includes(t.oid));
        }
        return [];
    });

    return {
        ...sources,
        allTenants,
        tenants
    }
}