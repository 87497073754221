﻿<template>
    <component :is="as" :class="controlClass">
        <slot name="nav"
              :mode="mode"
              :can-toggle-mode="canToggleMode"
              :tab-infos="tabInfos"
              :class="navClass"
              :nav-link-class="navLinkClass"
              :direction="navDirection">
            <aunoa-tab-nav
                :active-index="activeIndex"
                :tab-infos="tabInfos"
                :class="navClass"
                :nav-link-class="navLinkClass"
                :mode="mode"
                :direction="navDirection"
                @update:active-index="onActiveIndex">
                <template v-slot:before-links v-if="$slots['before-links']">
                    <slot name="before-links" />
                </template>
                <template v-slot:after-links v-if="$slots['after-links']">
                    <slot name="after-links" />
                </template>
            </aunoa-tab-nav>
        </slot>
        <div :class="contentClass">
            <slot name="header" :tab-infos="tabInfos" />
            <aunoa-tab-content class="flex-fill h-100">
                <slot />
            </aunoa-tab-content>
            <slot name="footer" :tab-infos="tabInfos" />
        </div>
    </component>
</template>

<script lang="ts">

import type {TabInfo} from "../components/tab/usePaneSubscription";
import type {NavDirection, Mode} from "../components/tab/AunoaTabNav.vue";

import {provideContentSubscription} from "../components/tab/useContentSubscription";
import {defineComponent, toRefs, ref, PropType, computed} from "vue";
import {AunoaTabContent, AunoaTabNav} from "../components/tab/tab";
import {providePaneClass} from "../components/tab/usePaneClass";
import AunoaNavItem from "../components/nav/AunoaNavItem.vue";

declare type ActiveStyle = "line" | undefined;

declare type NavPosition = "top" | "start" | "end" | "bottom";

const positionDict: Record<NavPosition, string> = {
    "top": "flex-column",
    "start": "flew-row",
    "end": "flex-row-reverse",
    "bottom": "flex-column-reverse"
}

const directionDict: Record<NavPosition, NavDirection> = {
    "top": "horizontal",
    "start": "vertical",
    "end": "vertical",
    "bottom": "horizontal"
}

const markerDict: Record<NavPosition, string> = {
    "top": "active-marker-bottom",
    "start": "active-marker-end",
    "end": "active-marker-start",
    "bottom": "active-marker-top"
}

export default defineComponent({
    name: "AunoaTabControl",
    components: {
        AunoaTabContent,
        AunoaTabNav,
        AunoaNavItem
    },
    props: {
        as: {
            //type:  [Object, String],
            default: "div"
        },
        mode: {
            type: String as PropType<Mode | undefined>,
            default: undefined
        },
        canToggleMode: {
            type: Boolean,
            default: true
        },
        activeStyle: {
            type: String as PropType<ActiveStyle>,
            default: undefined
        },
        navPosition: {
            type: String as PropType<NavPosition>,
            default: "top"
        },
        navClass: {
            default: undefined
        },
        navLinkClass: {
            default: undefined
        },
        contentClass: {
            default: undefined
        },
        paneClass: {
            default: undefined
        },
        activeIndex: {
            type: Number
        }
    },
    emit: ["update:activeIndex"],
    setup(props,{emit}) {

        const {paneClass} = toRefs(props);
        const _tabs: TabInfo[] = [];
        const tabInfos = ref<TabInfo[]>([]);

        const controlClass = computed(() => ["tab-control", "d-flex", positionDict[props.navPosition]]);
        const contentClass = computed(() => ["d-flex flex-column flex-fill", props.contentClass].filter(Boolean));

        const navClass = computed(() => [
            props.navClass,
            props.activeStyle === "line" ? markerDict[props.navPosition] : undefined,
        ].filter(Boolean));

        const navLinkClass = computed(() => [
            props.navLinkClass,
        ].filter(Boolean));

        const navDirection = computed<NavDirection>(() => directionDict[props.navPosition]);

        providePaneClass(paneClass);
        provideContentSubscription({
            addTab(tab: TabInfo) {
                //console.log("addTab", tab.id.value);
                _tabs.push(tab);
            },
            removeTab(tab: TabInfo) {
                //console.log("removeTab", tab.id.value);
                const index = _tabs.indexOf(tab);
                _tabs.splice(index, 1);
            },
            setSortedTabIds(ids: string[]) {
                //console.log("setSortedTabIds", ids);
                const tabDict = _tabs.reduce((o, v) => {
                    o[v.id.value] = v
                    return o;
                }, <Record<string, TabInfo>>{});
                // @ts-ignore
                tabInfos.value = ids.map(id => tabDict[id]).filter(pane => !!pane);
            }
        });

        //onMounted(() => {
        //    console.log("AunoaTabControl mounted ");
        //});
        //onUpdated(() => {
        //    console.log("AunoaTabControl updated");
        //});
        //onBeforeUnmount(() => {
        //    console.log("AunoaTabControl unmounting");
        //});
        
        const onActiveIndex = (tabIndex:number) => 
            emit("update:activeIndex", tabIndex);
        
        return {
            tabInfos,
            controlClass,
            contentClass,
            navClass,
            navLinkClass,
            navDirection,
            onActiveIndex
        }

    }
});

</script>

