﻿import {defineComponent, PropType} from "vue";

declare type DropDirection = "dropdown" | "dropup" | "dropstart" | "dropend";

export default defineComponent({
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        dropDirection: {
            type: String as PropType<DropDirection>,
            default: "dropdown"
        },
        dropMenuClass: {
            type: String
        },
        headerText: {
            type: String,
        }        
    }
});
