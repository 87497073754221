﻿import { ref } from "vue";

const _baseUrl = ref("/")

export const provideMateBaseUrl = (baseUrl:string) => {
    _baseUrl.value = baseUrl;
};

export const useMateBaseUrl = () => {
        
    return {
        baseUrl: _baseUrl.value,
    }
};