﻿import type {InjectionKey, Ref} from "vue";
import {inject, provide} from "vue";

export interface PageInfo {
    icon: Ref<string>;
    title: Ref<string>;
}

const INJECTION_KEY: InjectionKey<PageInfo> = Symbol("AUNOA_PAGE_INFO");

export const providePageInfo = (icon: Ref<string>, title: Ref<string>) => {
    
    const pageInfo:PageInfo = {
        icon,
        title
    }
    
    provide(INJECTION_KEY, pageInfo);
}

export const usePageInfo = () => inject(INJECTION_KEY) as PageInfo;
