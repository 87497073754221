﻿import type {Ref, InjectionKey} from "vue";
import type {PageOptions} from "./usePageOptions";

import {computed, inject, provide, ref, shallowRef, watch, onMounted, onBeforeUnmount} from "vue";
import {useAunoaLayout} from "../tools/layout";

type TeleportTarget = "commands" | "endCommands" | "footerCommands" | "footerIconCommands";
type TeleportCount = Record<TeleportTarget, Ref<number>>;

export interface PageHost extends TeleportCount {
    mountOptions(pageOptions?: PageOptions): void;
    unmountOptions(): void;
    
}

const INJECTION_KEY: InjectionKey<PageHost> = Symbol("AUNOA_PAGE_HOST");

export const providePageHost = () => {

    const layoutCollapseHeader = useAunoaLayout().collapseHeader;

    const _pageOptions = ref<PageOptions>();
    const pageIcon = computed(() => _pageOptions.value?.icon);
    const pageHeader = computed(() => _pageOptions.value?.header);
    const mainClass = computed(() => _pageOptions.value?.mainClass);

    const disableBodyScrolling = computed(() => !!_pageOptions.value?.disableBodyScrolling);
    const disableHorizontalContentPadding = computed(() => !!_pageOptions.value?.disableHorizontalContentPadding);
    const _collapseHeader = computed(() => !!_pageOptions.value?.collapseHeader);

    watch([_collapseHeader, disableBodyScrolling], ([ch, dbs]) => layoutCollapseHeader.value = ch && dbs, {immediate: true});

    provide(INJECTION_KEY, {
        commands: ref(0),
        endCommands: ref(0),
        footerCommands: ref(0),
        footerIconCommands: ref(0),
        mountOptions: (pageOptions: PageOptions) => {
            _pageOptions.value = pageOptions;
        },
        unmountOptions: () =>  {
            _pageOptions.value = undefined;
        }        
    });

    return {
        pageIcon,
        pageHeader,
        mainClass,

        disableBodyScrolling,
        disableHorizontalContentPadding,
    }

};


export const usePageHost = () => {

    const pageHost = inject(INJECTION_KEY, <any>{});

    const withTeleport = (target: TeleportTarget) => {

        const incrementTeleportCount = () => pageHost[target].value += 1
        const decrementTeleportCount = () => pageHost[target].value -= 1

        onMounted(incrementTeleportCount);
        onBeforeUnmount(decrementTeleportCount);
    }

    return {
        pageHost,
        withTeleport
    }
}