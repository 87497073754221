import type {Ref} from "vue";
import type {User} from "../mixins/UserMixin";
import type {RouteLocation} from "vue-router";

import {useLocalStorage} from "./storage/useLocalStorage";
import {singleton} from "./singletons";

interface PageStorage {
    visitedAt: Date;
    path: string;
}

interface Storage {
    version: number;
    storedAt: Date;
    pages: PageStorage[];
}

const create = (pages: PageStorage[]): Storage => ({
    version: 1,
    storedAt: new Date(),
    pages: pages
})

const useStorage = (user: User) => singleton(`AUNOA_RECENTLY_USED_PAGES_STORAGE_${user?.id}`, () => {
    const storage = useLocalStorage(user).items("most-recently-used");
    const pages = <Ref<Storage>>storage.subscribeObject<Storage>("pages", create([]));
    return {
        pages
    }
});

export const provideMostRecentlyUsedPages = (user: User) => {

    const max = 5;
    
    const {pages} = useStorage(user);
    
    const addRoute = (route: RouteLocation) => {
        const {path, redirectedFrom} = route;
        if (!redirectedFrom && path !== "/") {
            pages.value = create([
                {
                    visitedAt: new Date(),
                    path: path
                },
                ...(pages.value?.pages || [])
                    .filter(item => item.path !== path)
                    .filter((_, i) => i + 1 < max)
            ]);
        }
    }

    return {
        addRoute,
    }
}

export const useMostRecentlyUsedPages = (user: User) => {

    const {pages} = useStorage(user);
    
    const getPathes = () => (pages.value?.pages || []).map(p => p.path);
    
    return {
        getPathes
    }
}