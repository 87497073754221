﻿import {inject, InjectionKey, provide, Ref} from "vue";

const INJECTION_KEY: InjectionKey<Ref> = Symbol("AUNOA_PANE_CLASS");

export const providePaneClass = (paneClass:Ref) => {
    provide(INJECTION_KEY, paneClass);
}

export const usePaneClass = () => {

    const paneClass = inject(INJECTION_KEY, undefined);

    return {
        paneClass
    }
}