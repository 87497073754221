<template>
    <admin-layout
        ref="adminLayout"
        default-gravatar-image="robohash"
        :root-page-header="'MATE'"
        :show-zucchetti="false"
        :navigation="navigation"
        :permitted="permitted"
        :is-footer-fluid="true"
        :user="user"
        @locking="onLocking">
        <template v-slot:titleNavItems>
            <select-primary-tenant-nav-item />
        </template>
        <template v-slot:userDropdownItems v-if="hasTestMode">
            <aunoa-dropdown-item
                :text="t('Mate.TestMode')"
                icon="fad fa-flask-round-potion"
                :end-icon="testMode?'fad fa-lg fa-toggle-on text-primary':'fad fa-lg fa-toggle-off'"
                @click="testMode = !testMode"
            />
        </template>
        <router-view />
        <template v-slot:footerBrandNavItems>
            <li class="nav-item mate-icon">
                <router-link :to="{name:'dashboard'}">
                    <mate-icon />
                </router-link>
            </li>
            <li class="nav-item" v-if="!permitted">
                <span class="nav-text">
                    <i class="fa-lg fad fa-eye-slash pe-2" />
                    <span v-text="t('Aunoa.Auth.ViewNotPermitted')" />
                </span>
            </li>
            <li class="nav-item" v-else-if="currentActivity && 0">
                <span class="nav-text" v-text="currentActivity.downloaded" />
            </li>
            <li class="nav-item" v-else>
                <span class="nav-text" v-text="t('Aunoa.Network.Ready')" />
            </li>
        </template>
        <template v-slot:footerNavItems>
            <aunoa-nav-item
                v-if="hasUpdate"
                class="update"
                icon="fat fa-triangle-exclamation fa-beat"
                text="Update"
                @click="forceUpdate"
            />
        </template>
        <template v-slot:footerIconNavItems>
            <template v-if="currentActivity?.duration>=1000">
                <li class="nav-item" v-if="(currentActivity.uploaded+currentActivity.downloaded)>0">
                    <span class="nav-text" v-text="toByteSizeStr(currentActivity.uploaded+currentActivity.downloaded)" />
                </li>
                <li class="nav-item">
                    <span class="nav-text" v-text="toDurationSizeStr(currentActivity.duration)" />
                </li>
            </template>
            <aunoa-progress-nav-item
                drop-direction="dropup"
                drop-menu-class="dropdown-menu-end"
                :axios-activities="axiosActivities"
                :axios-busy-count="axiosBusyCount" />
        </template>
        
        <modal-code-editor ref="modalCodeEditor" />
                
    </admin-layout>
    
</template>
<!--
        :status-text="ensureTextTranslated('@@Aunoa.Network.Ready')"

-->
<script lang="ts">

import type {MateLinkItem} from "../utils/useRouting";

import SelectPrimaryTenantNavItem from "../components/SelectPrimaryTenantNavItem.vue";
import {provideMateHub, provideUserRelogin, useAxiosActivities, usePingApi, useTenancy} from "@tnt/mate-api";
import {useLocalizationHelper} from "../utils/useLocalizationHelper";
import {useAccessTokenStorage} from "../utils/useAccessTokenStorage";
import {useRoutePermissions} from "../utils/useRoutePermissions";
import ModalCodeEditor from "../modals/ModalCodeEditor.vue";
import {defineComponent, provide, ref, computed} from "vue";
import MateIcon from "../components/MateIcon.vue";
import {useAuth} from "../utils/useAuth";
import {
    AdminLayout,
    AunoaDropdownCheckItem,
    AunoaDropdownDivider,
    AunoaDropdownItem,
    AunoaNavItem,
    AunoaProgressNavItem,
    enumerateLinks,
    useAunoaI18n,
    useTestMode,
    LinkItemState, toByteSizeStr, toDurationSizeStr,
} from "bootstrap-aunoa";
import {provideModalCodeEditor, useModalCodeEditor} from "../utils/useModalCodeEditor";

export default defineComponent({
    name: "App",
    components: {
        MateIcon,
        AdminLayout,
        AunoaNavItem,
        AunoaDropdownItem,
        AunoaDropdownDivider,
        AunoaProgressNavItem,
        AunoaDropdownCheckItem,
        SelectPrimaryTenantNavItem,
        ModalCodeEditor
    },
    setup() {

        const adminLayout = ref();
        const {testMode} = useTestMode();
        const {primaryTenantOid} = useTenancy();
        const {t, ensureTextTranslated} = useAunoaI18n();
        const {navigation, user, accessToken} = useAuth();
        const {clearAccessToken} = useAccessTokenStorage();
        const {ensureTextTranslationWithFallback} = useLocalizationHelper(t);
        const {busyCount: axiosBusyCount, activities: axiosActivities, currentActivity} = useAxiosActivities();

        const hasTestMode = computed(() => !!navigation.value.permissions['/internal/test-mode']?.[primaryTenantOid.value]);
        const {hasUpdate} = provideMateHub(accessToken);
        const {ping} = usePingApi();
      
        provide("$$ENSURE-TRANSLATION", ensureTextTranslationWithFallback);

        provideUserRelogin(() => {ping();return adminLayout.value.requestUserLogin("expired")});

        const {permitted} = useRoutePermissions();

        const forceUpdate = () => {
            //const router = useRouter();
            //router.go(0);
            window.history.go();
            //console.log("forceUpdate")
        }

        const onLocking = () => {
            clearAccessToken();
        }

        const {modalCodeEditor} = provideModalCodeEditor()


        return {
            t,
            user,
            testMode,
            hasUpdate,
            navigation,
            permitted,
            hasTestMode,
            adminLayout,
            forceUpdate,
            axiosBusyCount,
            axiosActivities,
            currentActivity,
            modalCodeEditor,
            
            ensureTextTranslated,
            toDurationSizeStr,
            toByteSizeStr,
            onLocking
        }
    }

});
</script>
