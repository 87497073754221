﻿<template>
    <div class="text-center">
        <h1 class="text-danger" style="font-size: 9rem">404</h1>
        <h1 v-text="t('Aunoa.Routing.PageNotFound404')" />
        <h5 v-text="$route.fullPath" />
    </div>
</template>

<script lang="ts">

import {defineComponent} from "vue";
import {useAunoaI18n} from "../utils/useAunoaI18n";

export default defineComponent({
    name: "AunoaNotFound404",
    setup() {

        const {t} = useAunoaI18n();


        return {
            t
        }

    }
});

</script>

