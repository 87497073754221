import type {Ref} from "vue";
import type {Facade} from "./_abstracts";

import {HubConnection} from "@microsoft/signalr";
import {ref} from "vue";
import {useScopeSources} from "../useScopeSources";
import {useAccessToken} from "../useAccessToken";

export interface DataServiceFacade {
}

export const createDataServiceFacade = (connection: HubConnection): DataServiceFacade & Facade => {

    const {updateScopeSources} = useScopeSources();
    const {accessToken} = useAccessToken();

    const onCreate = (groupStr:string, typeStr:string) => {
        //console.log("CREATE", groupStr, typeStr);
        updateScopeSources(accessToken).catch();
    }

    const onUpdate = (groupStr:string, typeStr:string, key:string) => {
        //console.log("UPDATE", groupStr, typeStr, key);
        updateScopeSources(accessToken).catch();
    }

    const onDelete = (groupStr:string, typeStr:string, key:string) => {
        //console.log("DELETE", groupStr, typeStr, key);
        updateScopeSources(accessToken).catch();
    }

    connection.on("DataServiceCreate", onCreate);
    connection.on("DataServiceUpdate", onUpdate);
    connection.on("DataServiceDelete", onDelete);

    const initialize = async () => {
    }

    const dispose = () => {
        connection.off("DataServiceCreate", onCreate);
        connection.off("DataServiceUpdate", onUpdate);
        connection.off("DataServiceDelete", onDelete);
    }

    return {
        initialize,
        dispose
    }

}
