﻿<template>
    <li>
        <div class="dropdown-divider" v-bind="$attrs" />
    </li>
</template>

<script lang="ts">

import {defineComponent} from "vue";

export default defineComponent({
    name: "AunoaDropdownDivider",
    inheritAttrs: false
});

</script>

