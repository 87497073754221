﻿import {scopeSources} from "./scope/scopeSources";
import {singleton} from "./base/singletons";
import {useScopeApi} from "./useScopeApi";

const KEY = "MATE_SCOPE_SOURCES";

export const provideScopeSources = () =>
    singleton(KEY, () =>
        scopeSources({
            load: (accessToken) => {
                const {getScope} = useScopeApi(accessToken);
                return getScope();
            },
        }))

export const useScopeSources = () =>
    singleton(KEY, () =>
        scopeSources({
            load: () => {
                throw new Error("Call of provideScopeSources Missing");
            },
        }))



