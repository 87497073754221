﻿import type {Ref} from "vue";
import type {ApiError} from "./error";
import type {Options as BaseOptions} from "./useEndpoint";
import type {AxiosInstance, AxiosRequestConfig} from "axios";

import {useEndpoint} from "./useEndpoint";
import {AccessToken} from "../types";
import {readonly} from "vue";

type Xml = string;

interface Options extends BaseOptions {
}

const APPLICATION_XML = "application/xml";

const getAccept = (options: Options) => APPLICATION_XML;

export const useXmlEndpoint = (
    axiosInstance: AxiosInstance,
    url: string | Ref<string>,
    baseHeaders: () => any,
    lastApiError: Ref<ApiError|undefined>,
    accessToken: AccessToken
) => {

    const {
        funcs, busy, result, status, error, username, etag, date, oid,
        getHeadersFromOptions, head, get, put, post, patch, delete: delete_
    } = useEndpoint<Xml, Options>(axiosInstance, url, baseHeaders, lastApiError, accessToken);

    funcs.requestConfig = (params: any, options: Options, send = false): AxiosRequestConfig => ({
        // @ts-ignore
        $$options: options,
        params: params,
        auth: options.auth,
        headers: {
            ...baseHeaders(),
            "Accept": getAccept(options),
            "Content-Type": send ? `${APPLICATION_XML}; charset=utf-8` : undefined,
            ...getHeadersFromOptions(options)
        }
    })

    return {
        busy: readonly(busy),
        error: readonly(error),
        result: readonly(result),
        status: readonly(status),

        username: readonly(username),
        etag: readonly(etag),
        date: readonly(date),
        oid: readonly(oid),

        head,
        get,
        put,
        post,
        patch,
        delete: delete_
    }
};