﻿<template>
    <aunoa-nav-item
        id="selectLocaleNavItem"
        :drop-direction="dropDirection"
        :drop-menu-class="dropMenuClass"
        :header-text="t('Aunoa.Locale.Language')"
        :text="indicator==='text'?nativeLanguageNames[locale]:locale"
    >
        <aunoa-dropdown-check-item
            v-for="l in primaryLocales"
            :checked="l===locale"
            :text="nativeLanguageNames[l]"
            :end-text="l"
            @click="$emit('select',l)"
        />
        <aunoa-dropdown-divider v-if="primaryLocales.length>0 && secondaryLocales.length>0" />
        <aunoa-dropdown-check-item
            v-for="l in secondaryLocales"
            :checked="l===locale"
            :text="nativeLanguageNames[l]"
            :end-text="l"
            @click="$emit('select',l)"
        />
    </aunoa-nav-item>
</template>

<script lang="ts">

import type {PropType} from "vue";

import AunoaDropdownCheckItem from "./dropdown/AunoaDropdownCheckItem.vue";
import AunoaSelectNavItemMixin from "../mixins/AunoaSelectNavItemMixin";
import AunoaDropdownDivider from "./dropdown/AunoaDropdownDivider.vue";
import {useAunoaI18n} from "../utils/useAunoaI18n";
import {defineComponent, toRefs, computed} from "vue";
import AunoaNavItem from "./nav/AunoaNavItem.vue";
import {useLocale} from "../utils/useLocale";

declare type Indicator = "code" | "text";

export default defineComponent({
    name: "AunoaSelectLocaleNavItem",
    mixins: [AunoaSelectNavItemMixin],
    components: {
        AunoaDropdownCheckItem,
        AunoaDropdownDivider,
        AunoaNavItem,
    },
    props: {
        indicator: {
            type: String as PropType<Indicator>,
            default: "code"
        },
        locale: {
            type: String,
            required: true
        },
        locales: {
            type: Array as PropType<string[]>,
            required: true
        }
    },
    emits: ["select"],
    setup(props) {

        const {t} = useAunoaI18n();
        const {locales} = toRefs(props);
        const locale = useLocale();

        const primaryLocales = computed(() => locale.getPrimaryLocales(locales.value));
        const secondaryLocales = computed(() => locale.getSecondaryLocales(locales.value));

        return {
            t,
            ...locale,
            primaryLocales,
            secondaryLocales
        }
    }
});

</script>

