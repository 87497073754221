﻿import {AccessToken} from "./types";
import {useMateApi} from "./useMateApi";

export const useNavigationApi = (accessToken: AccessToken) => {
    
    const {jsonEndpoint} = useMateApi(accessToken)
    const api = jsonEndpoint("/api/ui/navigation");

    const getLinks = () => api.get({});
    const getPermissions = () => api.get({}, {url: "/permissions"}).then(r=>{
        // LGB for quick testings....
        //r["/tools/messages"][2]=[];
        
        //console.log("getPermissions", r);
        return r;
    });
    const getProgress = () => api.get({}, {url: "/progress"});

    return {
        getLinks,
        getPermissions,
        getProgress
    }
}
