﻿<template>
    <div
        v-if="v && v.$errors"
        class="form-field-error text-end" v-for="error in v.$errors">
        <span v-text="message(error, label, value)" />
    </div>
</template>

<!--

    <pre  class="mb-1 p-1" style="font-size: 11px">{{ JSON.stringify({...validation, ...v}, undefined, 4) }}</pre>

<i class="fad fa-exclamation-circle pe-1" />
-->

<script lang="ts">
import {defineComponent} from "vue";
import {useVuelidationErrorMessages} from "../../implementations/forms/useVuelidationErrorMessages";

const NOT_DETAILED = false;

export default defineComponent({
    name: "AunoaErrorMessage",
    props: {
        v: {
            type: Object
        },
        label: {},
        value: {}
    },
    setup() {
        const {message} = useVuelidationErrorMessages(NOT_DETAILED);
        return {
            message
        }
    }
});

</script>
