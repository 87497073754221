﻿<template>
    <div v-bind="attrs">
        <slot />
    </div>
</template>

<script lang="ts">

import type {TabInfo} from "./usePaneSubscription";

import {provideFieldGroupInjection} from "../../implementations/forms/useFieldGroupInjection";
import {computed, defineComponent, onBeforeUnmount, onMounted, onUpdated, toRefs} from "vue";
import {usePaneSubscription} from "./usePaneSubscription";
import {toKebapCase} from "../../utils/textCasing";
import {usePaneClass} from "./usePaneClass";


let uidCounter = 102267;

export default defineComponent({
    name: "AunoaTabPane",
    inheritAttrs: false,
    components: {},
    props: {
        icon: {
            type: String,
            default: undefined
        },
        linkText: {
            type: String,
            default: undefined
        }
    },
    setup(props, {attrs: _attrs}) {

        const uid = uidCounter++;
        const {icon, linkText} = toRefs(props);

        const {paneClass} = usePaneClass();
        const {paneSubscription} = usePaneSubscription();

        const id = computed(() => `tab-pane-${toKebapCase(<string>_attrs.id)}-${uid}`);

        const attrs = computed(() => ({
            ..._attrs,
            "id": id.value,
            "class": [
                "tab-pane fade",
                _attrs.class || paneClass?.value
            ].filter(Boolean),
            "role": "tabpanel",
            "aria-labelledby": `${id.value}-link`
        }));

        const {fieldErrorCount} = provideFieldGroupInjection()

        const tab: TabInfo = {
            id: id,
            icon: icon,
            linkText: linkText,
            fieldErrorCount: fieldErrorCount
        };

        onMounted(() => {
            paneSubscription?.addTab(tab);
            //console.log("AunoaTabPane mounted: " + attrs.id);
        });
        onUpdated(() => {
            //paneSubscription?.removeTab(tab);
            //paneSubscription?.addTab(tab);
            //console.log("AunoaTabPane updated: " + _attrs.id);
        });
        onBeforeUnmount(() => {
            paneSubscription?.removeTab(tab);
            //console.log("AunoaTabPane unmounting: " + attrs.id);
        });

        return {
            attrs
        }

    }
});

</script>

