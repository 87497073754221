<template>
    <table class="table table-hack table-striped table-data-grid table-simplified-hover mb-0 bg-body">
        <aunoa-data-head
            :grid-model="gridModel"
            :calc-head-height="true"
            column-class="sticky-top bg-header"
        >
            <template v-slot:before-cells="{columnClass}">
                <th :class="columnClass">&nbsp;</th>
            </template>
        </aunoa-data-head>
        <tbody v-if="entities">
        <aunoa-data-row
            v-for="(entity, index) in entities"
            :grid-model="gridModel"
            :query-context="queryContext"
            :entity="entity"
            :index="index"
            :last="index===entities.length-1"
            :is-disabled="isDisabled"
        />
        </tbody>
    </table>

</template>

<script lang="ts">

import type {Lookup, LookupFactories, Tables} from "bootstrap-aunoa";

import {computed, defineComponent, PropType, ref, toRefs, unref, watch, onMounted, onBeforeUnmount, reactive, toRaw, shallowRef} from "vue";
import AunoaDataHead from "../components/AunoaDataHead.vue";
import AunoaDataRow from "../components/AunoaDataRow";

export default defineComponent({
    name: "AunoaSimpleDataGrid",
    components: {
        AunoaDataHead,
        AunoaDataRow
    },
    props: {
        gridModel: {
            type: Object as PropType<Tables.Model.Grid>,
            required: true
        },
        entities: {
            type: Array,
            required: true
        },
        lookupFactories: {
            type: Object as PropType<LookupFactories>,
            default: undefined
        }
    },
    setup: (props) => {

        const
            {
                lookupFactories,
            } = toRefs(props);
      
        const lookups = computed(() => {
            const o: Record<string, Lookup> = {};
            const factories = unref(lookupFactories);
            if (factories) {
                Object.keys(factories).forEach(name => o[name] = factories[name].create());
            }
            return o;
        })
      
        return {
            queryContext: reactive({
                  Lookups: lookups
            }),
            isDisabled: ()=>false,
        }
    }
})

</script>

