﻿import {useRoutePermissions} from "../utils/useRoutePermissions";
import {useTenancy, useScopeSources} from "@tnt/mate-api";
import {computed} from "vue";

export const useRouteScope = () => {

    const sources = useScopeSources();
    const {tenants: allTenants} = sources;

    const {tenantOids} = useTenancy();
    const {permittedTenantOids} = useRoutePermissions();
    
    const primaryTenants = computed(() => {
        if (tenantOids.value?.length) {
            // @ts-ignore
            return allTenants.value.filter(t => t.oid && tenantOids.value.includes(t.oid));
        }
        return [];
    });
    

    const tenants = computed(() => {

        //sources.tenants.value.forEach(t => {
        //    //if (t.oid === 2) {
        //    //    t.id = "BACKERY-EULER-BERLIN";
        //    //}
        //})

        if (permittedTenantOids.value?.length) {
            // @ts-ignore
            return allTenants.value.filter(t => t.oid && permittedTenantOids.value.includes(t.oid));
        }
        return allTenants.value;
    });

    return {
        ...sources,
        primaryTenants,
        allTenants,
        tenants
    }
}