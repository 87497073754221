﻿import type {PropType} from "vue";
import type {Tables, Entities} from "bootstrap-aunoa";
import {computed, defineComponent, ref, toRefs, watch, h} from "vue";
import {renderCell} from "./AunoaDataCell";
import {ensureClass, useAunoaI18n} from "bootstrap-aunoa";

export default defineComponent({
    name: "AunoaDataRow",
    inheritAttrs: false,
    props: {
        gridModel: {
            type: Object as PropType<Tables.Model.Grid>,
            required: true
        },
        entity: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            default: -1,
        },
        last: {
            type: Boolean,
            default: false,
            
        },
        queryContext: {
            type: Object as PropType<Entities.QueryContext>
        },
        getKey: {
            type: Function,
            default: () => undefined
        },
        isDisabled: {
            type: Function,
            default: () => false
        }
    },
    emits: ["click", "dblClick"],
    setup(props, {emit, slots}) {

        const {entity: _entity} = toRefs(props);

        const entityContext = ref<Partial<Entities.EntityContext>>({});

        let renderCount = 0;
        //let counter = 0;
        watch(_entity, entity => {
            //console.log("entity changed", entity.Oid, ++counter);
            renderCount = 0;
            if (entityContext.value?.Entity !== entity) {
                entityContext.value = {
                    ...props.queryContext,
                    Entity: entity,
                    EntityKey: props.getKey?.(entity),
                    EntitySelected: false,
                }
            }
        }, {immediate: true});

        let lastEntity: any;
        let lastTimeout: any;

        const onClick = (e: MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            const entity = entityContext.value.Entity;
            if (lastTimeout) {
                clearTimeout(lastTimeout);
                lastTimeout = undefined;
            }
            if (lastEntity && entity && lastEntity === entity) {
                lastEntity = undefined;
                //console.log("### dblClick ###", entityContext.value);
                //emit("dblClick", entityContext.value);
            } else {
                lastEntity = entity;
                lastTimeout = setTimeout(() => lastEntity = undefined, 420);
                //console.log("### click ###", entityContext.value);
                emit("click", entityContext.value);
            }
        }

        const {ensureTextTranslated} = useAunoaI18n();

        return () => {
            renderCount++;
            const selected = entityContext.value.EntitySelected;
            const disabled = props.isDisabled(entityContext.value.Entity);

            const cells = props.gridModel.columns.map(c => renderCell(c.lines, <Entities.EntityContext>entityContext.value, ensureTextTranslated));
            const index = props.index;
            if (index >= 0) {

                cells.splice(0, 0, h("th", {
                    rowspan: selected ? 3 : undefined,
                    class: "text-center small h-100"
                }, h("div", {
                    class: "flex-column fw-normal"
                }, [
                    h("div",
                      disabled ? h("i", {class: "fas fa-lock text-muted"}) : undefined
                      //"R-"+renderCount  
                    ),
                    h(
                        "div",
                        {class: "mt-auto text-muted small"},
                        (index === 0 || index % 10 === 9 || props.last) ? `‐${index + 1}‐` : (index + 1) % 5 === 0 ? `‐` : ""
                    )
                ])));
            }

            //console.log("Render Row", index, "#"+renderCount, entityContext.value.Entity.Oid, selected ? "selected" : "");

            const trClass = ensureClass([
                selected ? "tr-extended" : undefined,
                disabled ? "tr-disabled" : undefined
            ]);
            return [
                h("tr", {onClick: onClick, class: trClass}, cells),
                selected
                    ? h("tr", {class: "tr-stripe-equalization"}, h("td", {colspan: props.gridModel.columns.length}))
                    : undefined,
                selected
                    ? h("tr", {class: "tr-extension"},
                        h("td", {colspan: props.gridModel.columns.length, class: "p-0"}, slots.inline({entityContext: entityContext.value})))
                    : undefined,
            ].filter(Boolean);
        }

    }
});
