﻿import {DataGroup, DataType} from "@tnt/mate-api";

import {RouteComponent} from "vue-router";
import {useAuth} from '../utils/useAuth';

declare type Lazy<T> = () => Promise<T>;

export interface ComponentMapping {
    path?: string;
    alias?: string;
    component: Lazy<RouteComponent>;
    props?: any;
    related?: Record<string, ComponentMapping>;
    meta?: any;
}

export const mappings: Record<string, ComponentMapping> = {

    "ArticleQuickSelection": {
        component: () => import("./settings/ArticleQuickSelectionIndex.vue"),
        related: {
            "ArticleQuickSelectionEditor": {
                path: "/settings/pos/article-quick-selections/:id",
                component: () => import("./settings/ArticleQuickSelection.vue"),
                props: (route: any) => ({
                    id: route.params.id,
                }),
                meta: {
                    // LGB: just temporay for quick solution
                    ignorePermissions: true,
                    allowAnonymous: true,
                }
            }
        }
    },
    "QuickAccess": {
        component: () => import("./settings/QuickAccessIndex.vue"),
        related: {
            "QuickAccessEditor": {
                path: "/settings/pos/quick-accesses/:id",
                component: () => import("./settings/QuickAccess.vue"),
                props: (route: any) => ({
                    id: route.params.id,
                }),
                meta: {
                    // LGB: just temporay for quick solution
                    ignorePermissions: true,
                    allowAnonymous: true,
                }
            }
        }
    },
    "Bridge": {
        component: () => import("./diagnostics/Bridge.vue")
    },
    //"BridgeDetails": {
    // path: "/service/bridge/:id",
    //component: () => import("./diagnostics/BridgeDetails.vue"),
    // props: (route: any) => ({
    //     uid: route.params.uid?.toLowerCase()     
    // })
    //},
    "Configuration": {
        path: "/settings/:target/configuration",
        //component: () => import("./settings/Configuration.vue")
        component: () => import("./settings/FramedConfiguration.vue"),
        props: (route: any) => ({
            target: route.params.target?.toLowerCase()
        })
    },
    "Monitoring": {
        component: () => import("./diagnostics/FramedMonitoring.vue")
    },
    "DatabaseUsage": {
        component: () => import("./diagnostics/DatabaseUsage.vue")
    },
    // "DocumentLocks": {
    //     component: () => import("./diagnostics/DocumentLocks.vue")
    // },
    "DocumentViewer": {
        path: "/document/:documentGroup/:documentType",
        component: () => import("./documents/DocumentGrid.vue"),
        props: (route: any) => ({
            documentGroup: route.params.documentGroup?.toLowerCase(),
            documentType: route.params.documentType?.toLowerCase(),
            query: route.query
        })
    },
    "Message": {
        component: () => import("./chat/Chats.vue"),
    },
    "ReceiptTemplate": {
        component: () => import("./settings/XmlReceiptTemplates.vue")
    },

    "ReportGrid": {
        path: "/report/:reportGroup/:reportType",
        component: () => import("./report/ReportGrid.vue"),
        props: (route: any) => ({
            reportGroup: route.params.reportGroup?.toLowerCase(),
            reportType: route.params.reportType?.toLowerCase(),
            query: route.query
        })
    },
    "ReportExport": {
        path: "/report/export/:reportGroup/:reportType",
        component: () => import("./report/ReportExport.vue"),
        props: (route: any) => ({
            reportGroup: route.params.reportGroup?.toLowerCase(),
            reportType: route.params.reportType?.toLowerCase(),
            query: route.query
        })
    },
    "MasterData": {
        path: "/data/:dataGroup/:dataType",
        component: () => import("./data/DataGrid.vue"),
        props: (route: any) => ({
            dataGroup: route.params.dataGroup?.toLowerCase(),
            dataType: route.params.dataType?.toLowerCase(),
            query: route.query
        })
    },    
    "ContactTasks":{
        //path: "/data/:dataGroup",
        component: () => import("./data/DataGrid.vue"),
        props: (route: any) => ({
            dataGroup: DataGroup.PosPreferences,
            dataType: DataType.ContactTask,
            query: route.query
        })
    },    
    "DocumentLocks":{
        //path: "/data/:dataGroup",
        component: () => import("./data/DataGrid.vue"),
        props: (route: any) => ({
            dataGroup: DataGroup.PosOperating,
            dataType: DataType.DocumentLock,
            query: route.query
        })
    },
    "PromotionEditor": {
        component: () => import("./data/DataGrid.vue"),
        props: (route: any) => ({
            dataGroup: DataGroup.Campaigns,
            dataType: DataType.Promotion,
            query: route.query
        })
    },
    "RandomScanSheet": {
        component: () => import("./tools/RandomScanSheet.vue")
    },
    "RetailIcons": {
        component: () => import("./tools/RetailIcons.vue")
    },
    "ShoppingCart": {
        component: () => import("./dev-tools/ShoppingCart.vue")
    },
    "VisualReceiptDesigner": {
        path: "/visual-receipt-designer/:documentType",
        component: async () => {
            //await new Promise(resolve => setTimeout(resolve, 5000));
            return await import("@tnt/receipt-design");
        },
        props: (route: any) => {
            const {user} = useAuth();
            return {
                user: user.value,
                documentType: route.params.documentType
            };
        }
    },
    "UnprocessedDocuments": {
        component: () => import("./diagnostics/UnprocessedDocuments.vue")
    },
    "TerminalServer": {
        component: () => import("./diagnostics/TerminalServer.vue")
    },
    "ServerLog": {
        component: () => import("./logging/ServerLog.vue")
    },
    "TerminalServerLog": {
        component: () => import("./logging/TerminalServerLog.vue")
    },
    "Swagger": {
        component: () => import("./tools/Swagger.vue")
    },
    "RapiDoc": {
        component: () => import("./tools/RapiDoc.vue")
    },
    "Dropzone": {
        component: () => import("./tools/Dropzone.vue")
    },
    "ReportEngine": {
        component: () => import("./data/DataGrid.vue"),
        props: (route: any) => ({
            dataGroup: DataGroup.PosPreferences,
            dataType: DataType.ReportEngine,
            query: route.query
        })
    }
}
