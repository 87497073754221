﻿import {Ref, unref} from "vue";
import {Target as BaseTarget, useDecoration} from "./useDecoration";
import {CurrencyAmountDecoration, CurrencyAmountOptions, ContextablePropertyPath} from "../../types";
import {isFunction, isString} from "../../utils/inspect";
import {createContextablePropertyPath} from "../../utils/properties";


interface Target extends BaseTarget {
    currencyAmountOptions?: CurrencyAmountOptions;
}

export const useCurrencyAmountDecoration = (target: Target | Ref<Target>) => {

    function get(): CurrencyAmountOptions {
        const t = unref(target);
        return t.currencyAmountOptions = t.currencyAmountOptions || {};
    }

    const decoration: CurrencyAmountDecoration = {
        ...useDecoration(target),
        SuppressZero: (value = true) => set(options => options.suppressZero = value),
        UseCurrency: (value: string | ((m: ContextablePropertyPath) => void)) => {
            if (isString(value)) {
                get().currency = value;
            } else if (isFunction(value)) {
                const builder = createContextablePropertyPath();
                (<any>value)(builder);
                const propertyPath = builder.Build();
                get().currency = "{{" + propertyPath + "}}"
            }
            return decoration
        },

    }

    function set(action: (options: CurrencyAmountOptions) => void) {
        action(get());
        return decoration;
    }

    return decoration;
};