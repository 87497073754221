﻿<template>
    <aunoa-form
        class="form-query padding-top" :class="{'inner-border-dashed':formModel}"
        :entity="entity"
        :entity-model="entityModel"
        :form-model="formModel"
        :lookup-factories="lookupFactories"
        mode="search"
    >
        <div class="row">
            <aunoa-form-group
                v-if="formModel"
                v-for="([id, group]) in Object.entries(formModel)"
                class="col"
                row-class="row"
                column-class="col"
                :id="id"
                :group-model="group" />
            <div class="pt-3" v-else />
        </div>

        <div class="row border-bottom shadow-sm" v-if="$slots.navItems">
            <aunoa-nav :grip="true">
                <slot name="navItems" />
            </aunoa-nav>
        </div>

    </aunoa-form>
</template>

<script lang="ts">

import type {PropType} from "vue";
import type {Entities, Forms} from "../types";
import type {LookupFactories} from "../implementations/lookup/useLookup";

import AunoaFormGroup from "../components/form/AunoaFormGroup.vue";
import AunoaNavItem from "../components/nav/AunoaNavItem.vue";
import AunoaForm from "../components/form/AunoaForm.vue";
import AunoaNav from "../components/nav/AunoaNav.vue";
import {defineComponent, toRefs, ref, watch} from "vue";

export default defineComponent({
    name: "AunoaDataForm",
    components: {
        AunoaForm,
        AunoaFormGroup,
        AunoaNav,
        AunoaNavItem
    },
    props: {
        entity: {
            type: Object,
            default: undefined
        },
        entityModel: {
            type: Array as PropType<Entities.Model.Property[]>,
            default: undefined
        },
        formModel: {
            type: Object as PropType<Forms.Model.Form>,
            default: undefined
        },
        lookupFactories: {
            type: Object as PropType<LookupFactories>,
            default: undefined
        }
    },
    setup(props, {emit}) {

        return {}
    }
});

</script>