﻿<template>
    <aunoa-checkbox-tree
        :texts="translatedTexts(entity.Permissions.Texts)"
        :items="entity.Permissions.Items"
        style="height:500px"
        search-placeholder="Search in Permissions"
    />
</template>

<script lang="ts">

import {defineComponent, PropType} from "vue";
import {AunoaCheckboxTree, useAunoaI18n} from "bootstrap-aunoa";

export default defineComponent({
    name: "RolePermissions",
    components: {AunoaCheckboxTree},
    props: {
        entity: {
            type: Object as PropType<Tnt.Dto.Identity.RoleDto>,
            required: true
        }
    },
    setup() {

        const {ensureTextTranslated} = useAunoaI18n();
        const translatedTexts = (text: string[]):string[] => 
            text.map(ensureTextTranslated)

        return {
            translatedTexts
        }
    }
});

</script>

