﻿import {App} from "vue";
import AunoaTabContent from "./AunoaTabContent.vue";
import AunoaTabNav from "./AunoaTabNav.vue";
import AunoaTabNavLink from "./AunoaTabNavLink.vue";
import AunoaTabPane from "./AunoaTabPane.vue";

export interface TabComponentOptions {
}

export const installTabComponents = (app: App, options: TabComponentOptions) => {
    options = options || {};
    app
        .component(AunoaTabContent.name, AunoaTabContent)
        .component(AunoaTabNav.name, AunoaTabNav)
        .component(AunoaTabNavLink.name, AunoaTabNavLink)
        .component(AunoaTabPane.name, AunoaTabPane);
};

export {
    AunoaTabContent,
    AunoaTabNav,
    AunoaTabNavLink,
    AunoaTabPane
}
