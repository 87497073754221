﻿<template>
    <li>
        <component :is="tag" class="dropdown-header" v-bind="$attrs">
            <span v-text="text" />
        </component>
    </li>
</template>

<script lang="ts">

import {defineComponent} from "vue";

export default defineComponent({
    name: "AunoaDropdownHeader",
    inheritAttrs: false,
    props: {
        tag: {
            type: String,
            default: "h5"
        },
        text: {
            type: String
        }
    }
});

</script>

