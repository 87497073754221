import {onBeforeUnmount} from "vue";

export const useEventListener = <K extends keyof HTMLElementEventMap>(
    element: HTMLElement | Document | Window,
    type: K,
    listener: (ev: HTMLElementEventMap[K]) => any,
    addOptions?: boolean | AddEventListenerOptions,
    removeOptions?: boolean | EventListenerOptions
) => {
    if (!element) {
        return;
    }
    // @ts-ignore
    element.addEventListener<K>(type, listener, addOptions);
    onBeforeUnmount(() => {
        // @ts-ignore
        element.removeEventListener<K>(type, listener, removeOptions);
    })
}


export const useCustomEventListener = (
    element: HTMLElement | Document,
    type: string,
    listener: EventListenerOrEventListenerObject,
    addOptions?: boolean | AddEventListenerOptions,
    removeOptions?: boolean | EventListenerOptions
) => {
    if (!element) {
        return;
    }
    element.addEventListener(type, listener, addOptions);
    onBeforeUnmount(() => {
        element.removeEventListener(type, listener, removeOptions);
    })
} 