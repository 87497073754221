import type {PropType, ComponentObjectPropsOptions} from "vue";

import {useEmitableVisibilityEvents, useVisibilityEvents} from "./useVisibilityEvents";
import {computed, onBeforeUnmount, onMounted, ref} from "vue";
import {Modal} from "bootstrap";

export type ModalSize = "sm" | "md" | "lg" | "xl" | "max" | "fullscreen";

export interface ModalProps {
    backdrop: string | boolean;
    centered: boolean;
    focus: boolean;
    keyboard: boolean;
    size: ModalSize;
}

export const modalPropsOptions: ComponentObjectPropsOptions<ModalProps> = {
    backdrop: {
        type: [String, Boolean],
        default: true
    },
    centered: {
        type: Boolean,
        default: false
    },
    focus: {
        type: Boolean,
        default: true
    },
    keyboard: {
        type: Boolean,
        default: true
    },
    size: {
        type: String as PropType<ModalSize>,
        default: "md"
    }
}

export const useModal = <TProps extends ModalProps = ModalProps>(props: TProps, emit: any) => {

    const visible = ref(false);
    const shown = ref(false);
    const modalElement = ref<HTMLElement>();

    const modal = ref<Modal>();

    useEmitableVisibilityEvents("modal", emit, {element: modalElement});
    useVisibilityEvents("modal", {
        element: modalElement,
        onShow: () => visible.value = true,
        onShown: () => shown.value = true,
        onHide: () => shown.value = false,
        onHidden: () => visible.value = false,
    });

    const dialogClass = computed(() => [
        "modal-dialog",
        props.centered ? "modal-dialog-centered" : undefined,
        props.size === "sm" ? "modal-sm" : undefined,
        props.size === "lg" ? "modal-lg" : undefined,
        props.size === "xl" ? "modal-xl" : undefined,
        props.size === "max" ? "modal-max" : undefined,
        props.size === "fullscreen" ? "modal-fullscreen" : undefined,
    ].filter(Boolean));

    const toggle = () => modal.value?.toggle();
    const show = () => modal.value?.show();
    const hide = () => modal.value?.hide();
    const handleUpdate = () => modal.value?.handleUpdate();

    onMounted(() => {
        const element = <any>modalElement.value;

        document.getElementById("app")?.appendChild(element);
        
        modal.value = new Modal(element, {
            keyboard: props.keyboard,
            backdrop: <any>props.backdrop,
            focus: props.focus
        })
        onBeforeUnmount(() => {
            modal.value?.dispose();
        });
    });


    return {
        shown,
        visible,
        dialogClass,
        modalElement,

        handleUpdate,
        toggle,
        show,
        hide

    }

}