import type {Ref} from "vue";
import type {Facade} from "./_abstracts";
import {HubConnection} from "@microsoft/signalr";
import {ref} from "vue";

export interface BridgeFacade {
    bridgeDict: Ref<BridgeDict>   
    subscribeJobs(uid:string): Promise<any>
    unSubscribeJobs(uid:string): Promise<any>
    startJob(uid: string, jobName:string): Promise<any>
    cancelJob(uid: string, jobName:string): Promise<any>
}

type BridgeUid = string;
type JobId = string;

export interface Bridge {
    id: string;
    version: string|undefined;
    buildDate: Date|undefined;
    state: "CONNECTING" | "ON" | string | null;
    jobCount: number;
    jobFailedCount: number;
    jobs: JobDict;
}

export interface JobItem {  
    name: string;
    state: number;
    startedAt: Date|undefined;
    finishedAt: Date|undefined;
    countMax: number;
    count: number;
    error: string|null;
    category: string;
    tenant: string;
    countryCode: string;
    description: string;
    countUnit: string;
    isManualStarted: boolean;
}

export type BridgeDict = Record<BridgeUid, Bridge>;
export type JobDict = Record<JobId, JobItem>

export const createConnectingBridge = (): Bridge => ({
    id: "...",
    version: undefined,
    buildDate: undefined,
    state: "CONNECTING",
    jobCount: 0,
    jobFailedCount: 0,
    jobs: {}
})

export const createJobDummy = (name:string): JobItem => ({
    name: name,
    state: -99,
    startedAt: undefined,
    finishedAt: undefined,
    countMax: -1,
    count: -1,
    error: "",
    category: "",
    tenant: "",
    countryCode: "",
    description: "",
    countUnit: "",
    isManualStarted: false
})

export const createBridgeFacade = (connection: HubConnection): BridgeFacade & Facade => {

    const bridgeDict = ref<BridgeDict>({});   
    let subscriptions = <string[]>[];

    const onUpdateState = (str: string) => {
        //console.log(str);
        const segments = (str + "||||||||").split("|");
        const version = +segments[0];
        if (version === 1) {
            const [_, uid, id, state, countStr, failedCountStr, uri, version, dateStr] = segments;
            const bridge = bridgeDict.value[uid]
                ? bridgeDict.value[uid]
                : (bridgeDict.value[uid] = createConnectingBridge())
            bridge.id = id;
            if (version) {
                bridge.version = version;
            }
            if (dateStr) {
                bridge.buildDate = new Date(dateStr);
            }
            if(bridge.jobs===undefined){
                bridge.jobs = {};    
            }            
            bridge.state = state;
            bridge.jobCount = parseInt(`0x${countStr}`);
            bridge.jobFailedCount = parseInt(`0x${failedCountStr}`);
            //console.log(JSON.stringify(bridge, null, 4));
        }
    }
    
    const onUpdateJobs = (str: string) => {
      //console.log(str);        
        const lines = str.split("\r\n");
        const segments = (lines.shift() + "||").split("|");
        const version = +segments[0];
        if (version === 1) {
            const [_, uid, id] = segments;
           
            const bridge = bridgeDict.value[uid];            
            if(bridge){     
                if(bridge.jobs===undefined){
                    bridge.jobs = {};
                }
                for(let line of lines) {
                    const jobSegments = line.split("|");                    
                    const [_, name, state, startedAt, finishedAt, countMax, count, error, category, tenant, countryCode, description, countUnit] = jobSegments;                    
                    if(name!==undefined) {
                        const job = bridge.jobs[name]
                            ? bridge.jobs[name]
                            : (bridge.jobs[name] = createJobDummy(name))
                        
                        if(job.state!==-99){
                            job.state = parseInt(state);
                            job.startedAt = startedAt ? new Date(parseInt(startedAt, 16)) : undefined
                            job.finishedAt = finishedAt ? new Date(parseInt(finishedAt, 16))  : undefined
                            job.countMax = parseInt(countMax);
                            job.count = parseInt(count);
                            job.error = error;
                        }
                        else {
                            job.state = parseInt(state);
                            job.startedAt = startedAt ? new Date(parseInt(startedAt, 16)) : undefined
                            job.finishedAt = finishedAt ? new Date(parseInt(finishedAt, 16))  : undefined
                            job.countMax = parseInt(countMax);
                            job.count = parseInt(count);
                            job.error = error;
                            job.category = category;
                            job.tenant = tenant;
                            job.countryCode = countryCode;
                            job.description = description;
                            job.countUnit = countUnit;                            

                            if (job.category==undefined || !job.category.length) {
                                job.category = "Unknown";
                            }
                            if (job.countryCode === "$" || job.countryCode === "*") {
                                job.countryCode = "Global";
                            }
                        }

                        job.error = job.error ? job.error.replace(/-NEWLINE-/g, "<br/>").replace(/\\/g, " / ") : null;
                       
                        if (job.state >= 3 && job.isManualStarted) {
                            job.isManualStarted = false;
                            switch (job.state) {
                                case 3:
                                    //TODO: notification?
                                    console.log(`${job.name}<br/>Operation successfull`);
                                    break;
                                case 4:
                                    //TODO: notification?
                                    console.error(`${job.name}<br/>Operation failed.`);
                                    break;
                                case 5:
                                    //TODO: notification?
                                    console.warn(`${job.name}<br/>Operation cancelled`);
                                    break;
                            }
                        }
                    }
                }                
            }
        }
      
    }

    connection.on("UpdateBridgeState", onUpdateState);
    connection.on("UpdateBridgeJobs", onUpdateJobs);

    const initialize = async () => {
        await connection.invoke("SubscribeBridgeStateNotification");
    }

    const startJob = (uid: string, jobName:string) => {
        return connection.invoke("StartBridgeJob", uid, jobName);
    }

    const cancelJob = (uid: string, jobName:string) => {
        return connection.invoke("CancelBridgeJob", uid, jobName);
    }
    
    const subscribeJobs = async (uid:string) => {        
        if(subscriptions.indexOf(uid)<0) {
            //console.log("subscribe");
            await connection.invoke("SubscribeBridgeJobNotification", uid, true);
            subscriptions.push(uid);
        }
    }

    const unSubscribeJobs = async (uid:string) => {
        if(subscriptions.indexOf(uid)>=0) {
          //  console.log("unsubscribe");
            await connection.invoke("SubscribeBridgeJobNotification", uid, false).catch(()=>{});
            subscriptions = subscriptions.filter(d => d !== uid)
        }
    }

    const dispose = () => {
       // console.log("disposal");
        connection.off("UpdateBridgeState", onUpdateState);
        connection.off("UpdateBridgeJobs", onUpdateJobs);
        subscriptions.forEach(sub => connection.invoke("SubscribeBridgeJobNotification", sub, false));
        //let p = connection.invoke("SubscribeBridgeJobNotification", "00000000000000000000000000000000", false);        
    }

    return {        
        bridgeDict,
        initialize,
        startJob,
        cancelJob,
        subscribeJobs,
        unSubscribeJobs,
        dispose
    }
}