﻿export enum LinkItemState {
    Hidden = 0,
    InProgress = 1,
    Testable = 2,
    Visible = 3
}

export interface LinkItem {
    //parent?: LinkItem;
    id?: string;
    path?: string | null;
    name: string;
    title?: string | null;
    icon?: string | null;
    children?: LinkItem[];
    parentNames?: string[];
    state?: LinkItemState;
    isVisible?(): boolean;
    //progress?: boolean;
}

declare type EnumerateLinksCallback<T extends LinkItem = LinkItem> = (link: T, hierarchicalLinks: T[]) => void;

export const isLinkVisible = (item: LinkItem) =>
    item?.isVisible
        ? item.isVisible()
        : true

export const linkStateIcon = (item: LinkItem) => {
    if (item) {
        switch (item.state) {
            case LinkItemState.InProgress:
                return "fad fa-person-digging fa-flip-horizontal text-disabled";
            case LinkItemState.Testable:
                return "fad fa-flask-round-potion text-primary";
        }
    }
    return undefined;
}

function _enumerateLinks<T extends LinkItem = LinkItem>(links: T[], cb: EnumerateLinksCallback<T>, parentLinks: T[]): void {
    links.forEach(link => {
        const hierarchicalLinks = parentLinks.concat([link]);
        cb(link, hierarchicalLinks);
        if (link.children) {
            _enumerateLinks<T>(link.children as T[], cb, hierarchicalLinks);
        }
    });
}


export function enumerateLinks<T extends LinkItem = LinkItem>(links: T[], cb: EnumerateLinksCallback<T>): void {
    _enumerateLinks(links, cb, []);
}

