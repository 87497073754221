﻿<template>
    <aunoa-nav-item
        id="progressNavItem"
        :drop-direction="dropDirection"
        :drop-menu-class="dropMenuClass"
        :header-text="t('Aunoa.Network.NetworkActivities')"
        :icon="busy?`fa-fw ${busyIcon}`:'fa-fw far fa-circle'"
        @show-dropdown="showTable=true"
        @dropdown-hidden="showTable=false">
        <li v-if="showTable">
            <table class="table table-small small">
                <thead>
                <tr>
                    <th v-text="t('Aunoa.Network.Method')" />
                    <th class="text-center" v-text="t('Aunoa.Network.Status')" />
                    <th v-text="'Url'" />
                    <th class="text-end">
                        <i class="fa-lg fad fa-upload" />
                    </th>
                    <th class="text-end">
                        <i class="fa-lg fad fa-download" />
                    </th>
                    <th class="text-end">
                        <i class="fa-lg far fa-stopwatch" />
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="a in axiosActivities">
                    <td>{{ a.method.toUpperCase() }}</td>
                    <td class="text-center">
                        <span class="badge badge-sm px-2 bg-danger" v-text="a.status" v-if="a.status>=400" />
                        <span class="badge badge-sm px-2 bg-info" v-text="a.status" v-else-if="a.status>=300" />
                        <span v-else-if="a.status && a.error">
                            <span class="badge badge-sm px-2 bg-danger" v-text="a.status" />
                            <i class="fas fa-bolt-lightning" />
                        </span>
                        <span class="badge badge-sm px-2 bg-success" v-text="a.status" v-else-if="a.status" />
                        <i class="fa-lg" :class="busyIcon" v-else />
                    </td>
                    <td class="vw-min40">
                        <div>
                            <span v-if="a.baseUrl" v-text="a.baseUrl.replace(/\/$/g, '')" class="text-muted" />
                            <span class="fw-bold" v-text="a.url" />
                            <template v-for="({p,v},i) in params(a.params)">
                                <span class="text-muted" v-text="i===0?'?':'&'"></span>
                                <span v-text="p"></span>
                                <span class="text-muted">=</span>
                                <span class="fw-bold" v-text="v"></span>
                            </template>
                        </div>
                        <div class="text-danger" v-if="a.error">
                            <i v-text="ensureTextTranslated(a.error)" />
                        </div>
                    </td>
                    <td class="text-end text-nowrap" v-text="toByteSizeStr(a.uploaded, '-')" />
                    <td class="text-end text-nowrap" v-text="toByteSizeStr(a.downloaded, '-')" />
                    <td class="text-end text-nowrap" v-text="toDurationSizeStr(a.duration, '-')" />
                </tr>
                </tbody>
            </table>
        </li>
    </aunoa-nav-item>
</template>

<!--

    <li class="nav-item"><span class="nav-text">{{ routerBusyCount }}|{{ axiosBusyCount }}</span></li>

-->

<script lang="ts">

import type {PropType} from "vue";

import {toByteSizeStr, toDurationSizeStr} from "../implementations/decoration/numberDecoration";
import {defineComponent, ref, toRefs, computed, onBeforeMount, onBeforeUnmount} from "vue";
import AunoaSelectNavItemMixin from "../mixins/AunoaSelectNavItemMixin";
import {resolveIconAlias} from '../utils/useIconAlias';
import {useAunoaI18n} from "../utils/useAunoaI18n";
import AunoaHourglass from "./AunoaHourglass.vue";
import AunoaNavItem from "./nav/AunoaNavItem.vue";
import {useRouter} from "vue-router";

interface Activity {
    started: number;
    status: number;
    method: string;
    duration: number;
    uploaded: number;
    downloaded: number;
    baseUrl: string;
    url: string;
    params: any;
}

export default defineComponent({
    name: "AunoaProgressNavItem",
    mixins: [AunoaSelectNavItemMixin],
    components: {
        AunoaHourglass,
        AunoaNavItem
    },
    props: {
        axiosActivities: {
            type: Array as PropType<Activity[]>,
            default: []
        },
        axiosBusyCount: {
            type: Number,
            default: 0
        }
    },
    setup(props) {

        const {axiosBusyCount} = toRefs(props);
        const routerBusyCount = ref(0);
        const showTable = ref(false);

        const busyIcon = resolveIconAlias("busy");

        onBeforeMount(() => {
            const router = useRouter();
            const unguardEachBefore = router.beforeEach((to) => {
                (<any>to).$$busy = true;
                routerBusyCount.value++;
            });
            const unguardEachAfter = router.afterEach((to) => {
                if ((<any>to).$$busy) {
                    setTimeout(() => routerBusyCount.value--, 50);
                }
            });
            onBeforeUnmount(() => {
                unguardEachBefore();
                unguardEachAfter();
            });
        });

        const {t, ensureTextTranslated} = useAunoaI18n();

        const busy = computed(() => routerBusyCount.value || axiosBusyCount.value);

        const params = (o: any) =>
            Object.entries(o || {}).map(([p, v]) => ({p, v})).filter(i => i.v != null);

        return {
            ensureTextTranslated,
            //routerBusyCount,
            toDurationSizeStr,
            toByteSizeStr,
            showTable,
            busyIcon,
            params,
            busy,
            t
        }
    }
});

</script>