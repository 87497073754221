<template>
    <transition-group tag="div" name="toast" class="toast-container">
        <toast v-for="m in messages" :message="m" :key="m.date"  />
    </transition-group>
</template>

<script lang="ts">

import type {PropType} from "vue";
import type {TypedMessage} from "../utils/useToasts";

import Toast from "./components/Toast.vue";
import {defineComponent} from "vue";

export default defineComponent({
    name: "AunoaToastsControl",
    components: {Toast},
    props: {
        messages: {
            type: Array as PropType<TypedMessage[]>,
            required: true
        }
    }
})

</script>

