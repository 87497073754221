﻿<template>
    <aunoa-card v-bind="cardAttrs">
        <aunoa-card-header :icon="icon" :text="title(t)" />

        <div class="card-body pb-3">
            <div class="text-muted small">
                <span class="float-end" v-if="isTenancyVisible">
                    <span class="pe-2" v-text="tenant?.id" />
                    <i class="flag-icon shadow-sm" :class="tenant?.countryFlag()" />
                </span>
                <span v-text="formatCurrency(info.max, selectedCurrency)" />
            </div>

            <table class="charts-css column show-labels data-spacing-6 reverse-data show-primary-axis show-5-secondary-axes show-data-on-hover"
                   style="--labels-size: 2.5rem; height: 180px; cursor:default">
                <tbody>
                <tr v-for="{date,amount} in info.items">
                    <td :style="{'--color':color(date), '--size':info.max?amount/info.max:0}">
                        <span
                            class="data small position-absolute text-center _bg-dark _text-light"
                            style="top:-20px;left:-40px;right:-40px;z-index:1;pointer-events: none;background-color: var(--aunoa-opacity-40-background-color)"
                            v-text="formatCurrency(amount, selectedCurrency)"
                            
                        />
                    </td>
                    <th scope="row" class="fw-normal small text-center">
                        <span v-text="formatDate(date, 'iiiiii')" class="text-muted" style="font-variant: normal;text-transform: uppercase" />
                        <span v-text="formatDate(date, 'dd')" class="text-muted" />
                    </th>
                </tr>
                </tbody>
            </table>

            <div class="text-center small">
                {{ formatDate(info.items[info.items.length - 1]?.date, 'PP') }} – {{ formatDate(info.items[0]?.date, 'PP') }}
            </div>

        </div>
        <div class="card-footer d-flex justify-content-between align-items-center">
            <div>
                <span class="_text-muted" v-text="formatDate(date,'PP')" />
                <br>
                <span class="text-muted small" v-text="formatDate(date,'p')" />
            </div>
            <div class="text-end">
                <span class="text-muted small" v-text="t('Mate.Current')" />
                <br>
                <aunoa-currency-amount class="text-primary" :amount="info.items[0]?.amount||0" :currency="selectedCurrency" />
            </div>
        </div>

    </aunoa-card>
</template>

<script lang="ts">

import {formatDate, useAunoaI18n, formatCurrency, AunoaCard, AunoaCardHeader, AunoaCurrencyAmount} from "bootstrap-aunoa";
import {useCachedTenancyDataFromProps, useCurrenciesApi, useTurnoverApi} from "@tnt/mate-api";
import {useRouteScope} from "../../../scope/useRouteScope";
import {defineComponent, computed, ref, toRefs, watch} from "vue";
import {useDashboardCard} from "../useDashboard";
import {useAuth} from "../../../utils/useAuth";

interface Turnover {
    date: Date;
    amount: number;
}

interface Info {
    avg: number;
    max: number;
    items: Turnover[];
}

const createInfo = (): Info => ({
    avg: 0,
    max: 0,
    items: []
});


const roundMax = (num: number) => {
    const digits = Math.ceil(num).toString().length;
    const d = Math.pow(10, digits) / 20;
    return Math.ceil(num / d) * d;
}

const createLastDays = (date: Date, n: number) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    return Array.from(Array(n)).map((_, i) => Date.UTC(year, month, day - i))
}

const meta = {
    createdAt: "2021-04-22T00:00:00.000Z",
    icon: "fad fa-file-alt",
    title: (t: any) => t("Mate.WeeklyTurnover")
}

export default defineComponent({
    $$meta: meta,
    name: "WeeklyTurnoverCard",
    components: {AunoaCard, AunoaCardHeader, AunoaCurrencyAmount},
    props: {
        tenantOid: {
            type: Number,
            default: undefined
        }
    },
    setup(props) {

        const {t} = useAunoaI18n();
        const date = ref(new Date());

        const {accessToken} = useAuth();
        const {getTenant} = useRouteScope();
        const {tenantOid} = toRefs(props);
        const {getCurrency} = useCurrenciesApi(accessToken);
        const {getDailyTurnover} = useTurnoverApi(accessToken);
        const {data: info, currentTenantOid, isTenancyVisible} = useCachedTenancyDataFromProps<Info>(tenantOid, "WeeklyTurnover", createInfo);

        const selectedCurrency = ref("");

        const update = (info: Info, tenantOid: number) => {
            date.value = new Date();
            //date.value = new Date(2019, 1, 20);
            const counts = createLastDays(date.value, 7).map(date => (<Turnover>{
                date: new Date(date),
                amount: 0
            }));
            info.items = counts;
            return Promise.all(counts.map(dc =>
                Promise
                    .all([
                        getCurrency(tenantOid),
                        getDailyTurnover(tenantOid, dc.date)
                    ])
                    .then(([cc, r]) => {
                        selectedCurrency.value = cc.currency;
                        
                        const t = r[tenantOid] || {};
                        const dateKey = dc.date.toISOString().substr(0, 19);
                        const d = t[dateKey] || {};
                        dc.amount = d[cc.currency.toLowerCase()] || 0;

                        const amountArray = counts.map(i => i.amount);
                        const sum = amountArray.reduce((a, b) => a + b, 0);
                        info.avg = (sum / amountArray.length) || 0;
                        info.max = roundMax(Math.max(...amountArray)) || 0;
                    })));

        }

        const card = useDashboardCard({
            update: () => update(info.value, currentTenantOid.value)
        });

        watch(currentTenantOid, () => card.update())
        const tenant = computed(() => getTenant(currentTenantOid.value));

        const color = (d: Date) => `var(--4season-color-${d.getMonth() + 1})`;

        return {
            t,
            date,
            info,
            color,
            tenant,
            selectedCurrency,
            isTenancyVisible,
            formatDate,
            formatCurrency,
            ...meta,
            ...card
        }
    }
});

</script>
