﻿<!--suppress HtmlFormInputWithoutLabel -->
<template>
    <div v-bind="fieldAttrs">
        <slot name="label">
            <aunoa-form-label v-if="label" v-bind="labelAttrs" />
        </slot>
        <input v-bind="controlAttrs" type="number" />
        <aunoa-error-message v-bind="errorMessageAttrs" />
    </div>
</template>

<script lang="ts">

import {fieldProps, useField} from "../../implementations/forms/useField";
import {defineComponent} from "vue";
import AunoaFormLabel from "./AunoaFormLabel.vue";
import AunoaErrorMessage from "./AunoaErrorMessage.vue";

export default defineComponent({
    name: "AunoaNumberBox",
    inheritAttrs: false,
    components: {AunoaFormLabel, AunoaErrorMessage},
    props: {
        ...fieldProps,
        modelValue: {
            type: Number,
        },
        nullable: {
            type: Boolean,
            default: undefined
        }
    },
    setup(props, ctx) {

        const field = useField(props as any, ctx, {inputType: "number"})
        return {
            ...field
        }
    }
});

</script>
