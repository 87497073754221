﻿<template>
    <tr>
        <td v-bind="attrs()" @click="onToggle()" />
        <td
            v-if="tenants"
            v-for="{oid} in tenants" :key="oid"
            v-bind="attrs(oid)"
            @click="onToggle(oid)"
        />
        <th v-html="text" />
    </tr>
</template>

<script lang="ts">

import {computed, defineComponent, PropType, toRefs} from "vue";
import {getEnabling, Permission, Role} from "../../implementations/forms/usePermissions";
import {isUndefinedOrNull} from "../../utils/inspect";
import {markStr, SearchOption} from "../../utils/search";

const regExpSlash = new RegExp(/([^<])(\/)/, "g");
export const slashStr = (str: string) => str.replace(regExpSlash, "$1<span class='slash'>$2</span>");

export default defineComponent({
    name: "CheckboxTr",
    props: {
        permission: {
            type: Object as PropType<Role | Permission>,
            required: true
        },
        searchOption: {
            type: Object as PropType<SearchOption>,
            default: undefined
        },
        tenants: {
            type: Array
        }
    },
    emits: ["toggle"],
    setup(props, {emit}) {

        const {permission, searchOption} = toRefs(props);

        const attrs = (tenantOid?: number) => {
            let enabled = getEnabling(permission.value, tenantOid).e;
            const checked = enabled === true;
            const unchecked = enabled === false;
            if (isUndefinedOrNull(enabled)) {
                const parent = permission.value.$$().activeParent(tenantOid);
                enabled = parent && getEnabling(parent, tenantOid)?.e;
            }
            return {
                class: [
                    checked ? "fa-check" : undefined,
                    unchecked ? "fa-ban" : undefined,
                    enabled ? "enabled" : undefined
                ].filter(Boolean)
            }
        }

        const text = computed(() => slashStr(markStr(permission.value.$$().text, searchOption.value as any)));

        const onToggle = (tenantOid?: number) => emit("toggle", permission.value, tenantOid);

        return {
            attrs,
            text,
            onToggle,
        }
    }

});

</script>
