﻿import {useMateApi} from "./useMateApi";
import {AccessToken} from "./types";

type TenancyGroup = number;
type DateGroup = string;
type CurrencyGroup = string;
type Turnover = Record<TenancyGroup, Record<DateGroup, Record<CurrencyGroup, number>>>;


export const useTurnoverApi = (accessToken: AccessToken) => {

    const {jsonEndpoint} = useMateApi(accessToken);
    const api = jsonEndpoint("/api/turnover");

    const getDailyTurnover = (tenantOid: number, referencedate:Date) =>
        api.get<Turnover>({tenantOid, referencedate}, {url: "/daily"});

    return {
        getDailyTurnover
    }
}