﻿import arSA from "date-fns/locale/ar-SA";
import cs from "date-fns/locale/cs";
import de from "date-fns/locale/de";
import enUS from "date-fns/locale/en-US";
import es from "date-fns/locale/es";
import fr from "date-fns/locale/fr";
import it from "date-fns/locale/it";
import ja from "date-fns/locale/ja";
import ru from "date-fns/locale/ru";

const locales:Record<string, any> = {
    ar: arSA,
    cs,
    de,
    en: enUS,
    es,
    fr,
    it,
    ja,
    ru
}

export default locales;