<template>
    <div class="chip d-inline-flex">
        <div class="d-flex align-items-center">
            <span v-if="icon==='shortcut'" v-text="code" v-bind="iconAttrs" :style="iconStyle"/>
            <i v-else-if="icon" v-bind="iconAttrs" :class="icon" :style="iconStyle" />
            <span v-if="text" v-text="text" class="chip-text" />
            <button type="button" class="btn-close" tabindex="-1" @click="$emit('delete')" />
        </div>
    </div>
</template>

<script lang="ts">

import {computed, defineComponent, ref, watch, nextTick, toRefs, onMounted} from "vue";
import {stringToCode, stringToHslStyles} from "../../utils/chips";


export default defineComponent({
    name: "AunoaChip",
    props: {
        icon: {
            type: String
        },
        iconStyle: {
            default: undefined 
        },
        text: {
            type: String
        }
    },
    emits: ["delete"],
    setup(props) {

        const {text} = toRefs(props);
        
        const code = computed(() => stringToCode(text.value));
        
        const iconAttrs = computed(() => ({
            "class": ["bg-custom chip-icon small"],
            //"style": stringToHslStyles(text.value)
        }));

        return {
            code,
            iconAttrs
        }

    }
});

</script>

