﻿import {isDistanceDateFormat, isNumber, isNumberOptions, isString, isUndefined, isUndefinedOrNull} from "../../utils/inspect";
import {DateFormat, NumberOptions} from "../../types";
import {useLocale} from "../../utils/useLocale";

const createDigits = (digits: number | undefined) => digits ? {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits
} : undefined;

export const formatNumber = (value: any, options?: number | NumberOptions) => {
    if (isUndefinedOrNull(value)) {
        return value;
    }

    if (isString(value)) {
        value = parseFloat(value);
    }

    let digits: any;
    if (isNumber(options)) {
        digits = createDigits(options);
    } else if (isNumberOptions(options)) {
        if (options.suppressZero && value === 0) {
            return undefined;
        }
        digits = createDigits(options.digits)
    }

    const {locale} = useLocale();

    return new Intl.NumberFormat(locale.value || "en", {
            style: "decimal",
            ...digits
        }
    ).format(value);
}

const KILOBYTE = 1024;
const MEGABYTE = 1024 * KILOBYTE;
const GIGABYTE = 1024 * MEGABYTE;

export const toByteSizeSegments = (bytes: number) =>
    bytes >= GIGABYTE
        ? [
            {class: "fmt-amount", text: formatNumber(bytes / GIGABYTE, {digits: 1})},
            {class: "fmt-space", text: " "},
            {class: "fmt-unit", text: "GB"}
        ]
        : bytes >= MEGABYTE
        ? [
            {class: "fmt-amount", text: formatNumber(bytes / MEGABYTE, {digits: 1})},
            {class: "fmt-space", text: " "},
            {class: "fmt-unit", text: "MB"}
        ]
        : bytes >= KILOBYTE
            ? [
                {class: "fmt-amount", text: formatNumber(bytes / KILOBYTE, {digits: 1})},
                {class: "fmt-space", text: " "},
                {class: "fmt-unit", text: "kB"}
            ]
            : [
                {class: "fmt-amount", text: formatNumber(bytes, {digits: 0})},
                {class: "fmt-space", text: " "},
                {class: "fmt-unit", text: "B"}
            ];


export const toByteSizeStr = (bytes: any, defaultStr = "0 B") =>
    bytes >= GIGABYTE
        ? formatNumber(bytes / GIGABYTE, {digits: 1}) + " GB"
        : bytes >= MEGABYTE
        ? formatNumber(bytes / MEGABYTE, {digits: 1}) + " MB"
        : bytes >= KILOBYTE
            ? formatNumber(bytes / KILOBYTE, {digits: 1}) + " kB"
            : bytes > 0 ? bytes + " B" : defaultStr;


export const toDurationSizeStr = (ms: any, defaultStr = "0 ms") =>
    ms >= 1000
        ? formatNumber(ms / 1000.0, {digits:1}) + " s"
        : ms > 0 ? ms + " ms" : defaultStr;    
