﻿<template>
    <component :is="tag" class="card" :class="{'editing':editMode}">
        <div class="card-header editing p-0" v-if="editMode">
            <ul class="nav">
                <li class="nav-item">
                    <button type="button" class="nav-link px-2" @click="onSetup" @mousedown.prevent.stop="onDummy">
                        <i class="fas fa-lg fa-cog" />
                    </button>
                </li>
                <li class="nav-item ms-auto" v-if="!required">
                    <button type="button" class="nav-link px-2" @click="onClose">
                        <i class="fas fa-lg fa-close" />
                    </button>
                </li>
            </ul>
        </div>        
        <slot />
    </component>
</template>

<script lang="ts">

import {defineComponent, computed, toRefs} from "vue";

export default defineComponent({
    name: "AunoaCard",
    props: {
        tag: {
            type: String,
            default: "div"
        },
        required: {
            type: Boolean,
            default: false
        },
        editMode: {
            type: Boolean,
            default: false
        }
    },
    emit: ["close"],
    setup(props, {emit}) {

        const onDummy = (event:Event) => {
            console.log("onDummy");
            event.stopPropagation();
            event.preventDefault()
        }

        const onSetup = (event:Event) => {
            console.log("onSetup");
            event.stopPropagation();
            event.preventDefault()
        }

        const onClose = (event:Event) => {
            event.stopPropagation();
            event.preventDefault()
            emit("close");
        }

        return {
            onDummy,
            onSetup,
            onClose
        }
    }
});

</script>

