﻿<!--suppress ES6PreferShortImport, PointlessBooleanExpressionJS, HtmlFormInputWithoutLabel -->
<template>
    <div ref="container" class="table-scroll-container input-border-radius-sm">
        <table class="table-permissions" :style="style">
            <thead>
            <tr>
                <th class="bg-subheader">
                    <div v-if="tenants">
                        <span>Global</span>
                    </div>
                    <i class="fad fa-globe fa-lg" v-if="tenants" />
                    <i class="fad" v-else />
                </th>
                <th v-if="tenants"
                    class="bg-subheader"
                    v-for="{oid, id, country} in tenants"
                    :key="oid"
                >
                    <div>
                        <span v-text="id" />
                    </div>
                    <i class="flag-icon shadow-sm" :class="['flag-icon-'+country]" />
                </th>
                <th class="bg-subheader">
                    <div v-for="permission in permissionsWithLongestPathes" v-text="slashedText(permission)" />
                    <input
                        class="form-control form-control-sm"
                        :placeholder="searchPlaceholder"
                        type="search"
                        style="min-width: 24ch;max-width: 32ch"
                        v-model="filterText">
                </th>
            </tr>
            </thead>


            <tbody>
            <checkbox-tr
                v-for="permission in permissions"
                :key="permission.p"
                :permission="permission"
                :search-option="searchOption"
                :tenants="tenants"
                @toggle="onToggle"
            />
            </tbody>

        </table>
    </div>
</template>

<script lang="ts">
import {Permission, Role, usePermissions} from "../implementations/forms/usePermissions";
import CheckboxTr, {slashStr} from "./components/CheckboxTr.vue";
import {computed, defineComponent, PropType, ref, toRefs, watch} from "vue";
import {useElementSize} from "../utils/useElementSize";
import {isStrMatching, markStr} from "../utils/search";
import {useSearching} from "../utils/useSearching";

export default defineComponent({
    name: "AunoaCheckboxTree",
    components: {CheckboxTr},
    props: {
        texts: {
            type: Array as PropType<string[]>,
            default: []
        },
        items: {
            type: Array as PropType<(Role | Permission)[]>,
            required: true
        },
        tenants: {
            type: Array
        },
        showOnlyActive: {
            type: Boolean,
            default: false
        },
        searchPlaceholder: {
            type: String,
            default: "Search"
        }
    },
    setup(props) {

        const {texts: _texts, items: _items} = toRefs(props);

        const {permissions, onToggle} = usePermissions(_texts, _items);

        const permissionsWithLongestPathes = computed(() => permissions.value
            .slice(0)
            .sort((a, b) => b.$$().text.length - a.$$().text.length)
            .filter((t, i) => i < 7)
        );

        const filterText = ref("");
        const {searchOption} = useSearching(filterText, 500);

        const filter = (p: Role | Permission) => !searchOption.value || isStrMatching(p.$$().text, searchOption.value);
        const filteredPermissions = computed(() => permissions.value.filter(filter));

        const {element: container, contentRect} = useElementSize();

        const style = computed(() => ({
            "--min-check-count": `${Math.min(1 + (props.tenants?.length || 0), 6)}`,
            "--content-width": `${contentRect.value.width}px`
        }));

        const slashedAndMarkedText = (p: Role | Permission) => slashStr(markStr(p.$$().text, searchOption.value as any));
        const slashedText = (p: Role | Permission) => slashStr(p.$$().text);

        return {
            style,
            container,
            filterText,
            searchOption,
            permissionsWithLongestPathes,
            permissions: filteredPermissions,
            slashedAndMarkedText,
            slashedText,
            onToggle
        }
    }

});

</script>
