﻿import {unref} from "vue";
import type {Jwt, AccessToken, AccessTokenInfo} from "../types";

const toInfo = (jwt: Jwt): AccessTokenInfo => ({
    username: jwt.unique_name,
    validFrom: new Date(jwt.nbf * 1000),
    validTill: new Date(jwt.exp * 1000),
    createdAt: new Date(jwt.iat * 1000)
})

const toJwt = (accessToken: string): Jwt => {
    const base64 = accessToken.split('.')[1].replace(/-/g, '+').replace(/_/g, '/');
    const jsonStr = decodeURIComponent(atob(base64).split('')
        .map(c => `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`).join(''));
    return JSON.parse(jsonStr);
};


export const convertToJwt = (accessToken: AccessToken) => {
    const token = unref(accessToken);
    return token ? toJwt(<string>token) : undefined;
};

export const convertToInfo = (accessToken: AccessToken) => {
    const jwt = convertToJwt(accessToken);
    return jwt ? toInfo(jwt) : undefined;
};

export const isAccessTokenValid = (accessToken: AccessToken, tresholdSeconds = 5) => {
    const token = unref(accessToken);
    if (token === "ANONYMOUS") {
        return true;
    }
    if (!token) {
        return false;
    }
    const jwt = toJwt(<string>token);
    const expired = jwt?.exp - tresholdSeconds;
    const utcNowInSeconds = Math.round(Date.now() * 0.001);
    return jwt && utcNowInSeconds <= expired;
}

