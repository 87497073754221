import {AccessToken, } from "./types";
import {useMateApi} from "./useMateApi";

export interface UnprocessedDocument
{
    oid : number;
    tenant: string;
    location: string;
    terminalOid: number;
    terminal: string;
    createdAt: Date;
    modifiedAt: Date;
    error: string;
    retries: number;
    country: string;
    
    //
    flagIcon: string;
    
}

export interface DocumentLock
{
    oid : number;
    documentId: string,
    documentType: string,
    tenant: string;
    location: string;
    terminalOid: number;
    terminal: string;
    closedAt: Date;
    lockedAt: Date;
    operatorId: string;
    operatorName: string;
    country: string;
    //
    flagIcon: string;
}

export const useDiagnosticsApi = (accessToken: AccessToken) => {

    const {jsonEndpoint} = useMateApi(accessToken);
    const api = jsonEndpoint("/api/diagnostics");

    const getUnprocessedDocumentsOverview = (params?:any) => api
        .get<UnprocessedDocument[]>(params, {url: "/unprocessed-documents"})
            .then(list=>list.map(e=>{
                e.flagIcon = "flag-icon shadow-sm flag-icon-"+e.country?.toLowerCase();
                return e;
            }))
        ;

    const unprocessedDocumentsCrudService = () => {
        const key = (entity: UnprocessedDocument) => `${entity.oid}`;
        const read = (key:string) => getUnprocessedDocumentsOverview({entityOid: key}).then(list => list?.[0]);
        const readList = () => getUnprocessedDocumentsOverview();
        return {
            key, 
            read,
            readList
        }
    }

    const getDocumentLocksOverview = (params?:any) => api
        .get<DocumentLock[]>(params, {url: "/document-locks"})
        .then(list=>list.map(e=>{
            e.flagIcon = "flag-icon shadow-sm flag-icon-"+e.country?.toLowerCase();
            return e;
        }))
    ;

    const documentLocksCrudService = () => {
        const key = (entity: DocumentLock) => `${entity.oid}`;
        const read = (key:string) => getDocumentLocksOverview({entityOid: key}).then(list => list?.[0]);
        const readList = () => getDocumentLocksOverview();
        return {
            key,
            read,
            readList
        }
    }

    return {
        getUnprocessedDocumentsOverview,
        unprocessedDocumentsCrudService,
        getDocumentLocksOverview,
        documentLocksCrudService
    }
}