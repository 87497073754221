﻿import {computed, Ref, ref} from "vue";
import {isDefined, isUndefinedOrNull} from "./inspect";

const AUTO = "auto";
const SMOOTH = "smooth";
const ITEM_QUERY = ".dropdown-item";

export const useDropdownScrolling = (menuElement: Ref<HTMLDivElement | undefined>) => {

    const querySelector = <E extends Element = HTMLElement>(selectors: string): E | undefined =>
        menuElement.value?.querySelector<E>(selectors) || undefined;

    const querySelectorAll = <E extends Element = HTMLElement>(selectors: string): NodeListOf<E> | undefined =>
        menuElement.value?.querySelectorAll<E>(selectors);

    const getItems = () => Array.from(querySelectorAll(ITEM_QUERY) || []);

    const containerElement = computed(()=>querySelector(".dropdown-items"));

    const to = (item: HTMLElement | undefined) => {
        const container = containerElement.value;
        if (container && item) {
            item.focus({preventScroll: true});
            container.scrollTo({
                behavior: AUTO,
                top: item.offsetTop - (container.clientHeight - item.clientHeight) / 2
            });
        }
    };

    const toFirst = () => {
        const item = querySelector(`${ITEM_QUERY}:first-of-type`);
        item && item.focus();
        return !!item;
    }

    const toLast = () => {
        const item = querySelector(`${ITEM_QUERY}:last-of-type`);
        item && item.focus();
        return !!item;
    }

    const toValue = (value: any) => {
        if (isDefined(value)) {
            const item = querySelector(`${ITEM_QUERY}[data-value='${value}']`);
            if (item) {
                to(item);
                return true;
            }
        }
        toFirst();
        return false;
    };

    const toStartChar = (char: string) => {
        if (char) {
            const all = querySelectorAll(`${ITEM_QUERY}[data-start='${char}']`);
            if (all?.length) {
                const items = Array.from(all);
                const currentItem = items.filter(i => i === document.activeElement)[0];
                const currentIndex = items.indexOf(currentItem);
                to(currentIndex < items.length - 1
                    ? items[currentIndex + 1]
                    : items[0]);
                return true;
            }
        }
        return false;
    };

    const pageUp = () => {
        const container = containerElement.value;
        if (container) {
            const currentItem = document.activeElement as HTMLElement;
            const item = getItems()
                .filter(item => item.offsetTop > currentItem.offsetTop + currentItem.clientHeight - container.clientHeight)[0];
            if (item) {
                item.focus({preventScroll: true});
                container.scrollTo({
                    behavior: SMOOTH,
                    top: item.offsetTop
                });
            }
        }
    }

    const pageDown = () => {
        const container = containerElement.value;
        if (container) {
            const currentItem = document.activeElement as HTMLElement;
            const item = getItems()
                .filter(item => item.offsetTop + currentItem.clientHeight < currentItem.offsetTop + container.clientHeight)
                .reverse()[0];
            if (item) {
                item.focus({preventScroll: true});
                container.scrollTo({
                    behavior: SMOOTH,
                    top: item.offsetTop + item.clientHeight - container.clientHeight
                });
            }
        }
    }

    const isFirst = (item: HTMLElement) => {
        const menu = menuElement.value;
        const items = Array.from<any>(menu?.querySelectorAll(`${ITEM_QUERY}:not(.disabled):not(:disabled)`) || []);
        return items.indexOf(item) === 0;
    }

    return {
        to,
        toFirst,
        toLast,
        pageUp,
        pageDown,
        toValue,
        toStartChar,
        isFirst
    }
}

