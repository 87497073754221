import {Directive} from "vue";
import {isBoolean} from "../utils/inspect";

const ensure = (value: any) => isBoolean(value) ? value : true;

export default <Directive>{
    mounted(el, binding) {
        //console.log("mounted", binding);
        if (ensure(binding.value)) {
            setTimeout(() =>el.focus());
        }
    },
    updated(el, binding) {
        //console.log("updated", binding);
        if (ensure(binding.value) && !ensure(binding.oldValue)) {
            setTimeout(() =>el.focus());
        }
    }
}