﻿<!--suppress JSUnresolvedVariable -->
<template>
    <div class="text-center">
        <h1 class="text-danger" style="font-size: 9rem">403</h1>
        <h1 v-text="t('Aunoa.Routing.PageForbidden403')" />
        <h5 v-text="$route.query['requested']" />
        <span v-text="t('Aunoa.Routing.PageForbidden403Description')" style="max-width: 40ch; display: inline-block" />
    </div>
</template>

<script lang="ts">

import {defineComponent} from "vue";
import {useAunoaI18n} from "../utils/useAunoaI18n";

export default defineComponent({
    name: "AunoaForbidden403",
    setup() {
        const {t} = useAunoaI18n();
        return {
            t
        }
    }
});

</script>

