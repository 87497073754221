﻿import {Directive} from "vue";
import {getStickyTop} from "../tools/layout";
import {Disposable, ensureDisposed} from "../utils/disposable";

const PROP = "__aunoa_sticky_directive__";
const SCROLL = "scroll";

interface StickyHTMLElement extends HTMLElement {
    [PROP]: Disposable
}

function create(el: StickyHTMLElement, isStickyClassName: string) {

    const toggle = (force: boolean) => el.classList.toggle(isStickyClassName || "is-sticky", force);

    function isSticky() {
        const rect = el.getBoundingClientRect();
        const top = getStickyTop();
        return rect.y === top;
    }

    const handleScroll = (ev: Event) => toggle(isSticky());

    function dispose() {
        window.removeEventListener(SCROLL, handleScroll);
        toggle(false);
    }

    el.classList.toggle("sticky-top", true);
    window.addEventListener(SCROLL, handleScroll);
    toggle(isSticky())

    return dispose;
}

export default <Directive<StickyHTMLElement, string>>{
    beforeMount(el, binding) {
        el[PROP] = create(el, binding.value);
    },
    updated(el, binding) {
        ensureDisposed(el[PROP]);
        el[PROP] = create(el, binding.value);
    },
    unmounted(el) {
        ensureDisposed(el[PROP]);
        delete (<any>el)[PROP];
    }
}