import {isNull, isString, regexAC, regexCA} from "./inspect";

type Format = "ca" | "ac";

const decimalPlaceExceptions: Record<string, number> = {
    "BHD": 3,
    "BYR": 0,
    "CLP": 0,
    "COP": 0,
    "IDR": 0,
    "IRR": 0,
    "ISK": 0,
    "JPY": 0,
    "KHR": 0,
    "KPW": 0,
    "KRW": 0,
    "LAK": 0,
    "LBP": 0,
    "OMR": 3,
    "PYG": 0,
    "VND": 0,
    "XAF": 0,
    "YER": 0
};

export interface CurrencyAndAmount {
    currency: string;
    amount: number;
    format: Format;
}

const create = (match: RegExpMatchArray, format: Format): CurrencyAndAmount => {
    const amountStr = match[format === "ac" ? 1 : 2];
    const currency = match[format === "ac" ? 2 : 1];
    //const digits = getDecimalPlaces(currency);
    return {
        format,
        currency,
        amount: parseFloat(amountStr)
    }
}

export const asCurrencyAndAmount = (value: any): CurrencyAndAmount | undefined | null => {
    if (isNull(value)) {
        return null;
    }
    if (isString(value)) {
        let match = regexAC.exec(value)
        if (match) {
            return create(match, "ac");
        }
        match = regexCA.exec(value)
        if (match) {
            return create(match, "ca");
        }
        return {
            format: "ca",
            currency: value,
            amount: <any>null
        }
    }
    return undefined;
};

export const getDecimalPlaces = (currency: string) =>
    decimalPlaceExceptions[currency] >= 0 ? decimalPlaceExceptions[currency] : 2;

export const toAmountString = (currency: string, amount: number, locale?: string) => {
    const digits = getDecimalPlaces(currency);
    return new Intl.NumberFormat(locale || "en", {
        style: "decimal",
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
        useGrouping: false
    }).format(amount);
}

export const toCurrenyAmountValue = (currency: string, amount: number | null, nullable = false) =>
    nullable && (amount === null || amount === undefined)
        ? null
        : parseFloat(toAmountString(currency, amount || 0));

export const toCurrenyAmountString = (currency: string, amount: number | null, format?: Format, nullable = false) => {
    if (nullable && (amount === null || amount === undefined)) {
        return currency;
    }
    const value = toAmountString(currency, amount || 0);
    return format === "ac"
        ? `${value} ${currency}`
        : `${currency} ${value}`;
}    
    
