﻿import {useI18nController} from "./useLocale";
import {onBeforeUnmount} from "vue";
import {singleton} from "./singletons";

export const useAunoaI18nController = () => singleton("$$AUNOA-I18N-CONTROLLER", () => useI18nController({
    loadMessage: locale => {
        console.log("loading", `../../locales/au/${locale}.json`);
        return import(`../../locales/au/${locale}.json`);
        //return axios.get(`/loc/au/${locale}.json`).then(result => result.data);
    }
}));

export const useAunoaI18n = () => {

    const {useI18n, registerI18n, ensureTranslated} = useAunoaI18nController();

    const i18n = useI18n("local");
    const unregister = registerI18n(i18n);
    onBeforeUnmount(unregister);

    const ensureTextTranslated = (text: string) =>
        text && text.startsWith && text.startsWith("@@Aunoa.")
            ? i18n.t(text.substr(2))
            : text ? ensureTranslated(text, i18n) : "";

    return {
        ...i18n,
        ensureTextTranslated,
    }
}