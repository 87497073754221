﻿import {CrudService, CrudException} from "bootstrap-aunoa";
import {computed, ref, Ref, unref} from "vue";

const missingCrudService = (resolve: any, reject: any) => reject(<CrudException>{
    message: "CRUD-Service does not exist."
});
const notImplemented = (resolve: any, reject: any, func: string) => reject(<CrudException>{
    message: `Not implemented '${func}' in CRUD-Service`
});

const notImplementedEmpty = (resolve: any, reject: any) => notImplemented(resolve, reject, "empty");
const notImplementedCreate = (resolve: any, reject: any) => notImplemented(resolve, reject, "create");
const notImplementedRead = (resolve: any, reject: any) => notImplemented(resolve, reject, "read");
const notImplementedReadList = (resolve: any, reject: any) => notImplemented(resolve, reject, "readList");
const notImplementedUpdate = (resolve: any, reject: any) => notImplemented(resolve, reject, "update");
const notImplementedDelete = (resolve: any, reject: any) => notImplemented(resolve, reject, "delete");
const notImplementedReadDetails = (resolve: any, reject: any) => notImplemented(resolve, reject, "readDetails");

export const useCrudService = <TEntity = any, TKey = any, TQuery = any>(
    crudService: CrudService<TEntity, TKey, TQuery> | Ref<CrudService<TEntity, TKey, TQuery>>) => {

    const _empty = (service: CrudService<TEntity, TKey, TQuery>, query: TQuery): Promise<TEntity> =>
        service ?
            service.empty
                ? service.empty(query)
                : new Promise<TEntity>(notImplementedEmpty)
            : new Promise<TEntity>(missingCrudService);
    
    const _create = (service: CrudService<TEntity, TKey, TQuery>, entity: TEntity): Promise<TEntity> =>
        service ?
            service.create
                ? service.create(entity)
                : new Promise<TEntity>(notImplementedCreate)
            : new Promise<TEntity>(missingCrudService);

    const _read = (service: CrudService<TEntity, TKey, TQuery>, key: TKey, etag?: string, entity?: TEntity): Promise<TEntity> =>
        service ?
            service.read
                ? service.read(key, etag, entity)
                : new Promise<TEntity>(notImplementedRead)
            : new Promise<TEntity>(missingCrudService);

    const _readList = (service: CrudService<TEntity, TKey, TQuery>, query: TQuery, skip: number, take: number): Promise<TEntity[]> =>
        service ?
            service.readList
                ? service.readList(query, {skip, take})
                : new Promise<TEntity[]>(notImplementedReadList)
            : new Promise<TEntity[]>(missingCrudService);

    const _update = (service: CrudService<TEntity, TKey, TQuery>, key: TKey, entity: TEntity, etag?: string): Promise<TEntity> =>
        service ?
            service.update
                ? service.update(key, entity, etag)
                : new Promise<TEntity>(notImplementedUpdate)
            : new Promise<TEntity>(missingCrudService);

    const _delete = (service: CrudService<TEntity, TKey, TQuery>, key: TKey, etag?: string): Promise<void> =>
        service ?
            service.delete
                ? service.delete(key, etag)
                : new Promise(notImplementedDelete)
            : new Promise(missingCrudService);

    const _readDetails = (service: CrudService<TEntity, TKey, TQuery>, key: TKey, entity: TEntity, etag?: string): Promise<TEntity> =>
        service ?
            service.readDetails
                ? service.readDetails(key, entity, etag)
                : new Promise(notImplementedReadDetails)
            : new Promise(missingCrudService);

    const getKeySupported = computed<boolean>(() => !!unref(crudService)?.key);
    const getETagSupported = computed<boolean>(() => !!unref(crudService)?.etag);
    const getDisabledSupported = computed<boolean>(() => !!unref(crudService)?.disabled);
    const createSupported = computed<boolean>(() => !!unref(crudService)?.create);
    const readSupported = computed<boolean>(() => !!unref(crudService)?.read && getKeySupported.value);
    const readListSupported = computed<boolean>(() => !!unref(crudService)?.readList);
    const updateSupported = computed<boolean>(() => !!unref(crudService)?.update && getKeySupported.value);
    const deleteSupported = computed<boolean>(() => !!unref(crudService)?.delete && getKeySupported.value);
    const emptySupported = computed<boolean>(() => !!unref(crudService)?.empty);
    const readDetailsSupported = computed<boolean>(() => !!unref(crudService)?.readDetails && getKeySupported.value);

    const getKey = (entity: TEntity) => unref(crudService)?.key?.(entity);
    const getETag = (entity: TEntity) => unref(crudService)?.etag?.(entity);
    const isDisabled = (entity: TEntity) => unref(crudService)?.disabled?.(entity);

    const empty = (query: TQuery) => _empty(unref(crudService), query);
    const create = (entity: TEntity) => _create(unref(crudService), entity);
    const read = (key: TKey, etag?: string, entity?:TEntity) => _read(unref(crudService), key, etag, entity);
    const readList = (query: TQuery, skip: number, take: number) => _readList(unref(crudService), query, skip, take);
    const update = (key: TKey, entity: TEntity, etag?: string) => _update(unref(crudService), key, entity, etag);
    const __delete = (key: TKey, etag?: string) => _delete(unref(crudService), key, etag);
    const readDetails = (key: TKey, entity: TEntity, etag?: string) => _readDetails(unref(crudService), key, entity, etag);

    // ******************************

    return {
        getKeySupported,
        getETagSupported,
        getDisabledSupported,
        createSupported,
        readSupported,
        readListSupported,
        updateSupported,
        deleteSupported,
        emptySupported,
        readDetailsSupported,

        getKey,
        getETag,
        isDisabled,

        empty,
        create,
        read,
        readList,
        update,
        delete: __delete,
        readDetails
    }

};