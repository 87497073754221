﻿<template>
    <aunoa-card v-bind="cardAttrs">
        <aunoa-card-header :icon="icon" :text="title(t)" />
        <div class="card-header bg-transparent text-end small text-muted" v-if="isTenancyVisible && false">
            <span class="pe-2" v-text="tenant?.id" />
            <i class="flag-icon shadow-sm" :class="tenant?.countryFlag()" />
        </div>

        <div class="card-body pb-3">
            <table class="charts-css bar show-labels data-spacing-1 labels-align-end reverse-data"
                   style="--labels-size: 80px">
                <tbody>
                <tr v-for="{date,count} in info.items">
                    <td :style="{'--color':color(date), '--size':info.max?count/info.max:0}">
                        <span class="data" v-if="count && false">{{ count || '' }}</span>
                        <span v-else>&nbsp;</span>
                    </td>
                    <th scope="row" class="fw-normal small">
                        <span v-text="formatDate(date, 'MMM yyyy')" class="text-muted pe-1" />
                    </th>
                    <th scope="row" class="fw-normal small w-100 text-end pe-1" style="inset-inline-start:0">
                        <span>{{ count || '' }}</span>
                    </th>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="card-footer d-flex justify-content-between align-items-end">
            <div>
                <span class="small text-muted" v-if="isTenancyVisible">
                    <i class="flag-icon shadow-sm" :class="tenant?.countryFlag()" />
                    <span class="ps-2" v-text="tenant?.id" />
                    <br>
                </span>

                <span v-text="formatDate(current.date,'MMM yyyy')" />
            </div>
            <div class="text-end" v-if="info.avg">
                <span class="text-muted small" v-text="t('Mate.Average')" />
                <br>
                <span class="text-primary" v-text="deltaPercentage()" />
                <span class="text-muted small">%</span>
            </div>
        </div>
    </aunoa-card>
</template>

<script lang="ts">

import {useCachedTenancyDataFromProps, useDocumentToolsApi} from "@tnt/mate-api";

import {formatDate, useAunoaI18n, usePackeryItem, formatNumber, AunoaCard, AunoaCardHeader} from "bootstrap-aunoa";
import {defineComponent, computed, watch, ref, toRefs, inject, onMounted, nextTick} from "vue";

import {useDashboardCard} from "../useDashboard";
import {useAuth} from "../../../utils/useAuth";
import {useRouteScope} from "../../../scope/useRouteScope";

interface DocumentCount {
    date: Date;
    count: number;
}

interface Info {
    avg: number;
    max: number;
    items: DocumentCount[];
}

const createInfo = (): Info => ({
    avg: 0,
    max: 0,
    items: []
});

const createLastNMonth = (n: number) => {
    const now = new Date();
    //const now = new Date(2019, 7, 1);
    const year = now.getFullYear();
    const month = now.getMonth();
    return Array.from(Array(n)).map((_, i) => Date.UTC(year, month - i, 1))
}

const meta = {
    createdAt: "2021-04-22T00:00:00.000Z",
    icon: "fad fa-file-alt",
    title: (t: any) => t("Mate.MonthlyDocumentThroughput"),
    multiInstance: true
}

export default defineComponent({
    $$meta: meta,
    name: "MonthlyDocumentThroughputCard",
    components: {AunoaCard, AunoaCardHeader},
    props: {
        tenantOid: {
            type: Number,
            default: undefined
        },
        months: {
            type: Number,
            default: 12
        }
    },

    setup(props) {

        const {t} = useAunoaI18n();
        const {fit} = usePackeryItem();

        const {accessToken} = useAuth();
        const {getTenant} = useRouteScope();
        const {tenantOid, months} = toRefs(props);
        const {getDocumentsThroughput} = useDocumentToolsApi(accessToken);
        const {
            data: info,
            currentTenantOid,
            isTenancyVisible
        } = useCachedTenancyDataFromProps<Info>(tenantOid, "MonthlyDocumentThroughput", createInfo);

        const update = (info: Info, tenantOid: number) => {
            const documentCounts = createLastNMonth(months.value).map(date => (<DocumentCount>{
                date: new Date(date),
                count: 0
            }));
            info.items = documentCounts;
            nextTick(fit);
            return Promise.all(documentCounts
                .map(dc =>
                    getDocumentsThroughput(tenantOid, dc.date)
                        .then(r => {
                            dc.count = r[tenantOid] || 0;
                            const countArray = documentCounts.map(i => i.count);
                            const sum = countArray.reduce((a, b) => a + b, 0);
                            info.avg = countArray.length ? (sum / countArray.length) : 0;
                            info.max = Math.max(...countArray);
                        })));
        }

        const card = useDashboardCard({
            update: () => update(info.value, currentTenantOid.value)
        });

        watch(currentTenantOid, () => card.update())
        const tenant = computed(() => getTenant(currentTenantOid.value));
        const current = computed(() => info.value.items[0]);

        const color = (d: Date) => `var(--4season-color-${d.getMonth() + 1})`;

        const deltaPercentage = () => {
            const p = ((current.value.count / info.value.avg) - 1) * 100;
            return `${p < 0 ? "" : "+"}${formatNumber(p, 1)}`;
        }

        return {
            t,
            color,
            current,

            info,
            tenant,
            isTenancyVisible,
            deltaPercentage,
            formatDate,
            getTenant,
            ...meta,
            ...card
        }
    }
});

</script>
