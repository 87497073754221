
interface Singletons {
    [key: string]: any;
}

const singletons:Singletons = {};

export const singleton = <T>(key: string, create: () => T): T => {
    singletons[key] = singletons[key] || create();
    return singletons[key] as T;
};

export const singletonExists = (key: string): boolean => singletons[key];

