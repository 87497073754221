﻿import {isArray} from "../../utils/inspect";

export const decorate = (str: string, d: any) => {
    switch (d) {
        case "Quotes":
            return `"${str}"`;
        case "Brackets":
            return `(${str})`;
        case "SquareBrackets":
            return `[${str}]`;
        case "AngleBrackets":
            return `<${str}>`;
        case "CurlyBrackets":
            return `{${str}}`;
        case "Goosfoot":
            return `»${str}«`;
        case "Gravatar":
            return `XX`;
        default:
            return isArray(d) && d.length === 2
                ? d[0] + str + d[1]
                : str
    }
}
