﻿import {computed, Ref, ref, watch} from "vue";
import {isPromise} from "../../utils/inspect";

export const useFormSubmission = <T = any>(entity: Ref<T>) => {

    const busy = ref(false);
    const failedCount = ref(0);
    const successCount = ref(0);
    const lastError = ref<object | boolean>(false);

    const isSubmitted = computed(() => successCount.value > 0);
    
    watch(entity, e => {
        busy.value = false;
        failedCount.value = 0;
        successCount.value = 0;
        lastError.value = false;
    });

    const submit = (onSubmit: (e: T) => Promise<void>): Promise<void> =>
        new Promise<void>((resolve, reject) => {
            busy.value = true;
            const sumbmitResult = onSubmit(entity.value);
            if (isPromise(sumbmitResult)) {
                sumbmitResult
                    .then(() => {
                        lastError.value = false;
                        successCount.value++;
                        busy.value = false;
                        resolve();
                    })
                    .catch(error => {
                        lastError.value = error;
                        failedCount.value++;
                        busy.value = false;
                        reject(error)
                    });
            } else {
                busy.value = false;
                resolve();
            }
        });


    return {
        busy,
        failedCount,
        successCount,
        lastError,
        isSubmitted,
        submit,
    }

}