﻿<template>
    <svg x="0px" y="0px" viewBox="0 0 48 48">
        <rect x="0" y="0" width="48" height="48" /> 
        <polygon points="30.06,21.86 40.75,32.55 19.37,32.55" /> 
        <polygon points="23.59,15.45 27.86,19.72 15.09,32.55 7.25,32.55" />
    </svg>
</template>

<script lang="ts">

import {defineComponent} from "vue";

export default defineComponent({
    name: "MateIcon"
});

</script>
