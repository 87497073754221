﻿<template>
    <label class="d-flex justify-content-between">
        <span>
            <span v-html="ensureTextTranslated(label)" />
            <span class="supplement show-focus-within" v-text="t('Aunoa.Form.Readonly')" v-if="readonly" />
            <span class="supplement" v-text="t('Aunoa.Form.Required')" v-if="required" />
        </span>
        <span>
            <slot />
        </span>
    </label>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {useAunoaI18n} from "../../utils/useAunoaI18n";

export default defineComponent({
    name: "AunoaFormLabel",
    props: {
        label: {
            type: [String, Boolean],
        },
        required: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        //invalid: {
        //    type: Boolean,
        //    default: false
        //}
    },
    setup(props, ctx) {

        const {t, ensureTextTranslated} = useAunoaI18n();
        return {
            t,
            ensureTextTranslated
        }

    }
});

</script>
