<template>
    <div class="container-fluid">
        <router-view />
    </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, onBeforeUnmount} from "vue";


export default defineComponent({
    name: "EmbeddingLayout",
    components: {
    },

    setup() {

        const embed = (value: boolean) => document.body.classList.toggle("embedded", value);

        onMounted(() => embed(true));
        onBeforeUnmount(() => embed(false));

        return {
        }

    }

});
</script>

<style lang="scss">

</style>


