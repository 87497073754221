﻿import {isString, isCurrencyAmountOptions, isUndefined, isNumber, isDefined, regexAC, regexCA} from "../../utils/inspect";
import {ensureResolvedPropertyPath} from "../../utils/properties";
import {CurrencyAmountOptions} from "../../types";
import {useLocale} from "../../utils/useLocale";

export const formatCurrency = (amount: number, currency: string, currencyDisplay = "code") => {
    const {locale} = useLocale();
    return currency
        ? new Intl.NumberFormat(locale.value || "en", {
            style: "currency",
            currency: currency,
            currencyDisplay
        }).format(amount)
        : undefined;
}

// wrong handlinmg minus sign
// export const getCurrencyFormatSegmentsOld = (amount: number, currency: string) => {
//     const value = formatCurrency(amount, currency);
// 
//     let match = value && regexAC.exec(value)
//     if (match) {
//         return [{class: "fmt-amount", text: match[1]}, {class: "fmt-space", text: " "}, {class: "fmt-unit", text: match[2]}]
//     } else {
//         match = value && regexCA.exec(value);
//         return match
//             ? [{class: "fmt-unit", text: match[1]}, {class: "fmt-space", text: " "}, {class: "fmt-amount", text: match[2]}]
//             : []
//     }
// }

const mappings: Record<string, string> = {
    "currency": "fmt-unit",
    "minusSign": "fmt-minus-sign"
}

export const getCurrencyFormatSegments = (amount: number, currency: string) => {
    if (!currency) {
        return [];
    }
    const {locale} = useLocale();
    const options: Intl.NumberFormatOptions = {
        style: "currency",
        currency: currency,
        currencyDisplay: "code"
    };
    return new Intl.NumberFormat(locale.value || "en", options)
        .formatToParts(amount)
        .map(p => ({class: mappings[p.type] || `fmt-${p.type}`, text: p.value}));

}

export const toCurrencyAmount = (value: any, options: CurrencyAmountOptions, obj: any) => {
    let currency: string | undefined = undefined;
    let amount: number | undefined = undefined;


    if (isNumber(value)) {
        amount = value;
    } else if (isString(value)) {
        let match = regexAC.exec(value)
        if (match) {
            amount = parseFloat(match[1]);
            currency = match[2];
        } else {
            match = regexCA.exec(value);
            if (match) {
                amount = parseFloat(match[2]);
                currency = match[1];
            } else {
                amount = parseFloat(value)
            }
        }
    } else {
        return value;
    }

    if (isUndefined(currency)) {
        if (isString(options)) {
            currency = options;
        } else if (isCurrencyAmountOptions(options)) {
            currency = options.currency;
        }
        if (currency && obj) {
            currency = ensureResolvedPropertyPath<string>(currency, obj);
        }
    }

    if (isUndefined(currency)) {
        return undefined;
    }

    const suppressZero = isCurrencyAmountOptions(options) && options.suppressZero;

    if (amount === 0 && suppressZero) {
        //return undefined;
        return {
            amount: undefined,
            currency: undefined
        };
    }

    return {
        amount,
        currency
    };
};

export const toCurrencyAmountStr = (value: any, options: CurrencyAmountOptions, obj: any) => {
    const ca = toCurrencyAmount(value, options, obj);
    return isDefined(ca) && isDefined(ca.amount) && isDefined(ca.currency)
        ? formatCurrency(ca.amount, <string>ca.currency)
        : ca;
};
