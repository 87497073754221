﻿import {computed, inject, InjectionKey, onBeforeUnmount, onMounted, provide, Ref, ref, unref, watch, WatchStopHandle} from "vue";

const INJECTION_KEY: InjectionKey<{
    add(fieldValidation:any):void;
    remove(fieldValidation:any):void;
}> = Symbol();


export const provideFieldGroupInjection = () => {

    const fieldValidations = ref<any[]>([]);
    const fieldErrorCount = computed(()=>fieldValidations.value.filter(v=>v.$error).length);

    provide(INJECTION_KEY, {
        add: (fieldValidation) => {
            fieldValidations.value.push(fieldValidation);
        },
        remove: (fieldValidation) => {
            const index = fieldValidations.value.indexOf(fieldValidation);
            if (index >= 0) {
                fieldValidations.value.splice(index, 0);
            }
        }
    });

    return {
        fieldValidations,
        fieldErrorCount
    }

}


export const useFieldGroupInjection = (myFieldValidation: Ref) => {
   
    onMounted(() => {

        const fieldGroup = inject(INJECTION_KEY, {
            add: (fieldValidation) => {},
            remove: (fieldValidation) => {}
        });

        const stopWatch = watch(myFieldValidation, (fieldValidation, oldFieldValidation) => {
            if (oldFieldValidation) {
                fieldGroup.remove(oldFieldValidation);
            }
            if (fieldValidation) {
                fieldGroup.add(fieldValidation);
            }
        }, {immediate: true});

        onBeforeUnmount(() => {
            stopWatch();
            if (myFieldValidation.value) {
                fieldGroup.remove(myFieldValidation.value);
            }
        });
        
    });
    

}

