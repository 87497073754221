﻿import type {
    ChangePasswordRequest,
    DashboardStorage,
    LoginRequest,
    ResetPasswordRequest,
    UserProfile,
    ValidateEmailRequest
} from "@tnt/mate-api";
import {
    PrepareResetPasswordRequest,
    provideScopeSources,
    RegisterRequest,
    setupPrimaryTenancy,
    useAuthApi,
    useNavigationApi,
    useUserApi
} from "@tnt/mate-api";

import {useAccessTokenStorage} from "./useAccessTokenStorage";
import {AuthProvider, useAunoaOptions, useLocalStorage} from "bootstrap-aunoa";
const {options: aunoaOptions} = useAunoaOptions();
import {useRouting} from "./useRouting";
import {LOGIN_ROUTE} from "../consts";
import {reactive, ref} from "vue";
import router from "../router";

interface AuthLoginRequest extends LoginRequest {
    remember?: boolean;
}

export type {
    LoginRequest
}

const user = ref<UserProfile>();
const dashboard = ref<DashboardStorage>();
const {navigation, setLinks} = useRouting();

const update = async (accessToken: string) => {

    const {updateScopeSources} = provideScopeSources();

    const {getUserProfile, useDashboardStore} = useUserApi(accessToken);
    const {getLinks, getPermissions, getProgress} = useNavigationApi(accessToken)
    const [userProfile, dashboardStorage, links, permissions, progress,]
        : [UserProfile, DashboardStorage, any, any, any, any] = await Promise.all([
        getUserProfile(),
        useDashboardStore().get(),
        getLinks(),
        getPermissions(),
        getProgress(),
        updateScopeSources(accessToken)
    ]);

    const storagePrimaryTenantOid = useLocalStorage(userProfile).items("tenancy").subscribeInt("primary");
    setupPrimaryTenancy(<any>storagePrimaryTenantOid);

    user.value = userProfile;
    dashboard.value = dashboardStorage;
    setLinks(links, permissions, progress);
};

export const initializeAuth = async () => {
    router.beforeEach((to, from, next) => {
        //console.log("useAuth from", from.path, "to", to.path, to);

        if (to.meta["allowAnonymous"] !== true) {
            if (!user?.value) {
                next({name: LOGIN_ROUTE, query: {redirect: to.fullPath}})
                return;
            }
        } else {
            if (to.name === LOGIN_ROUTE && to.params.action === "logout") {
                logout().then();
            }
        }

        next();
    });

    const {accessToken, clearAccessToken, authProvider} = useAccessTokenStorage();
    if (accessToken.value) {
        try {
            await update(<string>accessToken.value);       
            aunoaOptions.auth.authProvider = authProvider.value === "AAD" ? AuthProvider.AzureActiveDirectory : AuthProvider.Integrated;
        } catch (error) {
            clearAccessToken();
        }
    }
}

export const createLoginRequest = () => {
    const {remember} = useAccessTokenStorage();
    return reactive<AuthLoginRequest>({
        username: "",
        password: "",
        remember: remember.value === true
    });
};

export const login = async (request: LoginRequest) => {
    const {login} = useAuthApi();
    const {token} = await login(request);
    if (token) {
        await update(token);
        const {storeAccessToken} = useAccessTokenStorage();
        storeAccessToken(token, undefined, (<AuthLoginRequest>request)?.remember);
    }
};

export const createRegistrationRequest = () => {
    return reactive(<RegisterRequest>{
        username: undefined,
        password: undefined,
        firstname: undefined,
        lastname: undefined,
        email: undefined
    });
};

export const register = (request: RegisterRequest) => {
    const {register} = useAuthApi();
    return register(request);
};


export const getAuthSettings = () => {
    const {getAuthSettings} = useAuthApi();
    return getAuthSettings();
};

export const loginWithAadToken = async (aadAuthResult: any) => {
    const {loginWithAadToken} = useAuthApi();
    //await new Promise(resolve => setTimeout(resolve, 10000));
    const {token} = await loginWithAadToken(aadAuthResult);
    if (token) {
        await update(token);
        const {storeAccessToken} = useAccessTokenStorage();
        storeAccessToken(token, "AAD", false);
    }
};

export const createEmailValidationRequest = () => {
    return reactive(<ValidateEmailRequest>{
        userId: undefined,
        token: undefined
    });
};

export const validateEmail = async (request: ValidateEmailRequest) => {
    const {validateEmail} = useAuthApi();
    const {token} = await validateEmail(request);
    if (token) {
        await update(token);
        const {storeAccessToken} = useAccessTokenStorage();
        storeAccessToken(token, undefined,false);
    }
};

export const createChangePasswordRequest = () => {
    return reactive(<ChangePasswordRequest>{
        password: undefined,
        newPassword: undefined
    });
};

export const changePassword = async (request: ChangePasswordRequest) => {
    const {accessToken} = useAccessTokenStorage();
    if (accessToken.value) {
        const {changePassword} = useUserApi(<string>accessToken.value);
        await changePassword(request);
    }

};

export const createResetPasswordRequest = () => {
    return reactive(<ResetPasswordRequest>{
        userId: undefined,
        token: undefined,
        newPassword: undefined
    });
};

export const createPrepareResetPasswordRequest = () => {
    return reactive(<PrepareResetPasswordRequest>{
        email: undefined
    });
};

export const requestResetPassword = (request: PrepareResetPasswordRequest) => {
    const {resetPassword} = useAuthApi();
    return resetPassword(request);
};

export const resetPassword = async (request: ChangePasswordRequest) => {
    const {resetPasswordSubmit} = useAuthApi();
    const {token} = await resetPasswordSubmit(request);
    if (token) {
        await update(token);
        const {storeAccessToken} = useAccessTokenStorage();
        storeAccessToken(token, undefined,false);
    }
};

export const logout = () => new Promise<void>(resolve => {
    navigation.value.rootItems = [];
    navigation.value.pathDict = {};
    user.value = undefined;
    dashboard.value = undefined;
    const {clearAccessToken} = useAccessTokenStorage();
    clearAccessToken();
    resolve();
});

export const useAuth = () => {
    const {accessToken} = useAccessTokenStorage();
    return {
        user,
        dashboard,
        navigation,
        accessToken
    }
};