﻿<template>
    <div ref="menuElement" v-bind="menuAttrs">
        <div class="dropdown-items">
            <button
                v-for="option in getOptions()"
                v-bind="getOptionAttrs(option)"
                :key="option.value"
                @click="onSelect($event, option.value)"
            >
                <span
                    v-if="multiSelect"
                    class="fal fa-lg text-muted pe-3"
                    :class="isSelected(option)?'fa-square-check':'fa-square'"
                />
                <i v-if="optionsHaveFlagIcons" class="flag-icon shadow-sm me-2" :class="getFlagIcon(option)"  />
                <i v-else-if="optionsHaveIcons && optionsHaveColors" class="fa-fw custom-icon-rounded me-2" :class="getIcon(option)" :style="getColoredIconStyle(option)"/>
                <i v-else-if="optionsHaveIcons" class="fa-fw me-2" :class="getIcon(option)" />

                <span v-html="getFilteredText(option)" />
                <i class="fas ps-2 fa-sm fa-trash" v-if="option.display?.deleted" />
                <i class="fas ps-2 fa-sm fa-lock" v-if="option.display?.disabled" />
                
            </button>
            <i v-if="options.length===0" class="px-3 small text-danger">Kein Eintrag</i>
        </div>
        <ul class="nav small border-top" v-if="hasSelectNavbar">
            <aunoa-nav-item icon="grip" class="pe-1" disabled tabindex="-1" v-if="0" />
            <aunoa-nav-item :text="t('Aunoa.Command.SelectAll')" @click="selectAll()" />
            <aunoa-nav-item :text="t('Aunoa.Command.SelectNone')" @click="selectNone()" />
            <li class="nav-item ms-auto">
                <span class="nav-text text-muted" v-text="options.length" />
            </li>
        </ul>
    </div>
</template>


<script lang="ts">

import type {LookupDetailedDisplay, LookupDetailedOption} from "../../implementations/lookup/useLookup";

import {computed, defineComponent, ref, watch, nextTick, toRefs, onMounted, onBeforeUnmount} from "vue";
import {useAunoaI18n} from "../../utils/useAunoaI18n";
import {useDropdown} from "../../utils/useDropdown";
import AunoaNavItem from "../nav/AunoaNavItem.vue";

const getStartChar = (str: any) => str?.[0]?.toUpperCase();

export default defineComponent({
    name: "AunoaDropdownMenu",
    //inheritAttrs: false,
    components: {AunoaNavItem},
    setup(props, {attrs}) {

        const {t, ensureTextTranslated} = useAunoaI18n();
        const dropdown = useDropdown();
        //const {optionsHaveIcons, optionsHaveColors, getDisplayIcon, lookup} = dropdown;

        const isSelected = (option: LookupDetailedOption) => dropdown.selectedValues.value.indexOf(option.value) >= 0;

        const getOptions = (): LookupDetailedOption[] => dropdown.options.value;

        const menuAttrs = computed(() => ({
            ...attrs,
            //"class": {
            //    "show-navbar": hasSelectNavbar.value
            //},
            "style": {
                //...attrs?.style,
                // todo: query height and paddings from css, make 7 as parameter
                //"max-height": `${8 + 7 * 29 + 8}px`
            }
        }));

        const getOptionAttrs = (option: LookupDetailedOption) => ({
            "type": "button",
            "data-value": option.value,
            "data-start": getStartChar(option.display?.text) || " ",
            "class": {
                "dropdown-item": true,
                "text-truncate": true,
                "text-muted": option.display?.deleted || option.display?.disabled,
                //"active": isSelected(option),
                //"focus": focus
            }
        });


        return {
            t,
            ...dropdown,
            menuAttrs,

            getOptions,

            ensureTextTranslated,
            getOptionAttrs,
            isSelected,


        }

    }
})


</script>
