﻿<template>
    <aunoa-nav-item
        id="selectThemeNavItem"
        :drop-direction="dropDirection"
        :drop-menu-class="dropMenuClass"
        :header-text="t('Aunoa.Theme.Title')"
        :icon="icon[theme]"
    >
        <aunoa-dropdown-check-item
            :checked="theme==='auto'"
            :text="t('Aunoa.Theme.Auto')"
            :end-icon="icon['auto']"
            @click="set('auto')"
        />
        <aunoa-dropdown-divider />
        <aunoa-dropdown-check-item :checked="theme==='light'"
            :text="t('Aunoa.Theme.Light')"
            :end-icon="icon['light']"
            @click="set('light')"
        />
        <aunoa-dropdown-check-item
            :checked="theme==='dark'"
            :text="t('Aunoa.Theme.Dark')"
            :end-icon="icon['dark']"
            @click="set('dark')"
        />
    </aunoa-nav-item>
</template>

<script lang="ts">

import {defineComponent} from "vue";
import AunoaDropdownCheckItem from "./dropdown/AunoaDropdownCheckItem.vue";
import AunoaDropdownDivider from "./dropdown/AunoaDropdownDivider.vue";
import AunoaNavItem from "./nav/AunoaNavItem.vue";
import AunoaSelectNavItemMixin from "../mixins/AunoaSelectNavItemMixin";
import {useAunoaI18n} from "../utils/useAunoaI18n";

export default defineComponent({
    name: "AunoaSelectThemeNavItem",
    mixins: [AunoaSelectNavItemMixin],
    components: {
        AunoaDropdownCheckItem,
        AunoaDropdownDivider,
        AunoaNavItem
    },
    props: {
        theme: {
            type: String,
        }
    },
    emits: ["update:theme"],
    setup(props, {emit}) {

        const {t} = useAunoaI18n();
        const set = (value: string) => emit("update:theme", value);

        const icon = {
            "auto": "fad fa-eclipse",
            "light": "fad fa-sun",
            "dark": "fad fa-moon"
        }

        return {
            t,
            set,
            icon
        }
    }
});

</script>

