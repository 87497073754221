﻿import type {InjectionKey, Ref} from "vue"

import {inject, provide, unref} from "vue"

const INJECTION_KEY: InjectionKey<Ref<string | undefined>> = Symbol("MATE_ACCESS_TOKEN");

export const provideAccessToken = (accessToken: Ref<string | undefined>) => {

    provide(INJECTION_KEY, accessToken);
}


export const useAccessToken = () => {

    const accessToken = inject(INJECTION_KEY);

    const setAsCookie = (expirationSeconds = 5) => {
        const token = unref(accessToken);
        if (token && token !== "ANONYMOUS") {
            if(expirationSeconds>0) {
                const date = new Date();
                date.setTime(date.getTime() + expirationSeconds * 1000);
                document.cookie = `MATE.auth.token=${token}; Path=/; Expires=${date.toUTCString()}`;
            }
            else {
                document.cookie = `MATE.auth.token=${token}; Path=/;`;
            }
            return true;
        }
        return false;
    }

    return {
        accessToken,
        setAsCookie
    }
}