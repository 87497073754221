﻿<template>
    <aunoa-checkbox-tree
        :texts="translatedTexts(entity.Permissions.Texts)"
        :items="entity.Permissions.Items"
        :tenants="tenants"
        search-placeholder="Search in Permissions"
        style="height:500px"
    />
</template>

<script lang="ts">

import {computed, defineComponent, PropType, toRefs, watch} from "vue";
import {AunoaCheckboxTree, useAunoaI18n} from "bootstrap-aunoa";
import {useRouteScope} from "../../scope/useRouteScope";

export default defineComponent({
    name: "UserPermissions",
    components: {AunoaCheckboxTree},
    props: {
        entity: {
            type: Object as PropType<Tnt.Dto.Identity.UserDto>,
            required: true
        }
    },
    setup(props) {

        const {entity: user} = toRefs(props);
        const {tenants: scopeTenants} = useRouteScope();
        const tenantOids = computed(() => user.value.Permissions.Items[0].t.map(i => i.t));

        const tenants = computed(() => scopeTenants.value
            .filter(t => t.oid && tenantOids.value.includes(t.oid))
            .map(t => ({
                id: t.id,
                oid: t.oid,
                country: t.parent?.id?.toLowerCase()
            })));

        const {ensureTextTranslated} = useAunoaI18n();
        const translatedTexts = (texts: string[]) => texts.map(ensureTextTranslated);

        return {
            translatedTexts,
            tenants
        }
    }
});

</script>

