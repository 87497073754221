﻿<template>
    <component
        :is="field.component"
        :name="field.name"
        :property="property||field.property"
        :label="label||field.label"
        :rules="field.rules"
        :field-class="fieldClass"
        :disabled-model="field.disabled"
        :readonly-model="field.readonly"
        :required-model="field.required"
        v-bind="field.attrs"
    />

</template>

<script lang="ts">

import {defineComponent, PropType} from "vue";
import {Forms} from "../../types";

export default defineComponent({
    name: "AunoaFormField",
    props: {
        field: {
            type: Object as PropType<Forms.Model.Field>
        },
        fieldClass: {},
        property: {},
        label: {}
    }
});

</script>
