﻿import type {Ref} from "vue";
import type {ComponentMapping} from "../views";
import type {RouteRecordRaw} from "vue-router";
import type {Navigation as AunoaNavigation, LinkItem} from "bootstrap-aunoa";

import {ADMIN_ROUTE, FORBIDDEN_ROUTE, EMBEDDED_ROUTE} from "../consts";
import {useLocalizationHelper} from "./useLocalizationHelper";
import {enumerateLinks} from "bootstrap-aunoa";
import {useTenancy} from "@tnt/mate-api";
import {mappings} from "../views";
import {ref, watch} from "vue";
import router from "../router";

type TenantOid = number;
type Path = string;
type Component = string;
type RemoveFunc = () => void;

type Permissions = Record<TenantOid, string[]>

export interface MateLinkItem extends LinkItem {
    component?: string;
    documentType?: string;
    forDocumentNumberSearches?: boolean;
}

export interface Navigation extends AunoaNavigation<MateLinkItem> {
    permissions: Record<Path, Permissions>;
    progress: Record<Path, boolean>;
}

const ensurePermittedLinks = (links: MateLinkItem[], permissions: Record<Path, Permissions>, tenantOid: number): any[] =>
    links.map(link => {
        if (link.children) {
            const children = ensurePermittedLinks(link.children, permissions, tenantOid);
            return children.length ? {...link, children} : undefined;
        }
        return link.path && (permissions[link.path]?.[tenantOid] || link.path === "/")
            ? link
            : undefined;
    }).filter(Boolean)

const createRouting = (navigation: Ref<Navigation>, links: MateLinkItem[], permissions: Record<Path, Permissions>, progress: Record<Path, boolean>) => {

    const pathDict: Record<Path, MateLinkItem> = {};
    const compDict: Record<Component, RemoveFunc[]> = {};
    
    const addRouteRecordRaw =  (component: Component, route:RouteRecordRaw) => 
        compDict[component] = [
            router.addRoute(ADMIN_ROUTE, route),
            router.addRoute(EMBEDDED_ROUTE, {...route, path: "/e" + route.path})
        ];
    

    enumerateLinks<MateLinkItem>(links, (link: MateLinkItem, hl: MateLinkItem[]) => {
        link.icon = link.icon || hl.map(l => l.icon).filter(Boolean).reverse()[0];
        link.parentNames = hl.filter((l, i) => i < hl.length - 1).map(l => l.name).reverse();

        if (link.path) {
            pathDict[link.path] = link;
            // const inProgress = progress[link.path];
            // if (inProgress) {
            //     //console.log(link.path, inProgress);
            //     //return;
            // }

            if (link.component && !compDict[link.component] && mappings[link.component]) {
                const mapping = mappings[link.component];
                addRouteRecordRaw(link.component, {
                    name: link.id,
                    path: mapping.path || link.path,
                    component: mapping.component,
                    props: mapping.props,
                    meta: mapping.meta
                });
                if (mapping.related) {
                    Object.entries(mapping.related).forEach(([name, m]) => {
                        addRouteRecordRaw(name, {
                            path: m.path as string,
                            component: m.component,
                            props: m.props,
                            meta: {
                                ...m.meta,
                                responsiblePath: link.path
                            }
                        });
                    });
                }

            }
        }
    });

    const {primaryTenantOid} = useTenancy();

    const unwatch = watch(primaryTenantOid, tenantOid => {
        navigation.value.rootItems = ensurePermittedLinks(links, permissions, tenantOid);
    }, {immediate: true});

    const unguard = router.beforeEach((to, from, next) => {
        //console.log("useRouting from", from.path, "to", to.path, to);
        if (!to.meta.ignorePermissions) {
            if (to.name !== FORBIDDEN_ROUTE && !pathDict?.[to.path]) {
                //console.log(to);
                next({name: FORBIDDEN_ROUTE, query: {requested: to.fullPath}})
                return;
            }
        }
        next();
    });

    const dispose = () => {
        unguard();
        unwatch();
        Object.keys(compDict).forEach(component => compDict[component].forEach(remover => remover()));
    };

    navigation.value.permissions = permissions;
    navigation.value.progress = progress;
    navigation.value.pathDict = pathDict;

    return {
        dispose
    }
}

export const useRouting = () => {

    const {ensureTextTranslated} = useLocalizationHelper();

    const navigation = ref<Navigation>({
        rootItems: [],
        nameFunc: item => ensureTextTranslated(item.name),
        permissions: {},
        progress: {}
    });

    let currentRouting: any;

    const setLinks = (links: MateLinkItem[], permissions: Record<Path, Permissions>, progress: any) => {
        currentRouting?.dispose();
        currentRouting = createRouting(navigation, links, permissions, progress)
    }

    return {
        navigation,
        setLinks
    }
}