﻿<template>
    <button ref="buttonElement" v-bind="buttonAttrs">
        <i v-if="icon" :class="tryResolveIconAlias(icon)" />
        <span v-if="text" v-text="ensureTextTranslated(text)" />
        <i
            v-if="errorBadge>0 && errorBadge <10"
            class="fas text-danger ps-1"
            :class="['fa-circle-'+errorBadge]" />
        <span
            v-else-if="errorBadge"
            class="badge badge-sm rounded-pill bg-danger ms-1"
            v-text="errorBadge" />
    </button>
</template>

<script lang="ts">

import type {PropType} from "vue";
import type {TabInfo} from "./usePaneSubscription";

import {getVisibilityEventNames, useEmitableVisibilityEvents} from "../../utils/useVisibilityEvents";
import {computed, defineComponent, ref, toRefs, onBeforeUnmount, watch} from "vue";
import {tryResolveIconAlias} from "../../utils/useIconAlias";
import {useAunoaI18n} from "../../utils/useAunoaI18n";
import {Tab} from "bootstrap";

let idCounter = 181011;

export default defineComponent({
    name: "AunoaTabNavLink",
    inheritAttrs: false,
    components: {},
    props: {
        tabInfo: {
            type: Object as PropType<TabInfo>,
            default: undefined
        },
        tabId: {
            type: String
        },
        icon: {
            type: String
        },
        text: {
            type: String
        },
        errorBadge: {}
    },
    emits: [...getVisibilityEventNames("tab")],
    setup(props, {attrs: _attrs, emit}) {

        const bsTab = ref<Tab>();
        const {tabInfo, tabId} = toRefs(props);
        const {ensureTextTranslated} = useAunoaI18n();
        const refId = computed(() => tabId?.value || `nlt${idCounter++}`);
        const {element: buttonElement} = useEmitableVisibilityEvents("tab", emit);
        
        const buttonAttrs = computed(() => ({
            ..._attrs,
            "class": [
                "nav-link",
                _attrs.class
            ].filter(Boolean),
            "id": `${refId.value}-link`,
            "type": "button",
            "role": "tab",
            "data-bs-toggle": "tab",
            "data-bs-target": `#${refId.value}`,
            "aria-controls": refId.value
        }));

        const show = () => bsTab.value?.show();

        watch(buttonElement, element => {
            bsTab.value?.dispose();
            bsTab.value = element ? new Tab(element) : undefined;
        });

        watch(tabInfo, (newTab, oldTab) => {
            if (oldTab) {
                oldTab.show = undefined;
            }
            if (newTab) {
                newTab.show = show;
            }
        }, {immediate: true});

        onBeforeUnmount(() => {
            bsTab.value?.dispose();
            buttonElement.value = undefined;
        });

        return {
            buttonElement,
            buttonAttrs,
            ensureTextTranslated,
            tryResolveIconAlias,
            show
        }

    }
});

</script>

