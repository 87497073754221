﻿import type {LookupOption} from "../implementations/lookup/useLookup";

import {isLookupDetailedDisplay} from "../implementations/lookup/useLookup";
import {getSearchStrings} from "./search";
import {computed, ref, Ref} from "vue";

interface Options {
    passWithoutFilter: boolean;
    onSelect(option: LookupOption): void;
    onClear(): void;
}

const ENTER_KEY = "Enter";
const ARROW_UP_KEY = "ArrowUp";
const ARROW_DOWN_KEY = "ArrowDown";

export const useTypeahead = (
    value: Ref<any>,
    text: Ref<string>,
    lookupOptions: Ref<LookupOption[]>,
    options?: Partial<Options>
) => {

    const typeaheadEnabled = ref(false);
    const typeaheadIndex = ref(-1);

    const searchStrings = computed(() => getSearchStrings(text.value).map(s => s.toLowerCase()));

    const filteredOptions = computed(() => searchStrings.value.length
        ? lookupOptions.value.filter(option => {
            if (option.display) {
                const value = isLookupDetailedDisplay(option.display)
                    ? option.display.text?.toLowerCase()
                    : option.display.toString().toLowerCase();
                return value && searchStrings.value.every(s => value.indexOf(s) >= 0)
            } else {
                return false;
            }
        })
        : options && options.passWithoutFilter ? lookupOptions.value : []
    );

    const onInputFocus = (e: FocusEvent) => {
        if (!value.value && text.value && text.value.length && filteredOptions.value.length) {
            typeaheadEnabled.value = true;
        }
    }

    const onInputInput = (e: InputEvent) => {
        options && options.onClear && options.onClear();
        typeaheadEnabled.value = true;
        typeaheadIndex.value = filteredOptions.value.length > 0 ? 0 : -1;
    }

    const onInputKeydown = (e: KeyboardEvent) => {
        console.log("onInputKeydown(TAH)", e);
        switch (e.code) {

            case ARROW_UP_KEY:
                if (typeaheadIndex.value > 0) {
                    typeaheadIndex.value--;
                }
                e.preventDefault();
                break;

            case ARROW_DOWN_KEY:
                if (typeaheadIndex.value + 1 < filteredOptions.value.length) {
                    typeaheadIndex.value++;
                }
                e.preventDefault();
                break;

            case ENTER_KEY:
                if (typeaheadIndex.value >= 0) {
                    e.preventDefault();
                    const option = filteredOptions.value[typeaheadIndex.value];
                    option && options?.onSelect?.(option);
                    setTimeout(() => {
                        typeaheadEnabled.value = false;
                    }, 10);
                    typeaheadIndex.value = -1;
                }
                break;
        }
    }

    return {

        searchStrings,
        filteredOptions,
        typeaheadEnabled,
        typeaheadIndex,

        onInputFocus,
        onInputInput,
        onInputKeydown

    }
};