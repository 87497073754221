﻿<template>
    <component :is="tag" class="card-header">
        <router-link class="ms-2 float-end fad fa-external-link-alt" :to="to" v-if="to" />
        <i class="fa-lg text-primary me-2" :class="icon" v-if="icon" />
        <span v-text="text" />
    </component>
</template>

<script lang="ts">

import {defineComponent} from "vue";

export default defineComponent({
    name: "AunoaCardHeader",
    props: {
        tag: {
            type: String,
            default: "div"
        },
        text: {
            type: String,
            default: undefined
        },
        to: {
            default: undefined
        },
        icon: {
            type: String,
            default: undefined
        }
    }
});

</script>

