﻿import {copyToClipboard} from "./clipboard";
import {parseJson} from "./json";

export interface ClipboardContext {
    type: string;
    data: any[];
}

export const useClipboardData = () => {

    const ensureClipboardJson = (jsonStr: string, requiredType?: string) => {

        if (!jsonStr) {
            throw new Error("@@Aunoa.Clipboard.ClipboardEmpty");
        }

        if (!(jsonStr.startsWith("{") && jsonStr.endsWith("}"))) {
            throw new Error("@@Aunoa.Clipboard.WrongClipboardFormat");
        }

        const context = <ClipboardContext>parseJson(jsonStr);
        if (!context.type || !context.data) {
            throw new Error("@@Aunoa.Clipboard.WrongClipboardFormat");
        }
        if (requiredType && requiredType !== context.type) {
            throw new Error("@@Aunoa.Clipboard.InvalidClipboardType");
        }
        return context.data[0]
        
    }


    const read = async (requiredType?: string) => {
        
        if (!navigator.clipboard.readText) {
            throw new Error("@@Aunoa.Clipboard.KeyboardPasteOnly");
        }

        const text = await navigator.clipboard.readText();
        return ensureClipboardJson(text, requiredType); 


    }

    const write = (data: any, type?: string) => {
        const json = JSON.stringify({
            "type": type,
            "data": [data]
        }, null, 4);
        copyToClipboard(json);
    }

    return {
        ensureClipboardJson,
        read,
        write
    }
}


