﻿// LGB, quick and dirty, none development cases may be to improve

// https://www.appdevtools.com/case-converter
//const regex = /[a-zA-Z0-9]+/g;
const regex = /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g;

type Locales = string | string[];

export type TextCasing = "Lower" | "Upper" | "Sentence" | "Title" | "Capital" | "Pascal" | "Kebap" | "Snake" | "Constant" | "Camel";

const lowerWords = ["a", "an", "and", "as", "at", "but", "by", "en", "for", "if", "in", "nor", "of", "on", "or", "per", "the", "to", "v.", "v", "vs.", "v", "via"];

const to = (text: string, sep: string, f: (str: string, index: number) => string): string => {
    const matches = text?.match(regex);
    return matches ? matches.map(f).join(sep) : text;
};

export const toLowerCase = (text: string, locales?: Locales) => text ? text.toLocaleLowerCase(locales) : text;

export const toUpperCase = (text: string, locales?: Locales) => text ? text.toLocaleUpperCase(locales) : text;

export const toSentenceCase = (text: string, locales?: Locales) => to(text, " ", (s, i) => {
    if (i == 0) {
        return s[0].toLocaleUpperCase(locales) + s.slice(1)
    } else {
        const lower = s.toLocaleLowerCase(locales);
        return lower === "i" ? lower.toLocaleUpperCase(locales) : lower;
    }
});

export const toTitleCase = (text: string, locales?: Locales) => to(text, " ", s => {
    const title = s[0].toLocaleUpperCase(locales) + s.slice(1);
    const lower = s.toLocaleLowerCase(locales);
    return lowerWords.includes(lower) ? lower : title;
});

export const toCapitalCase = (text: string, locales?: Locales) => to(text, " ", s => s[0].toLocaleUpperCase(locales) + s.slice(1));

export const toPascalCase = (text: string, locales?: Locales) => to(text, "", s => s[0].toLocaleUpperCase(locales) + s.slice(1));

export const toKebapCase = (text: string, locales?: Locales) => to(text, "-", s => s.toLocaleLowerCase(locales));

export const toSnakeCase = (text: string, locales?: Locales) => to(text, "_", s => s.toLocaleLowerCase(locales));

export const toConstantCase = (text: string, locales?: Locales) => to(text, "_", s => s.toLocaleUpperCase(locales));

export const toCamelCase = (text: string, locales?: Locales) => to(text, "", (s, i) =>
    (i === 0
        ? s[0].toLocaleLowerCase(locales)
        : s[0].toLocaleUpperCase(locales)) + s.slice(1));

const dict: Record<TextCasing, (text: string, locales?: Locales) => string> = {
    Lower: toLowerCase,
    Upper: toUpperCase,
    Sentence: toSentenceCase,
    Title: toTitleCase,
    Capital: toCapitalCase,
    Pascal: toPascalCase,
    Kebap: toKebapCase,
    Snake: toSnakeCase,
    Constant: toConstantCase,
    Camel: toCamelCase,
}

export const toCase = (text: string, casing: TextCasing, locales?: Locales) => text && casing && dict[casing]
    ? dict[casing](text, locales)
    : text;

export const textCasing = {
    toLowerCase,
    toUpperCase,
    toSentenceCase,
    toTitleCase,
    toCapitalCase,
    toPascalCase,
    toKebapCase,
    toSnakeCase,
    toConstantCase,
    toCamelCase,
}