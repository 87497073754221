﻿import {inject, InjectionKey, provide, ref, Ref} from "vue";

export type FormMode = "default" | "search";

const INJECTION_KEY: InjectionKey<Ref<FormMode>> = Symbol();

export const provideFormMode = (formMode:Ref<FormMode>) => {

    provide(INJECTION_KEY, formMode);
}

export const useFormMode = () => {

    const formMode = inject(INJECTION_KEY, ref("default"));

    return {
        formMode
    }
}
