﻿import {AccessToken} from "./types";
import {useMateApi} from "./useMateApi";


export interface RecentChange {
    oid: number;
    modifiedAt: Date;
    userName: string;
    scope: string[];
    path: string[];
    state: "active" | "creating" | "updating" | "deleting" | "updated" | "deleted";
    value: string | null;
    previousValue: string | null;
}

export interface RecentChanges {
    recentTotal: number;
    recentTopChanges: RecentChange[];
}

export const useConfigApi = (accessToken: AccessToken) => {

    const {jsonEndpoint} = useMateApi(accessToken);
    const api = jsonEndpoint("/api");

    const getRecentChanges = (days = 90, take = 5) => api
        .get<RecentChanges>({days, take}, {url: "/pos-settings/recent-changes"});

    return {
        getRecentChanges
    }
}
