<template>
    <div v-bind="fieldAttrs" class="currency-amount-field" style="position: relative">
        <slot name="label">
            <aunoa-form-label v-if="label" v-bind="labelAttrs" />
        </slot>
        <div class="form-control form-control-sm embed-input enable-focus-border d-flex align-items-center position-relative">
            <input v-bind="baseControlAttrs" class="currency-amount-field" :class="isNull" type="number" ref="inputElement" />
            <span v-bind="formattedDisplayAttrs" v-text="formattedValue" />
            <div class="px-2" :class="isNull?'text-disabled':'text-muted'" v-text="currency" />
        </div>
        <aunoa-error-message v-bind="errorMessageAttrs" />
    </div>
</template>

<script lang="ts">

import {asCurrencyAndAmount, toAmountString, toCurrenyAmountString, toCurrenyAmountValue} from "../../utils/currencyAmount";
import {fieldProps, useField} from "../../implementations/forms/useField";
import {computed, defineComponent, ref, toRefs} from "vue";
import {useElementSize} from "../../utils/useElementSize";
import AunoaErrorMessage from "./AunoaErrorMessage.vue";
import {isUndefinedOrNull} from "../../utils/inspect";
import AunoaFormLabel from "./AunoaFormLabel.vue";
import {useLocale} from "../../utils/useLocale";

const useFormattedDisplay = () => {

    const {element: inputElement, offsetHeight, offsetWidth} = useElementSize();

    const attrs = computed(() => ({
        class: "formatted-display",
        style: {
            height: offsetHeight.value + "px",
            width: offsetWidth.value + "px"
        }
    }));

    return {
        inputElement,
        attrs
    }
}

export default defineComponent({
    name: "AunoaCurrencyAmountBox",
    inheritAttrs: false,
    components: {AunoaFormLabel, AunoaErrorMessage},
    props: {
        ...fieldProps,
        modelValue: {
            type: [String, Number]
        },
        currency: {
            type: String,
            default: "EUR"
        }
    },
    setup: function (props, ctx) {

        const format = ref<string>();
        const {locale} = useLocale();
        const specifiedCurrency = ref<string>();
        const {currency: currencyProp} = toRefs(props);
        const {inputElement, attrs: formattedDisplayAttrs} = useFormattedDisplay();
        const currency = computed(() => specifiedCurrency.value || currencyProp.value || "EUR");
        
        const isNull = computed(()=> field.value.value === null && props.nullable);

        const field = useField(props as any, ctx, {
            inputType: "number",
            setValue: (value: any) => {
                const ca = asCurrencyAndAmount(value);
                if (ca) {
                    specifiedCurrency.value = ca.currency;
                    format.value = ca.format;
                    return ca.amount;
                } else {
                    specifiedCurrency.value = undefined;
                    format.value = undefined;
                    return value;
                }
            },
            getValue: (value: any) => {
                return format.value === "ca" || format.value === "ac"
                    ? toCurrenyAmountString(currency.value, value, undefined/*format.value*/, !!props.nullable)
                    : value;

            },
            onBlur: (e: FocusEvent) => {
                field.value.value = toCurrenyAmountValue(currency.value, field.value.value, !!props.nullable);
            }
        })

        const formattedValue = computed(() => {
            return !isUndefinedOrNull(field.value.value)
                ? toAmountString(currency.value, field.value.value, locale.value)
                : "";
        });

        return {
            isNull,
            ...field,
            currency,
            inputElement,
            formattedValue,
            formattedDisplayAttrs
        }
    }
});

</script>
