﻿import {onBeforeUnmount, onMounted, ref} from "vue";

export function useElementVisibility() {

    const visible = ref(false);
    const observer = new IntersectionObserver(([e]) => visible.value = e.intersectionRatio > 0, {
        threshold: [0.0, 1.0]
    });


    const element = ref<HTMLElement>();

    onMounted(() => {
        if (element.value) {
            observer.observe(element.value);
        }
        else {
            visible.value = false;
        }
    });

    onBeforeUnmount(() => {
        if (element.value) {
            observer.unobserve(element.value);
        }
        visible.value = false;
    });
    
    
    return {
        visible, 
        element
    }

} 