﻿<template>
    <button type="button" class="btn" @click="onSort">
        <span class="pe-1" v-text="text" v-if="!iconAtStart" />
        <i :class="iconClass" />
        <span class="ps-1" v-text="text" v-if="iconAtStart" />
    </button>
</template>

<script lang="ts">
import {defineComponent, toRefs} from "vue";
import {useNamedSorting} from "../utils/useSorting";


export default defineComponent({
    name: "AunoaSortButton",
    props: {
        text: {
            type: String,
            required: true
        },
        iconAtStart: {
            type: Boolean,
            default: false
        },
        sortingName: {
            type: String,
            default: "default"
        },
        sort: {
            type: [String, Object],
            required: true
        },
        sortField: {
            type: String,
            required: true
        },
        sortDescending: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {

        const {sort, sortField, sortDescending} = toRefs(props);

        const sorting = useNamedSorting(props.sortingName, sort, sortField, sortDescending)

        return {
            ...sorting
        }
    }
});


</script>
