﻿declare var VITE_APP_LOCALES: string[];
declare var VITE_MATE_API: string;
declare var VITE_APP_VERSION: string;

let _locales:string[] = [];
let _mateApiUrl = "";
let _version = "";

if (typeof process === 'undefined' || typeof process.env === 'undefined') {
    console.log("vite mode detected");
    _locales = VITE_APP_LOCALES;
    _mateApiUrl = VITE_MATE_API;
    _version = VITE_APP_VERSION;
} else {
    console.log("vue cli mode detected");
    _locales = JSON.parse(<string>process.env.VUE_APP_LOCALES);
    _mateApiUrl = <string>process.env.VUE_APP_MATE_API;
    _version = <string>process.env.VUE_APP_VERSION;
}
console.log(`Using locales: ${_locales}`)
console.log(`Using MATE Api Url: ${_mateApiUrl}`)

const ensureTrailingSlash = (url:string) => {
    if(url.slice(-1)==='/')
        return url;
    return url+'/';        
}

export const locales = (): string[] => _locales;

export const mateApiUrl = (): string => _mateApiUrl!=='/' ? _mateApiUrl : ensureTrailingSlash(window.location.origin);//release build need domain at runtime!

export const version = (): string => _version;