import type {Ref, InjectionKey} from "vue";

import {ref, computed, provide, inject, watch, unref, onBeforeUnmount} from "vue";

type CustomSortFunc<T = any> = (a: T, b: T) => number;

export interface CustomSort<T = any> {
    ascendingIcon?: string;
    descendingIcon?: string;
    sort: CustomSortFunc;
}

export type Sort<T = any> = "alpha" | "numeric" | CustomSort<T>;

interface Sorting {
    currentSortField: Ref<string>;
    currentSortDescending: Ref<boolean>;
    currentSortFunc: Ref<CustomSortFunc>;
}


const INJECTION_KEY: InjectionKey<Record<string, Sorting>> = Symbol("AUNOA_SORTING");

export const provideSorting = () => {

    const sortings: Record<string, Sorting> = {};
    provide(INJECTION_KEY, sortings);

    const setNamedSorting = <T>(name: string, items: T[]|Ref<T[]>, sortField: string, sortDescending = false) => {
        const currentSortField = ref(sortField);
        const currentSortDescending = ref(sortDescending);
        const currentSortFunc = ref<CustomSortFunc>(() => 0);

        const sortedItems = computed<T[]>(() => [...unref(items)].sort((a: T, b: T) => {
            return currentSortDescending.value
                ? currentSortFunc.value?.(b, a) || 0
                : currentSortFunc.value?.(a, b) || 0;
        }));

        sortings[name] = <Sorting>{
            currentSortField,
            currentSortDescending,
            currentSortFunc
        };

        return {
            sortedItems,
            currentSortField,
            currentSortDescending
        }
    }

    const setSorting = <T>(items: T[]|Ref<T[]>, sortField: string, sortDescending = false) =>
        setNamedSorting("default", items, sortField, sortDescending);

    return {
        setNamedSorting,
        setSorting
    }

}


export const useNamedSorting = (name: string, sort: Ref<Sort | any>, sortField: Ref<string>, sortDescending: Ref<boolean>) => {

    const sortings = inject(INJECTION_KEY);
    
    const sorting = sortings?.[name];
    const currentSortField = sorting?.currentSortField || ref(sortField.value);
    const currentSortDescending = sorting?.currentSortDescending || ref(sortDescending.value);
    const currentSortFunc = sorting?.currentSortFunc || ref(() => 0);

    const descendingIcon = () =>
        sort.value === "alpha" ? "fa-sort-alpha-up-alt"
            : sort.value === "numeric"
                ? "fa-sort-amount-up"
                : sort.value.descendingIcon || "fa-sort-amount-up";

    const ascendingIcon = () =>
        sort.value === "alpha" ? "fa-sort-alpha-down"
            : sort.value === "numeric"
                ? "fa-sort-amount-down-alt"
                : sort.value.ascendingIcon || "fa-sort-amount-down-alt";

    const isDescending = () => currentSortField.value === sortField.value && currentSortDescending.value;
    const isAscending = () => currentSortField.value === sortField.value && !currentSortDescending.value;

    const iconClass = computed(() => [
        "fa-fw",
        "fal",
        "text-muted",
        isDescending()
            ? descendingIcon()
            : isAscending()
                ? ascendingIcon()
                : ""
    ]);

    watch([currentSortField, sortField, sort], ([csf, sf, s]) => {
        if (sf === csf) {
            if (s === "alpha") {
                currentSortFunc.value = (a: any, b: any) => a[sf].localeCompare(b[sf]);
            } else if (s === "numeric") {
                currentSortFunc.value = (a: any, b: any) => a[sf] - b[sf];
            } else {
                currentSortFunc.value = s.sort;
            }
        }
    }, {immediate: true});

    const onSort = () => {
        if (currentSortField.value === sortField.value) {
            currentSortDescending.value = !currentSortDescending.value;
        } else {
            currentSortField.value = sortField.value;
            currentSortDescending.value = sortDescending.value;
        }
    }

    return {
        iconClass,
        currentSortField,
        currentSortDescending,
        onSort
    }
}


export const useSorting = (sort: Ref<Sort | any>, sortField: Ref<string>, sortDescending: Ref<boolean>) =>
    useNamedSorting("default", sort, sortField, sortDescending);
