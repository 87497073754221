﻿<template>
    <svg x="0px" y="0px"  viewBox="0 0 605 142">
        <g>
            <g>
                <path id="z" class="st0" d="M203,96.8c0,2.2-0.3,4.4-0.8,6.9h-42l-1.6-2.4l24.1-46.7h-21.4c-0.3-1.3-0.5-2.5-0.6-3.6s-0.1-2.2-0.1-3.1c0-1,0-2.1,0.1-3.2c0.1-1.2,0.3-2.4,0.6-3.7h42.5l1.3,2.5l-23.9,46.6h21C202.7,92.6,203,94.8,203,96.8" />
                <path id="u" class="st0" d="M237.2,104.6c-4.7,0-8.6-0.6-11.7-1.9c-3.1-1.3-5.6-3.1-7.4-5.5c-1.8-2.4-3.1-5.3-3.8-8.8c-0.7-3.4-1.1-7.3-1.1-11.6V41.1c1.7-0.2,3.1-0.4,4.4-0.5c1.2-0.1,2.4-0.1,3.5-0.1c1,0,2.1,0,3.3,0.1s2.6,0.2,4.3,0.5V75c0,2.9,0.1,5.4,0.4,7.4c0.2,2,0.7,3.7,1.3,5s1.5,2.2,2.6,2.7c1.1,0.6,2.6,0.8,4.4,0.8s3.3-0.3,4.4-0.8c1.1-0.6,2-1.5,2.6-2.7c0.6-1.3,1-2.9,1.2-5c0.2-2,0.3-4.5,0.3-7.4V41.2c1.7-0.2,3.2-0.4,4.4-0.5c1.2-0.1,2.3-0.1,3.3-0.1s2.2,0,3.4,0.1c1.2,0.1,2.7,0.2,4.4,0.5v35.7c0,4.3-0.4,8.2-1.1,11.6s-2,6.3-3.8,8.8c-1.8,2.4-4.3,4.3-7.4,5.5C245.8,104,241.9,104.6,237.2,104.6" />
                <path class="st0" d="M287.9,72.4c0,12.3,5.2,18.5,15.5,18.5c1.9,0,3.6-0.2,5.3-0.5c1.7-0.3,3.7-0.9,6.1-1.7
			c1.9,4.2,3.2,8.5,3.8,12.9c-1.7,0.6-3.3,1.2-4.7,1.6c-1.5,0.4-2.9,0.7-4.2,0.9c-1.3,0.2-2.7,0.3-4,0.4s-2.7,0.1-4.1,0.1
			c-4.9,0-9.3-0.8-13-2.4c-3.8-1.6-6.9-3.8-9.4-6.7c-2.5-2.8-4.4-6.3-5.7-10.2c-1.3-4-1.9-8.2-1.9-12.9c0-4.6,0.7-9,2-12.9
			c1.3-4,3.3-7.4,5.8-10.3s5.7-5.2,9.4-6.8c3.7-1.6,7.9-2.4,12.6-2.4c2.8,0,5.4,0.2,7.9,0.6s5.3,1.2,8.4,2.4
			c-0.1,2.4-0.6,4.7-1.3,6.9c-0.8,2.2-1.5,4.2-2.4,5.9c-1.2-0.4-2.2-0.8-3.2-1c-1-0.3-1.9-0.5-2.7-0.7c-0.9-0.2-1.7-0.3-2.6-0.4
			s-1.8-0.1-2.8-0.1c-4.7,0-8.4,1.6-10.9,4.8C289.2,61.4,287.9,66.2,287.9,72.4" />
                <path class="st0" d="M338.7,72.4c0,12.3,5.2,18.5,15.5,18.5c1.9,0,3.6-0.2,5.3-0.5c1.7-0.3,3.7-0.9,6.1-1.7
			c1.9,4.2,3.2,8.5,3.8,12.9c-1.7,0.6-3.3,1.2-4.7,1.6c-1.5,0.4-2.9,0.7-4.2,0.9c-1.3,0.2-2.7,0.3-4,0.4s-2.7,0.1-4,0.1
			c-4.9,0-9.3-0.8-13-2.4c-3.8-1.6-6.9-3.8-9.4-6.7c-2.5-2.8-4.4-6.3-5.7-10.2c-1.3-4-1.9-8.2-1.9-12.9c0-4.6,0.7-9,2-12.9
			c1.3-4,3.3-7.4,5.8-10.3s5.7-5.2,9.4-6.8c3.7-1.6,7.9-2.4,12.6-2.4c2.8,0,5.4,0.2,7.9,0.6s5.3,1.2,8.4,2.4
			c-0.1,2.4-0.6,4.7-1.3,6.9c-0.8,2.2-1.5,4.2-2.4,5.9c-1.2-0.4-2.2-0.8-3.2-1c-1-0.3-1.9-0.5-2.7-0.7c-0.9-0.2-1.7-0.3-2.6-0.4
			s-1.8-0.1-2.8-0.1c-4.7,0-8.4,1.6-10.9,4.8C339.9,61.4,338.7,66.2,338.7,72.4" />
                <path class="st0" d="M417.7,104.2c-2.2,0-4.7-0.3-7.8-0.8V77.9h-15.4v25.5c-3.1,0.5-5.6,0.8-7.7,0.8c-2.2,0-4.7-0.3-7.8-0.8V41.1
			c1.6-0.2,3-0.4,4.3-0.5s2.4-0.2,3.4-0.2s2.2,0.1,3.5,0.2s2.7,0.3,4.2,0.5v23.1H410V41.1c1.6-0.2,3-0.4,4.2-0.5s2.4-0.2,3.4-0.2
			s2.2,0.1,3.5,0.2s2.7,0.3,4.2,0.5v62.3C422.3,103.9,419.8,104.2,417.7,104.2" />
                <path class="st0" d="M470.9,71c0,2-0.3,4.3-0.8,6.8h-16.8v12.4h21.9c0.5,2.4,0.8,4.7,0.8,6.6c0,2.1-0.3,4.4-0.8,6.9h-37.3V41h36.8
			c0.2,1.3,0.4,2.5,0.5,3.6s0.2,2.2,0.2,3.1c0,1.9-0.2,4.2-0.7,6.9h-21.4v9.6h16.8C470.7,66.7,470.9,69,470.9,71" />
                <path class="st0" d="M497.2,54.6h-14.6c-0.5-2.5-0.8-4.8-0.8-6.9c0-1.9,0.3-4.2,0.8-6.7h44.6c0.2,1.3,0.4,2.5,0.5,3.6
			s0.2,2.2,0.2,3.1c0,1.9-0.2,4.2-0.7,6.9h-14.6v48.8c-3.1,0.5-5.6,0.8-7.7,0.8c-2.2,0-4.7-0.3-7.8-0.8L497.2,54.6L497.2,54.6z" />
                <path class="st0" d="M547.9,54.6h-14.6c-0.5-2.5-0.8-4.8-0.8-6.9c0-1.9,0.3-4.2,0.8-6.7h44.5c0.2,1.3,0.4,2.5,0.5,3.6
			s0.2,2.2,0.2,3.1c0,1.9-0.2,4.2-0.7,6.9h-14.6v48.8c-3.1,0.5-5.6,0.8-7.7,0.8c-2.2,0-4.7-0.3-7.8-0.8V54.6H547.9z" />
                <path class="st0" d="M594.9,104.2c-2.2,0-4.7-0.3-7.8-0.8V41.1c1.6-0.2,3-0.4,4.3-0.5s2.4-0.2,3.4-0.2s2.2,0.1,3.5,0.2
			s2.7,0.3,4.2,0.5v62.3C599.5,103.9,596.9,104.2,594.9,104.2" />
                <polygon class="st0" points="101.4,55.6 87.9,55.6 86.9,56.7 85.1,58.6 101.4,58.6 		" />
                <polygon class="st0" points="101.4,49.6 93.3,49.6 92.6,50.3 90.6,52.6 101.4,52.6 		" />
                <polygon class="st0" points="101.4,67.7 77.1,67.7 75.5,69.3 74.2,70.7 101.4,70.7 		" />
                <polygon class="st0" points="101.4,61.6 82.5,61.6 81.2,63 79.7,64.7 101.4,64.7 		" />
                <path class="st0" d="M101.4,40.5" />
                <polygon class="st0" points="35.3,19.4 21.6,19.4 21.6,22.4 38.2,22.4 		" />
                <polygon class="st0" points="29.9,13.4 21.5,13.4 21.5,16.4 32.8,16.4 		" />
            </g>
            <g>
                <polygon class="st0" points="21.6,4.5 21.6,10.4 27.4,10.4 		" />
                <polygon class="st0" points="28.4,55.6 2.6,55.6 2.6,58.6 59.7,58.6 62.2,55.6 28.8,55.6 		" />
            </g>
            <g>
                <polygon class="st0" points="21.6,82.7 39.3,82.7 41.9,79.7 21.6,79.8 		" />
            </g>
            <g>
                <polygon class="st0" points="21.6,88.8 34.2,88.8 35.4,87.3 36.8,85.7 21.6,85.7 		" />
                <polygon class="st0" points="2.6,49.6 2.6,52.6 30.7,52.6 33,49.6 		" />
                <polygon class="st0" points="101.4,46.6 101.4,40.6 96,46.6 		" />
                <polygon class="st0" points="2.6,61.6 2.6,64.7 54.6,64.7 57.1,61.6 		" />
                <polygon class="st0" points="21.6,94.8 29.1,94.8 31.7,91.8 21.6,91.8 		" />
                <polygon class="st0" points="2.6,70.7 49.5,70.7 50.3,69.7 52,67.7 2.6,67.7 		" />
            </g>
            <g>
                <polygon class="st0" points="40.7,25.5 21.5,25.5 21.5,28.5 43.7,28.5 		" />
                <polygon class="st0" points="94.1,131 101.4,131 101.4,127.9 91.2,127.9 		" />
                <polygon class="st0" points="90.7,94.8 120.5,94.8 120.5,91.8 93.3,91.8 		" />
                <polygon class="st0" points="85.6,100.8 120.5,100.8 120.5,97.8 88.2,97.8 		" />
                <polygon class="st0" points="83.7,118.9 101.4,118.9 101.4,115.9 80.9,115.9 		" />
                <polygon class="st0" points="73.4,106.8 120.5,106.8 120.5,103.8 70.5,103.8 		" />
                <polygon class="st0" points="71.7,73.7 69.5,76 68.8,76.7 120.5,76.7 120.5,73.7 		" />
                <polygon class="st0" points="66.2,79.7 64,82 63.3,82.7 120.5,82.7 120.5,79.7 		" />
            </g>
            <g>
                <polygon class="st0" points="57.9,88.8 95.9,88.8 120.5,88.8 120.5,85.7 60.9,85.7 		" />
                <polygon class="st0" points="46.6,74.1 46.9,73.7 2.6,73.7 2.6,76.7 44.4,76.7 		" />
                <polygon class="st0" points="35.3,46.6 37.5,43.5 2.6,43.5 2.6,46.6 		" />
            </g>
            <g>
                <polygon class="st0" points="101.4,139.3 101.4,134 96.4,134 		" />
            </g>
            <g>
                <polygon class="st0" points="46.1,31.5 21.6,31.5 21.6,34.5 49.1,34.5 		" />
                <polygon class="st0" points="51.6,37.5 45.4,37.5 2.6,37.5 2.6,40.5 54.5,40.5 		" />
            </g>
            <g>
                <polygon class="st0" points="101.3,121.9 86.1,121.9 88.9,124.9 101.3,124.9 		" />
                <polygon class="st0" points="101.4,109.9 75.6,109.9 78.5,112.9 101.4,112.9 		" />
                <polygon class="st0" points="21.6,97.8 21.6,103.7 26.6,97.8 		" />
            </g>
        </g>
        <g v-if="true">
            <g>
                <path class="st0" d="M203,96.8c0,2.2-0.3,4.4-0.8,6.9h-42l-1.6-2.4l24.1-46.7h-21.4c-0.3-1.3-0.5-2.5-0.6-3.6s-0.1-2.2-0.1-3.1
			c0-1,0-2.1,0.1-3.2c0.1-1.2,0.3-2.4,0.6-3.7h42.5l1.3,2.5l-23.9,46.6h21C202.7,92.6,203,94.8,203,96.8" />
                <path class="st0" d="M237.2,104.6c-4.7,0-8.6-0.6-11.7-1.9c-3.1-1.3-5.6-3.1-7.4-5.5c-1.8-2.4-3.1-5.3-3.8-8.8
			c-0.7-3.4-1.1-7.3-1.1-11.6V41.1c1.7-0.2,3.1-0.4,4.4-0.5c1.2-0.1,2.4-0.1,3.5-0.1c1,0,2.1,0,3.3,0.1s2.6,0.2,4.3,0.5V75
			c0,2.9,0.1,5.4,0.4,7.4c0.2,2,0.7,3.7,1.3,5s1.5,2.2,2.6,2.7c1.1,0.6,2.6,0.8,4.4,0.8s3.3-0.3,4.4-0.8c1.1-0.6,2-1.5,2.6-2.7
			c0.6-1.3,1-2.9,1.2-5c0.2-2,0.3-4.5,0.3-7.4V41.2c1.7-0.2,3.2-0.4,4.4-0.5c1.2-0.1,2.3-0.1,3.3-0.1s2.2,0,3.4,0.1
			c1.2,0.1,2.7,0.2,4.4,0.5v35.7c0,4.3-0.4,8.2-1.1,11.6s-2,6.3-3.8,8.8c-1.8,2.4-4.3,4.3-7.4,5.5
			C245.8,104,241.9,104.6,237.2,104.6" />
                <path class="st0" d="M287.9,72.4c0,12.3,5.2,18.5,15.5,18.5c1.9,0,3.6-0.2,5.3-0.5c1.7-0.3,3.7-0.9,6.1-1.7
			c1.9,4.2,3.2,8.5,3.8,12.9c-1.7,0.6-3.3,1.2-4.7,1.6c-1.5,0.4-2.9,0.7-4.2,0.9c-1.3,0.2-2.7,0.3-4,0.4s-2.7,0.1-4.1,0.1
			c-4.9,0-9.3-0.8-13-2.4c-3.8-1.6-6.9-3.8-9.4-6.7c-2.5-2.8-4.4-6.3-5.7-10.2c-1.3-4-1.9-8.2-1.9-12.9c0-4.6,0.7-9,2-12.9
			c1.3-4,3.3-7.4,5.8-10.3s5.7-5.2,9.4-6.8c3.7-1.6,7.9-2.4,12.6-2.4c2.8,0,5.4,0.2,7.9,0.6s5.3,1.2,8.4,2.4
			c-0.1,2.4-0.6,4.7-1.3,6.9c-0.8,2.2-1.5,4.2-2.4,5.9c-1.2-0.4-2.2-0.8-3.2-1c-1-0.3-1.9-0.5-2.7-0.7c-0.9-0.2-1.7-0.3-2.6-0.4
			s-1.8-0.1-2.8-0.1c-4.7,0-8.4,1.6-10.9,4.8C289.2,61.4,287.9,66.2,287.9,72.4" />
                <path class="st0" d="M338.7,72.4c0,12.3,5.2,18.5,15.5,18.5c1.9,0,3.6-0.2,5.3-0.5c1.7-0.3,3.7-0.9,6.1-1.7
			c1.9,4.2,3.2,8.5,3.8,12.9c-1.7,0.6-3.3,1.2-4.7,1.6c-1.5,0.4-2.9,0.7-4.2,0.9c-1.3,0.2-2.7,0.3-4,0.4s-2.7,0.1-4,0.1
			c-4.9,0-9.3-0.8-13-2.4c-3.8-1.6-6.9-3.8-9.4-6.7c-2.5-2.8-4.4-6.3-5.7-10.2c-1.3-4-1.9-8.2-1.9-12.9c0-4.6,0.7-9,2-12.9
			c1.3-4,3.3-7.4,5.8-10.3s5.7-5.2,9.4-6.8c3.7-1.6,7.9-2.4,12.6-2.4c2.8,0,5.4,0.2,7.9,0.6s5.3,1.2,8.4,2.4
			c-0.1,2.4-0.6,4.7-1.3,6.9c-0.8,2.2-1.5,4.2-2.4,5.9c-1.2-0.4-2.2-0.8-3.2-1c-1-0.3-1.9-0.5-2.7-0.7c-0.9-0.2-1.7-0.3-2.6-0.4
			s-1.8-0.1-2.8-0.1c-4.7,0-8.4,1.6-10.9,4.8C339.9,61.4,338.7,66.2,338.7,72.4" />
                <path class="st0" d="M417.7,104.2c-2.2,0-4.7-0.3-7.8-0.8V77.9h-15.4v25.5c-3.1,0.5-5.6,0.8-7.7,0.8c-2.2,0-4.7-0.3-7.8-0.8V41.1
			c1.6-0.2,3-0.4,4.3-0.5s2.4-0.2,3.4-0.2s2.2,0.1,3.5,0.2s2.7,0.3,4.2,0.5v23.1H410V41.1c1.6-0.2,3-0.4,4.2-0.5s2.4-0.2,3.4-0.2
			s2.2,0.1,3.5,0.2s2.7,0.3,4.2,0.5v62.3C422.3,103.9,419.8,104.2,417.7,104.2" />
                <path class="st0" d="M470.9,71c0,2-0.3,4.3-0.8,6.8h-16.8v12.4h21.9c0.5,2.4,0.8,4.7,0.8,6.6c0,2.1-0.3,4.4-0.8,6.9h-37.3V41h36.8
			c0.2,1.3,0.4,2.5,0.5,3.6s0.2,2.2,0.2,3.1c0,1.9-0.2,4.2-0.7,6.9h-21.4v9.6h16.8C470.7,66.7,470.9,69,470.9,71" />
                <path class="st0" d="M497.2,54.6h-14.6c-0.5-2.5-0.8-4.8-0.8-6.9c0-1.9,0.3-4.2,0.8-6.7h44.6c0.2,1.3,0.4,2.5,0.5,3.6
			s0.2,2.2,0.2,3.1c0,1.9-0.2,4.2-0.7,6.9h-14.6v48.8c-3.1,0.5-5.6,0.8-7.7,0.8c-2.2,0-4.7-0.3-7.8-0.8L497.2,54.6L497.2,54.6z" />
                <path class="st0" d="M547.9,54.6h-14.6c-0.5-2.5-0.8-4.8-0.8-6.9c0-1.9,0.3-4.2,0.8-6.7h44.5c0.2,1.3,0.4,2.5,0.5,3.6
			s0.2,2.2,0.2,3.1c0,1.9-0.2,4.2-0.7,6.9h-14.6v48.8c-3.1,0.5-5.6,0.8-7.7,0.8c-2.2,0-4.7-0.3-7.8-0.8V54.6H547.9z" />
                <path class="st0" d="M594.9,104.2c-2.2,0-4.7-0.3-7.8-0.8V41.1c1.6-0.2,3-0.4,4.3-0.5s2.4-0.2,3.4-0.2s2.2,0.1,3.5,0.2
			s2.7,0.3,4.2,0.5v62.3C599.5,103.9,596.9,104.2,594.9,104.2" />
                <polygon class="st0" points="101.4,55.6 87.9,55.6 86.9,56.7 85.1,58.6 101.4,58.6 		" />
                <polygon class="st0" points="101.4,49.6 93.3,49.6 92.6,50.3 90.6,52.6 101.4,52.6 		" />
                <polygon class="st0" points="101.4,67.7 77.1,67.7 75.5,69.3 74.2,70.7 101.4,70.7 		" />
                <polygon class="st0" points="101.4,61.6 82.5,61.6 81.2,63 79.7,64.7 101.4,64.7 		" />
                <path class="st0" d="M101.4,40.5" />
                <polygon class="st0" points="35.3,19.4 21.6,19.4 21.6,22.4 38.2,22.4 		" />
                <polygon class="st0" points="29.9,13.4 21.5,13.4 21.5,16.4 32.8,16.4 		" />
            </g>
            <g>
                <polygon class="st0" points="21.6,4.5 21.6,10.4 27.4,10.4 		" />
                <polygon class="st0" points="28.4,55.6 2.6,55.6 2.6,58.6 59.7,58.6 62.2,55.6 28.8,55.6 		" />
            </g>
            <g>
                <polygon class="st0" points="21.6,82.7 39.3,82.7 41.9,79.7 21.6,79.8 		" />
            </g>
            <g>
                <polygon class="st0" points="21.6,88.8 34.2,88.8 35.4,87.3 36.8,85.7 21.6,85.7 		" />
                <polygon class="st0" points="2.6,49.6 2.6,52.6 30.7,52.6 33,49.6 		" />
                <polygon class="st0" points="101.4,46.6 101.4,40.6 96,46.6 		" />
                <polygon class="st0" points="2.6,61.6 2.6,64.7 54.6,64.7 57.1,61.6 		" />
                <polygon class="st0" points="21.6,94.8 29.1,94.8 31.7,91.8 21.6,91.8 		" />
                <polygon class="st0" points="2.6,70.7 49.5,70.7 50.3,69.7 52,67.7 2.6,67.7 		" />
            </g>
            <g>
                <polygon class="st0" points="40.7,25.5 21.5,25.5 21.5,28.5 43.7,28.5 		" />
                <polygon class="st0" points="94.1,131 101.4,131 101.4,127.9 91.2,127.9 		" />
                <polygon class="st0" points="90.7,94.8 120.5,94.8 120.5,91.8 93.3,91.8 		" />
                <polygon class="st0" points="85.6,100.8 120.5,100.8 120.5,97.8 88.2,97.8 		" />
                <polygon class="st0" points="83.7,118.9 101.4,118.9 101.4,115.9 80.9,115.9 		" />
                <polygon class="st0" points="73.4,106.8 120.5,106.8 120.5,103.8 70.5,103.8 		" />
                <polygon class="st0" points="71.7,73.7 69.5,76 68.8,76.7 120.5,76.7 120.5,73.7 		" />
                <polygon class="st0" points="66.2,79.7 64,82 63.3,82.7 120.5,82.7 120.5,79.7 		" />
            </g>
            <g>
                <polygon class="st0" points="57.9,88.8 95.9,88.8 120.5,88.8 120.5,85.7 60.9,85.7 		" />
                <polygon class="st0" points="46.6,74.1 46.9,73.7 2.6,73.7 2.6,76.7 44.4,76.7 		" />
                <polygon class="st0" points="35.3,46.6 37.5,43.5 2.6,43.5 2.6,46.6 		" />
            </g>
            <g>
                <polygon class="st0" points="101.4,139.3 101.4,134 96.4,134 		" />
            </g>
            <g>
                <polygon class="st0" points="46.1,31.5 21.6,31.5 21.6,34.5 49.1,34.5 		" />
                <polygon class="st0" points="51.6,37.5 45.4,37.5 2.6,37.5 2.6,40.5 54.5,40.5 		" />
            </g>
            <g>
                <polygon class="st0" points="101.3,121.9 86.1,121.9 88.9,124.9 101.3,124.9 		" />
                <polygon class="st0" points="101.4,109.9 75.6,109.9 78.5,112.9 101.4,112.9 		" />
                <polygon class="st0" points="21.6,97.8 21.6,103.7 26.6,97.8 		" />
            </g>
        </g>
    </svg>
    
</template>

<script lang="ts">

import {defineComponent} from "vue";

export default defineComponent({
    name: "Zucchetti"
});


</script>
