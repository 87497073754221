import type {Conditions} from "../types";
import {getPropertyName} from "../utils/reflectionLike";
import {isFunction, isUndefined} from "../utils/inspect";
import {createContextablePropertyPath} from "../utils/properties";

interface ConditionContext {
    model?: Conditions.Model.Condition;
}

const conditionModel = (value: any): Conditions.Model.Condition => {
    if (isFunction(value)) {
        const context: ConditionContext = {}
        const builder = conditionBuilder(context);
        value(builder);
        return context.model;
    }
    return !!(isUndefined(value) || value);
}

const coreConditionBuilder = (context: ConditionContext, property: string) => {

    const unary = (condition: Conditions.Model.UnaryConditionMode): Conditions.Builder.ConditionReady => {
        context.model = <Conditions.Model.UnaryCondition>{property, condition};
        return {}
    }

    const binaryOperator = (operator: Conditions.Model.BinaryOperatorConditionMode, value: any): Conditions.Builder.ConditionReady => {

        if (isFunction(value)) {
            const builder = createContextablePropertyPath();
            value(builder);
            value = "{{" + builder.Build() + "}}";
        }

        context.model = <Conditions.Model.BinaryOperatorCondition>{property, operator, value};

        return {}
    };

    return {
        unary,
        binaryOperator
    }
}

const stringConditionBuilder = (context: ConditionContext, property: string): Conditions.Builder.StringCondition => {

    const {unary, binaryOperator} = coreConditionBuilder(context, property);

    return {

        IsEmpty: () => unary("empty"),
        IsNull: () => unary("null"),
        IsNullOrEmpty: () => unary("nullOrEmpty"),
        IsNotEmpty: () => unary("notEmpty"),
        IsNotNull: () => unary("notNull"),
        IsNotNullOrEmpty: () => unary("notNullOrEmpty"),

        IsEqual: value => binaryOperator("equal", value),
        IsNotEqual: value => binaryOperator("notEqual", value),
        IsGreaterThan: value => binaryOperator("greaterThan", value),
        IsGreaterOrEqual: value => binaryOperator("greaterOrEqual", value),
        IsLessThan: value => binaryOperator("lessThan", value),
        IsLessOrEqual: value => binaryOperator("lessOrEqual", value)
    }
}

const numberConditionBuilder = (context: ConditionContext, property: string): Conditions.Builder.NumberCondition => {

    const {unary, binaryOperator} = coreConditionBuilder(context, property);

    return {

        IsZero: () => unary("zero"),
        IsOne: () => unary("one"),
        IsNull: () => unary("null"),
        IsNullOrZero: () => unary("nullOrZero"),
        IsNullOrOne: () => unary("nullOrOne"),
        IsNotZero: () => unary("notZero"),
        IsNotOne: () => unary("notOne"),
        IsNotNull: () => unary("notNull"),
        IsNotNullOrZero: () => unary("notNullOrZero"),
        IsNotNullOrOne: () => unary("notNullOrOne"),
        IsPositive: () => unary("positive"),
        IsNegative: () => unary("negative"),

        IsEqual: value => binaryOperator("equal", value),
        IsNotEqual: value => binaryOperator("notEqual", value),
        IsGreaterThan: value => binaryOperator("greaterThan", value),
        IsGreaterOrEqual: value => binaryOperator("greaterOrEqual", value),
        IsLessThan: value => binaryOperator("lessThan", value),
        IsLessOrEqual: value => binaryOperator("lessOrEqual", value)
    }
}

const dateConditionBuilder = (context: ConditionContext, property: string): Conditions.Builder.DateCondition => {

    const {unary, binaryOperator} = coreConditionBuilder(context, property);

    return {

        IsNull: () => unary("null"),
        IsNotNull: () => unary("notNull"),

        IsEqual: value => binaryOperator("equal", value),
        IsNotEqual: value => binaryOperator("notEqual", value),
        IsGreaterThan: value => binaryOperator("greaterThan", value),
        IsGreaterOrEqual: value => binaryOperator("greaterOrEqual", value),
        IsLessThan: value => binaryOperator("lessThan", value),
        IsLessOrEqual: value => binaryOperator("lessOrEqual", value)
    }
}

const booleanConditionBuilder = (context: ConditionContext, property: string): Conditions.Builder.BooleanCondition => {

    const {unary, binaryOperator} = coreConditionBuilder(context, property);

    return {

        IsFalse: () => unary("false"),
        IsTrue: () => unary("true"),
        IsNull: () => unary("null"),
        IsNotFalse: () => unary("notFalse"),
        IsNotTrue: () => unary("notTrue"),
        IsNotNull: () => unary("notNull"),
        IsNullOrFalse: () => unary("nullOrFalse"),
        IsNullOrTrue: () => unary("nullOrTrue"),

        IsEqual: value => binaryOperator("equal", value),
        IsNotEqual: value => binaryOperator("notEqual", value)
    }
}

const objectConditionBuilder = (context: ConditionContext, property: string): Conditions.Builder.ObjectCondition => {

    const {unary} = coreConditionBuilder(context, property);

    return {

        IsNull: () => unary("null"),
        IsNotNull: () => unary("notNull"),
    }
}

const arrayConditionBuilder = (context: ConditionContext, property: string): Conditions.Builder.ArrayCondition => {

    const {unary} = coreConditionBuilder(context, property);

    return {

        IsNull: () => unary("null"),
        IsNotNull: () => unary("notNull"),
        IsNotNullOrEmpty: () => unary("notNullOrEmpty")
    }
}

const propertyConditionBuilder = (context: ConditionContext) => {

    const properties: any[] = ["Entity"];

    const property = (getter: any) => {
        properties.push(getPropertyName(getter));
        return properties.join(".");
    }

    const builder: Conditions.Builder.PropertyCondition = {
        Property: getter => {
            properties.push(getPropertyName(getter));
            return builder;
        },
        String: getter => stringConditionBuilder(context, property(getter)),
        Number: getter => numberConditionBuilder(context, property(getter)),
        Date: getter => dateConditionBuilder(context, property(getter)),
        Boolean: getter => booleanConditionBuilder(context, property(getter)),
        Object: getter => objectConditionBuilder(context, property(getter)),
        Array: getter => arrayConditionBuilder(context, property(getter))
    }

    return builder;
}

const allBuilder = (models: Conditions.Model.Condition[]) => {
    const builder = <Conditions.Builder.AllCondition>{
        And: value => {
            models.push(conditionModel(value));
            return builder
        },
        False: () => {
            models.push(false);
            return builder
        }
    }
    return builder;
}

const anyBuilder = (models: Conditions.Model.Condition[]) => {
    const builder = <Conditions.Builder.AnyCondition>{
        Or: value => {
            models.push(conditionModel(value));
            return builder
        },
        True: () => {
            models.push(true);
            return builder
        }
    }
    return builder;
}

const groupBuilder = (context: ConditionContext, model: Conditions.Model.Condition) => {

    context.model = model;
    const models = [model];

    return <Conditions.Builder.ConditionGroup>{
        And: value => {
            context.model = <Conditions.Model.AllCondition>{whenAll: models};
            models.push(conditionModel(value))
            return allBuilder(models);
        },
        Or: value => {
            context.model = <Conditions.Model.AnyCondition>{whenAny: models};
            models.push(conditionModel(value))
            return anyBuilder(models);
        }
    }
}

const conditionBuilder = (context: ConditionContext): Conditions.Builder.Condition => ({
    ...propertyConditionBuilder(context),
    When: value => groupBuilder(context, conditionModel(value)),
    Not: value => {
        context.model = <Conditions.Model.NotCondition>{not: conditionModel(value)}
        return {}
    }
})

export const createConditionsModel = () => {

    const context: ConditionContext = {}
    const builder = conditionBuilder(context);
    const build = () => context.model

    return {
        builder,
        build
    }
}

export const createCondition = (value: any): Conditions.Model.Condition => {
    if (isFunction(value)) {
        const {builder: conditionsBuilder, build} = createConditionsModel();
        value(conditionsBuilder);
        return build();
    }
    return !!(isUndefined(value) || value);
}

