﻿<template>
    <component ref="itemElement" :is="as" >
        <slot :editMode="editMode" :width="width" :height="height" />
    </component>
</template>

<!--
<div class="pt-1 pb-3 small text-info text-center">{{width}} x {{height}}</div>
-->

<script lang="ts">

import {providePackeryItem} from "./usePackery";
import {defineComponent, toRefs} from "vue";

export default defineComponent({
    name: "AunoaPackeryItem",
    props: {
        as: {
            type: String,
            default: "div"
        }
    },
    setup() {
        
       
        const packeryItem = providePackeryItem();
        return {
            ...packeryItem
        }
    }
});

</script>

