import {Ref} from "vue";
import type {User} from "../../mixins/UserMixin";
import {computed, ref, watch} from "vue";
import {storageDefaultNamespace} from "../../core";

//export type StorageItem<T> = T | null | undefined;

export const useStorage = (storage: Storage, user?: User) => {

    let namespace = storageDefaultNamespace.value || "AUNOA";
    if (user && user.id) {
        namespace = `${namespace}.${user.id}`;
    }

    const items = (prefix?: string | undefined) => {
        let rootKey = namespace + ".";
        if (prefix) {
            rootKey = rootKey + prefix + ".";
        }

        const _subscribe = <T>(
            name: string,
            convToStorage: (value: T | undefined) => string | undefined,
            convToValue: (str: string) => T | undefined,
            defaultValue?: any): Ref<T | null | undefined> => {

            const key = rootKey + name;
            const internal = ref<string | null | undefined>(storage.getItem(key));

            //console.log("initial", key, internal.value);

            watch(internal, value => {
                if (value === undefined) {
                    storage.removeItem(key);
                } else if (value === null) {
                    storage.setItem(key, "null");
                } else {
                    storage.setItem(key, value);
                }
            });

            window.addEventListener("storage", (ev: StorageEvent) => {
                if (ev.storageArea === storage) {
                    if (ev.key === null) {
                        internal.value = undefined;
                    } else if (ev.key === key) {
                        internal.value = ev.newValue;
                    }
                }
            });

            return computed<T | null | undefined>({
                get(ctx?: any) {
                    const str = internal.value;
                    //console.log(key, str, ctx);
                    if (str === undefined || str === null) {
                        return defaultValue;
                    }
                    if (str === "null") {
                        return null;
                    }
                    try {
                        const value = convToValue(str);
                        return value !== null
                            ? value
                            : defaultValue;
                    } catch (e) {
                        console.error(`Error parsing ${str} as Key: ${key}`);
                        return defaultValue;
                    }
                },
                set(value: T | null | undefined) {
                    if (value === undefined) {
                        internal.value = <undefined>value;
                    } else if (value === null) {
                        internal.value = <null>value;
                    } else {
                        internal.value = convToStorage(value)
                    }
                }
            });
        };

        const subscribeObject = <T = any>(name: string, defaultValue?: T) =>
            _subscribe<T>(
                name,
                JSON.stringify,
                JSON.parse,
                defaultValue);

        const subscribeString = <T extends string = string>(name: string, defaultValue?: T) =>
            _subscribe<T>(
                name,
                value => value,
                str => <T>str,
                defaultValue);

        const subscribeInt = (name: string, defaultValue?: number) =>
            _subscribe<number>(
                name,
                value => value?.toString(),
                parseInt,
                defaultValue);

        const subscribeFloat = (name: string, defaultValue?: number) =>
            _subscribe<number>(
                name,
                value => value?.toString(),
                parseFloat,
                defaultValue);

        const subscribeBoolean = (name: string, defaultValue?: boolean) =>
            _subscribe<boolean>(
                name,
                value => value?.toString(),
                str => str.toLowerCase() === "true"
                    ? true
                    : (str.toLowerCase() === "false" ? false : undefined),
                defaultValue);

        return {
            subscribeObject,
            subscribeString,
            subscribeInt,
            subscribeFloat,
            subscribeBoolean
        }
    };

    return {
        items
    }

};