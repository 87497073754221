<template>
    <div class="container">
        <h5 class="pt-5" style="width: 20ch">
            <i class="fad fa-exclamation-triangle text-danger"></i>
            <span class="px-3">It looks like the MATE Api service has been not started!</span>
        </h5>
        <a class="btn btn-primary ms-5 mt-2" :href="link">Retry</a>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
    name: "AppFailed",
    setup() {
        return {
            link: window.location.href    
        }
    }
});

</script>


