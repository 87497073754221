﻿import type {InjectionKey, Ref} from "vue";
import type {LookupDisplay, LookupProps, LookupUse, LookupDetailedDisplay, LookupDetailedOption} from "../implementations/lookup/useLookup";

import {createSearchOption, isStrMatching, createReplaceRegExp, markStr} from "./search";
import {useCustomEventListener, useEventListener} from "./useEventListener";
import {ref, provide, inject, computed, onMounted, watch} from "vue";
import {useLookup} from "../implementations/lookup/useLookup";
import {useDropdownScrolling} from "./useDropdownScrolling";
import {useElementSizeV2} from "./useElementSize";
import {isDefined} from "./inspect";
import {Dropdown} from "bootstrap";


type DropdownMode = "select" | "typeahead" | "multi-select";

interface DropdownOptions {
    mode: DropdownMode;
    disabled: Ref<Boolean>;
    readonly: Ref<Boolean>;
}

interface DropdownUse extends LookupUse {

    multiSelect: Ref<boolean>;
    typeahead: Ref<boolean>;

    visible: Ref<boolean>;
    filterText: Ref<string>;
    menuFocused: Ref<boolean>;
    selectedValue: Ref<any>;
    selectedValues: Ref<any[]>;
    hasSelectNavbar: Ref<boolean>;

    fieldElement: Ref<HTMLDivElement | undefined>;
    menuElement: Ref<HTMLDivElement | undefined>;
    inputElement: Ref<HTMLInputElement | undefined>;
    buttonElement: Ref<HTMLButtonElement | undefined>;
    formControlElement: Ref<HTMLElement | undefined>;

    formControlAttrs: Ref;
    buttonIconAttrs: Ref;
    buttonAttrs: Ref;

    getTextFromOptionValue(value: any): string;
    getFilteredText(option: LookupDetailedOption): string;
    selectAll(): void;
    selectNone(): void;
    onSelect(event: Event, value: any): void;
    onDelete(event: Event, value: any): void;
}

const ARROW_UP_KEY = 'ArrowUp'
const ARROW_DOWN_KEY = 'ArrowDown'

const PAGE_UP_KEY = "PageUp";
const PAGE_DOWN_KEY = "PageDown";

const BACKSPACE_KEY = "Backspace";
const ENTER_KEY = "Enter";
const SPACE_KEY = "Space";
const HOME_KEY = "Home";
const END_KEY = "End";

const INJECTION_KEY: InjectionKey<DropdownUse> = Symbol("AUNOA_DROPDOWN");

const getStartChar = (str: any) => str?.[0]?.toUpperCase();

const provideDropdown = (props: LookupProps, dropdownOptions?: Partial<DropdownOptions>) => {

    let resettingSelectedValues = false;
    let showingMenu = false;
    let hidingMenu = false;

    const isMultiSelect = dropdownOptions?.mode === "multi-select";
    const isTypeahead = dropdownOptions?.mode === "typeahead";
    const isSelect = !isMultiSelect && !isTypeahead;
    const multiSelect = computed(() => isMultiSelect);
    const typeahead = computed(() => isTypeahead);
    const disabled = dropdownOptions?.disabled || ref(false);
    const readonly = dropdownOptions?.readonly || ref(false);

    const fieldElement = ref<HTMLDivElement>();
    const menuElement = ref<HTMLDivElement>();
    const inputElement = ref<HTMLInputElement>();
    const buttonElement = ref<HTMLButtonElement>();
    const formControlElement = ref<HTMLElement>();

    const {offsetHeight: formControlHeight} = useElementSizeV2({element: formControlElement});
    const lookup = useLookup(props);

    const visible = ref(false);
    const filterText = ref("");
    const menuFocused = ref(false);
    const selectedValues = ref<any[]>([]);
    const selectedValue = computed(() => selectedValues.value[selectedValues.value.length - 1]);

    const searchOption = computed(() => createSearchOption(filterText.value));
    const replaceRegExp = computed(() => createReplaceRegExp(searchOption.value));

    const getFilteredText = (option: LookupDetailedOption) =>
        markStr(lookup.getTranslatedText(option), replaceRegExp.value);

    const options = computed(() => searchOption.value.include.length > 0
        ? lookup.options.value.filter(option => isStrMatching(lookup.getTranslatedText(option), searchOption.value))
        : lookup.options.value
    );

    const hasSelectNavbar = computed(() => isMultiSelect && options.value.length > 0);

    const getTextFromOptionValue = (value: any) => lookup.getTranslatedText(lookup.optionsDict.value[value]);

    const scroll = useDropdownScrolling(menuElement);

    const tryDeleteValue = (value: any) => {
        const i = selectedValues.value.indexOf(value);
        if (i >= 0) {
            selectedValues.value.splice(i, 1);
            return true;
        }
        return false;
    }

    const tryAddValue = (value: any) => {
        const i = selectedValues.value.indexOf(value);
        if (i < 0) {
            selectedValues.value.push(value);
            return true;
        }
        return false;
    }

    const trySetValue = (value: any) => {
        if (isDefined(value)) {
            selectedValues.value = [value];
            return true;
        }
        return false;
    }

    const setValue = (value: any) => {
        //value = lookup.ensureValue(value);
        if (trySetValue(value)) {
            //input.value = "";
            filterText.value = "";
            dropdown.hide();
            setTimeout(() => {
                if (isSelect) {
                    const button = <HTMLButtonElement>buttonElement.value;
                    button.focus();
                } else {
                    const input = <HTMLInputElement>inputElement.value;
                    input.focus();
                    if (isTypeahead) {
                        setTimeout(() => input.select());
                    }
                }
            });
        }
    }

    const toggleValue = (value: any) => {
        if (canToggle) {
            if (!tryDeleteValue(value)) {
                tryAddValue(value);
            }
        } else {
            tryAddValue(value);
        }
    }

    let canToggle = true;
    let dropdown: Dropdown;

    const showAndKeepFocus = () => {
        //console.log("showAndKeepFocus");
        const d = <any>dropdown;
        const oldFocus: any = d._element.focus;
        d._element.focus = () => {
            d._element.focus = oldFocus;
        };
        dropdown.show();
    }

    const selectAll = () => options.value.map(o => o.value).forEach(tryAddValue);
    const selectNone = () => options.value.map(o => o.value).forEach(tryDeleteValue);

    const onSelect = (e: Event, value: any) => {
        //console.log("onSelect", e, option, canToggle ? "CAN TOGGLE" : "-");
        e.preventDefault();
        e.stopPropagation();
        if (isMultiSelect) {
            toggleValue(value);
        } else {
            setValue(value);
        }
    }

    const onDelete = (e: Event, value: any) => {
        tryDeleteValue(value);
    };

    watch([fieldElement, inputElement], ([field, input])=>{
        //console.log("fieldElement", field, input);
        if (field && input) {
            (<any>field).focus = () => {
                //console.log("HACK", input);
                input.focus();
                //setTimeout(()=>input.focus(),50);
            }
        }
    });
    
    onMounted(() => {
        const field = <HTMLDivElement>fieldElement.value;
        const menu = <HTMLDivElement>menuElement.value;
        const input = <HTMLInputElement>inputElement.value;
        const button = <HTMLButtonElement>buttonElement.value;
        const formControl = <HTMLElement>formControlElement.value;

        dropdown = new Dropdown(button, {
            //offset: [-2 * 4 - 1, 0],
            reference: "parent",
            // @ts-ignore
            autoClose: "outside",
            //autoClose: false,
            display: "static",
            popperConfig: {
                modifiers: [
                    {
                        name: 'applyStyles',
                        enabled: false
                    },
                    {
                        name: "computeStyles",
                        options: {
                            adaptive: false
                        }
                    }
                ]
            }
        });

        watch(formControlHeight, h => {
            menu.style.transform = `translate(0px, ${h - 1}px)`;
        }, {immediate: true})

        watch(selectedValue, v => {
            if (!resettingSelectedValues && !isMultiSelect) {
                setTimeout(() => {
                    input.value = isDefined(v) ? getTextFromOptionValue(v) : "";
                });
            }
        }, {immediate: true});

        const onMenuFocusIn = (e: FocusEvent) => {
            menuFocused.value = true;
        }

        const onMenuFocusOut = (e: FocusEvent) => {
            //console.log("onMenuFocusOut", e);
            if ((<any>e).relatedTarget?.parentElement !== menu) {
                menuFocused.value = false;
            }
        }

        // const onInputFocusIn = (e: FocusEvent) => {
        //     //console.log("onInputFocusIn", e, activity === Activity.ShowAndKeepFocus ? "ShowAndKeepFocus" : "", document.activeElement === input ? "ACTIVE" : "");
        //     //if (document.activeElement === input) {
        //     //    e.stopPropagation();
        //     //    e.stopImmediatePropagation();
        //     //    return;
        //     //}
        // }
        // 
        // const onInputFocusOut = (e: FocusEvent) => {
        //     //console.log("onInputFocusOut", e, activity === Activity.ShowAndKeepFocus ? "ShowAndKeepFocus" : "", document.activeElement === input ? "ACTIVE" : "");
        // }
        // 
        // const onInputFocus = (e: FocusEvent) => {
        //     //console.log("onInputFocus", e, input.value, !visible.value);
        //     //if (input.value /*&& activity !== Activity.ShowAndKeepFocus*/ && !visible.value) {
        //     //    //setTimeout(() => showAndKeepFocus());
        //     //}
        // }
        // 
        // const onInputBlur = (e: FocusEvent) => {
        //     //console.log("onInputBlur", e);
        //     //if (e.relatedTarget === formControl) {
        //     //    console.log("input.focus")
        //     //    input.focus();
        //     //}
        //     //if (activity === Activity.ShowAndKeepFocus) {
        //     //    console.log("input.focus")
        //     //    input.focus();
        //     //}
        // }

        const onFormControlClick = (e: MouseEvent) => {
            //console.log("onFormControlClick", e, document.activeElement, hidingMenu);
            if (hidingMenu || showingMenu) {
                return;
            }

            if (isSelect) {
                setTimeout(() => dropdown.show());
            } else {
                setTimeout(() => input.focus());
            }
        }

        const onDocumentKeydown = (e: KeyboardEvent) => {
            //console.log("onDocumentKeydown", e);
            canToggle = true;
            if ((<any>e).delegateTarget === menu) {
                switch (e.key) {

                    case ARROW_UP_KEY:
                    case HOME_KEY: {
                        if (scroll.isFirst(e.target as HTMLElement)) {
                            if (!input.value) {
                                dropdown.hide();
                            }
                            setTimeout(() => input.focus());
                        }
                    }
                }
            }
        }

        const onInputKeydown = (e: KeyboardEvent) => {
            //console.log("onInputKeydown", e);
            switch (e.key) {

                case ENTER_KEY:
                    if (input.value && visible.value) {
                        e.preventDefault();
                        e.stopPropagation();
                        if (options.value.length > 0) {
                            if (isMultiSelect) {
                                tryAddValue(options.value[0].value);
                                dropdown?.hide();
                            } else /*if (isUndefined(selectedValue.value))*/ {

                                setValue(options.value[0].value);
                            }
                        } else {
                            dropdown?.hide();
                        }
                    }
                    break;

                case ARROW_DOWN_KEY:
                    e.preventDefault();
                    dropdown.show();
                    setTimeout(() => scroll.toValue(selectedValue));
                    break;

                case BACKSPACE_KEY:
                    const values = selectedValues.value;
                    if (isMultiSelect && !input.value && values.length > 0) {
                        e.preventDefault();
                        values.splice(values.length - 1, 1);
                    }
                    break;
            }
        }

        const onMenuKeydown = (e: KeyboardEvent) => {
            //console.log("onMenuKeydown", e);
            canToggle = e.key !== ENTER_KEY;
            switch (e.key) {

                case HOME_KEY:
                    e.preventDefault();
                    scroll.toFirst();
                    break;

                case END_KEY:
                    e.preventDefault();
                    scroll.toLast();
                    break;

                case PAGE_UP_KEY:
                    e.preventDefault();
                    scroll.pageUp();
                    break;

                case PAGE_DOWN_KEY:
                    e.preventDefault();
                    scroll.pageDown();
                    break;

                case SPACE_KEY:
                    break;

                case ENTER_KEY:
                    setTimeout(() => {
                        filterText.value = "";
                        input.focus();
                        dropdown.hide();
                    });
                    break;

                default:
                    if (e.key?.length === 1) {
                        const startChar = getStartChar(e.key);
                        if (scroll.toStartChar(startChar)) {
                            e.preventDefault();
                        }
                    }
                    break;


            }
        }

        const onInputInput = (e: Event) => {
            //console.log("onInputInput", e, input.value);
            filterText.value = input.value;
            if (!isMultiSelect) {
                resettingSelectedValues = true;
                selectedValues.value = [];
                setTimeout(() => resettingSelectedValues = false);
            }
            if (input.value) {
                if (!visible.value) {
                    showAndKeepFocus();
                }
            } else if (visible.value) {
                dropdown.hide();
            }
        }

        const onMenuShow = (e: Event) => {
            //console.log("onMenuShow", e);
            showingMenu = true;
            visible.value = true;
            //menu.scrollTo({top:0});
            //console.log(document.activeElement);

            //menu.style.transform = `translate(0px, ${formControl.offsetHeight-1}px)`;
        }

        const onMenuShown = (e: Event) => {
            //console.log("onMenuShown", e);
            setTimeout(() => {
                showingMenu = false;
                if (!isMultiSelect && selectedValue.value && !filterText.value) {
                    scroll.toValue(selectedValue.value);
                } else {
                    menu.scrollTo({top: 0});
                }
            });
        }

        const onMenuHide = (e: Event) => {
            //console.log("onMenuHide", e);
            hidingMenu = true;
            if (isMultiSelect) {
                filterText.value = "";
            }
            menuFocused.value = false;
        }

        const onMenuHidden = (e: Event) => {
            //console.log("onMenuHidden", e);
            visible.value = false;
            setTimeout(() => {
                hidingMenu = false;
            });
        }

        useEventListener(menu, "focusin", onMenuFocusIn);
        useEventListener(menu, "focusout", onMenuFocusOut);


        // useEventListener(input, "focusin", onInputFocusIn);
        // useEventListener(input, "focusout", onInputFocusOut);
        // useEventListener(input, "focus", onInputFocus);
        // useEventListener(input, "blur", onInputBlur);

        useEventListener(formControl, "click", onFormControlClick);

        useEventListener(document, "keydown", onDocumentKeydown, true, true);
        useEventListener(input, "keydown", onInputKeydown);
        useEventListener(menu, "keydown", onMenuKeydown);

        useEventListener(input, "input", onInputInput);

        if (button.parentElement) {
            useCustomEventListener(button.parentElement, "show.bs.dropdown", onMenuShow);
            useCustomEventListener(button.parentElement, "shown.bs.dropdown", onMenuShown);
            useCustomEventListener(button.parentElement, "hide.bs.dropdown", onMenuHide);
            useCustomEventListener(button.parentElement, "hidden.bs.dropdown", onMenuHidden);
        }
        
        //setTimeout(() =>input.focus());
        
    });

    const formControlAttrs = computed(() => ({
        "tabindex": 1,
        "class": {
            "form-control": true,
            "form-control-sm": true,
            "embed-input": true,
            "embed-button": true,
            "select-box": true,
            "multi-select": isMultiSelect,
            "enable-focus-border": !menuFocused.value,
            "disable-focus-border": menuFocused.value
        }
    }));

    const buttonAttrs = computed(() => ({
        "type": "button",
        "class": ["btn", "dropdown-toggle"],
        "disabled": readonly.value || disabled.value,
        "tabindex": !isSelect ? -1 : undefined,
        "data-bs-toggle": "dropdown",
        "aria-expanded": false
    }));

    const buttonIconAttrs = computed(() => ({
        "class": {
            "fas": true,
            "fa-fw": true,
            "fa-chevron-down": true,
            "fa-flip-vertical-scaled": visible.value,
            "transform-animation": true
        }
    }));
    
    

    const result: DropdownUse = {
        ...lookup,
        options,

        multiSelect,
        typeahead,

        visible,
        filterText,
        menuFocused,
        selectedValue,
        selectedValues,
        hasSelectNavbar,

        fieldElement,
        inputElement,
        menuElement,
        buttonElement,
        formControlElement,

        formControlAttrs,
        buttonIconAttrs,
        buttonAttrs,

        getTextFromOptionValue,
        getFilteredText,
        selectAll,
        selectNone,
        onSelect,
        onDelete
    }

    provide(INJECTION_KEY, result);

    return result;
}


export const useDropdown = (props?: LookupProps, dropdownOptions?: Partial<DropdownOptions>): DropdownUse =>
    props
        ? provideDropdown(props, dropdownOptions)
        : <DropdownUse>inject(INJECTION_KEY);
