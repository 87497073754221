﻿<template>
    <div>
        <div class="nav-pane row">
            <aunoa-nav :grip="true">
                <aunoa-nav-item :text="t('Common.Refresh')" @click="update" />
                <template v-if="editMode">
                    <aunoa-nav-item icon="add" :title="t('Aunoa.Command.Add')">
                        <template v-for="[name,m] in Object.entries(cardDefinitions)">
                            <aunoa-dropdown-item
                                v-if="isVisible(name, m)"
                                :disabled="isDisabled(name, m)"
                                :icon="[m.icon || 'far', 'text-primary']"
                                :text="m.title(t)"
                                :end-text="usageCountStr(name, m)"
                                end-text-class="text-muted"
                                @click="add(name, m)">
                                <template v-slot:default v-if="m.variants && m.variants().length>0">
                                    <aunoa-dropdown-item
                                        v-for="v in m.variants()"
                                        :disabled="isDisabledVariant(name, m, v)"
                                        :text="v.name"
                                        @click="addVariant(name, m, v)"
                                    />
                                </template>
                            </aunoa-dropdown-item>
                        </template>
                    </aunoa-nav-item>
                    <aunoa-nav-item item-class="ms-auto" :text="t('Aunoa.File.Command.Save')" @click="onSaveChanges" />
                    <aunoa-nav-item :text="t('Aunoa.Command.Cancel')" @click="onCancelChanges" />
                </template>
                <aunoa-nav-item item-class="ms-auto" icon="fad fa-pen" @click="onStartEdit" v-else />
            </aunoa-nav>
        </div>
        <aunoa-packery
            ref="aunoaPackery"
            class="dashboard row grid pt-3"
            :edit-mode="editMode"
            :options="options">
            <aunoa-packery-item
                v-for="[key,card] in Object.entries(cardDict)"
                class="dashboard-tile pb-3"
                :class="card.tileClass"
                :data-key="key"
                :key="key">
                <component :is="card.component" v-bind="card.params" @close="remove(key)" />
            </aunoa-packery-item>
        </aunoa-packery>
    </div>
</template>

<script lang="ts">

import {AunoaNav, AunoaNavItem, AunoaDropdownItem, AunoaHourglass, AunoaScrollbar, AunoaPackery, AunoaPackeryItem} from "bootstrap-aunoa";
import {defineComponent, computed, watch, nextTick} from "vue";
import {provideDashboard} from "./useDashboard";
import {useAunoaI18n} from "bootstrap-aunoa";
import {useAuth} from "../../utils/useAuth";

/*

Recent (Sales) Receipts (Top 5)
- Abfrage, wie zur Zeit im Visual Receipt Designer
- erstmal fixer Mandant und Typ SalesReceipt
- später konfigurierbar

Anzahl aktiver Terminals der letzten 90 Tage
- aus Used License Keys

Terminal-Übersicht?
- Running     320
- Offline     40
- Disable      5

*/

export default defineComponent({
    name: "Dashboard",
    components: {
        AunoaNav, AunoaNavItem, AunoaDropdownItem,
        AunoaHourglass, AunoaScrollbar, AunoaPackery, AunoaPackeryItem,
    },
    setup() {
        const {t, locale} = useAunoaI18n();

        const {dashboard:dashboardStorage, accessToken} = useAuth();
        
        const dashboard = provideDashboard(dashboardStorage, accessToken);

        const options = {
            itemSelector: ".dashboard-tile",
            percentPosition: true
        };

        watch(locale, l => nextTick(dashboard.layout));
        

        return {
            t,
            options,
            ...dashboard
        }

    }
});

</script>
