﻿<template>
    <form
        ref="formElement"
        :class="formClass"
        :autocomplete="autocomplete||false"
        novalidate
        @submit.stop.prevent="onSubmitRequest"
    >
        <slot />
        <template v-if="0">
            <div class="font-monospace border-top p-2" style="white-space: pre; font-size: .67rem" v-text="JSON.stringify(v,null,2)" />
            <div class="font-monospace border-top p-2" style="white-space: pre; font-size: .67rem" v-text="JSON.stringify(formModel,null,2)"/>
        </template>
    </form>
</template>

<!--
        <pre class="p-2">{{JSON.stringify(entity.RoleExtensions,null,3)}}</pre>

        <template v-if="$slots.default">
            <slot />
        </template>
        <template v-else-if="formModel && entity">
            <aunoa-form-group
                v-for="([id, groupModel]) in Object.entries(formModel)"
                :group-model="groupModel"
            >
                <h3>{{id}}</h3>
                {{group}}
            </aunoa-form-group>
            <hr />
            <div>
                {{entity}}
            </div>
        </template>

-->


<script lang="ts">

import type {SubmitFunc} from "../../utils/forms";
import type {Entities, Forms} from "../../types";
import type {FormMode} from "../../implementations/forms/useFormMode";
import type {LookupFactories} from "../../implementations/lookup/useLookup";

import {defineComponent, toRefs, watch, computed, ref} from "vue";
import {createVuelidationRules} from "../../implementations/forms/createVuelidationRules";
import {providePropertyContexts} from "../../implementations/forms/usePropertyContext";
import {useFormSubmission} from "../../implementations/forms/useFormSubmission";
import {provideLookupFactories} from "../../implementations/lookup/useLookup";
import {provideValidation} from "../../implementations/forms/useValidation";
import {provideUndoRedo} from "../../implementations/forms/useUndoRedo";
import {provideFormMode} from "../../implementations/forms/useFormMode";
import {provideEntity} from "../../implementations/useEntity";
import {formPropsOptions} from "../../utils/forms";
import AunoaFormGroup from "./AunoaFormGroup.vue";
import {useVuelidate} from "@vuelidate/core";


interface FormProps {
    autocomplete: boolean;
    entity: any;
    entityModel: Entities.Model.Property[];
    formModel: Forms.Model.Form;
    lookupFactories: LookupFactories;
    mode: FormMode;
    onSubmit: SubmitFunc;
}

interface SubmitEvent extends Event {
    submitter: HTMLElement;
}

export default defineComponent({
    name: "AunoaForm",
    components: {
        AunoaFormGroup
    },
    props: {
        ...formPropsOptions
    },
    emits: ["update:isSubmitted", "update:hasDirtyChanges"],
    setup(props, {emit}) {

        const {
            lookupFactories,
            entity: _entity,
            entityModel: _entityModel,
            formModel: _formModel,
            mode: _mode,
            readOnly: _readOnly,
            //autocomplete
        } = toRefs(props);

        const rules = ref({});
        const isBusy = ref(false);
        const failedCount = ref(0);
        const successCount = ref(0);
        const formElement = ref<HTMLFormElement>();
        const formValidated = computed(() => !isBusy.value && (successCount.value>0 || failedCount.value>0));

        watch([_entityModel, _formModel], ([entityModel, formModel]) => {
            rules.value = createVuelidationRules(entityModel, formModel);
        }, {immediate: true});

        provideLookupFactories(lookupFactories);
        provideFormMode(_mode);
        provideEntity(_entity);
        providePropertyContexts(_entityModel, _readOnly);
        
        const v = useVuelidate(rules, _entity);
        const isInvalid = computed(() => v.value && v.value.$dirty && v.value.$invalid);
        provideValidation(<any>v);

        const validate = () => new Promise<boolean>((resolve, reject) => {
            if (v.value?.$validate) {
                v.value.$validate().then(resolve).catch(reject);
            } else {
                resolve(true);
            }
        });

        const {hasDirtyChanges, reset: resetUndoRedo} = provideUndoRedo();
        watch(hasDirtyChanges, v => emit("update:hasDirtyChanges", v), {immediate: true})

        const {submit, isSubmitted} = useFormSubmission(_entity);
        watch(isSubmitted, v => emit("update:isSubmitted", v), {immediate: true})
        
        // const isInvalid = ref(false);
        // watch(v, value => {
        //     isInvalid.value = value && value.$dirty && value.$invalid;
        // }, {immediate: true, deep: true})
        // 
        // const formValidated = ref(false);
        // watch([isBusy, failedCount, successCount], ([busy, failed, success]) => {
        //     formValidated.value = !busy && (success || failed);
        // }, {immediate: true})
        // 
        // const formClass = computed(() => ({
        //     "form-invalid": isInvalid.value,
        //     "form-validated": formValidated.value
        // }))
        
        watch(isInvalid, value=> formElement.value?.classList.toggle("form-invalid", value), {immediate:true});
        watch(formValidated, value=> formElement.value?.classList.toggle("form-validated", value), {immediate:true});

        const formClass = computed(() => ({
        }))

        const slotAttrs = computed(() => ({
            //validationErrorCount: v.value.$errors.length,
            hasDirtyChanges: hasDirtyChanges.value,
            isSubmitted: isSubmitted.value
        }));

        const onSubmitRequest = (event: SubmitEvent) => {
            //console.log(event);
            isBusy.value = true;
            validate()
                .then(valid => {
                    if (valid) {
                        successCount.value++;
                        failedCount.value = 0;
                        v.value?.$reset();

                        const onSubmit = props.onSubmit;

                        if (onSubmit) {
                            const option = event.submitter.dataset["submitOption"];
                            submit(e => onSubmit(e, option))
                                .then(() => resetUndoRedo())
                                .catch();
                        } else if (!event.submitter?.ariaDisabled) {
                            const emitPromise = (<any>event.submitter)?.$$.emitClick?.(<any>event);
                            if (emitPromise) {
                                // LGB: had to comment out
                                // event.submitter.focus();
                                emitPromise
                                    .catch()
                                    .finally(() => resetUndoRedo());
                            } else {
                                resetUndoRedo();
                            }
                        }

                    } else {
                        successCount.value = 0;
                        failedCount.value++;
                    }
                })
                .finally(() => setTimeout(() => {
                    isBusy.value = false;
                }));

        }

        return {
            v,
            formClass,
            slotAttrs,
            formElement,

            onSubmitRequest
        }
    }
});

</script>