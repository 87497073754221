﻿<template>
    <component ref="container" :is="as">
        <slot />
    </component>
</template>

<script lang="ts">

import {
    defineComponent,
    PropType,
    WatchStopHandle,
    Ref,
    ref,
    toRefs,
    unref,
    watch,
    nextTick,
    onMounted,
    onBeforeUpdate,
    onUpdated,
    onBeforeUnmount,
    markRaw
} from "vue";

import PerfectScrollbar from "perfect-scrollbar";


const eventMappings: Record<string, string> = {
    "ps-scroll-y": "vscroll",
    "ps-scroll-x": "hscroll",
    "ps-scroll-up": "vscroll-to-start",
    "ps-scroll-down": "vscroll-to-end",
    "ps-scroll-left": "hscroll-to-start",
    "ps-scroll-right": "hscroll-to-end",
    "ps-y-reach-start": "vscroll-at-start",
    "ps-y-reach-end": "vscroll-at-end",
    "ps-x-reach-start": "hscroll-at-start",
    "ps-x-reach-end": "hscroll-at-end"
}

// todo: include RTL detection

export default defineComponent({
    name: "AunoaScrollbar",
    props: {
        as: {
            type: String,
            default: "div"
        },
        options: {
            type: Object as PropType<PerfectScrollbar.Options>,
            default: undefined
        }
    },
    emits: [
        "vscroll",
        "vscroll-to-start",
        "vscroll-to-end",
        "vscroll-at-start",
        "vscroll-at-end",
        "hscroll",
        "hscroll-to-start",
        "hscroll-to-end",
        "hscroll-at-start",
        "hscroll-at-end"
    ],
    setup(props, {emit}) {

        const {options} = toRefs(props);
        const container = ref<HTMLElement>();

        const create = (element: HTMLElement) => {
            let scrollbar = new PerfectScrollbar(element, options.value);

            const optionsWatchStopHandle = watch(options, newOptions => {
                scrollbar.destroy();
                scrollbar = new PerfectScrollbar(element, newOptions);
            }, {deep: true});

            const onEvent = (event: Event) => emit(<any>eventMappings[event.type], event);
            Object.keys(eventMappings).forEach(psEvent => element.addEventListener(psEvent, onEvent));

            onUpdated(() => nextTick(() => scrollbar.update()));
            return () => {
                Object.keys(eventMappings).forEach(psEvent => element.removeEventListener(psEvent, onEvent));
                optionsWatchStopHandle();
                scrollbar.destroy();
            }
        }

        onMounted(() => {
            const dispose = create(<HTMLElement>container.value);
            onBeforeUnmount(dispose);
        });

        return {
            container
        }
    }
});

</script>

