﻿import { singleton, useI18nController } from "bootstrap-aunoa";
import { onBeforeUnmount } from "vue";

const useDataGridI18nController = () => singleton("$$AUNOA-DATA-GRID-I18N-CONTROLLER", () => useI18nController({
    loadMessage: locale => {
        //return axios.get(`../../locales/au/${locale}.json`).then(result => result.data);
        console.log("loading", `../../locales/dg/${locale}.json`);
        return import(`../../locales/dg/${locale}.json`);
    }
}));

export const useDataGridI18n = () => {
    const {useI18n, registerI18n} = useDataGridI18nController();
    const i18n = useI18n();
    const unregister = registerI18n(i18n);
    onBeforeUnmount(unregister);
    return {
        ...i18n
    }
}