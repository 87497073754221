﻿<template>
    <aunoa-card v-bind="cardAttrs">
        <aunoa-card-header :icon="icon" :text="title(t)" :to="'/settings/pos/configuration'"/>

        <div
            v-if="total===0"
            class="card-body text-center text-muted small"
            v-text="t('Mate.NoChangesInLastDays',{days})"
        />

        <div class="list-group list-group-flush small" v-else-if="total>0">
            <div class="list-group-item text-center text-muted" v-text="t('Mate.ChangesInLastDays',{days})" />
            <button
                class="list-group-item list-group-item-action"
                v-for="{modifiedAt, path, value, previousValue, oid, userName, state, scope} in changes"
                :class="{'selected':selected===oid}"
                @click="onToggle(oid)">
                
                <span class="d-block">
                    <span class="text-muted" v-text="inPast(modifiedAt)" />
                    <span
                        v-if="selected===oid"
                        class="float-end ps-2"
                        v-text="formatDate(modifiedAt,'PPp')"
                    />
                </span>
                <span class="d-block pb-2" v-if="selected===oid">
                    <span class="text-muted" v-text="t('Mate.DeletedFrom')" v-if="state==='deleted'" />
                    <span class="text-muted" v-text="t('Mate.CreatedBy')" v-else-if="previousValue===null" />
                    <span class="text-muted" v-text="t('Mate.ModifiedFrom')" v-else />
                    <span class="ps-1" v-text="userName" />
                    
                    <span class="float-end ps-2 text-muted">
                        <i class="fad fa-globe text-info" v-if="scope.length===1"/>
                        <span class="pe-1" v-text="scope[2]" v-if="scope.length>=3" />
                        <i class="flag-icon shadow-sm" :class="getCountryFlag(scope[1])" v-if="scope.length>=2" />
                        <span class="ps-1" v-text="scope[3]" v-if="scope.length>=4" />
                        <span v-text="'.'" v-if="scope.length>=5" />
                        <span v-text="scope[4]" v-if="scope.length>=5" />
                    </span>
                </span>
                <span v-text="path[path.length-1]" />
                <span class="float-end text-primary ps-2" :style="{
                    'max-width': '100%',
                    'text-decoration' : state==='deleted' ? 'line-through' : 'none'
                    }">
                    <i class="fad fa-trash" v-if="(value==='True' || value==='False') && state==='deleted'" />
                    <i class="far fa-check-square" v-else-if="value==='True'" />
                    <i class="far fa-square" v-else-if="value==='False'" />
                    <span v-else v-text="value" />
                </span>
                <span class="text-muted">
                    <span v-text="' ('" />
                    <template v-for="(t,i) in path">
                        <span class="px-1" v-if="i>0 && i<path.length-1" v-text="'/'" />
                        <span v-if="i<path.length-1" v-text="t" />
                    </template>
                    <span v-text="') '" />
                </span>
                <span class="d-block" v-if="selected===oid && state!=='deleted' && previousValue!==null">
                    <span class="text-muted" v-text="t('Mate.WithPreviousValue')" />
                    <span class="float-end ps-2" style="max-width: 100%">
                        <i class="far fa-check-square" v-if="previousValue==='True'" />
                        <i class="far fa-square" v-else-if="previousValue==='False'" />
                        <span v-else v-text="previousValue" />
                    </span>
                </span>
            </button>
            <div
                v-if="more>0"
                class="list-group-item text-muted text-end"
                v-text="'&hellip;'+t('Mate.AndCountMore',{count:more})"
            />

        </div>
        <div class="card-body" v-else />


    </aunoa-card>
</template>

<script lang="ts">

import type {RecentChange} from "@tnt/mate-api";

import {useAunoaI18n, usePackeryItem, formatDate, AunoaCard, AunoaCardHeader} from "bootstrap-aunoa";
import {defineComponent, ref, toRefs, nextTick, watch} from "vue";
import {useDashboardCard} from "../useDashboard";
import {useAuth} from "../../../utils/useAuth";
import {useConfigApi} from "@tnt/mate-api";

const changes = ref<RecentChange[]>([]);
const total = ref(-1);
const more = ref(0);

const meta = {
    createdAt: "2021-04-22T00:00:00.000Z",
    icon: "fad fa-tools",
    title: (t:any) => t("Sidebar.Settings")
}

export default defineComponent({
    $$meta: meta,
    name: "ConfigChangesCard",
    components: {AunoaCard, AunoaCardHeader},
    props: {
        days: {
            type: Number,
            default: 90
        },
        recent: {
            type: Number,
            default: 5
        }
    },
    setup(props) {

        const {days, recent} = toRefs(props);

        const {t} = useAunoaI18n();
        const {fit} = usePackeryItem();
        const selected = ref<number>();

        const {accessToken} = useAuth();
        const {getRecentChanges} = useConfigApi(accessToken);

        const card = useDashboardCard({
            link: "/settings/pos/configuration",

            update: () =>
                getRecentChanges(days.value, recent.value).then(/*async*/  result => {
                    
                    //await new Promise(resolve => setTimeout(resolve, 5000));
                    
                    total.value = result.recentTotal
                    changes.value = result.recentTopChanges;
                    more.value = total.value - changes.value.length;
                    nextTick(fit);
                })
        });

        const inPast = (date: any) => formatDate(date, {distance: {addSuffix: true}});

        watch([days, recent], card.update);

        const getCountryFlag = (country: string) =>
            `flag-icon-${country}`.toLowerCase();

        const onToggle = (oid: number) => {
            selected.value = selected.value !== oid ? oid : 0;
            nextTick(fit);
        }

        return {
            t,
            more,
            total,
            changes,
            ...meta,
            ...card,
            selected,
            formatDate,
            getCountryFlag,
            onToggle,
            inPast
        }
    }
});

</script>