﻿<template>
    <component class="deco" :is="as">
        <span v-for="s in segments" :class="s.class">{{s.text}}</span>
    </component>
</template>

<script lang="ts">

import {computed, defineComponent, toRefs} from "vue";
import {toByteSizeSegments} from "../implementations/decoration/numberDecoration";

export default defineComponent({
    name: "AunoaAbbreviatedValue",
    props: {
        as: {
            type: String,
            default: "span"
        },
        value: {
            type: Number,
            default: undefined
        },
        unit: {
            type: String,
            default: undefined
        }
    },
    setup(props) {
        
        const {value, unit} = toRefs(props);
        
        const segments = computed(() =>{
            switch (unit.value) {
                case "bytes":
                    return toByteSizeSegments(<any>value.value);
            }
            return [];
        });
        
        
        return {
            segments
        }
    }
});


</script>
