﻿import {isString} from "../../utils/inspect";
import {useAunoaI18n} from "../../utils/useAunoaI18n";

export const useVuelidationErrorMessages = (detailed = false) => {

    const {t} = useAunoaI18n();

    const ensureValue = (value: any) =>
        isString(value) && value.length > 12
            ? value.substr(0, 11) + "…"
            : value

    const valueLength = (value: any) =>
        isString(value) 
            ? value.length
            : 0

    const message = (error: any, fieldLabel: string, value: any) => {
        const key = `Aunoa.Validation.Message.${error.$validator}${detailed ? "Detailed" : ""}`
        const translation = t(key, {
            field: fieldLabel,
            value: ensureValue(value),
            valueLength: valueLength(value),
            ...error.$params
        });
        return key === translation
            ? error.$message
            : translation;
    }

    return {
        message
    }

}