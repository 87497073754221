import type {InjectionKey, Ref} from "vue";
import type {CardMetadata, TileSize} from "./cards";
import type {CardStorage, DashboardStorage} from "@tnt/mate-api";
import type {PromisableEvent} from "bootstrap-aunoa";

import {computed, unref, ref, inject, readonly, provide, watch, markRaw, onBeforeUnmount, onMounted, onUpdated, nextTick} from "vue";
import {useRoutePermissions} from "../../utils/useRoutePermissions";
import {useUserApi} from "@tnt/mate-api";
import cardDefinitions from "./cards";

interface Tile {
    initialUpdate: boolean;
    canUpdate: boolean;
    update(): Promise<any>;
}

interface TileOptions {
    initialUpdate?: boolean;
    link?: string;
    update?(): Promise<any>;
    //onClose?()
}

interface Dashboard {
    editMode: Ref<boolean>;
    addTile(tile: Tile): void;
    removeTile(tile: Tile): void;
}


export interface Card {
    //key: string;
    name: string;
    component: any;
    tileClass: string;
    params?: any;
    //icon?: string;
    //title?(t: any): string;
}

type CardDict = Record<string, Card>;

type CardFactory = (name: string, definition: CardMetadata) => CardDict;

const tileSizes: Record<TileSize, string> = {
    "medium": "col-xs-24 col-sm-24 col-md-12 col-lg-8 col-xl-6 col-xxxl-4 col-uw1-3 col-uw2-2"
}

const defaultFactory = (name: string, metadata: CardMetadata): CardDict =>
    ({
        [name]: {
            name,
            component: markRaw(metadata.component),
            tileClass: tileSizes[metadata.tileSize]
        }
    })

const INJECTION_KEY: InjectionKey<Dashboard> = Symbol("TNT_DASHBOARD");

export const provideDashboard = (dashboardStorage: Ref<DashboardStorage | undefined>, accessToken: Ref<string | undefined>) => {

    let isMounted = false;
    const tiles: Tile[] = [];
    const aunoaPackery = ref();
    const editMode = ref(false);
    const cardDict = ref<CardDict>({});
    const initialUpdates: Promise<any>[] = [];
    //const cardStorage = useLocalStorage().items("dashboard").subscribeObject<DashboardStorage>("cards");

    const layout = () => aunoaPackery?.value?.layout();

    const {useDashboardStore} = useUserApi(accessToken);

    const dashboard: Dashboard = {
        editMode,
        addTile: (tile: Tile) => {
            if (tile.canUpdate && tile.initialUpdate) {
                const updatePromise = tile.update();
                //console.log("addTile", isMounted, tile);

                if (!isMounted) {
                    initialUpdates.push(updatePromise);
                } else {
                    //nextTick(layout);
                }
            }
            tiles.push(tile);
        },
        removeTile: (tile: Tile) => {
            const index = tiles.indexOf(tile);
            tiles.splice(index, 1);
        }
    };
    provide(INJECTION_KEY, dashboard);

    const _updateTiles = () => tiles.filter(c => c.canUpdate).map(c => c.update());
    const _updateAll = () => Promise.all(_updateTiles()).then(() => nextTick(layout))

    const update = (event: PromisableEvent) => event.setPromise(_updateAll(), {successDuration: true});

    onMounted(() => {
        Promise
            .all(initialUpdates)
            .then(layout)
            .catch();
        isMounted = true;
    });

    const updateCards = () =>
        cardDict.value = dashboardStorage.value && Object.keys(dashboardStorage.value.cards).length > 0
            ? Object
                .entries(dashboardStorage.value.cards)
                .reduce((dict, [key, si]) => {
                    const metadata = cardDefinitions[si.name];
                    if (metadata) {
                        dict[key] = {
                            name: si.name,
                            component: markRaw(metadata.component),
                            tileClass: tileSizes[metadata.tileSize],
                            params: si.params
                        };
                    }
                    return dict;
                }, <CardDict>{})
            : Object
                .entries(cardDefinitions)
                .reduce((dict, [name, metadata]) => {
                    if (metadata.required) {
                        dict[name] = {
                            name: name,
                            component: markRaw(metadata.component),
                            tileClass: tileSizes[metadata.tileSize]
                        };
                    }
                    return dict;
                }, <CardDict>{});

    updateCards();

    const _add = (name: string, metadata: CardMetadata, key?: string, params?: any) => {
        key = key || (metadata.multiInstance
            ? `${name}-${Date.now().toString(16)}`
            : name);
        cardDict.value[key] = {
            name,
            component: markRaw(metadata.component),
            tileClass: tileSizes[metadata.tileSize],
            params
        };
    }

    const add = (name: string, metadata: CardMetadata) => {
        if (!metadata.variants) {
            _add(name, metadata);
        }
    }

    const addVariant = (name: string, metadata: CardMetadata, variant: any) =>
        _add(name, metadata, name + "-" + variant.keySuffix, variant.params);

    const remove = (key: string) =>
        delete cardDict.value[key];

    const usageCount = (name: string, metadata: CardMetadata) =>
        Object
            .entries(cardDict.value)
            .filter(([key, card]) => card.name === name).length;

    const usageCountStr = (name: string, metadata: CardMetadata) => {
        const count = usageCount(name, metadata);
        return count && metadata.multiInstance ? count + "×" : undefined;
    }

    const isVisible = (name: string, metadata: CardMetadata) => !metadata.required

    const isDisabled = (name: string, metadata: CardMetadata) => {
        if (!metadata.multiInstance) {
            if (!!metadata.variants) {
                return false
            }
            if (usageCount(name, metadata) > 0) {
                return true;
            }
        }
        return false;
    }

    const isDisabledVariant = (name: string, metadata: CardMetadata, variant: any) =>
        !!cardDict.value[name + "-" + variant.keySuffix];

    const createStorage = (date?: Date) => {
        const elements = <HTMLElement[]>(<any>aunoaPackery.value.packery).getItemElements();
        return <DashboardStorage>{
            version: 1,
            storedAt: date,
            cards: elements
                .map(element => <string>element.dataset["key"])
                .reduce((storageDict, key) => {
                    const card = cardDict.value[key];
                    if (card) {
                        storageDict[key] = <CardStorage>{
                            name: card.name,
                            params: card.params
                        };
                    }
                    return storageDict;
                }, <Record<string, CardStorage>>{})
        };
    }

    let snapshot: DashboardStorage;

    const storeChanges = () => {
        const storage = createStorage(new Date());
        return useDashboardStore()
            .set(storage)
            .then(() => {
                dashboardStorage.value = storage;
            })
    };

    const onStartEdit = () => {
        editMode.value = true;
        snapshot = createStorage();
    }

    const onSaveChanges = (event: PromisableEvent) =>
        event
            .setPromise(storeChanges(), {minDuration: true})
            .catch()
            .finally(() => editMode.value = false);

    const onCancelChanges = () => {
        editMode.value = false;
        if (JSON.stringify(snapshot) !== JSON.stringify(createStorage())) {
            cardDict.value = {};
            nextTick(updateCards)
                .catch();
        }
    };

    return {
        cardDict,
        cardDefinitions,
        editMode,
        aunoaPackery,
        isDisabledVariant,
        usageCountStr,
        usageCount,
        isDisabled,
        isVisible,

        remove,
        update,
        layout,
        add,
        addVariant,

        onStartEdit,
        onSaveChanges,
        onCancelChanges,
    }
}

export const useDashboardCard = (options: TileOptions = {}) => {
    options = options || {};

    const dashboard = inject(INJECTION_KEY, {
        editMode: ref(false),
        addTile: () => {
        },
        removeTile: () => {
        }
    });

    const updating = ref(false);
    const isDanger = ref(false);

    const background = () => {
        if (updating.value) {
            return "bg-loading";
        }
        if (isDanger.value) {
            return "bg-danger";
        }
        return undefined;
    }


    const tile: Tile = {
        initialUpdate: options?.initialUpdate !== false,
        canUpdate: !!options.update,
        update: () => {
            updating.value = true;
            return (options.update || Promise.resolve)().finally(() => updating.value = false);
        }
    }

    const permitted = computed(() =>
        options.link
            ? useRoutePermissions(options.link).permitted.value
            : true);

    dashboard.addTile(tile);
    onBeforeUnmount(() => dashboard.removeTile(tile));

    const cardAttrs = computed(() => ({
        "editMode": dashboard.editMode.value,
        "class": [
            "shadow-sm",
            background(),
            permitted.value ? undefined : "not-permitted"
        ].filter(Boolean)
    }));


    return {
        editMode: readonly(dashboard.editMode),
        updating,
        update: tile.update,
        link: options.link,
        permitted,
        isDanger,
        cardAttrs
    }
}