﻿<template>
    <nav class="navbar navbar-title navbar-expand">
        <aunoa-container>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler" aria-controls="navbarToggler"
                    aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon" />
            </button>
            <div class="collapse navbar-collapse" id="navbarToggler">
                <ul class="navbar-nav">
                    <aunoa-nav-item class="text-truncate enlarge" v-if="route.path!=='/'">
                        <template v-slot:link>
                            <i :class="pageStateIcon" class="me-2" v-if="pageStateIcon" />
                            <i :class="pageIcon" class="text-primary" v-if="pageIcon" />
                            <span class="page-header" v-text="pageHeader" style="font-size: 1.25rem;line-height: 1" />
                            <sup class="fad fa-stars fa-flip-horizontal text-primary" v-if="favorite" />
                        </template>
                        <aunoa-dropdown-check-item
                            :text="t('Aunoa.Favorites.MarkAsFavorite')"
                            end-icon="fad fa-stars fa-flip-horizontal"
                            v-model:checked="favorite"
                        />
                    </aunoa-nav-item>
                    <li class="nav-item" v-else>
                        <div class="nav-text text-truncate">
                            <span class="page-header" v-text="rootPageHeader" />
                        </div>
                    </li>
                </ul>
                <ul class="navbar-nav ms-auto">
                    <li class="nav-item" v-if="0">
                        <a class="nav-link px-3" href="#">
                            <i class="fad fa-search" />
                        </a>
                    </li>
                    <slot name="navItems" />
                    <aunoa-user-nav-item
                        drop-menu-class="dropdown-menu-end"
                        :user="user"
                        :default-gravatar-image="defaultGravatarImage"
                        @lock="$emit('lock')"
                    >
                        <template v-slot v-if="$slots.userDropdownItems">
                            <slot name="userDropdownItems" />
                        </template>
                    </aunoa-user-nav-item>
                </ul>
            </div>
        </aunoa-container>
    </nav>
</template>

<!--
<li class="nav-item">
    <a class="nav-link text-nowrap px-3 px-md-2" href="#">
        <i class="flag-icon flag-icon-de" />
        <span class="ms-2 d-none d-md-inline">TS-DE</span>
    </a>
</li>
-->


<script lang="ts">

import type {PropType} from "vue";
import type {DefaultGravatarImage} from "../components/AunoaGravatar.vue";

import AunoaDropdownCheckItem from "../components/dropdown/AunoaDropdownCheckItem.vue";
import {defineComponent, computed, onMounted, onBeforeUnmount} from "vue";
import AunoaUserNavItem from "../components/AunoaUserNavItem.vue";
import {provideFavoritePages} from "../utils/useFavoritePages";
import AunoaNavItem from "../components/nav/AunoaNavItem.vue";
import AunoaContainer from "../components/AunoaContainer.vue"
import {useAunoaI18n} from "../utils/useAunoaI18n";
import UserMixin from "../mixins/UserMixin";
import {useRoute} from "vue-router";

const toggleHasTitleBar = (value: boolean) => document.body.classList.toggle("has-title-bar", value);

export default defineComponent({
    name: "AunoaTitleBar",
    mixins: [UserMixin],
    components: {
        AunoaDropdownCheckItem,
        AunoaUserNavItem,
        AunoaContainer,
        AunoaNavItem
    },
    props: {
        pageIcon: {
            type: String,
            default: undefined,
        },
        pageStateIcon: {
            type: String,
            default: undefined,
        },
        pageHeader: {
            type: String,
            default: undefined
        },
        rootPageHeader: {
            type: String,
            default: undefined
        },
        defaultGravatarImage: {
            type: String as PropType<DefaultGravatarImage>,
            default: "blank"
        }
    },
    emits: ["lock"],
    setup(props) {

        const {t} = useAunoaI18n();
        const route = useRoute();

        onMounted(() => toggleHasTitleBar(true));
        onBeforeUnmount(() => toggleHasTitleBar(false));

        const {containsRoute, addRoute, removeRoute} = provideFavoritePages(<any>props.user);
        const favorite = computed<boolean>({
            get: () => containsRoute(route),
            set: (mark: boolean) => mark ? addRoute(route) : removeRoute(route)
        });

        return {
            t,
            route,
            favorite
        }
    }
});

</script>
