﻿<template>
    <div
        ref="modalElement"
        class="modal fade"
        tabindex="-1"
        :aria-labelledby="ariaLabelId"
        aria-hidden="true"
    >
        <div :class="dialogClass">
            <aunoa-form
                v-if="visible"
                class="modal-content"
                :autocomplete="autocomplete"
                :entity="entity"
                :entity-model="entityModel"
                :form-model="formModel"
                :lookup-factories="lookupFactories"
                :mode="mode"
            >
                <div class="modal-header shadow-sm" v-if="$slots.header">
                    <slot name="header" :title="title" />
                </div>
                <div class="modal-header" :class="headerClass" v-else-if="title || !disableCloseButton">
                    <h5 class="modal-title" v-text="title" :id="ariaLabelId" />
                    <button
                        v-if="!disableCloseButton"
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        @click="onCancel"
                    />
                </div>
                <div class="modal-body p-0" ref="bodyElement">
                    <aunoa-tab-control
                        :can-toggle-mode="true"
                        class="form-inline inner-border-solid"
                        nav-position="start"
                        active-style="line"
                        nav-class="minw-8r"
                        content-class="mh-r16"
                    >
                        <aunoa-tab-pane
                            v-for="([id, group]) in Object.entries(formModel)"
                            class="container-fluid pt-2 pb-4 px-3 ms-0"
                            :id="id"
                            :link-text="ensureTextTranslated(group.label)"
                        >
                            <aunoa-form-group
                                :group-model="group"
                                as="section"
                                row-class="row"
                                column-class="col-lg col-12"
                                field-class="mb-3"
                            >
                                <template v-slot:header>
                                    <h6 class="mb-3" v-text="ensureTextTranslated(group.label)" />
                                </template>
                            </aunoa-form-group>
                        </aunoa-tab-pane>
                    </aunoa-tab-control>
                </div>
                <div class="modal-footer p-0" :class="footerClass">
                    <aunoa-nav class="p-0 m-0">
                        <aunoa-nav-item
                            type="submit"
                            :text="ensureTextTranslated(applyText||'@@Aunoa.Command.Apply')"
                            @click="onApply"
                        />
                        <aunoa-nav-item
                            :text="ensureTextTranslated(cancelText||'@@Aunoa.Command.Cancel')"
                            @click="onCancel"
                        />
                    </aunoa-nav>

                </div>
            </aunoa-form>
        </div>
    </div>

</template>

<!--
<aunoa-nav class="p-0 m-0 w-100">
    <aunoa-nav-item icon="grip" class="pe-1" disabled tabindex="-1" />
    <aunoa-nav-item
        type="submit"
        :text="ensureTextTranslated(applyText||'@@Aunoa.Command.Apply')"
        @click="onApply"
    />
    <aunoa-nav-item
        :text="ensureTextTranslated(cancelText||'@@Aunoa.Command.Cancel')"
        @click="onCancel"
    />
</aunoa-nav>
-->

<script lang="ts">


import type {PromisableEvent} from "../types";

import {defineComponent, ref, computed} from "vue";
import AunoaForm from "../components/form/AunoaForm.vue";
import {getVisibilityEventNames} from "../utils/useVisibilityEvents";
import AunoaFormGroup from "../components/form/AunoaFormGroup.vue";
import {modalPropsOptions, useModal} from "../utils/useModal";
import AunoaTabPane from "../components/tab/AunoaTabPane.vue";
import AunoaNavItem from "../components/nav/AunoaNavItem.vue";
import AunoaTabControl from "../controls/AunoaTabControl.vue";
import AunoaNav from "../components/nav/AunoaNav.vue";
import {formPropsCoreOptions} from "../utils/forms";
import {useAunoaI18n} from "../utils/useAunoaI18n";


export default defineComponent({
    name: "AunoaModalForm",
    components: {
        AunoaForm, AunoaFormGroup, AunoaTabControl, AunoaTabPane, AunoaNav, AunoaNavItem
    },
    props: {
        ...modalPropsOptions,
        ...formPropsCoreOptions,
        title: {
            type: String,
            default: undefined
        },
        applyText: {
            type: String,
            default: undefined
        },
        cancelText: {
            type: String,
            default: undefined
        },
        disableCloseButton: {
            type: Boolean,
            default: false
        },
        headerClass: {},
        footerClass: {}
    },
    emits: ["apply", ...getVisibilityEventNames("modal")],
    setup(props, {attrs, emit}) {

        const entity = ref();
        const bodyElement = ref();

        const {ensureTextTranslated, t} = useAunoaI18n()
        const modal = useModal(<any>props, emit);

        const ariaLabelId = computed(() =>
            attrs.id && modal.visible.value && props.title
                ? attrs.id + "Label"
                : undefined);

        const onApply = (event: PromisableEvent) => {
            const classList = bodyElement.value.classList;
            const emitPromise = new Promise<{ busyPromise: Promise<any> }>(resolve => {
                const setPromise = event.setPromise;
                event.setPromise = (promise, options) => {
                    classList.toggle("bg-loading", true);
                    promise
                        .catch()
                        .finally(() => {
                            classList.toggle("bg-loading", false);
                        });
                    const busyPromise = setPromise(promise, options);
                    resolve({busyPromise});
                    return busyPromise;
                }
                resolve({busyPromise: Promise.resolve()});
            })
            emit("apply", event, entity.value);
            emitPromise
                .then(({busyPromise}) => {
                        busyPromise.then(() => {
                            modal.hide();
                        }).catch();
                    }
                );
        }

        const onCancel = (event: PromisableEvent) =>
            modal.hide();

        const show = (newEntity: any) => {
            entity.value = newEntity;
            modal.show();
        }


        return {
            t,
            ...modal,
            bodyElement,
            ensureTextTranslated,
            ariaLabelId,
            entity,
            show,

            onApply,
            onCancel
        }
    }
});

</script>


