import {useLocalStorage} from "./storage/useLocalStorage";
import {singleton} from "./singletons";

export const useTestMode = () => singleton("$$AUNOA-TEST-MODE", () => {

    const testMode = useLocalStorage()
        .items("test-mode")
        .subscribeBoolean("enabled", false);

    return {
        testMode
    }
})