﻿import {useMateApi} from "./useMateApi";

export const usePingApi = () => {

    const {endpoint} = useMateApi("ANONYMOUS");
    const api = endpoint("/api/v1");

    const ping = () => api.get<void>({}, {url: "/ping"});

    return {
        ping
    }
}
