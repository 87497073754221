<template>
    <aunoa-modal-dialog
        ref="modalDialog"
        id="modalCodeEditor"
        :size="size"
        :icon="icon"
        :title="title"
        :focus="true"
        :disable-close-button="false"
        :has-commands-bar="true"
        :has-footer="true"
        :backdrop="backdrop"
        :centered="true"
        footer-class="p-0"
        body-class="p-0"
        @modal-hidden="onHidden"
        @modal-shown="cm.editor.focus()"
        v-slot:default="{shown}"
    >
        <aunoa-code-mirror
            ref="cm"
            class="modal-display-inline"
            :disable-panes="true"
            :content="content"
            :config="config"
            :style="style"
        >
            <teleport to="#modalCodeEditor .modal-nav-bar" v-if="shown">
                <aunoa-code-mirror-command-nav-items />
            </teleport>
            <teleport to="#modalCodeEditor .modal-footer" v-if="shown">
                <aunoa-nav class="m-0">
                    <aunoa-code-mirror-footer-nav-items />
                </aunoa-nav>
            </teleport>
        </aunoa-code-mirror>
    </aunoa-modal-dialog>
</template>

<script lang="ts">

import type {ModalDialog, ModalSize} from "bootstrap-aunoa";
import type {ModalOptions} from "../utils/useModalCodeEditor";
import type {CmConfiguration} from "@bootstrap-aunoa/codemirror";

import {defineComponent, ref, computed, defineAsyncComponent} from "vue";
import {AunoaNav, AunoaModalDialog} from "bootstrap-aunoa";

const AunoaCodeMirror = defineAsyncComponent({
    loader: () =>
        import("@bootstrap-aunoa/codemirror")
            .then(lib => lib.AunoaCodeMirror)
})

const AunoaCodeMirrorCommandNavItems = defineAsyncComponent({
    loader: () =>
        import("@bootstrap-aunoa/codemirror")
            .then(lib => lib.AunoaCodeMirrorCommandNavItems)
})

const AunoaCodeMirrorFooterNavItems = defineAsyncComponent({
    loader: () =>
        import("@bootstrap-aunoa/codemirror")
            .then(lib => lib.AunoaCodeMirrorFooterNavItems)
})

export default defineComponent({
    name: "ModalCodeEditor",
    components: {
        AunoaNav,
        AunoaModalDialog,
        AunoaCodeMirror,
        AunoaCodeMirrorCommandNavItems,
        AunoaCodeMirrorFooterNavItems
    },
    setup: () => {

        const modalDialog = ref<ModalDialog>();
        const content = ref("");
        const config = ref<CmConfiguration>();

        const size = ref<ModalSize>("md");
        const icon = ref<string>();
        const title = ref<string>();

        const backdrop = ref<"static" | boolean>("static");

        let _resolve: any;
        let _reject: any;

        const open = (options: ModalOptions) =>
            new Promise<any>((resolve, reject) => {
                _resolve = resolve;
                _reject = reject;
                config.value = {
                    readOnly: options.readOnly,
                    lineNumbers: options.lineNumbers,
                    lineWrapping: options.lineWrapping,
                    languageMode: options.languageMode,
                    onClose: () => {
                        modalDialog.value?.hide();
                    }
                };
                size.value = options.modalSize || "xl";
                icon.value = options.modalIcon || "fad fa-code";
                title.value = options.modalTitle || "Code Editor";
                backdrop.value = options.modalSize === "fullscreen"
                    ? false
                    : options.readOnly
                        ? true
                        : "static";
                content.value = options.content;
                modalDialog.value?.show();
            })

        const onHidden = () => {
            config.value = undefined;
            content.value = "";
        }

        const style = computed(() => {
            if (size.value !== "fullscreen") {
                return {"height": "calc(96vh - 140px)"}
            }
            return undefined;
        });


        const cm = ref();

        return {
            cm,
            modalDialog,
            config,
            content,
            size,
            icon,
            title,
            backdrop,
            open,
            style,
            onHidden
        }
    }
});


</script>

