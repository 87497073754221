<template>
    <section class="plain-layout">
        <slot />
        <aunoa-toasts-control 
            class="top-0 start-50 translate-middle-x p-5"
            :messages="messages" 
        />
    </section>
</template>

<script lang="ts">

import AunoaToastsControl from "../controls/AunoaToastsControl.vue";
import {provideToasts} from "../utils/useToasts";
import {defineComponent} from "vue";

export default defineComponent({
    name: "PlainLayout",
    components: {
        AunoaToastsControl
        
    },
    setup: ()=> {

        const {messages} = provideToasts();

        return {
            messages
        }

    }
});



</script>

