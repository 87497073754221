﻿
type ReloginUser = () => Promise<void>;

let login: ReloginUser = Promise.resolve;

export const provideUserRelogin = (reloginUser: ReloginUser) => {
    login = reloginUser;
}

export const useUserRelogin = () => {

    return {
        requestUserLogin: login
    }
}