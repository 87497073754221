﻿<template>
    <span :class="statusClass" v-text="ensureTextTranslated(value)" />
</template>

<script lang="ts">

import {decorate} from "../implementations/decoration/decoration";
import {defineComponent, watch, PropType, ref, toRefs} from "vue";
import {useAunoaI18n} from "../utils/useAunoaI18n";
import {TextCasing, toCase} from "../utils/textCasing";
import {isPromise} from "../utils/inspect";

interface Lookup {
    resolve(record: any, key: any): any;
}

export default defineComponent({
    name: "AunoaLookupSpan",
    props: {
        resolved: {
            required: true
        },
        casing: {
            type: String as PropType<TextCasing>,
            default: undefined
        },
        decoration: {
            type: Array,
            default: undefined
        }
    },
    setup(props) {

        const value = ref<any>();
        const statusClass = ref<any>();

        const {
            resolved: _resolved,
            decoration: _decoration,
            casing: _casing
        } = toRefs(props);

        const {ensureTextTranslated} = useAunoaI18n();

        watch([_resolved, _decoration, _casing], ([resolved, decoration, casing]) => {

            const toDisplayCase = (text: any) => text && casing
                ? toCase(text, casing)
                : text;

            const setValue = (v: any) => {
                value.value = decoration
                    ? decoration.reduce(decorate, toDisplayCase(v))
                    : toDisplayCase(v);
            };
            
             if (isPromise(resolved)) {
                 value.value = undefined;
                 statusClass.value = "fad fa-spinner fa-pulse text-muted";
                 resolved.then(result => {
                     statusClass.value = undefined;
                     setValue(result);
                 }).catch(e => {
                     statusClass.value = undefined;
                     value.value = "-";
                 });
             } else {
                 setValue(resolved);
             }
            
        }, {immediate: true});

        return {
            ensureTextTranslated,
            statusClass,
            value
        }
    }
});

</script>
