﻿import type {Conditions} from "../../types";
import type {Ref, ComponentOptions, ComponentObjectPropsOptions} from "vue";

import {computed, defineComponent, ref, toRef, watch, nextTick} from "vue";
import AunoaFormLabel from "../../components/form/AunoaFormLabel.vue";
import AunoaErrorMessage from "../../components/form/AunoaErrorMessage.vue";
import {isDefined, isNumber, isUndefined, isUndefinedOrNull} from "../../utils/inspect";
import {getValueFromPropertyPath, setInPath} from "../../utils/properties";
import {useAunoaI18n} from "../../utils/useAunoaI18n";
import {useFieldValidation} from "./useValidation";
import {useFieldGroupInjection} from "./useFieldGroupInjection";
import {useUndoRedo} from "./useUndoRedo";
import {usePropertyContext} from "./usePropertyContext";
import {useEntity} from "../useEntity";
import {useFormMode} from "./useFormMode";
import {useConditionModel} from "../../utils/useConditionModel";

const UPDATE = "update:modelValue";

let uidCount = 181011;

export interface CommonFieldProps {
    modelValue: any;
    name: string;
    property: string;
    label: string | boolean;
    labelClass: any;
    fieldClass: any;
    lazy: boolean | undefined;
    nullable: boolean;
    rules: string;
    requiredModel: Conditions.Model.Condition;
    readonlyModel: Conditions.Model.Condition;
    disabledModel: Conditions.Model.Condition;
}

export const fieldProps: ComponentObjectPropsOptions<CommonFieldProps> = {
    modelValue: {},
    name: {
        type: String,
    },
    property: {
        type: String,
    },
    label: {
        type: [String, Boolean],
    },
    rules: {
        type: String,
    },
    fieldClass: {},
    labelClass: {},
    nullable: {
        type: Boolean,
        default: true
    },
    lazy: {
        type: Boolean,
        default: undefined
    },
    disabledModel: {
        type: [Boolean, Object],
        default: undefined
    },
    readonlyModel: {
        type: [Boolean, Object],
        default: undefined
    },
    requiredModel: {
        type: [Boolean, Object],
        default: undefined
    }
}

interface ValueOptions {
    setValue?: (v: any) => any;
    getValue?: (v: any) => any;
}

interface Options extends ValueOptions {
    inputType?: "number" | "hidden";
    onInput?: (e: InputEvent) => void;
    onBlur?: (e: FocusEvent) => void;
}

export const useFieldCore = (props: CommonFieldProps, attrs: any) => {

    const value = ref();
    const entityContext = ref();
    const {entity} = useEntity();
    const nameProp = toRef(props, "name");
    const linesProp = toRef(<any>props, "lines");
    const propertyProp = toRef(props, "property");

    const requiredModelProp = toRef(props, "requiredModel");
    const readonlyModelProp = toRef(props, "readonlyModel");
    const disabledModelProp = toRef(props, "disabledModel");
    const {propertyContext} = usePropertyContext(propertyProp);
    const {formMode} = useFormMode();

    const name = computed(() => nameProp.value || `af-${uidCount++}-${Date.now().toString(16)}`);

    watch(entity, e => entityContext.value = {Entity: e}, {immediate: true});
    const {bool: _required} = useConditionModel(entityContext, requiredModelProp);
    const {bool: _readonly} = useConditionModel(entityContext, readonlyModelProp);
    const {bool: _disabled} = useConditionModel(entityContext, disabledModelProp);

    const required = computed(() => !!fieldValidation.value?.required?.$invalid);
    //const required = computed(() => _required.value || propertyContext.value?.$required || _attrs.required || false);
    const readonly = computed(() => _readonly.value || propertyContext.value?.$readonly || attrs.readonly || false);
    const disabled = computed(() => _disabled.value || propertyContext.value?.$disabled || attrs.disabled || false);

    const rows = computed(() => linesProp.value > 1
        ? linesProp.value
        : propertyContext.value?.$multiline
            ? 4
            : undefined);

    const type = computed(() => !rows.value
        ? formMode.value === "search" && attrs.type === "text" ? "search" : attrs.type
        : undefined);

    const {fieldValidation} = useFieldValidation(propertyProp);
    useFieldGroupInjection(fieldValidation);

    const getLabel = () => {
        if (props.label === true) {
            return propertyContext.value?.$label || propertyProp.value;
        }
        if (props.label === " ") {
            return "&nbsp;";
        }
        return props.label;
    }

    const {locale, ensureTextTranslated} = useAunoaI18n();
    const label = computed(() => ensureTextTranslated(getLabel() as string));
    const placeholder = ref<string>();

    const fieldAttrs = computed(() => ({
        "class": [
            "form-field",
            props.fieldClass,
            fieldValidation.value?.$dirty && fieldValidation.value?.$invalid
                ? "form-field-invalid"
                : undefined
            //hasFocus.value ? "has-focus" : undefined,
            //errorMessage.value ? "is-invalid" : undefined,
        ].filter(Boolean)
    }));

    const labelAttrs = computed(() => ({
        "for": name.value,
        "class": ["form-label", "form-label-xs"],
        "label": label.value,
        "required": required.value,
        "readonly": readonly.value,
        //"invalid": !!fieldValidation.value?.$invalid
    }));

    const inputGroupAttrs = computed(() => ({
        "class": ["input-group", "input-group-sm"]
    }));

    const inputAttrs = computed(() => ({
        ...attrs,
        "type": type.value,
        "value": value.value
    }));

    const errorMessageAttrs = computed(() => ({
        "v": fieldValidation.value,
        "label": label.value,
        "value": value.value,
    }));

    watch(locale, _ => {
        setTimeout(() => {
            placeholder.value = ensureTextTranslated(attrs.placeholder);
        }, 0);
    }, {immediate: true})

    return {
        fieldValidation,
        propertyContext,
        entityContext,
        propertyProp,
        placeholder,
        required,
        readonly,
        disabled,
        formMode,
        entity,
        value,
        label,
        rows,
        type,
        name,

        labelAttrs,
        fieldAttrs,
        inputAttrs,
        inputGroupAttrs,
        errorMessageAttrs,
        //dropdownButtonAttrs
    }
}


export const useFieldValueCore = (props: CommonFieldProps, attrs: any, options: ValueOptions = {}) => {
    const field = useFieldCore(props, attrs);
    const {value, entity, propertyProp} = field;

    const setValue = options.setValue || (v => v);
    const getValue = options.getValue || (v => v);

    const modelValueProp = toRef(props, "modelValue");

    watch([modelValueProp, entity, propertyProp], ([v, e, p]) => {
        if (isDefined(v)) {
            value.value = setValue(v);
        } else {
            v = getValueFromPropertyPath(e, p);
            //if (p === "TenantOid" && v) {
            //    v = Number.parseFloat(v);
            //}
            value.value = setValue(v);
        }
    }, {immediate: true, deep: true});

    watch(value, v => {
        if (isUndefined(modelValueProp.value)) {
            const obj = entity.value;
            if (obj) {
                setInPath(obj, propertyProp.value, v, getValue);
            }
        }
    });

    return {
        ...field
    }
}

export const useField = (props: CommonFieldProps, ctx: any, options: Options = {}) => {

    const setValue = options.setValue || (v => v);
    const getValue = options.getValue || (v => v);

    const hidden = options.inputType === "hidden";

    const {attrs: _attrs, emit} = ctx;

    const _lazyProp = toRef(props, "lazy");
    const _nullableProp = toRef(props, "nullable");

    const _isLazy = computed(() => !!_lazyProp.value)

    const {addUndoRedo} = useUndoRedo();
    const {
        value, propertyProp, propertyContext,
        required, readonly, disabled,
        errorMessageAttrs, inputGroupAttrs, inputAttrs, fieldAttrs, labelAttrs,
        name, label, placeholder,
        formMode, rows, type,
        entity, fieldValidation
    } = useFieldValueCore(props, _attrs, options);

    const hasFocus = ref(false);


    const getValueFromInput = (input: HTMLInputElement): any => {
        switch (input.type) {
            case "number":
                return isUndefinedOrNull(input.value) || input.value === ""
                    ? _nullableProp.value ? null : 0
                    : parseFloat(input.value);
            default:
                return input.value;
        }
    };

    const getValueFromInputEvent = (e: InputEvent): any =>
        getValueFromInput(<HTMLInputElement>e.target);

    //const inputType

    const onChangeHandler = (e: InputEvent) => {
        value.value = getValueFromInputEvent(e);
        emit(UPDATE, getValue(value.value));
    };

    let prevValue: any;

    const onFocus = (e: FocusEvent) => {
        const input = e.target as HTMLInputElement;
        prevValue = input.value;
        hasFocus.value = true;
    };

    const onBlur = (e: FocusEvent) => {
        options.onBlur?.(e);

        const input = e.target as HTMLInputElement;
        const value = input.value;

        if (value !== prevValue) {
            addUndoRedo({
                dirtyChange: true,
                remark: `input ${prevValue}=>${value}`,
                undo: () => {
                    input.focus();
                    input.value = prevValue;
                },
                redo: () => {
                    input.focus();
                    input.value = value
                }
            });
        }
        hasFocus.value = false;
    };

    const min = computed(() => {
        const entityMin = propertyContext.value?.$min;
        const formMin = _attrs.min;
        return isNumber(entityMin) && isNumber(formMin)
            ? Math.max(entityMin, formMin)
            : isNumber(entityMin) ? entityMin : formMin;
    });

    const max = computed(() => {
        const entityMax = propertyContext.value?.$max;
        const formMax = _attrs.max;
        return isNumber(entityMax) && isNumber(formMax)
            ? Math.min(entityMax, formMax)
            : isNumber(entityMax) ? entityMax : formMax;
    });

    const baseControlAttrs = computed(() => ({
        ...inputAttrs.value,
        ...(hidden ? undefined : {
            "readonly": readonly.value,
            "disabled": disabled.value,
            "autocomplete": name.value,
            "placeholder": placeholder.value,
            "rows": rows.value,
            "name": name.value,
            "min": min.value,
            "max": max.value,
            "class": _attrs.class,
            "onFocus": [
                onFocus,
                ctx.attrs.onFocus
            ].filter(Boolean),
            "onBlur": [
                onBlur,
                ctx.attrs.onBlur
            ].filter(Boolean),
            "onInput": [
                options.onInput,
                !_isLazy.value
                    ? onChangeHandler
                    : undefined,
                ctx.attrs.onInput
            ].filter(Boolean),
            "onChange": [
                _isLazy.value
                    ? onChangeHandler
                    : undefined,
                ctx.attrs.onChange
            ].filter(Boolean)
        })
    }));

    const controlAttrs = computed(() => ({
        ...baseControlAttrs.value,
        "class": [
            "form-control",
            "form-control-sm",
            baseControlAttrs.value.class
        ].filter(Boolean)
    }));

    return {
        rows,
        type,
        name,
        value,
        hasFocus,
        formMode,
        disabled,
        readonly,
        inputGroupAttrs,
        fieldValidation,
        errorMessageAttrs,
        baseControlAttrs,
        controlAttrs,
        fieldAttrs,
        labelAttrs
    }


};