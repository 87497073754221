﻿<template>
    <aunoa-card v-bind="cardAttrs">

        <div class="card-header" v-if="isTenancyVisible">
            <span class="text-muted float-end">
                <span class="pe-2" v-text="tenant?.id" />
                <i class="flag-icon shadow-sm" :class="tenant?.countryFlag()" />
            </span>
            <span v-text="title(t)" />
        </div>

        <div class="list-group list-group-flush">
            <button
                class="list-group-item list-group-item-action d-flex"
                @click="selectedCurrency=info.refCurrency">
                <i class="flag-icon shadow-sm me-2" :class="getCountryFlag(info.refCurrency)" style="font-size:32px" />
                <span class="flex-grow-1">
                    <span class="text-muted" v-text="info.refCurrency" />
                </span>
                <template v-if="selectedCurrency!==info.refCurrency && info.refCurrency">
                    <span class="text-primary" v-text="'1 : '+getExchangeRate(selectedCurrency)" />
                    <span class="flex-grow-1 text-end">
                        <span class="text-muted" v-text="selectedCurrency" />
                    </span>
                    <i class="flag-icon shadow-sm ms-2" :class="getCountryFlag(selectedCurrency)" style="font-size:32px" />
                </template>
                <aunoa-currency-amount v-else-if="info.exchangeRates.length" :amount="info.testValue" :currency="info.refCurrency" />
            </button>
            <div class="list-group-item text-center text-muted small" v-text="t('Mate.NoExchangeRates')" v-if="!info.exchangeRates.length" />
            <button
                v-for="{foreignCurrency, exchangeRate, modifiedAt} in info.exchangeRates"
                class="list-group-item list-group-item-action d-flex align-items-start"
                :class="{'selected':selectedCurrency===foreignCurrency}"
                @click="selectedCurrency=selectedCurrency!==foreignCurrency?foreignCurrency:info.refCurrency">
                <i class="flag-icon shadow-sm me-2" :class="getCountryFlag(foreignCurrency)" style="font-size:32px" />
                <span class="flex-grow-1" style="line-height: 1.1">
                    <span class="text-muted pe-1" v-text="foreignCurrency" />
                    <span class="small" v-text="exchangeRate" />
                    <br>
                    <span class="text-muted small pe-2" v-text="formatDate(modifiedAt, 'Pp')" />
                </span>
                <span class="text-end" style="line-height: 1.1">
                    <aunoa-currency-amount
                        v-if="selectedCurrency===info.refCurrency"
                        class="text-nowrap"
                        :amount="info.testValue * exchangeRate"
                        :currency="foreignCurrency" />
                    <aunoa-currency-amount
                        v-else
                        class="text-nowrap"
                        :amount="info.testValue / exchangeRate"
                        :currency="info.refCurrency" />
                    <br>
                    <aunoa-currency-amount
                        v-if="selectedCurrency!==info.refCurrency"
                        class="text-nowrap text-muted small"
                        :amount="100"
                        :currency="foreignCurrency" />
                </span>
            </button>
        </div>
    </aunoa-card>
</template>

<script lang="ts">


type CurrencyCode = string;
type CountryCode = string;
import type {ExchangeRate} from "@tnt/mate-api";

import {useAunoaI18n, usePackeryItem, formatDate, formatCurrency, AunoaCard, AunoaCurrencyAmount, AunoaScrollbar, singleton} from "bootstrap-aunoa";
import {useCurrenciesApi, useCachedTenancyDataFromProps} from "@tnt/mate-api";
import {defineComponent, computed, ref, toRefs, nextTick, watch} from "vue";
import {useRouteScope} from "../../../scope/useRouteScope";
import {useDashboardCard} from "../useDashboard";
import {useAuth} from "../../../utils/useAuth";

interface Info {
    testValue: number;
    refCurrency: string;
    //selectedCurrency: string;
    exchangeRates: ExchangeRate[];
}


const currencyCountryExceptions: Record<CurrencyCode, CountryCode> = {}

const createInfo = (): Info => ({
    testValue: 100,
    refCurrency: "",
    exchangeRates: []
});

const meta = {
    createdAt: "2021-04-22T00:00:00.000Z",
    icon: "fad fa-money-bill-wave",
    title: (t: any) => t("Mate.ExchangeRates"),
    multiInstance: true
}

export default defineComponent({
    $$meta: meta,
    name: "ExchangeRatesCard",
    components: {AunoaCard, AunoaScrollbar, AunoaCurrencyAmount},
    props: {
        tenantOid: {
            type: Number,
            default: undefined
        }
    },
    setup(props) {

        const {t} = useAunoaI18n();
        const {fit} = usePackeryItem();
        const {accessToken} = useAuth();
        const {getTenant} = useRouteScope();
        const {tenantOid} = toRefs(props);
        const {getExchangeRates, getCurrency} = useCurrenciesApi(accessToken);
        const {data: info, currentTenantOid, isTenancyVisible} = useCachedTenancyDataFromProps<Info>(tenantOid, "ExchangeRates", createInfo);

        const selectedCurrency = ref("");

        const update = (info: Info, tenantOid: number) =>
            Promise
                .all([
                    getCurrency(tenantOid),
                    getExchangeRates(tenantOid)
                ])
                .then(([cc, r]) => {
                    info.refCurrency = cc.currency;
                    selectedCurrency.value = cc.currency;
                    const t = r[tenantOid] || {};
                    const c = t[cc.currency.toLowerCase()] || [];

                    info.exchangeRates = c
                        .sort((a, b) => {
                            const d = new Date(b.modifiedAt).valueOf() - new Date(a.modifiedAt).valueOf();
                            return d === 0 ? a.foreignCurrency.localeCompare(b.foreignCurrency) : d;
                        })
                        .filter((er, i) => i < 7)
                    ;
                    nextTick(fit);
                });

        const card = useDashboardCard({
            update: () => update(info.value, currentTenantOid.value)
        });

        watch(currentTenantOid, () => card.update())
        const tenant = computed(() => getTenant(currentTenantOid.value));

        const getCountryCode = (currencyOrCountryCode: string) =>
            currencyCountryExceptions[currencyOrCountryCode] || currencyOrCountryCode?.substr(0, 2);

        const getCountryFlag = (currencyOrCountryCode: string) =>
            `flag-icon-${getCountryCode(currencyOrCountryCode?.toUpperCase())?.toLowerCase()}`

        const getExchangeRate = (currency: string) =>
            info.value.exchangeRates.filter(er => er.foreignCurrency === currency)[0]?.exchangeRate;

        return {
            t,
            ...meta,
            ...card,
            info,
            tenant,
            isTenancyVisible,
            selectedCurrency,
            getExchangeRate,
            getCountryFlag,
            formatCurrency,
            formatDate
        }
    }
});

</script>