﻿export const ADMIN_ROUTE = "admin";
export const EMBEDDED_ROUTE = "embedded";
export const CODE_EDITOR_ROUTE = "codeEditor";
export const LOGIN_ROUTE = "login";
export const REGISTER_ROUTE = "register";
export const RESET_PASSWORD_ROUTE = "resetPassword";
export const CHANGE_PASSWORD_ROUTE = "changePassword";
export const VALIDATE_EMAIL_ROUTE = "validateEmail";
export const DASHBOARD_ROUTE = "dashboard";
export const FORBIDDEN_ROUTE = "forbidden403";
export const NOT_FOUND_ROUTE = "notFound404";


