﻿<template>
    <aunoa-card v-bind="cardAttrs" :required="required">
        <aunoa-card-header :icon="icon" :text="title(t)" v-if="false" />
        <div class="list-group list-group-flush">
            <div class="list-group-item text-center small" v-if="links.length===0">
                <div class="pb-1">
                    <i class="fat fa-info-circle me-1" />
                    <span v-text="t('Aunoa.Favorites.NoFavoritesQuestion')" />
                </div>
                <i class="d-inline-flex text-muted" v-text="t('Aunoa.Favorites.HowTo')" style="max-width:30ch" />
            </div>
            <router-link
                v-for="{name,icon,parentNames,path,permitted} in links"
                class="list-group-item list-group-item-action d-flex justify-content-start align-items-start link-item"
                :class="{'disabled':!permitted}"
                :to="path">
                <i class="fa-2x text-primary" :class="icon" />
                <span>
                    <span v-text="ensureTextTranslated(name)" />
                    <sup class="fad fa-stars fa-flip-horizontal text-primary" />
                    <br>
                    <span class="as-breadcrumb">
                        <span class="small text-muted" v-for="n in parentNames" v-text="ensureTextTranslated(n)" />                        
                    </span>
                </span>
            </router-link>
        </div>
    </aunoa-card>
</template>

<script lang="ts">

import {useAunoaI18n, useFavoritePages, AunoaCard, AunoaCardHeader} from "bootstrap-aunoa";
import {useDashboardCard} from "../useDashboard";
import {useAuth} from "../../../utils/useAuth";
import {computed, defineComponent} from "vue";
import {useTenancy} from "@tnt/mate-api";

const meta = {
    createdAt: "2021-04-22T00:00:00.000Z",
    icon: "fad fa-stars fa-flip-horizontal",
    title: (t: any) => t("Aunoa.Favorites.CardTitle"),
    required: true
}

export default defineComponent({
    $$meta: meta,
    name: "FavoritesCard",
    components: {AunoaCard, AunoaCardHeader},
    setup() {

        const card = useDashboardCard();
        const {navigation, user} = useAuth()
        const {primaryTenantOid} = useTenancy();
        const {t, ensureTextTranslated} = useAunoaI18n();
        const {getPathes} = useFavoritePages(<any>user.value);
        const pathDict = computed(() => navigation.value.pathDict || {});
        const links = computed(() => getPathes()
            .map(path => pathDict.value[path])
            .filter(Boolean)
            .map(link => ({
                ...link,
                permitted: navigation.value.permissions[link.path]?.[primaryTenantOid.value]
            }))
        );

        return {
            t,
            links,
            ensureTextTranslated,
            ...meta,
            ...card
        }
    }
});

</script>

