﻿import type {Entities, Forms} from "../../types";


import {isArray, isDefined, isNumber, isUndefined} from "../../utils/inspect";
import {setInPath, setInPathFunc} from "../../utils/properties";
import {useManagedTypes} from "./useManagedTypes";
import {
    helpers,
    required,
    numeric, integer, decimal,
    minLength, maxLength,
    minValue, maxValue,
    and
} from "@vuelidate/validators";

//import unique from "./validators/withMessages/unique";

type Rules = Record<string, object>;
//
// const unique1 = helpers.withMessage("UuUuUu", function (this: any, value: any) {
//     console.log("UNIQUE", value, this);
//     return true;
//
// });
//
// const unique2 = (value: any, rules: Rules) => {
//     console.log("UNIQUE", value, rules);
//     return true;
// };
// ;
//
// const unique3 = (rules: Rules) =>
//     helpers.withParams({type: "unique", value: rules} as any, (value: any) => {
//         console.log("UNIQUE", value, rules);
//         return true;
//     })

const unique = (rules: Rules) =>
    (value: any, vm: any) => {
        console.log("UNIQUE", value, rules, vm);
        return true;
    }

const extract = (field: any) => isArray(field)
    ? field.map(extract)
    : {property: field.property, rulesStr: field.rules}

const _required = (value: boolean | undefined) => value ? {required} : undefined
const _unique = (value: boolean | undefined, rules: Rules) => value ? {unique: unique(rules)} : undefined
const _numeric = (value: boolean | undefined) => value ? {numeric} : undefined;
const _integer = (value: boolean | undefined) => value ? {integer} : undefined;
const _decimal = (value: boolean | undefined) => value ? {decimal} : undefined;
const _minLength = (value: any) => isDefined(value) ? {minLength: minLength(value)} : undefined;
const _maxLength = (value: any) => isDefined(value) ? {maxLength: maxLength(value)} : undefined;
const _minValue = (value: any) => isDefined(value) ? {minValue: minValue(value)} : undefined;
const _maxValue = (value: any) => isDefined(value) ? {maxValue: maxValue(value)} : undefined;

const {getMinValue, getMaxValue, getNumberTypeValidation} = useManagedTypes();

const createRule = (p: Entities.Model.Property, rules: Rules) => ({
    ..._required(p.required),
    ..._unique(p.unique, rules),
    ..._minLength(p.minLength),
    ..._maxLength(p.maxLength),
    ..._minValue(getMinValue(p)),
    ..._maxValue(getMaxValue(p)),
    ...getNumberTypeValidation(p)
});

const initialize = (rules: Rules, properties: Entities.Model.Property[]) =>
    properties.forEach(p => {
        if (p.type === "Object" && p.properties && isArray(p.properties)) {
            initialize(rules[p.name] = {}, p.properties);
        } else {
            rules[p.name] = createRule(p, rules);
        }
    });

const ruleStrDict: Record<string, any> = {
    "required": () => ({required}),
    //"unique": () => ({unique}),
    "id": () => ({id: helpers.regex(/^([a-z0-9]*)(-[a-z0-9]+)*$/i)}),
    "numeric": () => ({numeric}),
    "integer": () => ({integer}),
    "decimal": () => ({decimal}),
    "minLength": _minLength,
    "maxLength": _maxLength,
    "min": _minValue,
    "max": _maxValue,
}

export const parseRule = (ruleStr: string) =>
    ruleStrDict[ruleStr.split(':')[0]]?.(...ruleStr.includes(':')
        ? ruleStr
            .split(':')
            .slice(1)
            .join(':')
            .split(',')
        : []);

export const parseRules = (rulesStr: string) =>
    rulesStr.split("|").map(parseRule).reduce((p: any, c: any) => ({...p, ...c}), {})

export const createVuelidationRules = (entityModel?: Entities.Model.Property[], formModel?: Forms.Model.Form) => {

    const rules: Rules = {};
    if (entityModel) {
        initialize(rules, entityModel);
    }
    if (formModel) {
        Object
            .entries(formModel)
            .map(([groupName, group]) => group.fields.map(extract).flat().filter(i => i?.rulesStr))
            .flat()
            .forEach(({property, rulesStr}) => {
                setInPathFunc(rules, property, (entityRules, p) => {
                    const formRules = parseRules(rulesStr);
                    // form rules will win
                    if (isUndefined(entityRules)) {
                        return formRules;
                    } else {
                        if (entityRules.integer || entityRules.decimal) {
                            delete formRules.numeric;
                        }
                        // todo: may compare min and max values of entity and form rules
                        //console.log("M", property, JSON.stringify(entityRules, undefined, 4), JSON.stringify(formRules, undefined, 4), rulesStr);
                        //console.log("***",property , JSON.stringify({...entityRules, ...formRules},null,4));
                        return {...entityRules, ...formRules};
                    }

                });
            });
    }
    //console.log(JSON.stringify(rules, undefined, 4));
    return rules;
}


