﻿<template>
    <div ref="tabContent" class="tab-content">
        <slot />
    </div>
</template>

<script lang="ts">

import type {TabInfo} from "./usePaneSubscription";

import {computed, defineComponent, toRefs, onMounted, onUpdated, onBeforeUnmount, ref} from "vue";
import {useContentSubscription} from "./useContentSubscription";
import {providePaneSubscription} from "./usePaneSubscription";
import {providePaneClass, usePaneClass} from "./usePaneClass";

export default defineComponent({
    name: "AunoaTabContent",
    components: {},
    props: {
        paneClass: {
            //type: [String, Array, Object],
            default: undefined
        }
    },
    setup(props) {

        const {paneClass} = toRefs(props);
        const tabContent = ref<HTMLElement>();
                
        const {paneClass: controlPaneClass} = usePaneClass()
        const {contentSubscription} = useContentSubscription();

        providePaneClass(computed(() => [paneClass?.value, controlPaneClass?.value].filter(Boolean)));
        providePaneSubscription({
            addTab(tab: TabInfo) {
                //console.log("addTab", tab.id.value);
                contentSubscription?.addTab(tab);
            },
            removeTab(tab: TabInfo) {
                //console.log("removeTab", tab.id.value);
                contentSubscription?.removeTab(tab);
            }
        });

        const setSortedPaneIds = () => {
            //console.log("setSortedPaneIds");
            contentSubscription?.setSortedTabIds(Array
                .from<HTMLElement>(<any>tabContent.value?.children || [])
                .map(value => value.id)
                .filter(id => !!id))
        };

        onMounted(() => {
            //console.log("AunoaTabContent mounted ");
            setSortedPaneIds();
        });
        onUpdated(() => {
            //console.log("AunoaTabContent updated");
            setSortedPaneIds();
        });
        onBeforeUnmount(() => {
            //console.log("AunoaTabContent unmounting");
        });

        return {
            tabContent,
        }

    }
});

</script>

