import type {Ref} from "vue";
import type {Facade} from "./_abstracts";
import {HubConnection} from "@microsoft/signalr";
import {ref} from "vue";

export interface MonitoringFacade {
     ping() : Promise<any>
}

export const createMonitoringFacade = (connection: HubConnection): MonitoringFacade & Facade => {

    const ping = async () => {
        return connection.invoke("PingRequest");
    }

    function OnTerminalConnectionChanged() {
        
    }

    const initialize = async () => {
        connection.on("TerminalConnectionChanged", OnTerminalConnectionChanged);
        return connection.invoke("SubscribeTerminalMonitoring", true);        
    }

    const dispose = () => {
        connection.off("TerminalConnectionChanged");
        return connection.invoke("SubscribeTerminalMonitoring", false);
        // console.log("disposal");        
    }

    return {
        initialize,
        ping,
        dispose
    }
}