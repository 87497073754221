﻿<template>
    <component ref="containerElement" :is="as">
        <slot />
    </component>
</template>

<script lang="ts">

import type {PropType} from "vue";
import {defineComponent, watch, toRefs, onMounted, onBeforeUnmount} from "vue";
import {useAunoa} from "../../utils/useAunoa";
import {providePackery} from "./usePackery";

export default defineComponent({
    name: "AunoaPackery",
    props: {
        as: {
            type: String,
            default: "div"
        },
        editMode: {
            type: Boolean,
            default: false
        },
        options: {
            type: Object as PropType<PackeryOptions>,
            default: undefined
        }
    },
    emit: ["layoutCompleted", "positionChanged"],
    setup(props, {emit}) {

        const {options, editMode} = toRefs(props);
        const {isFluidReady} = useAunoa();
        const packery = providePackery(options, editMode, emit);
        const {layout} = packery;

        onMounted(() => {
            const unwatch = watch(isFluidReady, _ => layout());
            onBeforeUnmount(unwatch);
        });

        return {
            ...packery
        }
    }
});

</script>

