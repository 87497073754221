﻿<template>
    <nav class="navbar navbar-navigation navbar-expand p-0">
        <aunoa-container>
            <ul class="navbar-nav">
                <template v-for="l0 in navigation.rootItems">
                    <aunoa-navigation-bar-item
                        v-if="isLinkVisible(l0)"
                        :style="{width:(100/navigation.rootItems.length)+'%'}"
                        :navigation="navigation"
                        :link-item="l0"
                    />
                </template>
            </ul>
        </aunoa-container>
    </nav>
</template>

<script lang="ts">

import {onMounted, onBeforeUnmount, defineComponent, PropType, toRefs, watch} from "vue";
import {AunoaContainer, isLinkVisible, LinkItem} from "..";
import {Navigation, useNavigation} from "../navigation";
import AunoaNavigationBarItem from "../components/AunoaNavigationBarItem.vue";

export default defineComponent({
    name: "AunoaNavigationBar",
    components: {
        AunoaNavigationBarItem,
        AunoaContainer
    },
    props: {
        navigation: {
            type: Object as PropType<Navigation>,
            required: true
        }
    },
    setup(props) {
        const {navigation} = toRefs(props);
        const {ensureInitialized} = useNavigation();

        watch(navigation, ensureInitialized);

        const toggleHasNavigationBar = (value: boolean) => document.body.classList.toggle("has-navigation-bar", value);
        onMounted(() => toggleHasNavigationBar(true));
        onBeforeUnmount(() => toggleHasNavigationBar(false));
        
        
        return {
            isLinkVisible,
            
        }
        
    }
});

</script>
