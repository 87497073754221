﻿<template>
    <component :is="tag" class="container-animate" :class="[containerClass]">
        <slot />
    </component>
</template>

<script lang="ts">

import {defineComponent, computed, toRefs} from "vue";
import {useAunoa} from "..";

export default defineComponent({
    name: "AunoaContainer",
    props: {
        tag: {
            type: String,
            default: "div"
        },
        isFluid: {
            type: Boolean,
            default: undefined
        }
    },
    setup(props) {

        const {isFluid} = toRefs(props);
        const {getFluidClasses} = useAunoa();
        const containerClass = computed(() => getFluidClasses(isFluid.value))

        return {
            containerClass
        }
    }
});

</script>

