﻿<template>
    <header class="fixed-top shadow">
        <aunoa-title-bar
            :page-icon="pageIcon"
            :page-state-icon="pageStateIcon()"
            :page-header="pageHeader"
            :root-page-header="rootPageHeader"
            :user="user"
            :default-gravatar-image="defaultGravatarImage"
            @lock="requestUserLogin('lock')">
            <template v-slot:navItems v-if="$slots.titleNavItems">
                <slot name="titleNavItems" />
            </template>
            <template v-slot:userDropdownItems v-if="$slots.userDropdownItems">
                <slot name="userDropdownItems" />
            </template>
        </aunoa-title-bar>
        <aunoa-navigation-bar :navigation="navigation" />
        <aunoa-command-bar />
    </header>

    <aunoa-footer :is-fluid="isFooterFluid" :status-text="statusText" :show-zucchetti="showZucchetti">
        <template v-slot:startNavItems>
            <slot name="footerBrandNavItems" />
        </template>
        <template v-slot:internalNavItems>
            <slot name="footerNavItems" />
            <li class="nav-item" v-if="showBreakpointInfo">
                <span class="nav-text breakpoint-info" />
            </li>
            <aunoa-select-locale-nav-item
                drop-direction="dropup" drop-menu-class="dropdown-menu-end" indicator="code"
                :locale="locale" :locales="locales" @select="setLocale"
            />
        </template>
        <template v-slot:internalIconNavItems>
            <slot name="footerIconNavItems" />
            <aunoa-select-fluid-nav-item drop-direction="dropup" drop-menu-class="dropdown-menu-end" v-model:fluid="fluid" />
            <aunoa-select-theme-nav-item drop-direction="dropup" drop-menu-class="dropdown-menu-end" v-model:theme="theme" />
            <aunoa-nav-item
                :disabled="!(canScrollToTop||canScrollToBottom)"
                :icon="canScrollToTop?'fad fa-arrow-alt-square-up':'fad fa-arrow-alt-square-down'"
                @click="canScrollToTop?scrollToTop():scrollToBottom()" />
        </template>
    </aunoa-footer>
    
    <aunoa-content-container
        tag="main"
        :class="mainClass"
        :permitted="permitted"
        :disable-body-scrolling="disableBodyScrolling"
        :disable-horizontal-padding="disableHorizontalContentPadding">
        <div class="hourglass-container bg-loading" v-if="loading">
            <aunoa-hourglass icon-style="fat" style="font-size: 3rem" />
        </div>
        <slot v-else />
    </aunoa-content-container>
    
    <aunoa-toasts-control :messages="messages" />

    <aunoa-auth-relogin
        ref="authRelogin"
        :user="user"
        :default-gravatar-image="defaultGravatarImage"
    />
</template>

<script lang="ts">

import type {PropType} from "vue";
import type {AuthRelogin} from "../controls/AunoaAuthRelogin.vue";
import type {DefaultGravatarImage} from "../components/AunoaGravatar.vue";

import {computed, defineComponent, onBeforeMount, onBeforeUnmount, ref, toRefs, watch} from "vue";
import AunoaAuthRelogin from "../controls/AunoaAuthRelogin.vue";

import AunoaSelectLocaleNavItem from "../components/AunoaSelectLocaleNavItem.vue";
import AunoaSelectFluidNavItem from "../components/AunoaSelectFluidNavItem.vue";
import AunoaSelectThemeNavItem from "../components/AunoaSelectThemeNavItem.vue";
import {provideMostRecentlyUsedPages} from "../utils/useMostRecentlyUsedPages";
import AunoaContentContainer from "../components/AunoaContentContainer.vue";
import AunoaToastsControl from "../controls/AunoaToastsControl.vue";
import AunoaHourglass from "../components/AunoaHourglass.vue";
import {provideBodyBackground} from "../utils/useBodyBackground";
import {useBodyScrollbar} from "../tools/bodyScrollbar";
import {providePageHost} from "../utils/usePageHost";
import {providePageInfo} from "../utils/usePageInfo";
import {provideToasts} from "../utils/useToasts";
import {useTestMode} from "../utils/useTestMode";
import {useRoute, useRouter} from "vue-router";
import {useLocale} from "../utils/useLocale";
import UserMixin from "../mixins/UserMixin";
import {Navigation} from "../navigation";

import {
    AunoaFooter,
    AunoaNavigationBar,
    AunoaNavItem,
    AunoaTitleBar,
    enumerateLinks,
    LinkItem,
    LinkItemState,
    linkStateIcon,
    useAunoa,
    useAunoaI18n
} from "..";

import AunoaCommandBar from "../controls/AunoaCommandBar.vue";

export default defineComponent({
    name: "AdminLayout",
    mixins: [UserMixin],
    components: {
        AunoaNavItem,
        AunoaContentContainer,
        AunoaToastsControl,
        AunoaCommandBar,
        AunoaFooter,
        AunoaTitleBar,
        AunoaNavigationBar,
        AunoaSelectFluidNavItem,
        AunoaSelectLocaleNavItem,
        AunoaSelectThemeNavItem,
        AunoaAuthRelogin,
        AunoaHourglass
    },
    props: {
        navigation: {
            type: Object as PropType<Navigation>,
            required: true
        },
        rootPageHeader: {
            type: String,
            default: "AUNOA"
        },
        statusText: {
            type: String
        },
        showZucchetti: {
            type: Boolean,
            default: false
        },
        showBreakpointInfo: {
            type: Boolean,
            default: true
        },
        permitted: {
            type: Boolean,
            default: true
        },
        defaultGravatarImage: {
            type: String as PropType<DefaultGravatarImage>,
            default: "blank"
        },
        isFooterFluid: {
            type: Boolean,
            default: undefined
        }
    },
    emits: ["locking"],
    setup(props, {emit}) {

        const {
            //pageHeader: _pageHeader,
            navigation,

        } = toRefs(props);

        const {theme, fluid} = useAunoa();

        const {locale, locales, setLocale} = useLocale();

        const route = useRoute();
        const pageHost = providePageHost();
        const {currentRoute} = useRouter();
        const {testMode} = useTestMode();

        const bodyScrollbar = useBodyScrollbar();
        const {ensureTextTranslated} = useAunoaI18n();
        const {messages, clean: cleanMessages} = provideToasts();
        const {addRoute: addRouteToMostRecentlyUsedPages} = provideMostRecentlyUsedPages(<any>props.user);
        provideBodyBackground();

        watch(currentRoute, () => cleanMessages());

        let pathDict: Record<string, LinkItem> = {};

        const path = computed(() => (route.meta?.responsiblePath || route.path) as string);

        const _link = () => pathDict[path.value] || navigation.value.pathDict?.[path.value];

        const _getPageIcon = () => (pageHost.pageIcon.value || _link()?.icon || route.meta?.icon) as string;

        const _getPageHeader = () => {
            if (pageHost.pageHeader.value) {
                return pageHost.pageHeader.value;
            }
            const link = _link();
            return link?.title || link?.name || route.meta?.title;
        }

        const pageIcon = computed(_getPageIcon);
        const pageStateIcon = () => linkStateIcon(_link());

        const pageHeader = computed(() => {
            const h = _getPageHeader() as string;
            return h ? ensureTextTranslated(h) : route.path;
        });

        providePageInfo(pageIcon, pageHeader);

        watch([navigation, testMode], ([n, newTestMode]) => {
            pathDict = {};
            if (n) {
                const progresses = (<any>n).progress;
                enumerateLinks<LinkItem>(n.rootItems, (link, hl) => {
                    link.state = undefined;

                    if (link.path) {
                        link.icon = link.icon || hl.map(l => l.icon).filter(Boolean).reverse()[0];

                        if (progresses) {
                            const inProgress = progresses[link.path];
                            if (newTestMode) {
                                link.state = inProgress
                                    ? LinkItemState.InProgress
                                    : inProgress === false
                                        ? LinkItemState.Testable
                                        : LinkItemState.Visible;
                            } else {
                                link.state = inProgress
                                    ? LinkItemState.Hidden
                                    : LinkItemState.Visible;
                            }
                        }
                    }
                    link.isVisible = () =>
                        link.children
                            ? !!link.children?.some(l => l.isVisible?.())
                            : !!link.state && link.state > LinkItemState.Hidden
                });
            }

        }, {immediate: true})

        const authRelogin = ref<AuthRelogin>();

        const requestUserLogin = (reason: "lock" | "expired") => {
            if (reason === 'lock') {
                emit("locking");
            }
            return authRelogin.value 
                ? authRelogin.value.show(reason) 
                : Promise.reject("Missing authRelogin");
        };

        const loading = ref(false);

        onBeforeMount(() => {
            let timer = 0;
            const router = useRouter();
            const unguardEachBefore = router.beforeEach((to) => {
                timer = window.setTimeout(() => loading.value = true, 250);
            })
            const unguardEachAfter = router.afterEach((to) => {
                window.clearTimeout(timer);
                addRouteToMostRecentlyUsedPages(to);
                loading.value = false;
            })
            onBeforeUnmount(() => {
                unguardEachBefore();
                unguardEachAfter();
            });
        });

        return {
            ...pageHost,
            pageHeader,
            pageIcon,
            pageStateIcon,

            authRelogin,
            messages,
            loading,
            theme,
            fluid,
            locale,
            locales,
            setLocale,
            requestUserLogin,
            ...bodyScrollbar
        }

    }
});

</script>


