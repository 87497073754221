﻿import type {Ref} from "vue";
import {ref, watch, onMounted, onBeforeUnmount} from "vue";

const VOID = () => {
};

interface ElementSizeOptions<T extends HTMLElement = HTMLElement> {
    element?: Ref<T | undefined>;
    cssCustomVar?: {
        width?: boolean;
        height?: boolean;
    }
    onChanging?(): void;
    onChanged?(options: { deltaWidth: number; deltaHeight: number }): void;
}

export const useElementSize = <T extends HTMLElement = HTMLElement>() => {

    const element = ref<T>();

    const contentRect = ref<DOMRectReadOnly>(new DOMRectReadOnly());
    const offsetWidth = ref(0);
    const offsetHeight = ref(0);

    const resizeObserver = new ResizeObserver(([entry]) => {
        offsetWidth.value = element.value?.offsetWidth || 0;
        offsetHeight.value = element.value?.offsetHeight || 0;
    });

    watch(element, (newElement, oldElement) => {
        if (oldElement) {
            resizeObserver.unobserve(oldElement)
        }
        if (newElement) {
            resizeObserver.observe(newElement)
        }
    });

    return {
        element,
        contentRect,
        offsetWidth,
        offsetHeight
    }
};


export const useElementSizeV2 = <T extends HTMLElement = HTMLElement>(options?: ElementSizeOptions<T>) => {

    options = options || {};
    const _element = options.element || ref<T>();

    const cssWidth = !!options.cssCustomVar?.width;
    const cssHeight = !!options.cssCustomVar?.height;
    const onChanging = options.onChanging || VOID;
    const onChanged = options.onChanged || VOID;

    const contentRect = ref<DOMRectReadOnly>(new DOMRectReadOnly());
    const offsetWidth = ref(0);
    const offsetHeight = ref(0);

    let resizeObserver: ResizeObserver | undefined;

    watch(_element, (element, oldElement) => {
        if (oldElement) {
            resizeObserver?.unobserve(oldElement);
            resizeObserver = undefined;
        }
        if (element) {
            resizeObserver = new ResizeObserver(([entry]) => {
                onChanging();
                const oldContentRect = contentRect.value;
                contentRect.value = entry.contentRect;
                offsetWidth.value = element.offsetWidth || 0;
                offsetHeight.value = element.offsetHeight || 0;
                if (cssWidth) {
                    element.style.setProperty("--width", offsetWidth.value + "px");
                }
                if (cssHeight) {
                    element.style.setProperty("--height", offsetHeight.value + "px");
                }
                onChanged({
                    deltaWidth: entry.contentRect.width - oldContentRect.width,
                    deltaHeight: entry.contentRect.height - oldContentRect.height
                });
            });
            resizeObserver.observe(element);
        }
    }, {immediate: true});

    onBeforeUnmount(() => {
        _element.value && resizeObserver?.unobserve(_element.value);
        resizeObserver = undefined;
    });

    return {
        element: _element,
        contentRect,
        offsetWidth,
        offsetHeight
    }
};
