import {onMounted, onBeforeUnmount, ref, watch} from "vue";
import {isPromise} from "./inspect";
import {useAunoa} from "./useAunoa";
import {useRoute} from "vue-router";
import {singleton} from "./singletons";
import {getDuration} from "./dateAndTime";


const useShared = () => singleton("AUNOA_BODY_BACKGROUND", () => {

    const isBusy = ref(false);

    return {
        isBusy
    }
})


export const provideBodyBackground = () => {

    const {isFluid, setFluidClasses} = useAunoa();

    const {isBusy} = useShared();

    const route = useRoute();
    watch(route, (newRoute, oldRoute) => {
        if (newRoute?.path != oldRoute?.path) {
            isBusy.value = false;
        }
    });


    onMounted(() => {

        const container = document.createElement("div");
        container.classList.add("container-animate");
        container.classList.add("shadow");

        const canvas = document.createElement("div");
        canvas.classList.add("aunoa-canvas");
        canvas.appendChild(container);

        const body = document.body;
        body.insertBefore(canvas, body.firstElementChild);

        watch(isFluid, value => setFluidClasses(container, value), {immediate: true});
        watch(isBusy, value => {
            body.classList.toggle("canvas-busy", value);
            container.classList.toggle("bg-loading", value);
        }, {immediate: true})

        onBeforeUnmount(() => {
            body.removeChild(canvas);
        });

    });

}


export const useBodyBackground = () => {

    const {isBusy} = useShared();

    const startBodyBusy = <T = any>(promise: Promise<T>, minDuration: (number | boolean | string) = true) => {
        if (!isPromise(promise)) {
            return Promise.resolve();
        }
        isBusy.value = true;
        const duration = getDuration(minDuration, 333);
        Promise
            .all([
                new Promise<void>(resolve => promise.catch().finally(resolve)),
                new Promise<void>(resolve => setTimeout(resolve, duration))
            ])
            .finally(() => isBusy.value = false);
    }

    return {
        isBusy,
        startBodyBusy
    }


}