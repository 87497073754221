﻿import {LinkItem} from "./links";

export interface Navigation<TLinkItem extends LinkItem = LinkItem> {
    rootItems: TLinkItem[];
    _initialized?: boolean;
    nameFunc?(item: TLinkItem): string;
    pathDict?: Record<string, TLinkItem>;
}

export const useNavigation = () => {
    const ensureInitialized = (navigation: Navigation) => {
        if (!navigation._initialized) {
            navigation._initialized = true;
        }
    };
    return {
        ensureInitialized
    };
};
