﻿<template>
    <li>
        <button class="dropdown-item" :class="{'checked':checked}" @click.prevent="onClick">
            <span class="d-flex flex-row justify-content-between align-items-center">
                <span class="pe-5">
                    <i class="far fa-fw me-3" :class="{'fa-check':checked}" />
                    <i class="fa-fw me-2" :class="icon" v-if="icon" />
                    <span v-text="text" />
                </span>
                <slot name="end" v-if="$slots['end']" />
                <i class="fa-fw" :class="endIcon" v-else-if="endIcon" />
                <span v-text="endText" v-else-if="endText" />
            </span>
        </button>
    </li>
</template>

<script lang="ts">

import {defineComponent} from "vue";

export default defineComponent({
    name: "AunoaDropdownCheckItem",
    props: {
        checked: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
        },
        text: {
            type: String,
            required: true
        },
        endIcon: {
            type: String,
        },
        endText: {
            type: String
        }

    },
    slots: ["end"],
    emits: ["update:checked", "click"],
    setup(props, {emit}) {

        const onClick = () => {
            emit("update:checked", !props.checked);
            emit("click");
        }

        return {
            onClick
        }

    }
});

</script>

