﻿// noinspection SpellCheckingInspection

import type {Ref} from "vue";
import type {AccessToken} from "../types";
import type {ApiError} from "./error";
import type {Options as BaseOptions} from "./useEndpoint";
import type {AxiosInstance, AxiosResponse, AxiosRequestConfig} from "axios";

import {useEndpoint} from "./useEndpoint";
import {create$$scope} from "./scope";
import {readonly} from "vue";

interface Options extends BaseOptions {
    pascalCase?: boolean;
}

const APPLICATION_JSON = "application/json";

const getAccept = (options: Options) => options.pascalCase
    ? APPLICATION_JSON + "; profile=https://retail-enterprise-driven.com/schema/casing/pascal-case"
    : APPLICATION_JSON;

export const useJsonEndpoint = (
    axiosInstance: AxiosInstance,
    url: string | Ref<string>,
    baseHeaders: () => any,
    lastApiError: Ref<ApiError | undefined>,
    accessToken: AccessToken,
    preserveScope?: boolean
) => {

    const {
        funcs, busy, result, status, error, username, etag, date, oid,
        getHeadersFromOptions, setResponse,
        head, get, put, post, patch, delete: delete_
    } = useEndpoint<any, Options>(axiosInstance, url, baseHeaders, lastApiError, accessToken);

    funcs.requestConfig = (params: any, options: Options): AxiosRequestConfig => ({
        // @ts-ignore
        $$options: options,
        params: params,
        auth: options.auth,
        headers: {
            ...baseHeaders(),
            "Accept": getAccept(options),
            ...getHeadersFromOptions(options)
        }
    })

    funcs.ensureResponse = <TResponse>(axiosResponse: AxiosResponse<TResponse>) => {
        const res: any = axiosResponse.status === 204 ? undefined : axiosResponse.data;

        if (preserveScope) {
            const $$scope = create$$scope(axiosResponse.headers);
            if (Array.isArray(res)) {
                res.forEach(e => e.$$scope = $$scope);
            } else if (typeof res === "object") {
                res.$$scope = $$scope;
            }
        }

        result.value = res;
        setResponse(axiosResponse);
        return res;
    }

    return {
        busy: readonly(busy),
        error: readonly(error),
        result: readonly(result),
        status: readonly(status),
        username: readonly(username),
        etag: readonly(etag),
        date: readonly(date),
        oid: readonly(oid),
        head,
        get,
        put,
        post,
        patch,
        delete: delete_
    }
}
