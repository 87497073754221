﻿import type {LookupFactory} from "./lookup/useLookup";
import type {Forms, Conditions, LookupDisplayOptions, LookupValueOptions} from "../types";

import {isBoolean, isFunction, isNumber, isObject, isString, isUndefined} from "../utils/inspect";
import {useLookupValueOptions, useLookupDisplayOptions} from "./lookup/useLookupOptions";
import {createConditionsModel} from "./conditionsModel";
import {getPropertyName} from "../utils/reflectionLike";
import {buildArrayProperty} from "../utils/properties";
import {isLookupFactory} from "./lookup/useLookup";


interface FieldsHelper {
    createModel(): Forms.Model.Field;
}

interface RowsAndFieldsHelper {
    createModel(): Forms.Model.Field | Forms.Model.Field[];
}

interface GroupHelper {
    name(): string;
    createModel(): Forms.Model.Group;
}

export interface FormModelOptions {
}

type CreateFunc<TC> = (model: string) => {
    builder: TC,
    createModel: () => Forms.Model.Field
}

export const mergeFormModels = (...forms: (Forms.Model.Form)[]) => {
    forms = forms.filter(Boolean);
    if (forms.length == 1) {
        return forms[0];
    }
    const mergedForm: Forms.Model.Form = {};
    forms.forEach(form => {
        Object.keys(form).forEach(groupName => {
            const sourceGroup = form[groupName];
            const targetGroup = mergedForm[groupName] = mergedForm[groupName] || {
                component: sourceGroup.component,
                label: sourceGroup.label,
                fields: [],
            };
            sourceGroup.fields.forEach(fieldOrFields => {
                if (Array.isArray(fieldOrFields)) {
                    if (fieldOrFields.length == 1) {
                        targetGroup.fields.push(fieldOrFields[0]);
                    } else {
                        targetGroup.fields.push(fieldOrFields);
                    }
                } else {
                    targetGroup.fields.push(fieldOrFields);
                }
            });
        })
    })

    return mergedForm
}

const textBox = (property: string) => {

    let component: Forms.Model.FieldComponent = "AunoaTextBox";
    let placeholder: string;
    let disabled: boolean;
    let readonly: boolean;
    let required: boolean;
    let forPassword: boolean;
    let forID: boolean;
    let minLength: number;
    let maxLength: number;
    let lines: number;
    let textCasing: string | undefined;

    const builder: Forms.Builder.TextBox = {
        //Component: (value: Forms.Model.FieldComponent) => set(() => _component = value),
        Placeholder: (value: string) => set(() => placeholder = value),
        Required: (value = true) => set(() => required = value),
        Readonly: (value = true) => set(() => readonly = value),
        Disabled: (value = true) => set(() => disabled = value),
        ForPassword: (value = true) => set(() => forPassword = value),
        ForID: (value = true) => set(() => forID = value),
        Length: (value: number) => builder.MinLength(value).MaxLength(value),
        MinLength: (value: number) => set(() => minLength = value),
        MaxLength: (value: number) => set(() => maxLength = value),
        MultiLine: (value: number) => set(() => lines = value),
        UpperCase: (value = true) => set(() => textCasing = value ? "upper" : undefined),
        LowerCase: (value = true) => set(() => textCasing = value ? "lower" : undefined)
    }

    function set(action: () => void) {
        action();
        return builder;
    }

    return {
        builder,
        createModel: (): Forms.Model.Field => ({
            property,
            component,
            attrs: {
                "type": forPassword ? "password" : undefined,
                "placeholder": placeholder || undefined,
                "disabled": disabled || undefined,
                "readonly": readonly || undefined,
                "lines": lines > 1 ? lines : undefined,
                "text-casing": textCasing
            },
            rules: [
                required ? "required" : undefined,
                forID ? "id" : undefined,
                isNumber(minLength) ? `minLength:${minLength}` : undefined,
                isNumber(maxLength) ? `maxLength:${maxLength}` : undefined
            ].filter(Boolean).join("|") || undefined
        })
    };
};

const numberBox = (property: string) => {

    let component: Forms.Model.FieldComponent = "AunoaNumberBox";
    let placeholder: string;
    let disabled: boolean;
    let readonly: boolean;
    let required: boolean;
    let nullable: boolean;
    let isInteger: boolean;
    let isDecimal: boolean;
    let min: number;
    let max: number;

    const builder: Forms.Builder.NumberBox = {
        Placeholder: (value: string) => set(() => placeholder = value),
        Required: (value = true) => set(() => required = value),
        Readonly: (value = true) => set(() => readonly = value),
        Disabled: (value = true) => set(() => disabled = value),
        Nullable: (value = true) => set(() => nullable = value),
        Integer: () => set(() => {
            isInteger = true;
            isDecimal = false
        }),
        Decimal: () => set(() => {
            isDecimal = true;
            isInteger = false
        }),
        Min: (value: number) => set(() => min = value),
        Max: (value: number) => set(() => max = value)
    }

    function set(action: () => void) {
        action();
        return builder;
    }

    //const type = () => isInteger ? "integer" : "numeric";

    return {
        builder: builder,
        createModel: (): Forms.Model.Field => ({
            property,
            component,
            attrs: {
                "type": "number",
                "placeholder": placeholder || undefined,
                "disabled": disabled || undefined,
                "readonly": readonly || undefined,
                "min": min,
                "max": max
            },
            rules: [
                //type(),
                !required && !nullable ? "mayRequired" : undefined,
                required ? "required" : undefined,
                isNumber(min) ? `min:${min}` : undefined,
                isNumber(max) ? `max:${max}` : undefined,
                isInteger ? "integer" : isDecimal ? "decimal" : undefined, //"numeric",
            ].filter(Boolean).join("|"),
        })
    };
};

const dateTimeBox = (property: string, type: string) => {

    let component: Forms.Model.FieldComponent = "AunoaDateBox";
    let placeholder: string;
    let disabled: boolean;
    let readonly: boolean;
    let required: boolean;
    let min: Date;
    let max: Date;

    const builder: Forms.Builder.DateTimeBox = {
        Placeholder: (value: string) => set(() => placeholder = value),
        Required: (value = true) => set(() => required = value),
        Readonly: (value = true) => set(() => readonly = value),
        Disabled: (value = true) => set(() => disabled = value),
        Min: (value: Date) => set(() => min = value),
        Max: (value: Date) => set(() => max = value)
    }

    function set(action: () => void) {
        action();
        return builder;
    }

    return {
        builder: builder,
        createModel: (): Forms.Model.Field => ({
            property,
            component,
            attrs: {
                "type": type || undefined,
                "placeholder": placeholder || undefined,
                "disabled": disabled || undefined,
                "readonly": readonly || undefined,
                "min": min,
                "max": max
            },
            rules: [
                required ? "required" : undefined,
                //typeof min === "number" ? `min_value:${min}` : undefined,
                //typeof max === "number" ? `max_value:${max}` : undefined
            ].filter(Boolean).join("|"),
        })
    };
};

const booleanBox = (property: string) => {

    let component: Forms.Model.FieldComponent = "AunoaSelectBox";
    let disabled: boolean;
    let readonly: boolean;
    let required: boolean;
    let nullable: boolean;
    let falseText: string;
    let trueText: string;
    let nullText: string;
    let falseIcon: string;
    let trueIcon: string;

    const getFalseText = (key = "YesNo") => `@@Aunoa.Boolean.${key}.False`;
    const getTrueText = (key = "YesNo") => `@@Aunoa.Boolean.${key}.True`;

    const setTranslation = (key: string) => {
        falseText = getFalseText(key);
        trueText = getTrueText(key);
        if (key === "Lock") {
            falseIcon = "far fa-lock-open";
            trueIcon = "fas fa-lock text-muted";
        }
        return builder;

        //return setBoolean(getFalseText(key), getTrueText(key));
    };

    const builder: Forms.Builder.BooleanBox = {
        Required: (value = true) => set(() => required = value),
        Readonly: (value = true) => set(() => readonly = value),
        Disabled: (value = true) => set(() => disabled = value),
        Nullable: (value = true) => set(() => nullable = value),
        FalseText: (value: string) => set(() => falseText = value),
        TrueText: (value: string) => set(() => trueText = value),
        NullText: (value: string) => set(() => nullText = value),
        AsTrueFalse: () => setTranslation("TrueFalse"),
        AsYesNo: () => setTranslation("YesNo"),
        AsOnOff: () => setTranslation("OnOff"),
        AsEnabledDisabled: () => setTranslation("EnabledDisabled"),
        AsShowHide: () => setTranslation("ShowHide"),
        AsVisibleHidden: () => setTranslation("VisibleHidden"),
        AsLock: () => setTranslation("Lock")
    }

    function set(action: () => void) {
        action();
        return builder;
    }

    //function setBoolean(f: string, t: string) {
    //    falseText = f;
    //    trueText = t;
    //    return builder;
    //}

    return {
        builder: builder,
        createModel: (): Forms.Model.Field => ({
            property,
            component,
            attrs: {
                disabled,
                readonly,
                dataSource: [
                    [false, falseText || getFalseText(), falseIcon || "far fa-square"],
                    [true, trueText || getTrueText(), trueIcon || "far fa-check-square"],
                    nullable ? [null, nullText || "@@Aunoa.Form.NoOptionSelected"] : undefined
                ].filter(Boolean),
            },
            rules: [
                required ? "required" : undefined,
            ].filter(Boolean).join("|"),
        })
    }
}

const selectBox = (property: string, dataSource: string | undefined, multi: boolean) => {

    let component: Forms.Model.FieldComponent = multi
        ? "AunoaMultiSelectBox"
        : "AunoaSelectBox";

    let disabled: boolean;
    let readonly: boolean;
    let required: boolean;
    let searchEnabled: boolean;

    const valueOptions = useLookupValueOptions();
    const displayOptions = useLookupDisplayOptions();

    const builder: Forms.Builder.SelectBox = {
        Required: (value = true) => set(() => required = value),
        Readonly: (value = true) => set(() => readonly = value),
        Disabled: (value = true) => set(() => disabled = value),
        UseValue: (options: (v: LookupValueOptions) => void) => set(() => options(valueOptions.options)),
        UseDisplay: (options: (v: LookupDisplayOptions) => void) => set(() => options(displayOptions.options)),
        EnableSearch: (value = true) => set(() => searchEnabled = value)
    }

    function set(action: () => void) {
        action();
        return builder;
    }

    return {
        builder: builder,
        createModel: (): Forms.Model.Field => ({
            property,
            component,
            attrs: {
                //"placeholder": placeholder || undefined,
                "disabled": disabled || undefined,
                "readonly": readonly || undefined,
                "dataSource": dataSource,
                "valueExpression": valueOptions.expression(),
                "displayExpression": displayOptions.expression(),
                "displayCasing": displayOptions.casing(),
                "searchEnabled": searchEnabled || undefined,
            },
            rules: [
                required ? "required" : undefined,
            ].filter(Boolean).join("|"),
        })
    }
}

const prepareSelectBox = (property: string, multi: boolean) => {

    let createModel: () => Forms.Model.Field;

    const builder: Forms.Builder.PrepareSelectBox = {
        WithDataSource: (source: string | LookupFactory) => {
            const dataSource = isLookupFactory(source)
                ? source.name
                : isString(source) 
                    ? source 
                    : undefined;
            const box = selectBox(property, dataSource, multi);
            createModel = box.createModel;
            return box.builder;
        },
    }

    return {
        builder: builder,
        createModel: () => createModel()
    }

}

const _useField = <T = any>(register: (createFieldModel: () => Forms.Model.Field) => void, baseModel?: string) => {

    const getModel = (propertyName: any) => baseModel
        ? `${baseModel}.${propertyName}`
        : propertyName;

    function get<TC>(getProperty: (obj: T) => any, create: CreateFunc<TC>): TC {
        const propertyName = getPropertyName<T>(getProperty);
        const model = getModel(propertyName);
        const control = create(model);
        register(control.createModel);
        return control.builder;
    }

    let fieldBuilder: Forms.Builder.FieldBase<T> = {
        Property<TP extends T[keyof T]>(getter: (o: T) => TP) {
            const model = getModel(getPropertyName<T, TP>(getter));
            return _useField<TP>(register, model);
        },
        TextBox: (gp: (o: T) => string) => get(gp, m => textBox(m)),
        NumberBox: (gp: (o: T) => number | null) => get(gp, m => numberBox(m)),
        DateTimeBox: (gp: (o: T) => Date | null) => get(gp, m => dateTimeBox(m, "datetime-local")),
        DateBox: (gp: (o: T) => Date | null) => get(gp, m => dateTimeBox(m, "date")),
        TimeBox: (gp: (o: T) => Date | null) => get(gp, m => dateTimeBox(m, "time")),
        BooleanBox: (gp: (o: T) => boolean | null) => get(gp, m => booleanBox(m)),
        SelectBox: (gp: (o: T) => any) => get(gp, m => prepareSelectBox(m, false)),
        MultiSelectBox: (gp: (o: T) => any[]) => get(gp, m => prepareSelectBox(m, true)),
    }
    return fieldBuilder;
};

export const useField = <T = any>() => {

    let label: string | boolean = true;
    let name: string;
    let createFieldModel: () => Forms.Model.Field;
    let required: Conditions.Model.Condition;
    let readonly: Conditions.Model.Condition;
    let disabled: Conditions.Model.Condition;

    const condition = (value: any, defaultValue: Conditions.Model.Condition): Conditions.Model.Condition => {
        if (isFunction(value)) {
            const {builder: conditionsBuilder, build} = createConditionsModel();
            value(conditionsBuilder);
            return build();
        }
        return isUndefined(value) || value
            ? true
            : defaultValue;
    }

    const fieldBuilder: Forms.Builder.Field<T> = {
        Label: (value: string | boolean) => set(() => label = value),
        Name: (value: string) => set(() => name = value),
        Required: (value: any) => set(() => required = condition(value, required)),
        Readonly: (value: any) => set(() => readonly = condition(value, readonly)),
        Disabled: (value: any) => set(() => disabled = condition(value, disabled)),
        ..._useField<T>(value => createFieldModel = value)
    }

    const set = (action: () => void) => {
        action();
        return fieldBuilder;
    }

    const create = (name: string, o: any) => isBoolean(o) || isObject(o) ? {[name]: o} : undefined

    return {
        builder: fieldBuilder,
        createModel: (): Forms.Model.Field => ({
            name,
            label,
            ...createFieldModel?.(),
            ...create("required", required),
            ...create("readonly", readonly),
            ...create("disabled", disabled)
        })
    };
};

export const useRow = <T = any>() => {
    const _fields: FieldsHelper[] = [];

    const rowBuilder: Forms.Builder.Row<T> = {
        Field: (options: (f: Forms.Builder.Field<T>) => void) => {
            const field = useField<T>();
            options(field.builder);
            _fields.push(field);
            return rowBuilder
        }
    }

    return {
        builder: rowBuilder,
        createModel: () => _fields.map(field => field.createModel())
    }
};

export const useGroup = <T = any>(name: string) => {

    const _rowsAndFields: RowsAndFieldsHelper[] = [];

    let _label: string;

    const groupBuilder: Forms.Builder.Group<T> = {
        Label: (label) => {
            _label = label;
            return groupBuilder
        },
        Row: (options) => {
            const row = useRow<T>();
            options(row.builder);
            _rowsAndFields.push(row);
            return groupBuilder
        },
        Field: (options) => {
            const field = useField<T>();
            options(field.builder);
            _rowsAndFields.push(field);
            return groupBuilder
        },
        Loop: (arrayGetter, groups) => {
            const properties: string[] = [];
            const builder = buildArrayProperty(properties);
            arrayGetter?.(<any>builder);

            const subGroup = useGroup(name + "_" + properties.join("_"));
            groups(subGroup.builder);

            _rowsAndFields.push(<any>{
                name: () => subGroup.name(),
                createModel: () => ({
                    "array": properties.join("."),
                    ...subGroup.createModel()
                })
            });
            return groupBuilder
        }
    }

    return {
        builder: groupBuilder,
        name: () => name,
        createModel: () => ({
            label: _label,
            fields: _rowsAndFields.map(rowOrField => rowOrField.createModel())
        })
    }
};


export const createFormModel = <T = any>(options?: FormModelOptions): Forms.Builder.Form<T> => {
    const _groups: GroupHelper[] = [];

    const formBuilder: Forms.Builder.Form<T> = {
        Group: (name: string, options: (g: Forms.Builder.Group<T>) => void) => {
            const group = useGroup<T>(name);
            options(group.builder);
            _groups.push(group);
            return formBuilder;
        },
        CustomGroup: (name: string, label: string, component: string) => {
            _groups.push({
                name: () => name,
                createModel: () => ({
                    label: label,
                    fields: [],
                    component: component
                })
            });
            return formBuilder;
        },
        Build: () => {
            return _groups.reduce((groups, {name, createModel}) => {
                groups[name()] = createModel();
                return groups;
            }, <Forms.Model.Form>{})
        }
    }

    return formBuilder
};