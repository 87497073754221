﻿import type {AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError, AxiosInterceptorManager} from "axios";
import {computed, readonly, ref, reactive} from "vue";
import {toApiError} from "./error";
import axios from "axios";

// @ts-ignore
import extendedXhrAdapter from "./extendedXhrAdapter";

interface Activity {
    started: number;
    status: number | undefined;
    method: string | undefined;
    duration: number | undefined;
    uploaded: number;
    downloaded: number;
    baseUrl: string | undefined;
    url: string | undefined;
    params: any;
    error: string | undefined;
    interval: any;
}

interface $$AxiosRequestConfig extends AxiosRequestConfig {
    $$: Activity
}

interface $$AxiosResponse extends AxiosResponse {
    config: $$AxiosRequestConfig;
}

interface $$AxiosError extends AxiosError {
    config: $$AxiosRequestConfig;
}

const activities = ref<Activity[]>([]);
const currentActivity = ref<Activity>();

const busyCount = ref(0);
const busy = computed(() => busyCount.value > 0);

const onStarted = (config: $$AxiosRequestConfig) => {

    config.adapter = extendedXhrAdapter;

    let activity = <Activity>config.$$;
    const now = Date.now();
    if (activity) {
        activity.started = now;
    } else {
        activity = reactive({
            started: now,
            status: undefined,
            method: config.method,
            duration: undefined,
            uploaded: 0,
            downloaded: 0,
            baseUrl: config.baseURL,
            url: config.url,
            params: config.params,
            error: undefined,
            interval: undefined
        });
        busyCount.value++;
        if (activities.value.length == 16) {
            activities.value.shift();
        }

        activities.value.push(activity);
        currentActivity.value = activity;
        config.$$ = activity;
    }

    activity.interval = setInterval(() => {
        activity.duration = Date.now() - activity.started;
    }, 500)

    const prevOnUploadProgress = config.onUploadProgress;
    config.onUploadProgress = pe => {
        activity.uploaded = pe.loaded;
        prevOnUploadProgress?.(pe);
    };

    const prevOnDownloadProgress = config.onDownloadProgress;
    config.onDownloadProgress = pe => {
        //console.log("onDownloadProgress", pe);
        activity.downloaded = pe.loaded;
        prevOnDownloadProgress?.(pe);
    };

    return config;
}

const onFinished = (config: $$AxiosRequestConfig, status: number) => {
    const activity = config?.$$;
    clearInterval(activity.interval);

    activity.status = status;
    activity.duration = Date.now() - activity.started;

    if (activity.started === currentActivity.value?.started) {
        currentActivity.value = undefined;
    }

    setTimeout(() => busyCount.value--, 50);
    return activity;
}

const onError = (axiosError: $$AxiosError) =>
    new Promise((resolve, reject) => {
        const config = axiosError.config;
        if (!config) {
            const apiError = toApiError(axiosError);
            reject(apiError);
            return;
        }
        const activity = onFinished(config, axiosError.response?.status || 500);
        const responseUid = axiosError.response?.headers["red-rsp-uid"];
        if (responseUid) {
            axios
                .get<any>(`api/error(${responseUid})`, {
                    baseURL: config.baseURL,
                    headers: {
                        "Authorization": <any>config.headers?.Authorization
                    }
                })
                .then(response => {
                    const apiError = {message: response.data.Message};
                    activity.error = apiError.message;
                    reject(apiError);
                });
        } else {
            const apiError = toApiError(axiosError);
            activity.error = apiError.message;
            reject(axiosError);
        }
    })

export const interceptAxiosActivities = (axios: AxiosInstance) => {
   
    const request = axios.interceptors.request as AxiosInterceptorManager<$$AxiosRequestConfig>;
    const response = axios.interceptors.response as AxiosInterceptorManager<$$AxiosResponse>;

    const requestId = request.use(
         config => onStarted(config)
    );

    const responseId = response.use(
        response => {
            onFinished(response.config, response.status);
            return response;
        },
        error => onError(error)
    );

    const eject = () => {
         request.eject(requestId);
         response.eject(responseId);
     }

    return {
        eject
    }
}

export const useAxiosActivities = () => {

    return {
        busyCount: readonly(busyCount),
        busy: readonly(busy),
        activities: readonly(activities),
        currentActivity: readonly(currentActivity)
    }
}