import isURLSameOrigin from "axios/lib/helpers/isURLSameOrigin";
import parseHeaders from "axios/lib/helpers/parseHeaders";
import buildFullPath from "axios/lib/core/buildFullPath";
import createError from "axios/lib/core/createError";
import buildURL from "axios/lib/helpers/buildURL";
import cookies from "axios/lib/helpers/cookies";
import Cancel from "axios/lib/cancel/Cancel";
import settle from "axios/lib/core/settle";
import defaults from "axios/lib/defaults";
import utils from "axios/lib/utils";

const extendedXhrAdapter = (config) =>
    new Promise((resolve, reject) => {

        const requestData = config.data;
        const requestHeaders = config.headers;
        const responseType = config.responseType;
        let onCanceled;
        
        const done = () => {
            config.cancelToken?.unsubscribe(onCanceled);
            config.signal?.removeEventListener("abort", onCanceled);
        };

        if (utils.isFormData(requestData)) {
            delete requestHeaders["Content-Type"]; // Let the browser set it
        }

        let response = null;
        let request = new XMLHttpRequest();
        request.timeout = config.timeout;

        if (config.auth) {
            const username = config.auth.username || "";
            const password = config.auth.password ? unescape(encodeURIComponent(config.auth.password)) : "";
            requestHeaders.Authorization = `Basic ${btoa(username + ":" + password)}`;
        }

        const fullPath = buildFullPath(config.baseURL, config.url);
        request.open(config.method.toUpperCase(), buildURL(fullPath, config.params, config.paramsSerializer), true);

        const onHeadersReceived = () => {
            response = {
                data: null,
                status: request.status,
                statusText: request.statusText,
                headers: parseHeaders(request.getAllResponseHeaders()),
                config: config,
                request: request
            };
        };

        const onLoadEnd = () => {
            if (request) {
                response.data = !responseType || responseType === "text" || responseType === "json"
                    ? request.responseText
                    : request.response;

                settle(
                    value => {
                        resolve(value);
                        done();
                    },
                    err => {
                        reject(err);
                        done();
                    },
                    response
                );

                request = null;
            }
        };

        const onNetworkError = () => {
            const axiosError = createError("Network Error", config, null, request, response)
            reject(axiosError);
            request = null;
        }

        const onAbort = () => {
            if (request) {
                const axiosError = createError("Request aborted", config, "ECONNABORTED", request)
                reject(axiosError);
                request = null;
            }
        }

        const onTimeout = () => {
            const transitional = config.transitional || defaults.transitional;

            const timeoutErrorMessage = config.timeoutErrorMessage
                ? config.timeoutErrorMessage
                : config.timeout
                    ? `timeout of ${config.timeout}ms exceeded`
                    : "timeout exceeded";

            const axiosError = createError(
                timeoutErrorMessage,
                config,
                transitional.clarifyTimeoutError ? "ETIMEDOUT" : "ECONNABORTED",
                request);

            reject(axiosError);
            request = null;

        }
        request.onreadystatechange = () => {
            if (request?.readyState === 2) {
                onHeadersReceived();
            }
        };

        request.onloadend = onLoadEnd;
        request.onabort = onAbort;
        request.onerror = onNetworkError;
        request.ontimeout = onTimeout;

        if (utils.isStandardBrowserEnv()) {
            const xsrfValue = (config.withCredentials || isURLSameOrigin(fullPath)) && config.xsrfCookieName
                ? cookies.read(config.xsrfCookieName)
                : undefined;

            if (xsrfValue) {
                requestHeaders[config.xsrfHeaderName] = xsrfValue;
            }
        }

        utils.forEach(requestHeaders, (val, key) => {
            if (typeof requestData === "undefined" && key.toLowerCase() === "content-type") {
                delete requestHeaders[key];
            } else {
                request.setRequestHeader(key, val);
            }
        });

        if (!utils.isUndefined(config.withCredentials)) {
            request.withCredentials = !!config.withCredentials;
        }

        if (responseType && responseType !== "json") {
            request.responseType = responseType;
        }

        if (typeof config.onDownloadProgress === "function") {
            request.addEventListener("progress", config.onDownloadProgress);
        }

        if (typeof config.onUploadProgress === "function" && request.upload) {
            request.upload.addEventListener("progress", config.onUploadProgress);
        }

        if (config.cancelToken || config.signal) {
            onCanceled = cancel => {
                if (request) {
                    reject(!cancel || (cancel && cancel.type) ? new Cancel("canceled") : cancel);
                    request.abort();
                    request = null;
                }
            };

            config.cancelToken && config.cancelToken.subscribe(onCanceled);
            
            if (config.signal) {
                config.signal.aborted 
                    ? onCanceled() 
                    : config.signal.addEventListener("abort", onCanceled);
            }
        }

        request.send(requestData || null);
    })


export default extendedXhrAdapter;