﻿<template>
    <li :class="listItemClass" v-if="links.length">
        <button :class="mainClass" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="mt-auto" :class="linkItem.icon" />
            <span class="w-100 text-center" v-text="name(linkItem)" />
        </button>
        <div class="dropdown-menu shadow">
            <aunoa-container>
                <div class="row">
                    <template v-for="l1 in links">
                        <div :class="colClass" v-if="isLinkVisible(l1)">
                            <h5 class="dropdown-header">
                                <span v-text="name(l1)" />
                            </h5>
                            <ul class="nav flex-column pb-4 active-marker-start">
                                <template v-for="l2 in l1.children">
                                    <li class="nav-item" v-if="isLinkVisible(l2)">
                                        <router-link
                                            active-class=""
                                            exact-active-class="active"
                                            class="nav-link"
                                            :to="link(l2)">
                                            <span v-text="name(l2)" />
                                            <i v-if="l2" class="px-2" :class="linkStateIcon(l2)" />
                                        </router-link>
                                    </li>
                                </template>
                            </ul>
                        </div>
                    </template>
                </div>
            </aunoa-container>
        </div>
    </li>
    <li :class="listItemClass" v-else>
        <router-link :to="link(linkItem)" :class="mainClass">
            <i class="mt-auto" :class="linkItem.icon" />
            <span class="w-100 text-center" v-text="name(linkItem)" />
        </router-link>
    </li>
</template>

<script lang="ts">

import type {Navigation} from "../navigation";

import {computed, defineComponent, PropType, ref, toRefs, watch} from "vue";
import {AunoaContainer, isLinkVisible, LinkItem, linkStateIcon} from "..";
import {useRouter} from "vue-router";

function getChildren(linkItem: LinkItem, balance = false): LinkItem[] {
    if (!linkItem || !linkItem.children) {
        return [];
    }
    if (!balance) {
        return linkItem.children;
    }

    const items = linkItem.children.filter(l => l.children && l.children.length > 0);
    const singleSubItems = linkItem.children.filter(l => !l.children || l.children.length === 0);
    if (singleSubItems.length > 0) {
        items.unshift({
            name: linkItem.name,
            icon: linkItem.icon,
            children: singleSubItems,
            isVisible: () => singleSubItems.some(l => l.isVisible?.())
        });
    }

    return items;
}

const hasPath = (linkItem: LinkItem, path: string): any =>
    linkItem.path === path ||
    (linkItem.children && linkItem.children.some(l => hasPath(l, path)));

export default defineComponent({
    name: "AunoaNavigationBarItem",
    components: {
        AunoaContainer
    },
    props: {
        navigation: {
            type: Object as PropType<Navigation>,
            required: true
        },
        linkItem: {
            type: Object as PropType<LinkItem>,
            required: true
        }
    },
    setup(props, {}) {
        const router = useRouter()
        const {navigation, linkItem} = toRefs(props);

        const name = (item: LinkItem) => navigation.value.nameFunc
            ? navigation.value.nameFunc(item)
            : item.name;

        const links = computed(() => getChildren(linkItem.value, true));
        const colClass = computed(() => links.value.length >= 3
            ? "col-12 col-lg-8 col-xl-6 col-xxxl-4"
            : "col-12");

        const link = (item: LinkItem) => ({path: item.path});

        const isActive = ref(false);
        watch([linkItem, router.currentRoute], ([l, r]) =>
            setTimeout(() => isActive.value = hasPath(l, r?.path), 50), {immediate: true});

        const listItemClass = ["nav-item dropdown", "has-mega-menu"];
        const mainClass = computed(() => [
            "nav-link",
            "d-flex",
            "flex-column",
            "align-items-center",
            "dropdown-toggle",
            "w-100",
            isActive.value ? "active" : undefined
        ].filter(Boolean));

        return {
            name,
            links,
            colClass,
            isLinkVisible,
            linkStateIcon,
            listItemClass,
            mainClass,
            link,
            isActive
        }

    }
});

</script>

