﻿<template>
    <component class="deco deco-cuurency-amount" :is="as">
        <span v-for="s in getCurrencyFormatSegments(amount, currency)" :class="s.class">{{s.text}}</span>
    </component>
</template>

<script lang="ts">

import {defineComponent} from "vue";
import {getCurrencyFormatSegments} from "../implementations/decoration/currencyAmountDecoration";

export default defineComponent({
    name: "AunoaCurrencyAmount",
    props: {
        as: {
            type: String,
            default: "span"
        },
        amount: {
            type: Number,
            default: undefined
        },
        currency: {
            type: String,
            default: "EUR"
        }
    },
    setup() {
        return {
            getCurrencyFormatSegments
        }
    }
});


</script>
