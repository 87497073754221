<template>
    <aunoa-entity-editor
        v-if="mode==='view' || mode==='edit'"
        :read-only="mode!=='edit'"
        :keep-open-on-update="keepOpenOnUpdate"
        :scroll-to-top="scrollEditorToTop"
        :entity="entity"
        :entity-model="entityModel"
        :form-model="formModel"
        :lookup-factories="lookupFactories"
        :crud-service="crudService"
        @update="onUpdate"
        @close="onEditorClose"
    />
    <template v-else>
        <aunoa-nav class="px-table-cell" :class="navClass" :grip="true">
            <template v-if="updatePermission && updateSupported && formModel && !options.editDisabled">
                <aunoa-nav-item
                    :text="t('Aunoa.Command.Edit')"
                    @click="onEditInline" />
            </template>
            <template v-else-if="formModel">
                <!-- LGB: Why we require @@ here? -->
                <aunoa-nav-item
                    :text="t('@@Aunoa.View.Command.View')"
                    @click="onViewInline" />
            </template>
            <template v-if="options.details && options.details.navItemsComponent">
                <component
                    :is="options.details.navItemsComponent"
                    :entity="entity"
                    :state="options.details?.state"
                />
            </template>
            <slot
                v-else
                name="detail-nav-items"
                :entity="entity"
                :ensureUpToDate="ensureUpToDate"
                :currentQuery="currentQuery"
                :remove="remove"
                :close="close"
            />
            <aunoa-nav-item v-if="canMore" icon="more" :title="t('Aunoa.Command.More')">
                <template v-if="more.canEditInNewTab">
                    <aunoa-dropdown-item
                        icon="edit"
                        :text="t('Aunoa.Command.EditInNewTab')"
                        @click="onEditInNewTab"
                        disabled
                    />
                    <aunoa-dropdown-divider />
                </template>
                <template v-if="more.canAddToFavorites">
                    <aunoa-dropdown-item
                        icon="fad"
                        :text="t('Aunoa.Command.AddToFavorites')"
                        disabled
                        @click="onNothingYet"
                    />
                </template>
                <template v-if="more.canReload">
                    <aunoa-dropdown-item
                        icon="fad"
                        :text="t('Aunoa.Command.ReloadFromDatabase')"
                        @click="onReload"
                    />
                </template>
                <slot
                    name="more"
                    :entity="entity" />
                <template v-if="canShare">
                    <aunoa-dropdown-divider />
                    <aunoa-dropdown-item
                        icon="share"
                        :text="t('Aunoa.Command.Share')">
                        <aunoa-dropdown-item
                            icon="fal fa-link"
                            :text="t('Aunoa.Command.CopyLink')"
                            @click="onCopyLinkToClipboard"
                        />
                        <aunoa-dropdown-item
                            icon="fab fa-whatsapp"
                            :text="'WhatsApp'"
                            :href="getWhatsApp()"
                        />
                    </aunoa-dropdown-item>
                </template>
                <template v-if="more.canDuplicate || more.canCopyToClipboard">
                    <aunoa-dropdown-divider />
                    <aunoa-dropdown-item
                        v-if="more.canDuplicate"
                        icon="duplicate"
                        :text="t('Aunoa.Command.Duplicate')"
                        @click="onDuplicate"
                    />
                    <aunoa-dropdown-item
                        v-if="more.canCopyToClipboard"
                        icon="fad"
                        :text="t('Aunoa.Command.Clipboard.Copy')"
                        end-text=".json"
                        @click="onCopyToClipboard"
                    />
                </template>
                <template v-if="more.canPrint">
                    <aunoa-dropdown-divider />
                    <aunoa-dropdown-item
                        icon="print"
                        :text="t('Aunoa.Command.Print')"
                        @click="onNothingYet"
                    />
                </template>
                <template v-if="more.canDelete">
                    <aunoa-dropdown-divider />
                    <aunoa-dropdown-item
                        icon="delete"
                        :text="t('Aunoa.Command.Delete')"
                        :must-confirm="true"
                        @click="onDelete"
                    />
                </template>
            </aunoa-nav-item>
            <aunoa-nav-item item-class="ms-auto" icon="far fa-times" @click="onClose" />
        </aunoa-nav>
        <div class="dashed-top" v-if="detailsMode">
            <div class="details" v-if="detailsMode==='fetching'">
                <i class="fad fa-spinner fa-pulse" />
                <span class="ps-2">Fetching Details&hellip;</span>
            </div>
            <div class="details" v-else-if="detailsMode==='faulted'">
                Error
            </div>
            <aunoa-tab-control
                v-else-if="detailsMode==='ready'"
                :can-toggle-mode="true"
                class="form-inline inner-border-dashed"
                nav-position="start"
                active-style="line"
                nav-class="px-table-cell mw-r12"
                content-class="mh-r16"
                pane-class="container-fluid p-0 pb-3 ms-0"
            >
                <aunoa-tab-pane link-text="Details">
                    <template v-for="dm in detailsModel">
                        <table class="table table-small table-data-grid table-hack sub-table bg-body mb-3 caption-top" v-if="dm.grid">
                            <caption v-if="dm.title" v-text="ensureTextTranslated(dm.title)" />
                            <aunoa-data-head :grid-model="dm" tr-class="kookoo bg-subheader" column-class="sticky-top sub-sticky bg-subheader" />
                            <tbody>
                            <aunoa-data-row
                                v-for="entity in getDetailEntities(dm.grid)"
                                :query-context="queryContext"
                                :grid-model="dm"
                                :entity="entity"
                            />
                            </tbody>
                        </table>
                    </template>
                </aunoa-tab-pane>
                <aunoa-tab-pane link-text="JSON" v-if="false">
                    <pre style="font-size: 11px" v-if="true">{{ JSON.stringify(details, null, 4) }}</pre>
                </aunoa-tab-pane>
            </aunoa-tab-control>
        </div>
        <div class="details bg-body dashed-top" v-else-if="options.details">
            <component
                :is="options.details.component"
                :entity="entity"
                :state="options.details?.state"
            />
        </div>
        <div class="details bg-body dashed-top" :class="{'bg-loading': detailsBusy}" v-else-if="$slots.details">
            <slot name="details" :entity="entity" :startDetailsBusy="startDetailsBusy" />
        </div>
    </template>
</template>


<script lang="ts">

import type {PropType} from "vue";
import type {DataGrid} from "../DataGridOptions";
import type {LookupFactories, CrudService, PromisableEvent, Forms, Entities, Tables} from "bootstrap-aunoa";

import {useDataGridI18n} from "../implementations/useDataGridI18n";
import {useCrudService} from "../implementations/useCrudService";
import AunoaEntityEditor from "./AunoaEntityEditor.vue";
import {defineComponent, ref, toRefs, watch, computed} from "vue";
import {useRoute} from "vue-router";
import AunoaDataHead from "../components/AunoaDataHead.vue";
import AunoaDataRow from "../components/AunoaDataRow";

import {
    AunoaDropdownDivider,
    AunoaDropdownItem,
    AunoaNav,
    AunoaNavItem,
    AunoaTabControl,
    AunoaTabPane,
    copyToClipboard,
    getValueFromPropertyPath, useAunoaI18n, useToasts, useClipboardData, useTestMode, isPromise,
} from "bootstrap-aunoa";
import {getDuration} from "bootstrap-aunoa/src/utils/dateAndTime";


type Mode = "details" | "view" | "edit";
type DetailsMode = "fetching" | "ready" | "faulted" | undefined;

const ensureArray = (value: any) => Array.isArray(value)
    ? value
    : [value].filter(Boolean);

const NOT_MODIFIED = 304;

export default defineComponent({
    name: "AunoaDataGridInline",
    components: {
        AunoaDropdownItem,
        AunoaDropdownDivider,
        AunoaNav,
        AunoaNavItem,
        AunoaEntityEditor,
        AunoaTabControl,
        AunoaTabPane,
        AunoaDataHead,
        AunoaDataRow
    },
    props: {
        mode: {
            type: String as PropType<Mode>,
            default: "details"
        },
        closeOnEditorClose: {
            type: Boolean,
            default: false
        },
        keepOpenOnUpdate: {
            type: Boolean,
            default: false
        },
        queryContext: {
            type: Object,
            default: undefined,
        },
        currentQuery: {
            type: Object,
            default: undefined,
        },
        entity: {
            type: Object,
            required: true
        },
        detailsModel: {
            type: Object as PropType<Tables.Model.Details>,
            default: undefined
        },

        entityModel: {
            type: Array as PropType<Entities.Model.Property[]>,
            default: undefined
        },
        formModel: {
            type: Object as PropType<Forms.Model.Form>
        },
        lookupFactories: {
            type: Object as PropType<LookupFactories>,
            default: undefined
        },

        colspan: {
            type: Number,
            default: 1
        },
        crudService: {
            type: Object as PropType<CrudService>,
            default: undefined
        },
        options: {
            type: Object as PropType<DataGrid.Options>,
            default: undefined
        },

        createPermission: {
            type: Boolean,
            default: true
        },
        updatePermission: {
            type: Boolean,
            default: true
        },
        deletePermission: {
            type: Boolean,
            default: true
        },
        scrollEditorToTop: {
            type: Boolean,
            default: false
        },

        canShare: {
            type: Boolean,
            default: true
        },
        canCopyToClipboard: {
            type: Boolean,
            default: true
        }
    },
    emits: ["update:mode", "update", "remove", "close", "duplicate"],
    setup(props, {emit}) {

        const {
            canCopyToClipboard,
            deletePermission,
            createPermission,
            updatePermission,
            formModel,
            canShare,
            options,
            mode: _mode,
            entity: propsEntity,
            detailsModel: _detailsModel,
            crudService
        } = toRefs(props);

        const {path} = useRoute();
        const {t} = useDataGridI18n();
        const {testMode} = useTestMode();
        const {addSuccessToast, addErrorToast} = useToasts()
        const service = useCrudService(crudService);
        const {ensureTextTranslated} = useAunoaI18n();

        const mode = ref<Mode>("details");
        const detailsMode = ref<DetailsMode>(undefined);
        const _details = ref(undefined);
        const navClass = ref<any>("nav-fade-in");


        watch(_mode, m => mode.value = m, {immediate: true});
        watch(propsEntity, entity => {
            if (entity?.$$?.forceEdit) {
                mode.value = "edit";
            }
        }, {immediate: true});

        const _entity = ref();
        watch(propsEntity, entity => _entity.value = entity, {immediate: true});

        watch([_mode, _entity, _detailsModel], ([m, entity, detailsModel]) => {
            if (m === "details" && entity && detailsModel && service.readDetailsSupported) {
                const key = service.getKey(entity);
                const etag = service.getETag(entity);
                detailsMode.value = "fetching";
                _details.value = undefined;
                service
                    .readDetails(key, entity, etag)
                    .then(details => {
                        _details.value = details;
                        detailsMode.value = "ready";
                    })
                    .catch(error => {
                        detailsMode.value = "faulted";
                    });
            } else {
                detailsMode.value = undefined;
            }
        }, {immediate: true});

        const close = (entity: any) => emit("close", entity);
        const remove = (entity: any) => emit("remove", entity);

        const getSingleEntityUrl = () =>
            `${window.location.origin}${path}?$k=${btoa(service.getKey(_entity.value))}`

        const getWhatsApp = () =>
            `whatsapp://send?text=${getSingleEntityUrl()}`

        const setMode = (newMode: Mode) => {
            mode.value = newMode;
            emit("update:mode", newMode);
        }

        const getDetailEntities = (propertyPath: string) =>
            ensureArray(getValueFromPropertyPath({
                ...props.queryContext,
                Entity: _details.value,
            }, propertyPath));

        const closeAnimated = (entity: any) => {
            navClass.value = undefined;
            setTimeout(() => {
                navClass.value = "nav-fade-out";
                setTimeout(() => close(entity), 200);
            }, 10);
        }

        const ensureUpToDate = () =>
            new Promise<any>((resolve, reject) => {
                const entity = _entity.value;
                if (service.readSupported.value) {
                    const key = service.getKey(entity);
                    const etag = service.getETag(entity);
                    service
                        .read(key, etag, entity)
                        .then(newEntity => {
                            _entity.value = newEntity;
                            emit("update", _entity.value);
                            resolve(newEntity);
                        })
                        .catch(error => {
                            if (error.httpStatus === NOT_MODIFIED) {
                                resolve(entity);
                            } else {
                                reject(error);
                            }
                        });
                } else {
                    resolve(entity);
                }
            })

        const prepareView = async (mode:Mode) => {
            await ensureUpToDate();
            setMode(mode);
        }

        const prepareDuplicate = async () => {
            await ensureUpToDate();
            
            const json = JSON.stringify(_entity.value, null, 4);
            let duplicate = JSON.parse(json);
            
            duplicate = options.value.convertToClipboard?.(duplicate) || duplicate;

            const newEntity = service.emptySupported.value
                ? await service.empty(props.currentQuery)
                : undefined;

            duplicate = options.value.convertFromClipboard?.(duplicate, newEntity) || duplicate;
            
            emit("duplicate", duplicate);
        }

        const onNothingYet = () => {
            console.log("onNothingYet");
        };

        const {write: writeEntity} = useClipboardData();

        const detailsBusy = ref(false);
        const startDetailsBusy = <T = any>(promise: Promise<T>, minDuration: (number | boolean | string) = true) => {
            if (!isPromise(promise)) {
                return Promise.resolve();
            }
            detailsBusy.value = true;
            const duration = getDuration(minDuration, 333);
            Promise
                .all([
                    new Promise<void>(resolve => promise.catch().finally(resolve)),
                    new Promise<void>(resolve => setTimeout(resolve, duration))
                ])
                .finally(() => detailsBusy.value = false);
        }

        const onCopyToClipboard = (event: PromisableEvent) =>
            event
                .setPromise(ensureUpToDate())
                .then(entity => {

                    entity = _details.value || entity;
                    entity = options.value.convertToClipboard?.(entity) || entity;

                    writeEntity(entity, options.value.clipboardType);
                    addSuccessToast(t("Aunoa.Operation.ClipboardCopy.Success"));
                })

        const onCopyLinkToClipboard = () => {
            const link = getSingleEntityUrl();
            copyToClipboard(link);
        }

        const onViewInline = (event?: PromisableEvent) => event
            ? event.setPromise(prepareView("view"), {delay: false, minDuration: false, successDuration: false})
            : prepareView("view");

        const onEditInline = (event?: PromisableEvent) => event
            ? event.setPromise(prepareView("edit"), {delay: false, minDuration: false, successDuration: false})
            : prepareView("edit");

        const onEditInNewTab = (event: PromisableEvent) => console.log("Not Implemented");
        const onDuplicate = (event: PromisableEvent) => event.setPromise(prepareDuplicate());
        const onReload = (event: PromisableEvent) => event.setPromise(ensureUpToDate());

        const onEditorClose = (entity: any) => {
            setMode("details");
            if (props.closeOnEditorClose) {
                close(entity);
            }
        };

        const onUpdate = (entity: any, mode: Mode = "details") => {
            if (!props.keepOpenOnUpdate) {
                setMode(mode);
            }
            emit("update", entity)
        };


        const onDelete = (event: PromisableEvent) => {
            const entity = _entity.value;
            const key = service.getKey(entity);
            const etag = service.getETag(entity);
            const promise = service.delete(key, etag);
            event
                .setPromise(promise)
                .then(() => remove(entity))
                .catch(addErrorToast);
        }

        const onClose = () => closeAnimated(_entity.value);

        const more = computed(() => ({
            canEditInNewTab: testMode.value && updatePermission.value && service.updateSupported.value && !!formModel.value,
            canAddToFavorites: testMode.value,
            canReload: service.readSupported.value,
            canShare: canShare.value && service.getKeySupported.value,
            canDuplicate: createPermission.value && service.createSupported.value && !!formModel.value && !options.value.addDisabled && !options.value.duplicateDisabled,
            canCopyToClipboard: canCopyToClipboard.value,
            canPrint: !!options.value.print?.print,
            canDelete: deletePermission.value && service.deleteSupported.value && !options.value.deleteDisabled,
        }));

        const canMore = computed(() => Object.entries(more.value).reduce((b, [, v]) => b || v, false));

        return {
            more,
            canMore,
            ensureTextTranslated,
            navClass,
            t,
            mode,
            testMode,
            entity: _entity,
            detailsMode,
            details: _details,
            detailsBusy,
            startDetailsBusy,
            ensureUpToDate,
            getDetailEntities,
            getSingleEntityUrl,
            getWhatsApp,
            close,
            remove,
            onNothingYet,
            onViewInline,
            onEditInline,
            onEditInNewTab,
            onCopyToClipboard,
            onCopyLinkToClipboard,
            onDuplicate,
            onReload,
            onUpdate,
            onDelete,
            onEditorClose,
            onClose,
            ...service,
        }
    }

});
</script>

<!--

    entity {}
    
    entityAttend: {
        initialEntity,
        entity,
        
    }


-->
